// Customizable Area Start
import { createRef } from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall } from "../../../components/src/NavigationRouteWrapper/helpers";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

export interface IFile {
  file_url: string;
  file_name: string;
}

export interface IDetails {
  amenities: { type: string }[];
  apartment: { name: string }[];
  campsite: { name: string }[];
  hookups: { hookups_type: string }[];
  horse_accommodations: { type: string }[];
}

export interface IReview {
  attributes: {
    description: string;
    rating: number;
    created_date: string;
    reviewed_by: {
      first_name: string;
      last_name: string;
      profile_photo: {
        url: string;
      }
    }
  }
}

interface IData {
  full_name: string;
  membership: string;
  profile_photo: {
    url: null | string;
  };
  reviews_count: number;
  average_rating_count: number;
  bookings_count: number;
  listings_count: number;
  listing: any;
  reviews: {
    data: IReview[];
  }
}

interface S {
  // Customizable Area Start
  loading: boolean;
  isLoading: boolean;
  isOpen: boolean;
  data: IData | null;
  role: string | null;
  listingData: any;
  listArr: any;
  currentPage: number;
  isAllListingVisible: boolean;
  pagination: {
    current_page: number;
    total_pages: number;
    total_count: number;
  }
  // Customizable Area End
}

interface SS {
  id: string;
}

export default class ViewProfileController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getDetailsApiCallId: string = "";
  getListingApiCallId: string = "";
  scrollRef: React.RefObject<HTMLDivElement> = createRef();
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      loading: false,
      isLoading: false,
      isOpen: false,
      data: null,
      currentPage: 1,
      listingData: [],
      listArr: [],
      isAllListingVisible: false,
      pagination: {
        current_page: 1,
        total_pages: 0,
        total_count: 0,
      },
      role: localStorage.getItem("role")
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getDetailsApiCallId) {
        if (responseJson.data) {
          this.setState({ data: responseJson.data.attributes });
        }
        this.setState({ loading: false });
      } else if (apiRequestCallId === this.getListingApiCallId) {
        if (responseJson.data) {
          if (this.state.isAllListingVisible) {
            this.setState({ listingData: responseJson.data });
          } else {
            this.setState({ listArr: responseJson.data });
          }
          this.setState({
            pagination: responseJson.pagination,
            currentPage: responseJson.pagination.current_page ?? this.state.currentPage,
          });
        } else if (Array.isArray(responseJson.error)) {
          this.setState({ currentPage: 1, listingData: [] });
        }
        this.setState({ loading: false, isLoading: false });
      }
    }
  }

  async componentDidMount() {
    this.getDetails();
    if (this.state.role === "host") {
      this.setState({ isLoading: true }, () => {
        this.getListing(1);
      });
    }
  }

  onRoleChange = (role: string) => {
    this.setState({ role });
    if (role === "host") {
      this.setState({ isLoading: true }, () => {
        this.getListing(1);
      });
    } else {
      this.setState({ listArr: [] });
    }
  }

  componentDidUpdate(prevProps: Props, prevState: S) {
    if ((prevState.loading !== this.state.loading) && !this.state.isLoading) {
      this.scrollToTop();
    }
  }

  openDrawer = () => {
    this.setState({ isOpen: true });
  }

  scrollToTop = () => {
    if (this.scrollRef.current) {
      this.scrollRef.current.scrollIntoView();
    }
  }

  closeDrawer = () => {
    this.setState({ isOpen: false });
  }

  openAllListing = () => {
    this.setState({ isAllListingVisible: true, listingData: [] }, () => {
      this.getListing(1);
    });
  }

  onGoBack = () => {
    this.setState({
      currentPage: 1,
      listingData: [],
      isAllListingVisible: false,
    });
  }

  onChangePage = (currentPage: number) => {
    if (currentPage === this.state.currentPage) return;
    this.setState({ isLoading: true }, () => {
      this.getListing(currentPage);
    });
  }

  getListing = async (currentPage: number) => {
    this.setState({ currentPage, loading: true });
    const header = {
      token: localStorage.getItem("token")
    }
    this.getListingApiCallId = await apiCall({
      header,
      method: "GET",
      endPoint: `/bx_block_bulk_uploading/listings?page=${currentPage}&per_page=${this.state.isAllListingVisible ? 8 : 3}`,
    });
  }

  getDetails = async () => {
    this.setState({ loading: true });
    const header = {
      token: localStorage.getItem("token")
    }
    this.getDetailsApiCallId = await apiCall({
      header,
      method: "GET",
      endPoint: `/account_block/accounts/user_profile`,
    });
  }
  // Customizable Area End
}
