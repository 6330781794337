// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall } from "../../../components/src/NavigationRouteWrapper/helpers";
import moment from "moment";
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface IFile {
  file_url: string;
  file_name: string;
}

export interface IDetails {
  amenities: { type: string }[];
  apartment: { name: string }[];
  campsite: { name: string }[];
  hookups: { hookups_type: string }[];
  horse_accommodations: { type: string }[];
}

interface IAmitiesList {
  id: string;
  attributes: {
    amenity_name: string;
  }
}

interface S {
  // Customizable Area Start
  loading: boolean;
  isOpen: boolean;
  data: any[];
  isNotFound: boolean;
  isMapView: boolean;
  isSearchClicked: boolean;
  currentPage: number;
  perPage: number;
  location: string;
  checkIn: string | null;
  checkOut: string | null;
  guests: string;
  horses: string;
  housingOption: string;
  horseStabling: string[];
  min: number;
  max: number;
  amenities: string[];
  amitiesList: IAmitiesList[];
  oldFilter: string;
  pagination: {
    current_page: number,
    total_pages: number,
  }
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export const MAX_VALUE = 2000;

export default class AdvancedSearchPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  advanceSearchApiCallId: string = "";
  getAmitiesListApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      isOpen: false,
      isNotFound: false,
      data: [],
      isMapView: false,
      isSearchClicked: false,
      currentPage: 1,
      perPage: 8,
      housingOption: "",
      horseStabling: [],
      min: 0,
      max: MAX_VALUE,
      amenities: [],
      amitiesList: [],
      oldFilter: "",
      pagination: {
        current_page: 1,
        total_pages: 1,
      },
      guests: localStorage.getItem("guests") ?? "",
      horses: localStorage.getItem("horses") ?? "",
      location: localStorage.getItem("location") ?? "",
      checkIn: localStorage.getItem("checkIn") ? moment(localStorage.getItem("checkIn")).format("DD-MM-YYYY") : null,
      checkOut: localStorage.getItem("checkOut") ? moment(localStorage.getItem("checkOut")).format("DD-MM-YYYY") : null,
      // Customizable Area End
    };
    // Customizable Area Start
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getAmitiesListApiCallId) {
        if (responseJson.data) {
          this.setState({ amitiesList: responseJson.data, isOpen: true });
        }
        this.setState({ loading: false });
      } else {
        this.apiRequestHandling(apiRequestCallId, responseJson);
      }
    }
  }

  apiRequestHandling = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.advanceSearchApiCallId) {
      if (responseJson.data) {
        this.setState({
          isNotFound: false,
          isSearchClicked: false,
          pagination: responseJson.pagination,
          currentPage: responseJson.pagination.current_page ?? this.state.currentPage,
          data: this.state.isMapView && !this.state.isSearchClicked ? [...this.state.data, ...responseJson.data] : [...responseJson.data],
        });
      } else if (responseJson.message && Array.isArray(responseJson.message)) {
        const isNotFound = responseJson.message.some(
          (item: { Listing: { message: string; }; }) => {
            return item.Listing?.message === "No listings match your search. Please try different search parameters."
          });
        this.setState({ isNotFound, currentPage: 0, data: [] });
      }
      this.setState({ loading: false });
    }
  }

  async componentDidMount() {
    this.getList(1);
  }

  handleHashChange = () => {
    const hash = window.location.hash;
    if (!hash) {
      this.setState({ isMapView: false });
    }
  };

  openMapView = () => {
    this.setState({ isMapView: true, data: [] });
    this.getList(1);
  }

  closeMapView = () => {
    this.setState({ isMapView: false });
    this.getList(1);
  }

  onFilterClick = () => {
    const oldFilter = JSON.stringify({
      min: this.state.min,
      max: this.state.max,
      amenities: this.state.amenities,
      housingOption: this.state.housingOption,
      horseStabling: this.state.horseStabling
    });
    if (this.state.amitiesList.length > 0) {
      this.setState({ isOpen: true });
    } else {
      this.getAmenitiesList();
    }
    this.setState({ oldFilter });
  }

  onFilterClose = () => {
    const oldFilter = JSON.parse(this.state.oldFilter);
    this.setState({
      isOpen: false,
      oldFilter: "",
      min: oldFilter["min"],
      max: oldFilter["max"],
      amenities: oldFilter["amenities"],
      housingOption: oldFilter["housingOption"],
      horseStabling: oldFilter["horseStabling"],
    });
  }

  getFilterCount = () => {
    return [
      this.state.housingOption,
      this.state.horseStabling.length > 0,
      this.state.min !== 0 || this.state.max !== MAX_VALUE,
      this.state.amenities.length > 0
    ].filter(Boolean).length;
  }

  onChangeHousingOption = (housingOption: string) => {
    this.setState({
      housingOption: this.state.housingOption === housingOption ? "" : housingOption
    });
  }

  onChangeHorseStabling = (stabling: string) => {
    let horseStabling = [...this.state.horseStabling];
    horseStabling = horseStabling.includes(stabling) ? horseStabling.filter(item => item !== stabling) : [...horseStabling, stabling]
    this.setState({ horseStabling });
  }

  onApplyFilter = () => {
    this.setState({ isOpen: false });
    this.getList(1);
  }

  onRemoveFilter = () => {
    this.setState({
      housingOption: "",
      horseStabling: [],
      min: 0,
      max: MAX_VALUE,
      isOpen: false,
      amenities: []
    }, () => {
      this.getList(1);
    });
  }

  onChangeAmenities = (label: string) => {
    let amenities = [...this.state.amenities];
    amenities = amenities.includes(label) ? amenities.filter(item => item !== label) : [...amenities, label]
    this.setState({ amenities });
  }

  onSearch = (location: string, checkIn: Date | null, checkOut: Date | null, guests: string, horses: string) => {
    this.setState({
      location,
      guests,
      horses,
      isSearchClicked: true,
      checkIn: checkIn ? moment(checkIn).format("DD-MM-YYYY") : null,
      checkOut: checkOut ? moment(checkOut).format("DD-MM-YYYY") : null,
    }, () => {
      this.getList(1);
    });
  }

  onChangePage = (currentPage: number) => {
    if (currentPage === this.state.currentPage) return;
    this.getList(currentPage);
  }

  getAmenitiesList = async () => {
    this.setState({ loading: true });
    this.getAmitiesListApiCallId = await apiCall({
      method: "GET",
      endPoint: `/bx_block_bulk_uploading/amenities`,
    });
  }

  getList = async (currentPage: number) => {
    this.setState({ currentPage, loading: true });

    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    }

    const params = new URLSearchParams();
    let { location, checkIn, checkOut, guests, horses, housingOption, horseStabling, min, max, amenities, perPage } = this.state;
    if (max < min) {
      [min, max] = [max, min];
    }
    if (location) params.append("location", location);
    if (checkIn) params.append("start_date", checkIn);
    if (checkOut) params.append("end_date", checkOut);
    if (guests) params.append("no_of_guest", guests);
    if (horses) params.append("no_of_horse", horses);
    if (housingOption) params.append("housing_type", housingOption);
    if (horseStabling.length) params.append("horse_stall_type", `[${horseStabling.join(",")}]`);
    if (min) params.append("min_price", min.toString());
    if (max) params.append("max_price", max.toString());
    if (amenities.length) params.append("amenities", `[${amenities.join(",")}]`);
    if (currentPage) params.append("page", currentPage.toString());
    if (perPage) params.append("per_page", perPage.toString());

    this.advanceSearchApiCallId = await apiCall({
      header: header,
      method: "GET",
      endPoint: `bx_block_advanced_search/search/search?${params.toString()}`,
    });
  }
  goToDetails = (item:any) => {
    const productId=item.id;
    this.props.navigation.navigate?.("ProductDescription",{path:{productId}});  }
  // Customizable Area End
}