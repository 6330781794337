import React from "react";
import { Modal, Fade, Backdrop, CircularProgress } from "@mui/material";
import { styled } from "@mui/styles";

interface myProps {
  loading: boolean;
}

export default function Loader(props: myProps) {
  return <LoaderStyle
    disableAutoFocus={true}
    open={props.loading}
    closeAfterTransition
    slots={{ backdrop: Backdrop }}
    slotProps={{ backdrop: { timeout: 500 } }}
  >
    <Fade in={props.loading}>
      <div className="circular-loading">
        <CircularProgress size={40} sx={{ color: "#000" }} />
      </div>
    </Fade>
  </LoaderStyle>
}

const LoaderStyle = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  "& .MuiBackdrop-root": {
    opacity: "0.7 !important"
  },
  "& .circular-loading": {
    backgroundColor: "transparent",
    width: "auto",
    height: "auto",
  }
});