import React, { Component } from "react";

export default class TruncatedText extends Component<{ text: string; maxLength?: number }, { expanded: boolean }> {
  static defaultProps = {
    maxLength: 30,
  };

  constructor(props: { text: string; maxLength?: number }) {
    super(props);
    this.state = {
      expanded: false,
    };
  }

  toggleExpanded = () => {
    this.setState((prevState) => ({ expanded: !prevState.expanded }));
  };

  render() {
    const { text, maxLength } = this.props;
    const { expanded } = this.state;

    if (text.length <= maxLength!) {
      return text;
    }

    return (
      <>
        {expanded ? text : text.substring(0, maxLength!) + "... "}
        <span onClick={this.toggleExpanded}>
          {expanded ? "See Less" : "See More"}
        </span>
      </>
    );
  }
}