import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { apiCall } from "../../../components/src/NavigationRouteWrapper/helpers";
export interface IFile {
  file_url: string;
  file_name: string;
}

export interface IDetails {
  amenities: { type: string }[];
  apartment: { name: string }[];
  campsite: { name: string }[];
  hookups: { hookups_type: string }[];
  horse_accommodations: { type: string }[];
}

export interface IReason {
  attributes: {
    titles: string;
    descriptions: string;
    photo: {
      url: string;
    };
  }
}

export interface ICities {
  city_name: string;
  image_url: string;
  static_stay: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  isLoading: boolean;
  hotelLoading: boolean;
  data: any;
  cityData: any;
  currentPage: number;
  listData: any[];
  pagination: {
    current_page: number;
    total_pages: number;
    total_count: number;
  }
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      loading: false,
      isLoading: false,
      hotelLoading: false,
      data: null,
      cityData: null,
      listData: [],
      currentPage: 1,
      pagination: {
        current_page: 1,
        total_pages: 0,
        total_count: 0,
      },
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getDetailsApiCallId) {
        if (responseJson.headers) {
          this.setState({ data: responseJson });
        }
        this.setState({ loading: false });
      }
      if (apiRequestCallId === this.getCitiesApiCallId) {
        if (responseJson.city_names) {
          this.setState({ cityData: responseJson });
        }
        this.setState({ isLoading: false });
      } else {
        this.handleResponse(apiRequestCallId, responseJson);
      }
      // Customizable Area End
    }
  }

  // Customizable Area Start
  handleResponse = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.getHotelListApiCallId) {
      if (responseJson.data) {
        this.setState({
          listData: responseJson.data,
          pagination: responseJson.pagination,
          currentPage: responseJson.pagination.current_page ?? this.state.currentPage,
        });
      } else if (responseJson.message === "No Top Listing exist.") {
        this.setState({ currentPage: 1, listData: [] });
      }
      this.setState({ hotelLoading: false });
    }
  }

  getDetailsApiCallId: string = "";
  getCitiesApiCallId: string = "";
  getHotelListApiCallId: string = "";

  async componentDidMount() {
    this.getPageDetails();
    this.getCities();
    this.getHotelList(1);
  }

  openLink = (url: string) => {
    if (url) {
      window.open(url, "_blank", "noopener,noreferrer");
    }
  }

  onSearch = () => {
    this.props.navigation.navigate("AdvancedSearch");
  }

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  getHotelList = async (currentPage: number) => {
    this.setState({ currentPage, hotelLoading: true });
    this.getHotelListApiCallId = await apiCall({
      method: "GET",
      endPoint: `/bx_block_bulk_uploading/amenities/get_top_listing?page=${currentPage}&per_page=4`,
    });
  }

  getCities = async () => {
    this.setState({ isLoading: true });
    this.getCitiesApiCallId = await apiCall({
      method: "GET",
      endPoint: `/bx_block_bulk_uploading/amenities/city_details`,
    });
  }

  getPageDetails = async () => {
    this.setState({ loading: true });
    this.getDetailsApiCallId = await apiCall({
      method: "GET",
      endPoint: `/bx_block_bulk_uploading/amenities/landing_page`,
    });
  }

  goToProductDetails = (item: any) => {
    const productId = item.id;
    this.props.navigation?.navigate("ProductDescription", { path: { productId } });
  }

  handleNavigate = (route: string) => {
    const routeMsg = new Message(getName(MessageEnum.NavigationMessage));
    routeMsg.addData(getName(MessageEnum.NavigationTargetMessage), route);
    routeMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(routeMsg);
  };
  // Customizable Area End
}
