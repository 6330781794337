// Customizable Area Start
import { createRef } from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { apiCall } from "../../../../components/src/NavigationRouteWrapper/helpers";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

export interface IFile {
  file_url: string;
  file_name: string;
}

export interface IDetails {
  amenities: { type: string }[];
  apartment: { name: string }[];
  campsite: { name: string }[];
  hookups: { hookups_type: string }[];
  horse_accommodations: { type: string }[];
}

export interface IReview {
  attributes: {
    description: string;
    rating: number;
    created_date: string;
    reviewed_by: {
      first_name: string;
      last_name: string;
      profile_photo: {
        url: string;
      };
    };
  };
}

interface IData {
  full_name: string;
  membership: string;
  profile_photo: {
    url: null | string;
  };
  reviews_count: number;
  average_rating_count: number;
  bookings_count: number;
  listings_count: number;
  listing: any;
  reviews: {
    data: IReview[];
  };
}
interface ReportConfig {
  report_type: string;
  reportable_type: string;
  type: string;
  reportableId: string | undefined;
}
interface S {
  // Customizable Area Start
  loading: boolean;
  isLoading: boolean;
  isOpen: boolean;
  data: IData | null;
  role: string | null;
  listingData: any;
  listArr: any;
  currentPage: number;
  isAllListingVisible: boolean;
  pagination: {
    current_page: number;
    total_pages: number;
    total_count: number;
  };
  anchorEl: HTMLElement | null;
  openReport: boolean;
  reportConfig: ReportConfig;
  viewHostID: string | undefined;
  openBlockUser: boolean;
  // Customizable Area End
}

interface SS {
  id: string;
}

export default class ViewProfileWithReportBlockController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDetailsApiCallId: string = "";
  getListingApiCallId: string = "";
  scrollRef: React.RefObject<HTMLDivElement> = createRef();
  blockUserApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      loading: false,
      isLoading: false,
      isOpen: false,
      data: null,
      currentPage: 1,
      listingData: [],
      listArr: [],
      isAllListingVisible: false,
      pagination: {
        current_page: 1,
        total_pages: 0,
        total_count: 0,
      },
      role: localStorage.getItem("role"),
      anchorEl: null,
      openReport: false,
      reportConfig: {
        report_type: "",
        reportable_type: "",
        type: "",
        reportableId: "",
      },
      viewHostID: "",
      openBlockUser: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getDetailsApiCallId) {
        this.setState({ loading: false });
        if (responseJson.data) {
          const path = window.location.pathname;
          const id = path.split("/").pop();
          this.setState({
            data: responseJson.data[0].attributes,
            reportConfig: {
              report_type: "profile",
              reportable_type: "AccountBlock::Account",
              type: "report_profile",
              reportableId: id,
            },
          });
        } 
        if(responseJson.errors[0]){
          toast.error("You cannot view this profile as the user has blocked you.")
        }     
      } else if (apiRequestCallId === this.getListingApiCallId) {
        if (responseJson.data) {
          if (this.state.isAllListingVisible) {
            this.setState({ listingData: responseJson.data });
          } else {
            this.setState({ listArr: responseJson.data });
          }
          this.setState({
            pagination: responseJson.pagination,
            currentPage:
              responseJson.pagination.current_page ?? this.state.currentPage,
          });
        } else if (Array.isArray(responseJson.error)) {
          this.setState({ currentPage: 1, listingData: [] });
        }
        this.setState({ loading: false, isLoading: false });
      } else if (apiRequestCallId === this.blockUserApiCallId) {
        if (responseJson.meta) {
          toast.success(responseJson.meta.message);
          this.setState({
            isLoading: false,
            openBlockUser: false,
            anchorEl: null,
          });
        } else if (responseJson.errors) {
          toast.error("User already blocked.");
          this.setState({
            isLoading: false,
            openBlockUser: false,
            anchorEl: null,
          });
        }
      }
    }
  }

  async componentDidMount() {
    const path = window.location.pathname;
    const id = path.split("/").pop();
    this.setState({ viewHostID: id });
    this.getDetails();
    if (this.state.role === "guest") {
      this.setState({ isLoading: true }, () => {
        this.getListing(1);
      });
    }
  }

  componentDidUpdate(prevProps: Props, prevState: S) {
    if (prevState.loading !== this.state.loading && !this.state.isLoading) {
      this.scrollToTop();
    }
  }

  openDrawer = () => {
    this.setState({ isOpen: true });
  };

  scrollToTop = () => {
    if (this.scrollRef.current) {
      this.scrollRef.current.scrollIntoView();
    }
  };

  closeDrawer = () => {
    this.setState({ isOpen: false });
  };

  openAllListing = () => {
    this.setState({ isAllListingVisible: true, listingData: [] }, () => {
      this.getListing(1);
    });
  };

  onGoBack = () => {
    this.setState({
      currentPage: 1,
      listingData: [],
      isAllListingVisible: false,
    });
  };

  onChangePage = (currentPage: number) => {
    if (currentPage === this.state.currentPage) {
      this.setState({ isLoading: true }, () => {
        this.getListing(currentPage);
      });
    }
  };

  getListing = async (currentPage: number) => {
    const path = window.location.pathname;
    const id = path.split("/").pop();
    this.setState({ currentPage, isLoading: true });
    const header = {
      token: localStorage.getItem("token"),
    };
    this.getListingApiCallId = await apiCall({
      header,
      method: "GET",
      endPoint: `/bx_block_bulk_uploading/listings?account_id=${id}?page=${currentPage}&per_page=${
        this.state.isAllListingVisible ? 8 : 3
      }`,
    });
  };

  getDetails = async () => {
    const path = window.location.pathname;
    const id = path.split("/").pop();
    this.setState({ isLoading: true });
    const header = {
      token: localStorage.getItem("token"),
    };
    this.getDetailsApiCallId = await apiCall({
      header,
      method: "GET",
      endPoint: `/account_block/accounts/${id}`,
    });
  };
  handleClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  handleOpenReport = () => {
    this.setState({ anchorEl: null, openReport: true });
  };
  handleCloseReport = () => {
    this.setState({ openReport: false });
  };
  handleBlockUser = () => {
    this.setState({ anchorEl: null, openBlockUser: true });
  };

  handleCloseBlockUser = () => {
    this.setState({ openBlockUser: false });
  };

  blockUser = async () => {
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    const body = {
      data: {
        type: "reviews",
        attributes: {
          account_id: this.state.viewHostID,
        },
      },
    };

    this.blockUserApiCallId = await apiCall({
      header,
      body,
      method: "POST",
      endPoint: configJSON.blockUserApiEndpoint,
    });
  };
  // Customizable Area End
}
