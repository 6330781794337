import React from "react";

// Customizable Area Start
import { Box, Button, Dialog, styled, Typography } from "@mui/material";
// Customizable Area End

import EmailVerificationController, {
  Props,
} from "./EmailVerificationController.web";
import { checkButton } from "../assets";

export default class EmailVerification extends EmailVerificationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <CustomDialog
        data-test-id="closeVarificationChanges"
        open={this.state.isEmailVerified}
        onClose={this.closeVarificationChanges}
      >
        <Box className="container">
          <Box className="check-img">
            <img className="img" src={checkButton} alt="checkButton" />
          </Box>
          <Box>
            <Box>
              <Typography className="email-txt">Email Verified!</Typography>
            </Box>
            <Box>
              <Typography className="sub-txt">
                Congratulations, your email <b>{this.state.email}</b> has been
                successfully verified.
              </Typography>
            </Box>
          </Box>
          <Box>
            <BlackButton
              onClick={this.navigateToLogin}
              data-test-id="navigateToLogin"
            >
              Login
            </BlackButton>
          </Box>
        </Box>
      </CustomDialog>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const CustomDialog = styled(Dialog)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  "& .MuiDialog-paper": {
    borderRadius: "15px",
  },
  "& .container": {
    color: "#FFFFFF",
    padding: "50px 39px",
  },
  "& .check-img": {
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
    "& .img": {
      width: "80px",
      height: "80px",
    },
  },
  "& .email-txt": {
    fontFamily: "Spartan",
    fontSize: "30px",
    fontWeight: 700,
    lineHeight: "40px",
    letterSpacing: "-0.03em",
    textAlign: "center",
    color: "#003249",
  },
  "& .sub-txt": {
    marginTop: "12px",
    fontFamily: "Spartan",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "-0.02em",
    textAlign: "center",
    color: "#475569",
  },
});
const BlackButton = styled(Button)({
  padding: "8px 10px",
  marginTop: "54px",
  width: "100%",
  borderRadius: "8px",
  textTransform: "none",
  fontSize: "14px",
  fontFamily: "Spartan",
  letterSpacing: "-0.5px",
  whiteSpace: "nowrap",
  fontWeight: "700",
  color: "#fff",
  backgroundColor: "#003249",
  border: "1.5px solid #003249",
  "&:hover": {
    color: "#fff",
    backgroundColor: "#003249",
  },
  "&.Mui-disabled": {
    color: "#fff",
    backgroundColor: "#94A3B8",
    border: "1.5px solid #94A3B8",
  },
  "&.review": {
    width: "250px",
    padding: "10px",
    fontSize: "16px",
  },
});
// Customizable Area End
