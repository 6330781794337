import React from "react";

// Customizable Area Start
import {
  Box,
  Grid,
  createTheme,
  styled,
  ThemeProvider,
  Typography,
  IconButton,
  InputAdornment,
  Avatar,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import Header from "../../../components/src/AppHeader.web";
import Loader from "../../../components/src/Loader.web";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import CustomInput from "../../../components/src/CustomInput.web";
import { noChatSelectIcon, sendIcon } from "./assets";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FiberManualRecordRoundedIcon from "@mui/icons-material/FiberManualRecordRounded";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";

enum MsgType {
  Error = "error",
  Success = "success",
}

interface IMessage {
  id: number;
  account_id: number;
  chat_id: number;
  message: string;
  created_at: string;
  updated_at: string;
  is_mark_read: boolean;
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#003249",
      contrastText: "#fff",
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: "Spartan",
        },
        h4: {
          fontSize: "20px",
          fontFamily: "Spartan",
          fontWeight: 500,
          color: "#64748B",
          lineHeight: "30px",
        },
        h5: {
          fontSize: "18px",
          fontFamily: "Spartan",
          fontWeight: 700,
          paddingBottom: "8px",
        },
        h6: {
          fontSize: "14px",
          fontFamily: "Spartan",
          fontWeight: 700,
          color: "#003249",
          lineHeight: "22px",
        },
        body1: {
          fontSize: "12px",
          fontFamily: "Spartan",
          fontWeight: 400,
          color: "#475569",
          lineHeight: "18px",
        },
        body2: {
          fontSize: "0.8rem",
          fontFamily: "Spartan",
          fontWeight: 700,
        },
        subtitle1: {
          fontSize: "14px",
          fontFamily: "Spartan",
          fontWeight: 500,
          color: "#003249",
          lineHeight: "24px",
        },
        subtitle2: {
          fontSize: "10px",
          fontFamily: "Spartan",
          fontWeight: 500,
          lineHeight: "20px",
        },
        caption: {
          fontFamily: "Spartan",
          fontSize: "14px",
          fontWeight: 500,
          color: "#94A3B8",
          margin: "8px 0",
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: "0 !important",
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          gap: "15px",
        },
      },
    },
    MuiListItemAvatar: {
      styleOverrides: {
        root: {
          minWidth: "auto",
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          padding: "16px 10px",
          fontFamily: "Spartan",
          fontSize: 14,
          fontWeight: 400,
          gap: "15px",
          maxWidth: "max-content",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        inputAdornedEnd: {
          textOverflow: "ellipsis",
        },
        adornedEnd: {
          paddingRight: "0",
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: "8px",
          minWidth: 80,
          color: "#000000",
          boxShadow: "#F1F5F9 0px 8px 32px 0px, #000000 0px 4px 5px 0px",
          "& li": {
            borderRadius: "8px",
            "&:hover": {
              backgroundColor: "#F1F5F9",
            },
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          height: "auto",
          top: "140px !important",
          left: "calc(100% - 170px) !important",
        },
        list: {
          width: "130px !important",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          display: "flex",
          gap: 8,
          alignItems: "center",
          fontFamily: "Spartan",
          fontSize: "12px",
          fontWeight: 400,
          padding: "10px 10px 10px 16px !important",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: "0",
        },
      },
    },
  },
});

const StyledRoomListContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down(840)]: {
    padding: "0",
  },
}));

const CustomDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    borderRadius: "16px",
    width: "550px",
  },
  "& .title": {
    textAlign: "center",
  },
  "& .MuiDialogContent-root": {
    padding: "0px 30px 18px !important",
  },
  "& .dialog-content": {
    marginBottom: "10px",
    "& .popup-title": {
      textAlign: "center",
      letterSpacing: "0px",
      fontFamily: "Spartan",
      fontWeight: "700",
      fontSize: "18px",
      color: "#003249",
      lineHeight: "25px",
    },
    "& .option-description": {
      fontFamily: "Spartan",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "24px",
      color: "#716F77",
      paddingTop: "8px",
    },
  },
  "& .dialog-action": {
    padding: "0 30px 30px 30px",
    justifyContent: "center",
    display: "block",
    marginLeft: "0",
  },
  "& .close-btn": {
    color: "#003249",
    height: "15px",
    width: "15px",
  },
  "&.change-host": {
    "& .MuiDialogContent-root": {
      paddingTop: "15px !important",
      paddingBottom: "15px !important",
    },
  },
});

const WhiteButton = styled(Button)({
  padding: "8px 10px",
  textTransform: "none",
  width: "100%",
  color: "#0F172A",
  borderRadius: "8px",
  fontSize: "16px",
  fontFamily: "Spartan",
  fontWeight: "500",
  backgroundColor: "#fff",
  border: "1.5px solid #003249",
  marginTop: "10px",
  "&:hover": {
    color: "#0F172A",
    backgroundColor: "#fff",
  },
});
const BlackButton = styled(Button)({
  padding: "8px 10px",
  width: "100%",
  borderRadius: "8px",
  textTransform: "none",
  fontSize: "16px",
  fontFamily: "Spartan",
  whiteSpace: "nowrap",
  fontWeight: "700",
  color: "#fff",
  backgroundColor: "#003249",
  border: "1.5px solid #003249",
  "&:hover": {
    color: "#fff",
    backgroundColor: "#003249",
  },
  "&:disabled": {
    color: "#fff",
    backgroundColor: "#94A3B8",
    border: "none",
  },
});
// Customizable Area End

import ChatController, { configJSON, IChat, Props } from "./ChatController";

export default class Chat extends ChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderBlockUserPopup = (userName: string) => {
    return (
      <>
        <CustomDialog
          open={this.state.openBlockUser}
          onClose={() => this.handleCloseBlockUser()}
          data-test-id="cancelBtn"
        >
          <DialogTitle className="title">
            <IconButton
              onClick={this.handleCloseBlockUser}
              sx={{ position: "absolute", right: 16, top: 16 }}
              data-test-id="handleCloseBlockUser"
            >
              <CloseRoundedIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className="dialog-content">
            <Typography className="popup-title">
              {configJSON.blockUserPopupTitle} {userName}?
            </Typography>
            <Typography className="option-description">
              {configJSON.blockUserPopupSubtitle}
            </Typography>
          </DialogContent>
          <Box className="dialog-action">
            <BlackButton onClick={this.blockUser} data-test-id="blockBtn">
              {configJSON.blockBtn}
            </BlackButton>
            <WhiteButton onClick={this.handleCloseBlockUser}>
              {configJSON.cancelText}
            </WhiteButton>
          </Box>
        </CustomDialog>
      </>
    );
  };

  renderChatMessages = (chatMsg: IMessage) => {
    const { account_id, message, updated_at } = chatMsg;
    return (
      <List>
        {this.state.currentAcoountId !== account_id ? (
          <ListItem alignItems="center" sx={{ justifyContent: "start" }}>
            <Box className="messageStartBox">
              {message && (
                <>
                  <ListItemText primary={message} className="lightMsg" />
                  <Typography>
                    {new Date(updated_at).toLocaleTimeString("en-US", {
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: true,
                    })}
                  </Typography>
                </>
              )}
            </Box>
          </ListItem>
        ) : (
          <ListItem alignItems="center" sx={{ justifyContent: "end" }}>
            <Box className="messageEndBox">
              {message && (
                <>
                  <ListItemText primary={message} className="darkMsg" />
                  <Typography>
                    {new Date(updated_at).toLocaleTimeString("en-US", {
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: true,
                    })}
                  </Typography>
                </>
              )}
            </Box>
          </ListItem>
        )}
      </List>
    );
  };

  renderOpenChatModal = () => {
    const {
      searchText,
      chatRoomList,
      anchorElMenu,
      newMessage,
      messages,
    } = this.state;
    const groupedMessages = this.groupMessagesByDate(messages.messages);
    const { full_name, profile_photo } = messages?.accounts_chats?.find(
      (account) =>
        account.attributes.account.full_name !== this.state.currentUsername
    )?.attributes.account || { profile_photo: { url: "" }, full_name: "" };
    if (searchText.length > 0 && chatRoomList.length === 0) {
      return (
        <Box className="noChatSelectContainer">
          {noChatSelectIcon}
          <Box>
            <Typography
              variant="h5"
              sx={{ color: "#64748B", textAlign: "center" }}
            >
              {configJSON.notFoundTitle}
            </Typography>
            <Typography
              variant="h4"
              sx={{ width: "800px", textAlign: "center" }}
            >
              {configJSON.notFoundSubtitle}
            </Typography>
          </Box>
        </Box>
      );
    }

    if (chatRoomList.length === 0) {
      return (
        <Box className="noChatSelectContainer" sx={{ gap: "8px" }}>
          <Typography variant="h5" sx={{ color: "#64748B" }}>
            {configJSON.noMsgTitle}
          </Typography>
          <Typography variant="h4" sx={{ width: "380px", textAlign: "center" }}>
            {configJSON.noMsgSubtitle}
          </Typography>
        </Box>
      );
    }

    return messages.id ? (
      <>
        {this.renderBlockUserPopup(full_name)}
        <Box data-test-id="btnOpenChat" className="mainChatContainer">
          <Box className="openChatContainer">
            <Box className="chatContent">
              <ArrowBackIcon
                data-test-id="backBtn"
                className="pointer backBtnVisible"
                onClick={this.closeChat}
              />
              <Avatar
                src={profile_photo?.url}
                alt={full_name}
                className="chatDetailIcon"
              />
              <Box>
                <Typography variant="h5">
                  {full_name || `User ${messages?.id}`}
                </Typography>
                <Typography variant="body1">
                  {messages?.listing?.title}
                </Typography>
              </Box>
            </Box>
            <IconButton
              data-test-id="moreIcon"
              className="pointer"
              onClick={(event) => this.openMenu(event)}
            >
              <MoreVertRoundedIcon />
            </IconButton>
            <Menu
              data-test-id="chatRoomItemMenu"
              elevation={0}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={anchorElMenu !== null}
              onClose={() => this.setState({ anchorElMenu: null })}
            >
              <MenuItem data-test-id="reportMenuItem" sx={{ color: "#475569" }}>
                <FlagOutlinedIcon className="menuIcon" />{" "}
                {configJSON.reportMenu}
              </MenuItem>
              <MenuItem
                data-test-id="blockMenuItem"
                sx={{ color: "#F87171" }}
                onClick={this.handleOpenBlockUser}
              >
                <CloseRoundedIcon className="menuIcon" /> {configJSON.blockMenu}
              </MenuItem>
            </Menu>
          </Box>
          <Box
            id="chat-container"
            sx={{
              height: "calc(100vh - 265px)",
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
              padding: "0 40px",
              overflowY: "scroll",
              background: "#F1F5F9",
            }}
          >
            {Object.keys(groupedMessages).map((date) => {
              return (
                <Box key={date}>
                  <Box
                    className="messageDate"
                    display="flex"
                    alignItems="center"
                  >
                    <Typography variant="caption">
                      {new Date(date).toLocaleDateString() ===
                      new Date().toLocaleDateString()
                        ? "Today"
                        : date}
                    </Typography>
                  </Box>
                  {groupedMessages[date].map((message) =>
                    this.renderChatMessages(message)
                  )}
                </Box>
              );
            })}
          </Box>
          <Box className="inputContainer">
            <CustomInput
              data-test-id="sendMsgText"
              size="small"
              variant="outlined"
              placeholder={configJSON.typeMsgPlaceholder}
              value={newMessage}
              className="messageInput"
              onChange={(event) => {
                this.setState({
                  toastMsg: { message: "", type: MsgType.Error },
                  newMessage: event.target.value,
                });
              }}
              onKeyDown={this.createMessage}
            />
            <Box data-test-id="sendIcon" onClick={this.createMessage}>
              {sendIcon}
            </Box>
          </Box>
        </Box>
      </>
    ) : (
      <Box className="noChatSelectContainer">
        {noChatSelectIcon}
        <Typography variant="h4">{configJSON.selectChatText}</Typography>
      </Box>
    );
  };

  renderRoomList = () => {
    const { chatRoomList, selectedChatRoom } = this.state;
    return (
      <Box sx={{ marginTop: "20px" }}>
        {chatRoomList.length > 0
          ? chatRoomList.map((item, index: number) => (
              <Box
                key={`room-${index}`}
                className="listItemContainer"
                sx={{
                  background:
                    Number(item.id) === selectedChatRoom ? "#F1F5F9" : "",
                }}
              >
                <Box className="chatContent">
                  <Avatar
                    src={
                      this.getUserDetails(item)?.attributes?.account
                        ?.profile_photo?.url || ""
                    }
                    alt={
                      this.getUserDetails(item)?.attributes?.account?.full_name
                    }
                    className="chatIcon"
                  />
                  <Box
                    data-test-id="btnShowChat"
                    onClick={() => this.openChat(item)}
                    className="width100 pointer"
                  >
                    <Box className="displayFlex">
                      <Typography variant="h6" data-test-id="chatDetailTitle">
                        {this.getUserDetails(item)?.attributes?.account
                          ?.full_name || `User ${item.id}`}
                      </Typography>
                      <Box className="chatTimeWrapper">
                        <Typography
                          variant="subtitle2"
                          data-test-id="chatDetailTitle"
                          sx={{
                            color: this.getUnreadMsgs(item)
                              ? "#06D6A0"
                              : "#475569",
                          }}
                        >
                          {item.attributes.messages?.attributes?.updated_at
                            ? new Date(
                                item.attributes.messages?.attributes?.updated_at
                              ).toLocaleTimeString("en-US", {
                                hour: "2-digit",
                                minute: "2-digit",
                                hour12: true,
                              })
                            : null}
                        </Typography>
                        {this.getUnreadMsgs(item) ? (
                          <FiberManualRecordRoundedIcon
                            sx={{ color: "#06D6A0", width: 14, height: 14 }}
                          />
                        ) : null}
                      </Box>
                    </Box>
                    <Typography variant="body1">
                      {item.attributes.listing.title}
                    </Typography>
                    <Typography variant="subtitle1" className="textOverflow">
                      {item.attributes.messages?.attributes.message || ""}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            ))
          : null}
      </Box>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { isLoading, searchText } = this.state;
    return (
      <Header hideFilter navigation={this.props.navigation}>
        <ThemeProvider theme={theme}>
          <Wrapper data-test-id="mainWrapper">
            <Loader loading={isLoading} />
            <Grid container className="mainContainer">
              <Grid
                item
                sm={5}
                xs={12}
                md={3}
                lg={3}
                className="gridItemsFixed"
              >
                <Box sx={{ padding: "35px 16px 0" }}>
                  <Typography variant="h5">{configJSON.inbox}</Typography>
                  <CustomInput
                    data-test-id="searchInput"
                    value={searchText}
                    placeholder={configJSON.searchPlaceholder}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton component="label">
                            <SearchRoundedIcon
                              data-test-id="searchIcon"
                              sx={{
                                width: 24,
                                height: 24,
                                color: "#94A3B8",
                              }}
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    onChange={(event) => this.handleSearch(event)}
                    onKeyDown={this.searchChat}
                  />
                </Box>
                <StyledRoomListContainer
                  data-test-id="StyledRoomListContainer"
                  className="gridItems"
                >
                  {this.renderRoomList()}
                </StyledRoomListContainer>
              </Grid>
              <Grid
                item
                sm={7}
                xs={12}
                md={9}
                lg={9}
                sx={{
                  padding: 0,
                }}
              >
                <Box>{this.renderOpenChatModal()}</Box>
              </Grid>
            </Grid>
          </Wrapper>
        </ThemeProvider>
      </Header>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const Wrapper = styled("div")(({ theme }) => ({
  "& .pointer": {
    cursor: "pointer",
  },
  "& .width100": {
    width: "100%",
  },
  "& .displayFlex": {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .chatTimeWrapper": {
    display: "flex",
    alignItems: "center",
    gap: "3px",
  },
  "& .textOverflow": {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "10rem",
  },
  "& .mainContainer": {
    margin: "0",
    width: "100%",
  },
  "& .gridItemsFixed": {
    borderRight: "1px solid #E2E8F0",
  },
  "& .gridItems": {
    height: "calc(100vh - 200px)",
    overflow: "hidden",
    overflowY: "scroll" as "scroll",
  },
  "& .listItemContainer": {
    width: "100%",
    minHeight: "72px",
    margin: "8px 0",
    display: "flex",
    gap: "16px",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #E2E8F0",
    padding: "8px 16px",
  },
  "& .mainChatContainer": {
    display: "flex",
    justifyContent: "space-between",
    flexFlow: "column",
  },
  "& .openChatContainer": {
    display: "flex",
    gap: "16px",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "20px 40px 20px 16px",
    margin: 0,
    borderBottom: "1px solid #E2E8F0",
  },
  "& .messagStartBox": {
    display: "flex",
    alignItems: "start",
    flexDirection: "column",
  },
  "& .messageEndBox": {
    display: "flex",
    alignItems: "end",
    flexDirection: "column",
  },
  "& .messageDate": {
    display: "flex",
    gap: "20px",
    margin: "0 2.5rem",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .messageInput": {
    backgroundColor: "#F1F5F9",
    fontFamily: "Spartan",
  },
  "& .lightMsg": {
    backgroundColor: "#FFF",
    border: "1px solid #CBD5E1",
    borderRadius: "8px 8px 8px 0",
  },
  "& .darkMsg": {
    display: "flex",
    flexDirection: "row-reverse",
    backgroundColor: "#003249",
    borderRadius: "8px 8px 0 8px",
    "& .MuiTypography-root": {
      color: "#FFFFFF",
    },
  },
  "& .menuIcon": {
    width: "16px",
    height: "16px",
    marginRight: "8px",
  },
  "& .chatIcon": {
    width: "52px",
    height: "52px",
  },
  "& .chatContent": {
    width: "100%",
    display: "flex",
    gap: "15px",
    alignItems: "center",
  },
  "& .backBtnVisible": {
    display: "none",
  },
  "& .noChatSelectContainer": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "30px",
    height: "100%",
  },
  "& .chatDetailIcon": {
    width: "52px",
    height: "52px",
    objectFit: "contain",
  },
  "& .inputContainer": {
    borderTop: "1px solid #E2E8F0",
    padding: "16px 20%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "36px",
    position: "absolute",
    bottom: 0,
    left: "25%",
    right: 0,
  },
  [theme.breakpoints.down(1200)]: {
    "& .textOverflow": {
      width: "8rem",
    },
  },
  [theme.breakpoints.down(1060)]: {
    "& .textOverflow": {
      width: "6rem",
    },
  },
  [theme.breakpoints.down(960)]: {
    "& .gridItems": {
      height: "auto",
    },
    "& .textOverflow": {
      width: "12rem",
    },
  },
  [theme.breakpoints.down(840)]: {
    "& .listItemContainer": {
      minHeight: "auto",
      width: "auto",
    },
    "& .textOverflow": {
      width: "10rem",
    },
  },
  [theme.breakpoints.down(710)]: {
    "& .textOverflow": {
      width: "9rem",
    },
  },
  [theme.breakpoints.down(650)]: {
    "& .textOverflow": {
      width: "7rem",
    },
  },
  [theme.breakpoints.down(600)]: {
    "& .listItemContainer": {
      minHeight: "auto",
      width: "100%",
    },
    "& .backBtnVisible": {
      display: "block",
    },
    "& .textOverflow": {
      width: "25rem",
    },
  },
  [theme.breakpoints.down(540)]: {
    "& .textOverflow": {
      width: "20rem",
    },
  },
  [theme.breakpoints.down(460)]: {
    "& .textOverflow": {
      width: "15rem",
    },
  },
  [theme.breakpoints.down(380)]: {
    "& .textOverflow": {
      width: "10rem",
    },
  },
}));
// Customizable Area End
