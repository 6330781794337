import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { apiCall } from "../../../components/src/NavigationRouteWrapper/helpers";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  isOpen: boolean;
  reportConfig: any;
  onClose: Function;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  reportKeys: any;
  selectedReason: string;
  otherInfo: string;
  showSuccess: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ReportUserController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  sendReportCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      reportKeys: {
        "Inappropriate content":
          "Offensive language, discriminatory remarks, or inappropriate images in listings, profiles, or messages.",
        "Misleading or false information":
          "Listings or profiles that contain inaccurate, exaggerated, or false claims about the property, services, or amenities.",
        "Safety Concerns":
          "Any issue that poses a threat to the safety of guests, horses, or hosts (e.g., unsafe accommodations, lack of security, dangerous animals).",
        "Harassment or Abuse":
          "Abusive, threatening, or harassing behaviour from either a host or guest during communication or a stay.",
        "Animal Welfare Issues":
          "Concerns regarding the care, treatment, or environment provided for horses or other animals on-site.",
        Other:
          "Please provide us with more information so we can address this issue properly.",
      },
      selectedReason: "",
      otherInfo: "",
      showSuccess: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.sendReportCallId && responseJson) {
        this.handleReportResponse(responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleChange = (key: string) => {
    this.setState({ selectedReason: key });
  };

  handleReportResponse = (responseJson: any) => {
    if (responseJson.data) {
      this.setState({ showSuccess: true, selectedReason: '', otherInfo: '' });
    } else if (responseJson.errors?.[0]?.Report) {
      toast.error(responseJson.errors?.[0]?.Report?.base); 
    } else {
      toast.error('Something went wrong')
    }
  };

  isOtherSelected = () => this.state.selectedReason === "Other";

  closeReport = () => {
    this.props.onClose();
    this.setState({selectedReason:'',otherInfo:'',showSuccess:false});
  };

  sendReport = async () => {
    let req = {
      data: {
        type: this.props.reportConfig.type,
        attributes: {
          title: this.state.selectedReason,
          description: this.isOtherSelected()
            ? this.state.otherInfo
            : this.state.reportKeys[this.state.selectedReason],
          report_type: this.props.reportConfig.report_type,
          reportable_type: this.props.reportConfig.reportable_type,
          reportable_id: this.props.reportConfig.reportableId,
        },
      },
    };
    const header = {
      token: localStorage.getItem("token"),
    };
    this.sendReportCallId = await apiCall({
      header: header,
      contentType: "application/json",
      method: "POST",
      body: req,
      endPoint: `/bx_block_reviews/reports`,
    });
  };
  // Customizable Area End
}
