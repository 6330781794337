// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall } from "../../../components/src/NavigationRouteWrapper/helpers";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  active: number;
  data: string;
  // Customizable Area End
}

interface SS {
  id: string;
}

export default class PoliciesController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  policyApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      loading: false,
      active: -1,
      data: ""
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.policyApiCallId) {
        if (responseJson.data) {
          const description = Array.isArray(responseJson.data)
            ? responseJson.data[0]?.attributes?.description
            : responseJson.data?.attributes?.description;

          this.setState({ data: description });
        }
        this.setState({ loading: false });
      }
    }
  }

  async componentDidMount() {
    this.getPolicyInfo(0);
  }

  getPolicyInfo = async (active: number) => {
    if (active === this.state.active) return;

    this.setState({ active, loading: true });
    const header = {
      token: localStorage.getItem("token")
    }
    this.policyApiCallId = await apiCall({
      header,
      method: "GET",
      endPoint: API_LINKS[active as keyof typeof API_LINKS],
    });
  }
  // Customizable Area End
}

const API_LINKS = {
  0: "/bx_block_terms_and_conditions/terms_and_conditions/latest_record",
  1: "/bx_block_terms_and_conditions/privacy_policies",
  2: "/bx_block_terms_and_conditions/privacy_policies/booking_and_cancellation_policies",
  3: "/bx_block_terms_and_conditions/privacy_policies/safety_and_security_policies"
};