// Customizable Area Start
import React from "react";
import { Box, Typography, CircularProgress, Button, Avatar } from "@mui/material";
import { styled } from '@mui/material/styles';

import BlockedUserController from "./BlockedUserController.web";
import Header from "../../../components/src/AppHeader.web";
import Loader from "../../../components/src/Loader.web";
import { infoIcon } from "./assets";
// Customizable Area End

export default class BlockedUser extends BlockedUserController {
  // Customizable Area Start
  renderLeftSection = () => {
    return (
      <Box className="info-wrapper">
        <Box className="left-section">
          <Box className="container">
            {this.state.data.length > 0 ?
              this.state.data.map((item) => {
                const user = item.attributes.block_account;
                return (
                  <Box className="card">
                    <Box className="user-info">
                      <Avatar className="avatar" src={user.profile_photo?.url} />
                      <Box>
                        <Typography className="user-name">{user.first_name + " " + user.last_name}</Typography>
                        <Typography className="view-profile">View profile</Typography>
                      </Box>
                    </Box>
                    <Box>
                      <UnblockButton onClick={() => this.unblockUser(item.id)} data-test-id="unblock-btn">Unblock</UnblockButton>
                    </Box>
                  </Box>
                );
              })
              :
              <Box mt="70px" textAlign="center">
                <Typography className="not-found">No blocked user!</Typography>
              </Box>
            }
          </Box>
        </Box>
      </Box>
    );
  }

  renderRightSection = () => {
    return (
      <Box className="right-section">
        {infoIcon}
        <Typography className="guide">Here you can see blocked users</Typography>
        <Box className="guide-wrapper">
          <Typography className="guide-text">
            In this section, you can view all blocked users and unblocked them if you wish.
          </Typography>
        </Box>
      </Box>
    );
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <Header isSidebar hideFilter navigation={this.props.navigation} active="BlockedUser">
        <Loader loading={this.state.isLoading} />
        <Wrapper>
          {this.state.loading ? (
            <Box className="loading">
              <CircularProgress sx={{ color: "#000" }} />
            </Box>
          ) : (
            <>
              <Box className="heading">
                <Typography className="blocked-user">Blocked users</Typography>
              </Box>
              <Box display="flex" justifyContent="space-between">
                {this.renderLeftSection()}
                {this.renderRightSection()}
              </Box>
            </>
          )}
        </Wrapper>
      </Header>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const Wrapper = styled(Box)({
  height: "100%",
  padding: "30px 20px",
  paddingBottom: "105px",
  "& .loading": {
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .heading": {
    justifyContent: "space-between",
    display: "flex",
    marginBottom: "30px",
    "& .blocked-user": {
      letterSpacing: "-0.5px",
      fontFamily: "Spartan",
      textTransform: "uppercase",
      color: "#003249",
      fontWeight: "700",
      fontSize: "17px",
    }
  },
  "& .info-wrapper": {
    gap: "20px",
    padding: "0px 10px",
    display: "flex",
    alignItems: "center",
    flex: "1",
    flexDirection: "column",
    "& .left-section": {
      alignItems: "flex-start",
      gap: "20px",
      width: "400px",
      display: "flex",
      fontFamily: "Spartan",
      justifyContent: "space-between",
      "& .title": {
        color: "#003249",
        fontFamily: "Spartan",
        fontSize: "14px",
        fontWeight: "700",
        marginBottom: "15px"
      },
      "& .container": {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        "& .card": {
          boxSizing: "border-box",
          width: "100%",
          padding: "20px",
          borderRadius: "16px",
          background: "#fff",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          boxShadow: "0px 2px 8px 0px #00000014",
          "& .user-info": {
            display: "flex",
            alignItems: "center",
            gap: "10px",
            "& .avatar": {
              width: "44px",
              height: "44px",
              border: "1.5px solid #E0E7FF",
            },
            "& .user-name": {
              letterSpacing: "-0.5px",
              color: "#003249",
              fontFamily: "Spartan",
              fontSize: "14px",
              fontWeight: "700",
              marginBottom: "5px"
            },
            "& .view-profile": {
              letterSpacing: "-0.5px",
              color: "#475569",
              fontFamily: "Spartan",
              fontSize: "14px",
              fontWeight: "500",
            }
          }
        },
        "& .not-found": {
          fontFamily: "Spartan",
        }
      }
    }
  },
  "& .right-section": {
    display: "flex",
    width: "285px",
    gap: "15px",
    padding: "20px",
    height: "fit-content",
    flexDirection: "column",
    borderRadius: "8px",
    backgroundColor: "#F8FAFC",
    border: "1.5px solid #E2E8F0",
    "& .guide": {
      letterSpacing: "-0.5px",
      fontFamily: "Spartan",
      fontWeight: "500",
      color: "#000",
      fontSize: "16px",
    },
    "& .guide-wrapper": {
      flexDirection: "column",
      gap: "5px",
      display: "flex",
      "& .guide-text": {
        letterSpacing: "-0.3px",
        fontFamily: "Spartan",
        fontSize: "14px",
        display: "flex",
        fontWeight: "500",
        color: "#475569",
      }
    }
  },
});

const UnblockButton = styled(Button)({
  padding: "6px 13px",
  letterSpacing: "-0.5px",
  fontFamily: "Spartan",
  color: "#003249",
  fontSize: "16px",
  textTransform: "none",
  whiteSpace: "nowrap",
  borderRadius: "8px",
  backgroundColor: "#fff",
  fontWeight: "500",
  border: "1.5px solid #003249",
  "&:hover": {
    color: "#003249",
    backgroundColor: "#fff",
  }
});
// Customizable Area End