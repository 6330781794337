import React from "react";
import {
  Modal,
  Container,
  Box,
  Button,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  Paper,
  Typography,
  Input,
} from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";

import ContactusController, { Props } from "./ContactusController";

// Customizable Area Start

import {TextField, styled, Grid, FormControl, Select, MenuItem, Dialog} from "@mui/material";
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';

export const configJSON = require("./config");

// Customizable Area End

export default class Contactus extends ContactusController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      // Customizable Area Start
      <>
      <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={6} sx={{ position:"relative", display:'flex',justifyContent:'center' }}>
          <Box 
          style ={{
              width: "100%", 
              }}
          >
            <img src = {this.state.backImage} 
            style={{             
                width: "100%",
                height: "100vh",
                objectFit: "cover", 
                position:'relative',
                objectPosition: "top",
              }}/>
          </Box>
          <Box 
          sx={{
            backgroundColor: "#FEFEFE26",
            width: { xs: "40px", sm: "48px", md: "54px" },
            height: { xs: "40px", sm: "48px", md: "54px" }, 
            top: { xs: "20px", sm: "30px", md: "50px" }, 
            left: { xs: "20px", sm: "30px", md: "50px" }, 
            gap: "0px",
            borderRadius: "14px",
            opacity: "0px",
            boxShadow: "-5px 4px 26px 0px #0000001A",
            position: "absolute",
          }}>
            <Box 
            sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: { xs: "8px", sm: "10px", md: "14px" }, 
            }}
            >
              <img src={this.state.arrowImage}/>
            </Box>
          </Box>
          <MainBoxTextArea>
            <Box>
              <Typography 
              style={{
                  color: "#FFFFFF",
                  fontFamily: "Spartan",
                  fontSize: "30px",
                  fontWeight: 700,
                  lineHeight: "40px",
                  letterSpacing: "-0.03em",
              }}
              >
                Find the Ideal Haven for Your Horse
              </Typography>
            </Box>  
            <Box>
              <Typography 
              style={{
                color: "#FFFFFF",
                fontFamily: "Spartan",
                fontSize: "20px",
                fontWeight: 500,
                lineHeight: "30px",
                letterSpacing: "-0.02em",
                textAlign: "center", 
                padding: "0 25px",
              }}>
                Explore trusted, short-term stabling solutions with ease.
              </Typography>
            </Box>         
          </MainBoxTextArea>
        </Grid>
        <RightGrid item xs={12} sm={12} md={6} lg={6} sx={{ position:"relative", justifyContent:'center' }}>
          <Box>
              <Typography 
              style={{
                fontFamily: "Spartan",
                fontSize: "30px",
                fontWeight: 700,
                lineHeight: "40px",
                letterSpacing: "-0.03em",
                textAlign: "left",
                color: "#003249",
              }}>
                {configJSON.title}
              </Typography>
              <Typography 
              style={{
                fontFamily: "Spartan",
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "24px",
                letterSpacing: "-0.02em",
                textAlign: "left",
                color: "#475569",
              }}>
                {configJSON.subtitle}
              </Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
            <Box>
            <Box>
              <Typography 
              style={{
                fontFamily: "Spartan",
                fontSize: "14px",
                fontWeight: 700,
                lineHeight: "22px",
                letterSpacing: "-0.02em",
                textAlign: "left",
                marginTop: "2rem",
                color: "#64748B",
              }}>
              Email address
              </Typography>
              <TextField 
              onChange={this.handleEmailChange}
              value={this.state.email}
              data-test-id="handleEmailChange"
              error={!!this.state.emailError}
                style={{
                  width: "100%",
                  height: "56px",
                  borderRadius: "8px", 
                  marginTop: "4px",
                  border: "1px",
                }}
                placeholder="mail@example.com"
                type="email"
                name="email"
                variant="outlined"
                required
              />
              {this.state.emailError!="" && 
                <p style={{
                  color: "#DC2626",
                  fontFamily: "Spartan !important",
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "20px",
                  letterSpacing: "-0.02em",
                  textAlign: "left",
                  marginTop: "0px",
                  }}>
                  {this.state.emailError}
                </p>
              }
            </Box>
            <Box>
              <Typography 
              style={{
                fontFamily: "Spartan",
                fontSize: "14px",
                fontWeight: 700,
                lineHeight: "22px",
                letterSpacing: "-0.02em",
                textAlign: "left",
                marginTop: "16px",
                color: "#64748B",
              }}>
              Issue type
              </Typography>
              <FormControl variant="outlined" required
              style={{ 
                width: "100%",
                height: "56px",
                borderRadius: "8px",
                border: "1px",
                marginTop: "4px",
              }}>
                <Select value={this.state.issueType} 
                onChange={this.handleIssueType} 
                data-test-id="handleIssueType"
                IconComponent={NavigateNextRoundedIcon}
                style={{
                  color: this.state.issueType == "Select your issue type" ? "#94A3B8" : "#000000",
                  fontFamily: "Spartan",
                  fontSize: "16px",
                  fontWeight: 500,
                  lineHeight: "24px",
                  letterSpacing: "-0.02em",
                  textAlign: "left",
                 }}>
                  <MenuItem value='Select your issue type' style={{display:'none'}}>Select your issue type</MenuItem>
                  <MenuItem value="general_inquiry">general_inquiry</MenuItem>
                  <MenuItem value="technical_support">technical_support</MenuItem>
                  <MenuItem value="issue_with_booking">issue_with_booking</MenuItem>
                  <MenuItem value="feedback">feedback</MenuItem>
                  <MenuItem value="other">other</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box>
              <Typography
              style={{
                fontFamily: "Spartan",
                fontSize: "14px",
                fontWeight: 700,
                lineHeight: "22px",
                letterSpacing: "-0.02em",
                textAlign: "left",
                marginTop: "16px",
                color: "#64748B",
              }}>
              Detail of the query
              </Typography>
              <TextField
              onChange={this.handleDescription}
              value={this.state.description}
              data-test-id="handleDescription"
              style={{
                width: "100%",
                borderRadius: "8px",
                border: "1px",
                marginTop: "4px",
              }}
              placeholder="Tell us more about your query..."
              type="query"
              name="query"
              variant="outlined"
              multiline
              rows={4}
              required
              />
            </Box>
            </Box>
            <Button disabled={this.state.email && this.state.issueType && this.state.description ? false: true} 
            onClick={this.handleButtonClick} 
            data-test-id="handleButtonClick"
            style={{
              backgroundColor: this.state.email && this.state.issueType && this.state.description ? "#003249":'#94A3B8',
              color: "#FFFFFF",
              width: "100%",
              marginTop: "10vh",
              height: "56px",
              borderRadius: "8px",
              fontFamily: "Spartan",
              fontSize: "16px",
              fontWeight: 700,
              lineHeight: "24px",
              letterSpacing: "-0.03em",
              textTransform: "none",
            }}>
              Submit
            </Button>
          </Box>
        </RightGrid>
      </Grid>
      <CustomDialog style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} 
        open={this.state.linkSent} 
        onClose={this.handleClose}
        data-test-id="handleClose">
            <Box
            style={{
                color: "#FFFFFF",
                padding:'50px 39px'
                }}
            >
                <Box
                style={{
                    display:'flex',
                    justifyContent:'center'
                }}>
                    <img src={this.state.checkButton}/>
                </Box>
                <Box 
                style={{
                    marginTop: "62px",
                    }}>
                    <Box>
                        <Typography 
                        style={{
                            fontFamily: "Spartan",
                            fontSize: "30px",
                            fontWeight: 700,
                            lineHeight: "40px",
                            letterSpacing: "-0.03em",
                            textAlign: "center",
                            color: "#003249",
                        }}>
                            Inquiry sent!
                        </Typography>
                    </Box>
                    <Box>
                        <Typography 
                        style={{
                          marginTop: "12px",
                            fontFamily: "Spartan",
                            fontSize: "16px",
                            fontWeight: 400,
                            lineHeight: "24px",
                            letterSpacing: "-0.02em",
                            textAlign: "center",
                            color: "#475569",
                        }}>
                           Congratulations, Your inquiry has been sent to our support team
                        </Typography>
                    </Box>
                </Box>
                <Box>
                    <Button onClick={this.handleClose}
                    style={{
                        gap: "8px",
                        marginTop: "54px",
                        width: "100%",
                        padding:"16px",
                        color: "#FFFFFF",
                        backgroundColor: "#003249",
                        borderRadius: "8px",
                        fontFamily: "Spartan",
                        fontSize: "16px",
                        fontWeight: 700,
                        lineHeight: "24px",
                        letterSpacing: "-0.03em",
                        textTransform:'none',
                        }}>
                          Done
                    </Button>
                </Box>
            </Box>
        </CustomDialog>
      </>
      //Merge Engine End DefaultContainer
      // Customizable Area Start
    );
  }
}

// Customizable Area Start
const CustomDialog=styled(Dialog)({
  "& .MuiDialog-paper":{
    borderRadius: "15px"
  }
})

const MainBoxTextArea =styled(Box)({
  width: "82%",
  position: "absolute",
  background: "#FFFFFF33",
  bottom:"38px",
  borderRadius: "5px",
  backdropFilter: "blur(15px)",
  padding:"2rem 1rem",
  textAlign:"center",
  display: "flex",
  flexDirection: "column",
  gap: "1rem"
})

const RightGrid = styled(Grid)({
  height: "100vh",
  position: "relative",
  justifyContent: "center",
  padding: "5% 8% 3%",
  overflowY: "scroll",
})

// Customizable Area End
