//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { apiCall } from "../../../components/src/NavigationRouteWrapper/helpers";
import {toast} from "react-toastify"
import { setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");


export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  productData: { name: string; price: number; description: string; images:{url:string}[] };
  isloading: boolean;
  focusedImageIndex: number;
  product_id:number;
  productDetails:any;
  checkIn: Date | null;
  checkOut: Date | null;
  guests: string;
  horses: string;
  currentOpen: string;
  loading:boolean;
  isShowAllImages:boolean;
  isExpanded:boolean;
  isDrawerOpen:boolean;
  showLoginPopup:boolean;
  bookingDetails:any;
  totalNights:any;
  bookingDetailsConfirm: any;
  additionalMsg: string;
  showBookingConfirmPopup:boolean;
  bookingDatesError:string;
  isRequestChanges:boolean;
  previousBookingDetails:any;
  reqSubmitedDialog:boolean,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class GuestBookingFormController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  productDetailsCallId: string = '';
  createBookingApiCall:string='';
  bookingDetailsCallId:string='';
  checkAvailabilityApiCall: string = '';
  getBookingDetailsApiCallId:string='';
  bookingChangeRequestApiCall:string='';
  getStripeCredsApiCallID:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      getName(MessageEnum.RestAPIRequestMethodMessage),
      getName(MessageEnum.RestAPIRequestBodyMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      isloading: false,

      // Customizable Area Start
      productData: { name: "", price: 1, description: "", images: [{url:""}] },
      product_id: 8,
      focusedImageIndex:1,
      loading:false,
      isShowAllImages:false,
      isExpanded:false,
      isDrawerOpen:false,
      showLoginPopup:false,
      productDetails:null,
      bookingDetails: {
        horse_accommodations: [],
        apartment: [],
        campsite: [],
        hookups: [],
        amenities: []
      },
      totalNights:0,
      bookingDetailsConfirm:null,
      additionalMsg:'',
      showBookingConfirmPopup:false,
      bookingDatesError:'',
      isRequestChanges:false,
      previousBookingDetails:null,
      reqSubmitedDialog:false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      switch(apiRequestCallId){
        case this.productDetailsCallId:this.handleProductDetailsResponse(responseJson);
        break;
        case this.createBookingApiCall:this.handleCreateBookingResponse(responseJson);
        break;
        case this.bookingDetailsCallId:this.state.isRequestChanges ? this.handleGetBookingDetailsResponse(responseJson):this.handlebookingDetailsResponse(responseJson);
        break;
        case this.checkAvailabilityApiCall:this.handleAvailabilityResponse(responseJson);
        break;
        case this.bookingChangeRequestApiCall:this.handleBookingChangeRequest(responseJson);
        break;
        case this.getStripeCredsApiCallID: await setStorageData( "stripeKeysData", JSON.stringify(responseJson));
        break;
      }
  }
    // Customizable Area End
  }

  // Customizable Area Start
  getProductDetails = async (id: any) => {
    if (!id) {
      return;
    }
    this.setState({ isloading: true })
    this.productDetailsCallId = await apiCall({
      method: "GET",
      endPoint: `bx_block_bulk_uploading/listings/${id}/show_listing`,
    });
   this.handleStorageData();
  }

  handleStorageData = () => {
    const checkIn = localStorage.getItem('checkIn');
    const checkOut = localStorage.getItem('checkOut');
    const guests = localStorage.getItem('guests');
    const horses = localStorage.getItem('horses');
    this.setState({ checkIn: checkIn ? new Date(checkIn) : null, checkOut: checkOut ? new Date(checkOut) : null, guests: guests || '0', horses: horses || '0' }, () => {
      this.calculateNights();
      this.checkAvailability();
    });
  }
   
  capitalizedText = (inputText: string) => inputText.charAt(0).toUpperCase() + inputText.slice(1);

  isEnableBookNow = () =>this.state.isRequestChanges ? (this.bookingTotal().total - this.state.previousBookingDetails?.attributes.total_price) !== 0 : (this.state.checkIn && this.state.checkOut && this.state.guests != '0' && this.bookingTotal().total > 0 && !this.state.bookingDatesError.length);
  
  getBookingDetails = async (id: any,isReqChange:boolean=false) => {
    if (!id) {
      return;
    }
    this.setState({ isloading: true, isRequestChanges: isReqChange },async()=>{
      this.bookingDetailsCallId = await apiCall({
        method: "GET",
        endPoint: `/bx_block_request_management/bookings/${id}`,
      });
    })
  }

  handleProductDetailsResponse = (responseJson: any) => {
    if (responseJson.data) {
      this.setState({ productDetails: responseJson.data });
      this.prepareBookingsForm();
      this.checkAvailability();
    }
    this.setState({ isloading: false });
  }

  handleBookingChangeRequest = (responseJson: any) => {
    if (responseJson?.data?.id) {
      this.toggleCRSubmitedDialog();
    }else if(responseJson.error){
      toast.error(responseJson.error)
    }
  }

  toggleCRSubmitedDialog = () => this.setState({ reqSubmitedDialog: !this.state.reqSubmitedDialog });

  onDoneClick = () => {
    this.toggleCRSubmitedDialog();
    const bookingId = this.state.previousBookingDetails.id;
    this.props.navigation.navigate("BookingDetail", { path: { bookingId } });
  }

  handleGetBookingDetailsResponse=(responseJson: any)=>{
    if (responseJson.data) {
      this.setState({ previousBookingDetails: responseJson.data }, () => {
        const listingId = this.state.previousBookingDetails?.attributes.listing.data.id;
        this.getProductDetails(listingId);
      });
    }
    this.setState({ isloading: false });
  }

  handlebookingDetailsResponse = (responseJson: any) => {
    if (responseJson.data) {
      this.setState({ bookingDetailsConfirm: responseJson.data });
    }
    this.setState({ isloading: false });
  }

  handleCreateBookingResponse = (responseJson) => {
    if (responseJson.data?.id) {
      toast.success('Booking create successfully');
      const bookingId = responseJson.data.id;
      this.props.navigation.navigate("GuestBookingConfirmation", { path: { bookingId } });
    } else {
      toast.error(JSON.stringify(responseJson.errors))
    }
  }

  handleAvailabilityResponse = (responseJson) => {
    if(this.areDatesSame(this.state.checkIn,this.state.checkOut)){
      this.setState({ bookingDatesError: 'Check in and Check out dates can not be same.' });
      return; 
    }
    const bookingIssue = responseJson.error?.[0]?.Booking?.booking;
    if (bookingIssue) {
      this.setState({ bookingDatesError: bookingIssue });
      return;
    }
    this.setState({ bookingDatesError: '' }); 
  }

  areDatesSame = (date1, date2) => {
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    return d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate();
  };

  uniqueKeys = {
    booking_horse_accommodations: 'type',
    booking_apartment: 'name',
    booking_campsite: 'name',
    booking_hookups: 'hookups_type',
    booking_amenities: 'type',
  }
  countKeys = {
    booking_horse_accommodations: 'selected_stall',
    booking_apartment: 'selected_guest_capacity',
    booking_campsite: 'selected_guest_capacity',
    booking_hookups: 'selected_spot_capacity',
    booking_amenities: 'selected_horse',
  }

  getPrevCount = (item, type) => {
    const prevData = this.state.previousBookingDetails.attributes[type];
    const found = prevData.find(prevItem => prevItem[this.uniqueKeys[type]] == item[this.uniqueKeys[type]]);
    return found ? found[this.countKeys[type]] : 0;
  }

  getPrevTotal = (item, type) => {
    return 0;
  }

  convertType = (item) => item.type === 'stall' ? 'indoor' : 'outdoor' 

  getAppendedData = (data: any, type: string) => {
    const updatedData = data.map((item, index) => ({
      ...item,
      type: type === 'horse_accommodations' ? this.convertType(item): item.type || '',
      id: index + '_' + type,
      count: this.state.isRequestChanges ? this.getPrevCount(item,'booking_'+type) : 0,
      total_price: this.state.isRequestChanges ? this.getPrevTotal(item,'booking_'+type) : 0,
      key: type,
      is_selected: (this.state.isRequestChanges && (this.getPrevCount(item, 'booking_' + type) > 0)) ? true : false
    }));
    return updatedData;
  }

  prepareBookingsForm = () => {
    const { attributes } = this.state.productDetails
    if (this.state.isRequestChanges) {
      const { start_date, end_date, total_nights, total_guest_count, total_horse_count } = this.state.previousBookingDetails.attributes;
      this.setState({ checkIn: new Date(start_date), checkOut: new Date(end_date), totalNights: total_nights, guests: total_guest_count, horses: total_horse_count });
    }
    const bookingDetails = {
      horse_accommodations: this.getAppendedData(attributes.horse_accommodations, 'horse_accommodations'),
      apartment: this.getAppendedData(attributes.apartment, 'apartment'),
      campsite: this.getAppendedData(attributes.campsite, 'campsite'),
      hookups: this.getAppendedData(attributes.hookups, 'hookups'),
      amenities: this.getAppendedData(attributes.amenities, 'amenities'),
    }
    this.setState({ bookingDetails: bookingDetails },()=>{
      this.recalculateAllPricing();
    });
  }

  pricingUnits = {
    horse_accommodations: 'price',
    apartment: 'price_per_night',
    campsite: 'price_per_night',
    hookups: 'price_per_night',
    amenities: 'price',
  }

  calculateNights = () => {
    const start = this.state.checkIn;
    const end = this.state.checkOut;
    if (isNaN(start?.getTime()) || isNaN(end?.getTime())) {
      return
    }
    const differenceInTime = end - start;
    const differenceInNights = differenceInTime / (1000 * 3600 * 24);
    const result = Math.max(0, Math.floor(differenceInNights));
    this.setState({ totalNights: result },()=>{
      this.recalculateAllPricing();
    })
  }

  formatDateStr = (date, options) => date.toLocaleDateString(undefined, options).replace(',', '/').replace(' ', ' ');

  getDatesString = () => {
    const { attributes } = this.state.bookingDetailsConfirm;
    const start = new Date(attributes.start_date);
    const end = new Date(attributes.end_date)
    const options = { year: '2-digit', month: 'short', day: '2-digit' };
    return this.formatDateStr(start, options) + ' - ' +this.formatDateStr(end, options) + ` ( ${attributes.total_nights} nights)`;
  }

  recalculate = (key) => {
    const unit = this.pricingUnits[key];
    return this.state.bookingDetails[key]?.map(ele => ({ ...ele, total_price: ele[unit] * ele.count * this.state.totalNights }))
  }

  recalculateAllPricing = () => {
    const bookingDetails = {
      horse_accommodations: this.recalculate('horse_accommodations'),
      apartment: this.recalculate('apartment'),
      campsite: this.recalculate('campsite'),
      hookups: this.recalculate('hookups'),
      amenities: this.recalculate('amenities'),
    }
    this.setState({ bookingDetails: bookingDetails });
  }

  recalculatePricing = (data, item) => {
    const unit = this.pricingUnits[item.key];
    return data?.map(ele => ele.id === item.id ? { ...ele, total_price: ele[unit] * ele.count * this.state.totalNights } : ele);
  }

  totalAccomodation = () => this.totalBlockPrice('hookups') + this.totalBlockPrice('apartment') + this.totalBlockPrice('campsite')

  handleFormChange = (item, val, type: 'select' | 'count') => {
    let key = item.key;
    let data = this.state.bookingDetails[key];
    let updatedData = [];
    if (type == 'select') {
      updatedData = data?.map(ele => ele.id === item.id ? { ...ele, is_selected: val } : ele);
    } else {
      updatedData = data?.map(ele => ele.id === item.id ? { ...ele, count: + val } : ele);
    }
    updatedData = this.recalculatePricing(updatedData, item);

    const bookingDetails = Object.assign(this.state.bookingDetails);
    bookingDetails[key] = updatedData;

    this.setState({ bookingDetails: bookingDetails })
  };

  totalBlockPrice = (key) => {
    const result = this.state.bookingDetails[key]?.reduce((accumulator, item) => {
      const isSelectable = ['apartment', 'campsite'].includes(key);
      if ((isSelectable && item.is_selected) || !isSelectable) {
        return accumulator + item.total_price;
      }
      return accumulator;
    }, 0);
    return result;
  }

  onChangeValue = (currentOpen: string) => {
    this.setState({ currentOpen });
  }

  formatCurrency = (value, showDecimals: boolean = false) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: showDecimals ? 2 : 0,
      maximumFractionDigits: showDecimals ? 2 : 0,
    }).format(value).replace('$', '$ ');
  };

  bookingTotal = () => {
    let total = 0;
    Object.keys(this.pricingUnits)?.forEach((key) => total += this.totalBlockPrice(key))
    const charges = total * 0.10;
    return { total: Number((total + charges).toFixed(2)) || 0, charges: Number(charges.toFixed(2)) || 0 }
  }

  convertFormat = (dateStr) => {
    const parts = dateStr.split('-');
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1;
    const year = parseInt(parts[2], 10);
    return new Date(year, month, day);
  }

  getBlackoutDates = () => {
    const blackouts = this.state.productDetails.attributes?.blackout_dates.map((item) => this.convertFormat(item));
    return blackouts;
  }

  guestSelectorData = () => {
    return { label: this.state.guests ? this.state.guests + ' guests' : this.state.guests, value: this.state.guests }
  }

  horseSelectorData = () => {
    return { label: this.state.horses ? this.state.horses + ' horses' : this.state.horses, value: this.state.horses }
  }

  onChangeStr = () => this.state.currentOpen === 'checkout' ? '' : 'checkout'

  isCheckInOpen = () => this.state.currentOpen === 'checkIn';

  getCheckoutVal = (date) => this.state.checkOut && date && date > this.state.checkOut ? null : this.state.checkOut;

  checkInTime = () => this.state.productDetails.attributes?.check_in_time;
  checkOutTime = () => this.state.productDetails.attributes?.check_out_time;

  renderGuestAccoodationsText = () => {
    const { booking_apartment, booking_campsite, booking_hookups } = this.state.bookingDetailsConfirm.attributes
    let str = '';
    booking_apartment.forEach(item => {
      str += item.name + ': ' + item.selected_guest_capacity + ' guests'
    });
    str += ' • Camp Site: '
    let count = 0;
    booking_campsite.forEach(item => {
      count += item.selected_guest_capacity
    });
    str += count + ' guests • Hookup:'
    booking_hookups.forEach(item => {
      str += ' ' + item.selected_spot_capacity + ' ' + item.hookups_type
    });
    return str;
  }

  renderHorseStablingText = () => {
    const { booking_horse_accommodations } = this.state.bookingDetailsConfirm.attributes
    let str = '';
    booking_horse_accommodations.forEach((item, index) => {
      str += (index >= 1 ? ' • ' : ' ') + item.type + ': ' + item.selected_stall + (item.selected_horse > 1 ? ' horses' : ' horse')
    });
    return str;
  }

  renderAmenitiesText = () => {
    const { booking_amenities } = this.state.bookingDetailsConfirm.attributes
    let str = '';
    booking_amenities.forEach((item, index) => {
      str += (index >= 1 ? ' • ' : ' ') + item.type + ': ' + item.selected_horse + (item.selected_horse > 1 ? ' horses' : ' horse')
    });
    return str;
  }

  togglePopup = () => this.setState({ showBookingConfirmPopup: !this.state.showBookingConfirmPopup });
  
  viewBookings=()=>{
    this.togglePopup();
    this.props.navigation.navigate("ManageBooking");
  }

  onChangeCheckout = (date) => {
    const [startDate, endDate] = date;
    if (!endDate) {
      this.setState({ checkOut: startDate }, () => {
        this.calculateNights();
        this.checkAvailability();
      });
      localStorage.setItem("checkOut", startDate?.toString() ?? "");
    } else {
      this.setState({ checkIn: startDate, checkOut: endDate }, () => {
        this.calculateNights();
        this.checkAvailability();
      });
      localStorage.setItem("checkIn", startDate?.toString() ?? "");
      localStorage.setItem("checkOut", endDate?.toString() ?? "");
    }
    this.onChangeValue('');
  }

  onChangeCheckIn = (date) => {
    const checkIn = date;
    const checkOut = this.getCheckoutVal(date);
    this.setState({ checkIn, checkOut }, () => {
      this.calculateNights();
      this.checkAvailability();
    });
    localStorage.setItem("checkIn", checkIn.toString());
    localStorage.setItem("checkOut", checkOut?.toString() ?? "");
    this.onChangeValue('');
  }

  getMinDate = (date) => new Date(date) < new Date() ? new Date() : new Date(date);

  onBackPress = () => {
    this.props.navigation.goBack?.();
  }

  formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  getSelected = (data) => data.filter(item => item.is_selected);
  getWithCount = (data) => data.filter(item => item.count);

  prepareBookingRequest = (isReqChange: boolean = false) => {
    const horse_accommodations = this.getWithCount(this.state.bookingDetails.horse_accommodations).map(item => (
      {
        type: item.type,
        number_of_stalls: item.number_of_stalls,
        price: item.price,
        selected_stall: item.count
      }
    ))
    const amenities = this.getWithCount(this.state.bookingDetails.amenities).map(item => (
      {
        type: item.type,
        price: item.price,
        price_per_horse: item.price_per_horse,
        selected_horse: item.count,
        horse_capacity:item.horse_capacity
      }
    ))
    const apartment = this.getSelected(this.getWithCount(this.state.bookingDetails.apartment)).map(item => (
      {
        name: item.name,
        guest_capacity: item.guest_capacity,
        price_per_night: item.price_per_night,
        selected_guest_capacity: item.count
      }
    ))
    const campsite = this.getSelected(this.getWithCount(this.state.bookingDetails.campsite)).map(item => (
      {
        name: item.name,
        guest_capacity: item.guest_capacity,
        price_per_night: item.price_per_night,
        selected_guest_capacity: item.count
      }
    ))
    const hookups = this.getWithCount(this.state.bookingDetails.hookups).map(item => (
      {
        hookups_type: item.hookups_type,
        hookups_spot: item.hookups_spot,
        price_per_night: item.price_per_night,
        voltage_type: item.voltage_type,
        selected_spot_capacity: item.count
      }
    ))
    let reqBody = {};
    if (isReqChange) {
      reqBody = {
        "data": {
          "request_change_start_date": this.formatDate(this.state.checkIn),
          "request_change_end_date": this.formatDate(this.state.checkOut),
          "request_change_horse_accommodations": horse_accommodations,
          "request_change_amenities": amenities,
          "request_change_apartment": apartment,
          "request_change_campsite": campsite,
          "request_change_hookups": hookups
        }
      }
    } else {
      reqBody = {
        "data": {
          "listing_id": +this.state.productDetails.id,
          "start_date": this.formatDate(this.state.checkIn),
          "end_date": this.formatDate(this.state.checkOut),
          "booking_horse_accommodations": horse_accommodations,
          "booking_amenities": amenities,
          "booking_apartment": apartment,
          "booking_campsite": campsite,
          "booking_hookups": hookups
        }
      }
    }
    return reqBody;
  }

  formatSelectedDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  checkAvailability = async () => {
    if ((!this.state.checkIn || !this.state.checkOut) || this.state.isRequestChanges) {
      return
    }
    const req = {
      "data": {
        "listing_id": this.state.productDetails?.id,
        "start_date": this.formatSelectedDate(this.state.checkIn),
        "end_date": this.formatSelectedDate(this.state.checkOut),
      }
    }
    const header = {
      token: localStorage.getItem("token"),
    }
    this.checkAvailabilityApiCall = await apiCall({
      header: header,
      contentType: "application/json",
      method: "POST",
      body: req,
      endPoint: "/bx_block_request_management/bookings/check_booking_availability",
    });
  }

  sendChangeRequest = async() => {
  const header = {
    token: localStorage.getItem("token"),
  }
  this.bookingChangeRequestApiCall = await apiCall({
    header: header,
    contentType: "application/json",
    method: "PUT",
    body: this.prepareBookingRequest(true),
    endPoint: `/bx_block_request_management/bookings/${this.state.previousBookingDetails.id}`,
  });

  }

  callGetStripeKeysApi = async() => {
    const header = {
      token: localStorage.getItem("token"),
    }
    this.getStripeCredsApiCallID = await apiCall({
      header: header,
      contentType: "application/json",
      method: "GET",
      endPoint: configJSON.getStripeKeysEndpoint,
    })}

  createBooking = async () => {
    if (!this.state.checkIn || !this.state.checkOut || !this.state.guests) {
      toast.error('Please fill visitors details');
      return
    }
    if(this.state.isRequestChanges){
      this.sendChangeRequest();
      return;
    }
    const header = {
      token: localStorage.getItem("token"),
    }
    this.createBookingApiCall = await apiCall({
      header: header,
      contentType: "application/json",
      method: "POST",
      body: this.prepareBookingRequest(),
      endPoint: "/bx_block_request_management/bookings",
    });
  }
  // Customizable Area End
}
