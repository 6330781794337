// Customizable Area Start
import { createRef } from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { apiCall } from "../../../../components/src/NavigationRouteWrapper/helpers";
import { toast } from "react-toastify";
import moment from "moment";
import { Upload } from "antd";
import type { UploadFile } from "antd/es/upload/interface";
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

export interface IOption {
  hash: string;
  label: string;
  status: string;
  required?: boolean;
}

interface IFIleRes {
  blob_id: number;
  file_name: string;
  file_url: string;
}

interface IAmenityRes {
  attributes: {
    amenity_name: string;
    amenity_icon: {
      url: string | null;
    }
  }
}

interface IGeneralInfo {
  title: string;
  location: string;
  latitude: string;
  longitude: string;
  checkIn: string;
  checkOut: string;
  description: string;
  photos: UploadFile<any>[];
}

interface IHorseStabling {
  name: string;
  type: string;
  price: string;
  horse_capacity: string;
}

export interface IGuest {
  name: string;
  guest: string;
  price: string;
  isNew?: boolean;
  isEdit?: boolean;
  obj?: string | null;
  nameErr?: string;
  priceErr?: string;
}

interface IList {
  apartment: IGuest[];
  campsite: IGuest[];
  hookup: IGuest[];
}

export type ItemType = 'apartment' | 'campsite' | 'hookup';

interface S {
  // Customizable Area Start
  statusLoading: boolean;
  loading: boolean;
  isLoading: boolean;
  isAllCompleted: boolean;
  isAmenityValid: boolean;
  option: IOption[];
  currentOption: number;
  amenitiesList: any[];
  horseStabling: IHorseStabling[];
  listingId: string | null;
  title: string;
  location: string;
  latitude: string;
  longitude: string;
  checkIn: string;
  checkOut: string;
  description: string;
  photos: any[];
  removeIds: string[];
  lists: IList;
  isAdding: {
    apartment: boolean;
    campsite: boolean;
    hookup: boolean;
  };
  startDate: Date | null;
  endDate: Date | null;
  currentOpen: string;
  blockedDates: string[];
  selectedDates: Date[];
  // Customizable Area End
}

interface SS {
  id: string;
}

export default class CreateListingController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getStatusInfoApiCallId: string = "";
  generalInfoApiCallId: string = "";
  fetchPreviousInfoApiCallId: string = "";
  updateAmenitiesApiCallId: string = "";
  updateHorseStablingApiCallId: string = "";
  updateGuestAccommodationApiCallId: string = "";
  updateAvailabilityApiCallId: string = "";
  publishListingApiCallId: string = "";
  scrollRef: React.RefObject<HTMLDivElement> = createRef();
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    const hash = window.location.hash.replace("#", "");
    const index = OPTIONS.findIndex((option) => option.hash === hash.toLowerCase());
    const currentOption = index !== -1 ? index : 0;
    this.state = {
      statusLoading: false,
      loading: false,
      isLoading: false,
      isAllCompleted: false,
      option: OPTIONS,
      isAmenityValid: false,
      horseStabling: HORSE_STABLING_OPTION,
      listingId: localStorage.getItem("listingId"),
      currentOption: currentOption,
      title: "",
      location: "",
      latitude: "-34.55",
      longitude: "-58.47",
      checkIn: "",
      checkOut: "",
      description: "",
      photos: [],
      removeIds: [],
      amenitiesList: [],
      lists: {
        apartment: [],
        campsite: [],
        hookup: [],
      },
      isAdding: {
        apartment: false,
        campsite: false,
        hookup: false,
      },
      startDate: null,
      endDate: null,
      currentOpen: "",
      selectedDates: [],
      blockedDates: [],
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.generalInfoApiCallId) {
        if (responseJson.data) {
          this.state.isLoading && toast.success("The data was successfully accepted!");
          this.handleInfoRes(responseJson);
          localStorage.setItem("listingId", responseJson.data.id);
          this.updateStatus("general_information");
        }
        this.setState({ loading: false, isLoading: false });
      } else if (apiRequestCallId === this.updateAmenitiesApiCallId) {
        if (responseJson.data) {
          toast.success("Amenities data save successfully!!");
          this.updateStatus("amenities");
        }
        this.setState({ isLoading: false });
      } else {
        this.handleResponse(apiRequestCallId, responseJson);
      }
    }
  }

  handleResponse = async (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.updateHorseStablingApiCallId) {
      if (responseJson.data) {
        toast.success("Horse & stabling data save successfully!!");
        this.updateStatus("horse_stabling");
      }
      this.setState({ isLoading: false });
    } else if (apiRequestCallId === this.updateGuestAccommodationApiCallId) {
      if (responseJson.data) {
        toast.success("Guest accommodation data save successfully!!");
        this.updateStatus("guest_accommodation");
      }
      this.setState({ isLoading: false });
    } else if (apiRequestCallId === this.updateAvailabilityApiCallId) {
      if (responseJson.data) {
        toast.success("Availability data save successfully!!");
        const blockedDates = responseJson.data.attributes.blackout_dates ?? [];
        this.setState({ blockedDates, selectedDates: [] });
        this.updateStatus("availability");
      }
      this.setState({ isLoading: false });
    } else if (apiRequestCallId === this.publishListingApiCallId) {
      if (responseJson.message === "Listing successfully published.") {
        toast.success("List successfully published!!");
        this.props.navigation.navigate("Listings");
      } else if (responseJson.message === "This listing has already been published.") {
        toast.info("This listing has already been published!!");
      }
      this.setState({ isLoading: false });
    } else {
      this.handleApiResponse(apiRequestCallId, responseJson);
    }
  }

  processOptions = (responseJson: { status: { [x: string]: string; }; }) => {
    return OPTIONS.map(option => {
      const status = responseJson.status?.[option.key];
      let newStatus = option.status;

      switch (status?.toLowerCase()) {
        case "completed":
          newStatus = "Completed";
          break;
        case "pending":
          newStatus = "Pending";
          break;
        case "in progress":
          newStatus = "In Progress";
          break;
        default:
          break
      }
      return { ...option, status: newStatus };
    });
  }

  handleApiResponse = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.getStatusInfoApiCallId) {
      if (responseJson.status) {
        const option = this.processOptions(responseJson);
        const requiredSteps = option.filter(opt => opt.required);
        const isAllCompleted = requiredSteps.every(opt => opt.status === "Completed");
        this.setState({ isAllCompleted, option });
      }
      this.setState({ statusLoading: false });
    } else if (apiRequestCallId === this.fetchPreviousInfoApiCallId) {
      if (responseJson.amenities) {
        this.handleAmenitiesResponse(responseJson);
      } else if (responseJson.horse_accommodation) {
        this.handleHorseAccommodationResponse(responseJson);
      } else if (responseJson.apartment || responseJson.campsite || responseJson.hookups) {
        this.handleGuestAccommodationResponse(responseJson);
      } else if (responseJson.availability) {
        this.handleAvailabilityResponse(responseJson);
      }
      this.setState({ loading: false });
    }
  }

  handleAmenitiesResponse = (responseJson: any) => {
    const amenitiesList = responseJson.amenities.map((item: any) => ({
      type: item.type,
      price: item.price,
      icon: item.amenity_icon,
      price_per_horse: item.price_per_horse,
      selected: this.getPriceLabel(item.price),
      horse_capacity: item.horse_capacity || "0",
    }));
    this.setState({ amenitiesList });
  }

  handleHorseAccommodationResponse(responseJson: any) {
    const horseStabling = HORSE_STABLING_OPTION.map(option => {
      const matchingRes = responseJson.horse_accommodation?.find((item: { type: string }) => item.type === option.type);
      return matchingRes ? {
        ...option,
        price: matchingRes.price,
        horse_capacity: matchingRes.number_of_stalls || "0",
        name: matchingRes.type === "indoor" ? "Stall" : "Pasture Capacity"
      } : option;
    });
    this.setState({ horseStabling });
  }

  handleGuestAccommodationResponse(responseJson: any) {
    const lists: IList = {
      apartment: responseJson.apartment?.length > 0
        ? this.handleGuestRes(responseJson.apartment) : [],
      campsite: responseJson.campsite?.length > 0
        ? this.handleGuestRes(responseJson.campsite) : [],
      hookup: responseJson.hookups?.length > 0
        ? this.handleHookupsRes(responseJson.hookups) : []
    };
    this.setState({
      lists,
      isAdding: {
        apartment: false,
        campsite: false,
        hookup: false,
      }
    });
  }

  handleAvailabilityResponse(responseJson: any) {
    const { available_from, available_until, blackout_dates } = responseJson.availability;
    this.setState({
      selectedDates: [],
      startDate: available_from && new Date(available_from),
      endDate: available_until && new Date(available_until),
      blockedDates: blackout_dates ?? [],
    });
  }

  getPriceLabel = (price: number | null) => {
    if (price === null) return null;
    if (price === 0) return "free";
    return "set_price";
  }

  handleGuestRes = (arr: any[]) => {
    return arr.map((obj) => ({
      name: obj.name,
      guest: obj.guest_capacity,
      price: obj.price_per_night,
    }));
  }

  handleHookupsRes = (arr: any[]) => {
    return arr.map((obj) => ({
      name: obj.hookups_type,
      guest: obj.hookups_spot,
      price: obj.price_per_night,
    }));
  }

  handleInfoRes = (responseJson: any) => {
    const res = responseJson.data.attributes;
    this.setState({
      removeIds: [],
      title: res.title,
      location: res.address,
      latitude: res.latitude,
      longitude: res.longitude,
      description: res.description,
      listingId: responseJson.data.id,
      checkIn: moment(res.check_in_time, "hh:mm A").format("HH:mm"),
      checkOut: moment(res.check_out_time, "hh:mm A").format("HH:mm"),
      photos: res.files.map((file: IFIleRes) => ({
        isUrl: true,
        uid: file.blob_id.toString(),
        name: file.file_name,
        url: file.file_url
      })),
    });
  }

  updateStatus = (key: string, status: string = "Completed") => {
    const option = this.state.option.map((item: any) =>
      item.key === key ? { ...item, status } : item
    );
    const requiredSteps = option.filter(opt => opt.required);
    const isAllCompleted = requiredSteps.every(opt => opt.status === "Completed");
    this.setState({ option, isAllCompleted });
  }

  async componentDidMount() {
    this.fetchData();
    this.getStatusInfo();
    window.addEventListener("hashchange", this.handleHashChange);
  }

  async componentWillUnmount() {
    window.removeEventListener("hashchange", this.handleHashChange);
  }

  handleHashChange = () => {
    const hash = window.location.hash.replace("#", "");
    const index = OPTIONS.findIndex((option) => option.hash === hash.toLowerCase());
    const currentOption = index !== -1 ? index : 0;
    if (this.state.currentOption !== currentOption) {
      this.setState({ currentOption }, () => {
        this.scrollToTop();
      });
    }
  }

  scrollToTop = () => {
    if (this.scrollRef.current) {
      this.scrollRef.current.scrollTo({ top: 0 });
    }
  }

  goBack = () => {
    this.props.navigation.goBack();
  }

  onPreview = () => {
    if (!this.state.listingId) return;
    this.props.navigation.navigate("PreviewListing", { path: { id: this.state.listingId } });
  }

  handleAmenityChange = (index: number, key: string, value: string) => {
    this.setState((prevState) => {
      const amenitiesList = [...prevState.amenitiesList];
      const amenity = { ...amenitiesList[index], [key]: value };
      switch (key) {
        case "selected":
          if (amenity.horse_capacity === "0") amenity.horseErr = "Please select horses";
          if (value === "free") amenity.price = "";
          else if (value === "set_price" && !amenity.price) amenity.priceErr = "Please add price";
          amenity.radioErr = "";
          break;
        case "price":
          if (!value.trim()) {
            amenity.priceErr = "Please add price";
          } else if (Number(value) === 0) {
            amenity.priceErr = "Price can't be 0";
          } else {
            amenity.priceErr = "";
          }
          break;
        case "horse_capacity":
          if (!value) {
            amenity.horseErr = "Please select horses";
          } else {
            amenity.horseErr = "";
          }
          if (!amenity.selected) {
            amenity.radioErr = "Please select option";
          }
          break;
      }
      amenitiesList[index] = amenity;
      const isAmenityValid = amenitiesList.every((item) => !item.radioErr && !item.horseErr && !item.priceErr);
      return { amenitiesList, isAmenityValid };
    });
  }

  onChangeOption = (hash: string, currentOption: number) => {
    const { listingId, currentOption: prevOption } = this.state;
    if (listingId || currentOption === 0) {
      if (prevOption !== currentOption) {
        window.history.replaceState(null, "", `#${hash}`);
        this.setState({
          currentOption,
          isAmenityValid: false,
          isLoading: false,
        }, () => {
          this.fetchData();
          this.scrollToTop();
        });
      }
    }
  }

  fetchData = () => {
    if (!this.state.listingId) return;

    const { currentOption } = this.state;
    const optionHandlers: Record<number, () => void | Promise<void>> = {
      0: () => this.getGeneralInfo(),
      1: () => this.fetchPreviousInfo("horse_stabling"),
      2: () => this.fetchPreviousInfo("guest_accommodation"),
      3: () => this.fetchPreviousInfo("amenities"),
      4: () => this.fetchPreviousInfo("availability")
    };
    optionHandlers[currentOption]?.();
  }

  beforeUpload = (file: File) => {
    const isJpegOrHeic = ["image/jpg", "image/jpeg", "image/heic", "image/heif"].includes(file.type);
    const isLt5MB = file.size / 1024 / 1024 < 5;
    if (!isJpegOrHeic) {
      toast.error("Only HEIC, JPG or JPEG files are allowed.");
      return Upload.LIST_IGNORE;
    }
    if (!isLt5MB) {
      toast.error("File must be smaller than 5MB.");
      return Upload.LIST_IGNORE;
    }
    return false;
  }

  addItem = (type: ItemType) => {
    this.setState((prevState) => ({
      isAdding: { ...prevState.isAdding, [type]: true },
      lists: {
        ...prevState.lists,
        [type]: [...prevState.lists[type], { name: '', guest: '0', price: '', isNew: true, isEdit: false, obj: null }],
      },
    }));
  }

  updateItemField = (type: ItemType, index: number, field: keyof IGuest, value: string) => {
    this.setState((prevState) => {
      const lists = { ...prevState.lists };
      const obj = { ...lists[type][index], [field]: value };
      switch (field) {
        case "name":
          if (!obj.name) obj.nameErr = "Name is required";
          else obj.nameErr = "";
          break;
        case "price":
          if (!obj.price) obj.priceErr = "Price is required";
          else if (Number(obj.price) === 0) obj.priceErr = "Price can't be 0";
          else obj.priceErr = "";
          break;
        default:
          break;
      }
      lists[type][index] = obj;
      return { lists };
    });
  }

  handleItemSave = (type: ItemType, index: number) => {
    this.setState((prevState) => {
      const lists = { ...prevState.lists };
      lists[type][index] = { ...lists[type][index], isNew: false, isEdit: false, obj: null };
      return { lists, isAdding: { ...prevState.isAdding, [type]: false } }
    });
  }

  editItem = (type: ItemType, index: number) => {
    this.setState((prevState) => {
      const lists = { ...prevState.lists };
      const { name, guest, price } = lists[type][index];
      lists[type][index] = { ...lists[type][index], isEdit: true, obj: JSON.stringify({ name, guest, price }) };
      return { lists };
    });
  }

  deleteItem = (type: ItemType, index: number) => {
    this.setState((prevState) => {
      const lists = { ...prevState.lists };
      const item = lists[type][index];
      const isAdding = { ...prevState.isAdding };

      if (!item.isNew && item.obj) {
        const { name, guest, price } = JSON.parse(item.obj);
        lists[type][index] = { ...item, obj: null, isNew: false, isEdit: false, name, guest, price };
      } else {
        lists[type] = lists[type].filter((_, i) => i !== index);
        isAdding[type] = false;
      }
      return { lists, isAdding };
    });
  }

  onChangeCurrentOpen = (currentOpen: string) => {
    this.setState({ currentOpen });
  }

  handleDateChange = (isStart: boolean, endDate: Date | null, date: Date | [Date | null, Date | null] | null) => {
    if (isStart) {
      const newStartDate = date as Date;
      const newEndDate = endDate && newStartDate > endDate ? null : endDate;
      this.setState({ startDate: newStartDate, endDate: newEndDate });
      this.onChangeDate(newStartDate, newEndDate);
    } else {
      const [startDate, endDate] = date as [Date | null, Date | null];
      if (!endDate) {
        this.setState({ endDate: startDate });
        this.onChangeDate(null, startDate);
      } else {
        this.setState({ startDate, endDate });
        this.onChangeDate(startDate, startDate);
      }
    }
  }

  onChangeDate = (startDate: Date | null, endDate: Date | null) => {
    const { selectedDates } = this.state;
    let filteredDates = selectedDates;
    if (startDate) {
      filteredDates = filteredDates.filter(dateStr =>
        moment(dateStr, 'DD-MM-YYYY').isSameOrAfter(moment(startDate), 'day')
      );
    }
    if (endDate) {
      filteredDates = filteredDates.filter(dateStr =>
        moment(dateStr, 'DD-MM-YYYY').isSameOrBefore(moment(endDate), 'day')
      );
    }
    this.setState({ selectedDates: filteredDates, currentOpen: '' });
  }

  blackedDates = (date: Date) => {
    const { startDate, endDate, blockedDates } = this.state;
    if (!startDate || !endDate) return '';

    const dateStr = moment(date).format('DD-MM-YYYY');
    const isBlocked = blockedDates.some(blockedDate =>
      moment(blockedDate, 'DD-MM-YYYY').isBetween(moment(startDate), moment(endDate), undefined, '[]')
      && blockedDate === dateStr
    );
    return isBlocked ? 'blocked-dates' : '';
  };

  getAvailabilitySaveTitle = () => {
    const { selectedDates } = this.state;
    if (selectedDates.length === 0) {
      return "Save";
    } else {
      const hasBlockedDates = selectedDates.some((date) => this.isPresentInBlockedDate(date));
      return hasBlockedDates ? "Unblocked Dates" : "Blocked Dates";
    }
  }

  isPresentInBlockedDate = (date: Date) => {
    const { blockedDates } = this.state;
    return blockedDates.some((blockedDate) => blockedDate === moment(date).format("DD-MM-YYYY"));
  }

  handleSelectedDates = (newDates: Date[]) => {
    if (newDates.length === 0) {
      this.setState({ selectedDates: newDates });
      return;
    }

    let selectedDates: Date[];
    const lastDate = newDates[newDates.length - 1];
    const isLastDateBlocked = this.isPresentInBlockedDate(lastDate);

    if (isLastDateBlocked) {
      selectedDates = newDates.filter((date) => this.isPresentInBlockedDate(date))
    } else {
      selectedDates = newDates.filter((date) => !this.isPresentInBlockedDate(date));
    }
    this.setState({ selectedDates });
  }

  updateGeneralInfo = async (values: IGeneralInfo) => {
    this.setState({ isLoading: true });
    const header = {
      token: localStorage.getItem("token")
    }

    const { title, location, latitude, longitude, checkIn, checkOut, description, photos } = values;
    const formData = new FormData();
    formData.append("data[title]", title);
    formData.append("data[address]", location);
    formData.append("data[latitude]", latitude);
    formData.append("data[longitude]", longitude);
    checkIn && formData.append("data[check_in_time]", checkIn);
    checkOut && formData.append("data[check_out_time]", checkOut);
    description && formData.append("data[description]", description);
    if (photos.length > 0) {
      photos.forEach((photo) => {
        !photo.url && formData.append(`data[files][]`, photo.originFileObj ?? "");
      });
    }

    let url = "/bx_block_bulk_uploading/listings";
    if (this.state.listingId) {
      url += `/${this.state.listingId}`;
    }
    if (this.state.removeIds.length > 0) {
      url += `?remove_blob_ids=[${this.state.removeIds.join(",")}]`
    }

    this.generalInfoApiCallId = await apiCall({
      header,
      type: "formData",
      body: formData,
      endPoint: url,
      method: this.state.listingId ? "PATCH" : "POST",
    });
  }

  updateGuestAccommodation = async () => {
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    }
    const { lists } = this.state;
    const transformItem = (item: IGuest, isHookup: boolean = false) => {
      let { name, guest, price } = item;
      if (item.isEdit && item.obj) {
        const obj = JSON.parse(item.obj);
        name = obj.name;
        guest = obj.guest;
        price = obj.price;
      }
      return isHookup
        ? {
          hookups_type: name,
          hookups_spot: guest,
          price_per_night: price,
        }
        : {
          name: name,
          guest_capacity: guest,
          price_per_night: price,
        };
    }
    const body = {
      "data": {
        "apartment": lists.apartment.map((item) => transformItem(item)),
        "campsite": lists.campsite.map((item) => transformItem(item)),
        "hookups": lists.hookup.map((item) => transformItem(item, true)),
      },
    };
    this.updateGuestAccommodationApiCallId = await apiCall({
      header,
      body,
      method: "PUT",
      endPoint: `/bx_block_bulk_uploading/listings/${this.state.listingId}/add_guest_accommodation`,
    });
  }

  updateHorseStabling = async (values: { horseStabling: IHorseStabling[] }) => {
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    }
    const horseStabling = values.horseStabling
      .filter((item) => item.horse_capacity !== "0" && item.price !== "")
      .map((item) => {
        return {
          "type": item.type,
          "price": item.price,
          "number_of_stalls": item.horse_capacity,
        }
      });
    const body = {
      "data": {
        "horse_accommodations": horseStabling
      }
    }
    this.updateHorseStablingApiCallId = await apiCall({
      header,
      body,
      method: "PUT",
      endPoint: `/bx_block_bulk_uploading/listings/${this.state.listingId}/horse_stabling_or_pasture`,
    });
  }

  updateAmenities = async () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    }
    const amenities = this.state.amenitiesList
      .filter((item) => item.horse_capacity !== "0" && item.selected)
      .map((item) => {
        return {
          "type": item.type,
          "horse_capacity": item.horse_capacity,
          "price_per_horse": item.price ? true : false,
          "price": item.selected === "free" ? 0 : item.price,
        }
      });
    if (amenities.length === 0) {
      toast.warn("Add at least one amenity!");
      return;
    }

    this.setState({ isLoading: true });
    const body = {
      "data": {
        "amenities": amenities
      }
    }
    this.updateAmenitiesApiCallId = await apiCall({
      header,
      body,
      method: "PUT",
      endPoint: `bx_block_bulk_uploading/listings/${this.state.listingId}/add_amenities`,
    });
  }

  updateAvailability = async (btnType: string) => {
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    }
    const { selectedDates, blockedDates, startDate, endDate } = this.state;
    if (!startDate || !endDate) return;

    const startDateStr = moment(startDate).format('DD-MM-YYYY');
    const endDateStr = moment(endDate).format('DD-MM-YYYY');

    let blackoutDates = btnType === 'Unblocked Dates'
      ? blockedDates.filter(
        blockedDate => !selectedDates.some(date => moment(date).format('DD-MM-YYYY') === blockedDate))
      : [...selectedDates.map(date => moment(date).format('DD-MM-YYYY')), ...blockedDates];

    blackoutDates = blackoutDates.filter(dateStr =>
      moment(dateStr, 'DD-MM-YYYY').isBetween(moment(startDateStr, 'DD-MM-YYYY'), moment(endDateStr, 'DD-MM-YYYY'), undefined, '[]')
    );

    const body = {
      "data": {
        "available_dates": {
          "start_date": startDateStr,
          "end_date": endDateStr,
        },
        "blackout_dates": [...new Set(blackoutDates)]
      }
    }
    this.updateAvailabilityApiCallId = await apiCall({
      header,
      body,
      method: "PUT",
      endPoint: `/bx_block_bulk_uploading/listings/${this.state.listingId}/add_availability`,
    });
  }

  getGeneralInfo = async () => {
    this.setState({ loading: true });
    const header = {
      token: localStorage.getItem("token")
    }
    this.generalInfoApiCallId = await apiCall({
      header,
      method: "GET",
      endPoint: `/bx_block_bulk_uploading/listings/${this.state.listingId}`,
    });
  }

  fetchPreviousInfo = async (type: string) => {
    this.setState({ loading: true });

    const header = {
      token: localStorage.getItem("token")
    }
    this.fetchPreviousInfoApiCallId = await apiCall({
      header,
      method: "GET",
      endPoint: `/bx_block_bulk_uploading/listings/get_listing_state?type=${type}&listing_id=${this.state.listingId}`,
    });
  }

  getStatusInfo = async () => {
    if (!this.state.listingId) return;

    this.setState({ statusLoading: true });
    const header = {
      token: localStorage.getItem("token")
    }
    this.getStatusInfoApiCallId = await apiCall({
      header,
      method: "GET",
      endPoint: `/bx_block_bulk_uploading/listings/get_listing_state?listing_id=${this.state.listingId}`,
    });
  }

  publishListing = async () => {
    this.setState({ isLoading: true });
    const header = {
      token: localStorage.getItem("token")
    }
    this.publishListingApiCallId = await apiCall({
      method: "GET",
      header,
      endPoint: `/bx_block_bulk_uploading/listings/${this.state.listingId}/publish_listing`,
    });
  }
  // Customizable Area End
}

// Customizable Area Start
const OPTIONS = [
  { label: "General Information", hash: "general-info", key: "general_information", status: "Pending", required: true },
  { label: "Horse Stabling", hash: "horse-stabling", key: "horse_stabling", status: "Pending" },
  { label: "Guest accommodation", hash: "guest-accommodation", key: "guest_accommodation", status: "Pending" },
  { label: "Amenities", hash: "amenities", key: "amenities", status: "Pending" },
  { label: "Availability", hash: "availability", key: "availability", status: "Pending", required: true },
  { label: "Payment details", hash: "payment-details", key: "payment_details", status: "Pending", required: true },
  { label: "KYC Verification", hash: "kyc-verification", key: "kyc_verification", status: "Pending", required: true },
];

const HORSE_STABLING_OPTION = [{
  name: "Stall",
  type: "indoor",
  price: "",
  horse_capacity: "0",
},
{
  name: "Pasture Capacity",
  type: "outdoor",
  price: "",
  horse_capacity: "0",
}];
// Customizable Area End