// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall } from "../../../components/src/NavigationRouteWrapper/helpers";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  isLoading: boolean;
  emailMsg: boolean;
  emailReview: boolean;
  pushMsg: boolean;
  pushReview: boolean;
  // Customizable Area End
}

interface SS {
  id: string;
}

export default class NotificationSettingController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getSettingApiCallId: string = "";
  postSettingApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      loading: false,
      isLoading: false,
      emailMsg: false,
      emailReview: false,
      pushMsg: false,
      pushReview: false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getSettingApiCallId || apiRequestCallId === this.postSettingApiCallId) {
        if (responseJson.data) {
          const res = responseJson.data?.attributes;
          this.setState({
            emailReview: res?.email_reviews || false,
            emailMsg: res?.email_new_message || false,
            pushReview: res?.push_reviews || false,
            pushMsg: res?.push_new_message || false,
          });
        }
        this.setState({ loading: false, isLoading: false });
      }
    }
  }

  async componentDidMount() {
    this.getSettings();
  }

  onChangeToggle = async (field: string, val: boolean) => {
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    }
    const body = {
      "notification_setting": {
        "email_reviews": field === "emailReview" ? val : undefined,
        "email_new_message": field === "emailMsg" ? val : undefined,
        "push_reviews": field === "pushReview" ? val : undefined,
        "push_new_message": field === "pushMsg" ? val : undefined,
      }
    }

    this.postSettingApiCallId = await apiCall({
      header,
      body,
      method: "PATCH",
      endPoint: `/bx_block_settings/notification_settings`,
    });
  }

  getSettings = async () => {
    this.setState({ loading: true });
    const header = {
      token: localStorage.getItem("token")
    }
    this.getSettingApiCallId = await apiCall({
      header,
      method: "GET",
      endPoint: `/bx_block_settings/notification_settings`,
    });
  }
  // Customizable Area End
}