// Customizable Area Start
import React from "react";
import {
  Box, Typography, CircularProgress, Button, Card, CardContent, CardMedia,
  Avatar, Accordion, AccordionSummary, AccordionDetails, IconButton,
  Divider,
  Dialog,DialogTitle,DialogContent,DialogActions,
  TextField
} from "@mui/material";
import  SliderStyles  from '../../../../components/src/SliderStyles.web';
import { styled } from '@mui/material/styles';
import Slider from "react-slick";
import moment from "moment";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import EventBusyOutlinedIcon from '@mui/icons-material/EventBusyOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CloseIcon from '@mui/icons-material/Close';
import BookingDetailController, { IFile, IAmenities, IHorseAcc } from "./BookingDetailController.web";
import Header from "../../../../components/src/AppHeader.web";
import Loader from "../../../../components/src/Loader.web";
import StripePay from "../../../stripepayments/src/StripePay.web";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// Customizable Area End

export default class BookingDetail extends BookingDetailController {
  // Customizable Area Start

  getColor = (status: string) => {
    let color = "orange";
    if (status === "Confirmed" || status === "Pending") {
      color = "blue";
    } else if (status === "Cancelled_by_guest" || status === "Cancelled_by_host") {
      color = "red";
    } else if (status === "Active") {
      color = "active";
    }
    return color;
  }

  getStatus = (status: string) => {
    const { role } = this.state;
    const statusMap: Record<string, string> = {
      cancelled_by_host: role !== "guest" ? "Cancelled By You" : "Cancelled by Host",
      cancelled_by_guest: role === "guest" ? "Cancelled By You" : "Cancelled By Guest",
    };
    return statusMap[status.toLowerCase()]
      || status.split(/[_\s]+/).map((word: string) => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
  }

  getImage = (name: string, url: string) => {
    return (
      <CardMedia
        key={name}
        component="img"
        className="image"
        height="185"
        alt={name}
        image={url}
      />
    );
  }

  renderBasicInfo = () => {
    const data = this.state.data.attributes;
    const listing = data.listing.data.attributes;
    return (
      <CustomCard className="basic-info">
        <Box width="300px">
          <Box className="slider">
            <Slider {...{
              dots: true,
              infinite: true,
              customPaging: () => <Dot />,
            }}>
              {listing.cover_photo.url && this.getImage(listing.cover_photo.filename, listing.cover_photo.url)}
              {Array.isArray(listing.files) && listing.files.map((file: IFile) => {
                return this.getImage(file.file_name, file.url)
              })}
            </Slider>
          </Box>
        </Box>
        <CardContent className="content">
          <Typography className="title">{listing.title}</Typography>
          <Typography className="address">{listing.address}</Typography>
          {this.isChangeRequested() ? <Typography className="blur-text">{moment(data.request_change_start_date).format("MMM DD/YY")} - {moment(data.request_change_end_date).format("MMM DD/YY")} ({data.change_request_total_night} {this.nightsText(data.change_request_total_night)})</Typography>:
          <Typography className="blur-text">{moment(data.start_date).format("MMM DD/YY")} - {moment(data.end_date).format("MMM DD/YY")} ({data.total_nights} {this.nightsText(data.total_nights)})</Typography>}
          <Typography className="blur-text">{listing.total_guest} {listing.total_guest > 1 ? "Guests" : "Guest"} • {listing.total_horse} {this.horsesText(listing.total_horse)}</Typography>
          <Typography className={`status ${this.getColor(data.status)}`}>{this.getStatus(data.status)}</Typography>
        </CardContent>
      </CustomCard>
    );
  }

  renderBookingId = () => {
    return (
      <CustomCard>
        <Box p="10px">
          <Typography className="card-title">
            Booking ID
            <span>{this.state.data.attributes.booking_id}</span>
          </Typography>
          <Box pl="5px">
            <Box className="items">
              <Typography className="sub-title">View Listing</Typography>
              <IconButton onClick={this.redirectToListing}>
                <KeyboardArrowRightIcon sx={{ color: "000" }} />
              </IconButton>
            </Box>
            <Box className="items">
              <Typography className="sub-title">Change Request History</Typography>
              <IconButton onClick={this.redirectToListing}>
                <KeyboardArrowRightIcon sx={{ color: "000" }} />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </CustomCard>
    );
  }

  renderImportantInfo = () => {
    const { check_in_time, check_out_time } = this.state.data.attributes;
    return (
      <CustomCard>
        <Box p="10px">
          <Typography className="card-title">
            Important Info
          </Typography>
          <Box display="flex" flexDirection="column" gap="15px">
            <Box className="sub-wrap">
              <AccessTimeIcon />
              <Typography className="sub-title">Check in time {check_in_time}</Typography>
            </Box>
            <Box className="sub-wrap">
              <AccessTimeIcon />
              <Typography className="sub-title">Check out time {check_out_time}</Typography>
            </Box>
            <Box className="sub-wrap between">
              <Box className="sub-wrap">
                <AttachMoneyIcon />
                <Typography className="sub-title">10% Service Fee</Typography>
              </Box>
              <Typography className="link">Cancellation & Refund Policy</Typography>
            </Box>
          </Box>
        </Box>
      </CustomCard>
    );
  }

  renderTripInfo = () => {
    const isCR = this.isChangeRequested();
    const data = this.state.data.attributes;
    const accommodation = this.guestAccommodationsInfo();
    const horseStabling = this.horseStablingInfo();
    const amenities = this.amenitiesInfo();
    const accommodation_cr = accommodation != this.guestAccommodationsInfo(isCR) ? this.guestAccommodationsInfo(isCR) : null;
    const horseStabling_cr = horseStabling != this.horseStablingInfo(isCR) ? this.horseStablingInfo(isCR) : null;
    const amenities_cr = amenities != this.amenitiesInfo(isCR) ? this.amenitiesInfo(isCR) : null;
    return (
      <CustomCard>
        <Box p="10px">
          <Typography className="card-title">
            Check the trip information
          </Typography>
          <Box>
            {this.isChangeRequested() ? <><Box mb="5px">
              <Typography className="trip-title">Dates</Typography>
              <Typography className="trip-sub-striked">{moment(data.start_date).format("MMM DD/YY")} - {moment(data.end_date).format("MMM DD/YY")} ({data.total_nights} {this.nightsText(data.total_nights)})</Typography>
            </Box><Box mb="15px">
                <Typography className="trip-sub">{moment(data.request_change_start_date).format("MMM DD/YY")} - {moment(data.request_change_end_date).format("MMM DD/YY")} ({data.change_request_total_night} {data.change_request_total_night > 1 ? "nights" : "night"})</Typography>
              </Box></> :
              <Box mb="15px">
                <Typography className="trip-title">Dates</Typography>
                <Typography className="trip-sub">{moment(data.start_date).format("MMM DD/YY")} - {moment(data.end_date).format("MMM DD/YY")} ({data.total_nights} {this.nightsText(data.total_nights)})</Typography>
              </Box>}
            {accommodation && (
              <Box mb="15px">
                <Typography className="trip-title">Guests accommodations</Typography>
                <Typography className={`${accommodation_cr ? 'trip-sub-striked' : 'trip-sub'}`}>{accommodation}</Typography>
                {accommodation_cr && <Typography className="trip-sub">{accommodation_cr}</Typography>}
              </Box>
            )}
            {horseStabling && (
              <Box mb="15px">
                <Typography className="trip-title">Horses stabling</Typography>
                <Typography className={`${horseStabling_cr ? 'trip-sub-striked' : 'trip-sub'}`}>{horseStabling}</Typography>
                {horseStabling_cr && <Typography className="trip-sub">{horseStabling_cr}</Typography>}
              </Box>
            )}
            {amenities && (
              <Box>
                <Typography className="trip-title">Amenities</Typography>
                <Typography className={`${amenities_cr ? 'trip-sub-striked' : 'trip-sub'}`}>{amenities}</Typography>
                {amenities_cr && <Typography className="trip-sub">{amenities_cr}</Typography>}
              </Box>
            )}
          </Box>
        </Box>
      </CustomCard>
    );
  }

  renderHostInfo = () => {
    const { host_account, guest_account } = this.state.data.attributes;
    const userInfo = this.state.role === "host" ? guest_account : host_account;
    return (
      <CustomCard>
        <Box p="10px">
          <Typography className="card-title">
            {this.state.role === "host" ? "Guest Info" : "Host info"}
          </Typography>
          <Box mt="25px" display="flex" justifyContent="space-between">
            <Box className="user-info">
              <Avatar className="avatar" src={userInfo.profile_photo?.url} />
              <Box>
                <Typography className="user-name">{userInfo.first_name + " " + userInfo.last_name}</Typography>
                <Typography className="info">{this.state.role === "guest" && `${userInfo.listings_count} listing •`} {userInfo.reviews_count} Ratings</Typography>
              </Box>
            </Box>
            <Box className="rating">
              <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.91692 9.92509C2.08644 10.0545 2.29127 10.0138 2.54202 9.82155L5 7.93564L7.45798 9.82155C7.70873 10.0138 7.91709 10.0545 8.08308 9.92509C8.24553 9.79567 8.28438 9.58119 8.18549 9.27057L7.22137 6.24571L9.69701 4.38198C9.94775 4.19339 10.0466 3.9974 9.97954 3.79032C9.91597 3.59063 9.73233 3.4834 9.41448 3.48709L6.37732 3.50558L5.44851 0.46963C5.35316 0.155311 5.20483 0 5 0C4.79517 0 4.64684 0.155311 4.55149 0.46963L3.62268 3.50558L0.585516 3.48709C0.271205 3.4834 0.0840309 3.59063 0.0204624 3.79032C-0.0466378 3.9974 0.0522467 4.19339 0.302989 4.38198L2.77863 6.24571L1.81804 9.27057C1.71562 9.58119 1.75447 9.79567 1.91692 9.92509Z" fill="#003249" />
              </svg>
              <span>{userInfo.average_rating_count}</span>
            </Box>
            <Box>
              <WhiteButton data-test-id="sendMsgBtn" sx={{ width: "250px" }} onClick={() => this.navigateToInbox(userInfo)}>Send a Message </WhiteButton>
            </Box>
          </Box>
        </Box>
      </CustomCard>
    );
  }

  formatCurrency = (value: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value).replace('$', '$ ');
  }

  renderPaymentDetails = () => {
    const data = this.state.data.attributes;
    const key = this.getKeyBasedOnRole();
    const breakdownKey=this.getBreakdownKey();
    return (
      <Box className="payment-detail">
        <Typography className="title">Payment Detail</Typography>
        {this.isChangeRequested() ? <Typography className="nights">{data.change_request_total_night} { this.nightsText(data.change_request_total_night)}</Typography> : <Typography className="nights">{data.total_nights} {this.nightsText(data.total_nights)}</Typography>}
        <Box pl="20px">
          {!!data[breakdownKey].horse_accommodations && (
            <CustomAccordion defaultExpanded>
              <CustomAccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: '#003249' }} />}>
                <Box className="wrap-info">
                  <Typography className="text">Horse stabling</Typography>
                  <Typography className="text">{this.formatCurrency(data[breakdownKey].horse_accommodations)}</Typography>
                </Box>
              </CustomAccordionSummary>
              <CustomAccordionDetails>
                {data.booking_horse_accommodations.map((item: IHorseAcc) =>
                  <Box className="price-wrap">
                    <Typography className="price">{item.type}</Typography>
                    <Typography className="price">{this.formatCurrency(item.selected_item_price)}</Typography>
                  </Box>
                )}
              </CustomAccordionDetails>
            </CustomAccordion>
          )}
          {!!data[breakdownKey].guest_accommodations && (
            <CustomAccordion defaultExpanded>
              <CustomAccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: '#003249' }} />}>
                <Box className="wrap-info">
                  <Typography className="text">Guest accommodation</Typography>
                  <Typography className="text">{this.formatCurrency(data[breakdownKey].guest_accommodations)}</Typography>
                </Box>
              </CustomAccordionSummary>
              <CustomAccordionDetails>
                {!!data[breakdownKey].breakdown_guest_accommodations.apartment && (
                  <Box className="price-wrap">
                    <Typography className="price">Apartment Guests </Typography>
                    <Typography className="price">{this.formatCurrency(data[breakdownKey].breakdown_guest_accommodations.apartment)}</Typography>
                  </Box>
                )}
                {!!data[breakdownKey].breakdown_guest_accommodations.campsite && (
                  <Box className="price-wrap">
                    <Typography className="price">Camping Spots</Typography>
                    <Typography className="price">{this.formatCurrency(data[breakdownKey].breakdown_guest_accommodations.campsite)}</Typography>
                  </Box>
                )}
                {!!data[breakdownKey].breakdown_guest_accommodations.hookups && (
                  <Box className="price-wrap">
                    <Typography className="price">Hookup Options</Typography>
                    <Typography className="price">{this.formatCurrency(data[breakdownKey].breakdown_guest_accommodations.hookups)}</Typography>
                  </Box>
                )}
              </CustomAccordionDetails>
            </CustomAccordion>
          )}
          {!!data[breakdownKey].amenities && (
            <CustomAccordion defaultExpanded>
              <CustomAccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: '#003249' }} />}>
                <Box className="wrap-info">
                  <Typography className="text">Amenities</Typography>
                  <Typography className="text">{this.formatCurrency(data[breakdownKey].amenities)}</Typography>
                </Box>
              </CustomAccordionSummary>
              <CustomAccordionDetails>
                {data.booking_amenities.map((item: IAmenities) =>
                  <Box className="price-wrap">
                    <Typography className="price">{item.type}</Typography>
                    <Typography className="price">{this.formatCurrency(item.selected_item_price)}</Typography>
                  </Box>
                )}
              </CustomAccordionDetails>
            </CustomAccordion>
          )}
          <>{this.isChangeRequested() && <Box sx={{marginLeft:'10px'}} className="price-wrap">
              <Typography className="price">Platform Fee 10%</Typography>
              <Typography className="price">{this.formatCurrency(data[key]?.platform_fee)}</Typography>
            </Box>}</>
         {!this.isChangeRequested() && <Box className="price-wrap">
            <Typography className="text">Platform Fee 10%</Typography>
            <Typography className="text">{this.formatCurrency(data[breakdownKey]?.platform_fee)}</Typography>
          </Box>}
        </Box>
        {(this.isChangeRequested() || this.isPaymentRequired() ) && this.changeAmountValues().val ? <Box sx={{marginTop:'20px'}}>
        <Divider sx={{ backgroundColor: '#E2E8F0', my: 2 }} />
          <Box className="price-wrap">
            <Typography className="text">Original payment</Typography>
            <Typography className="text">{this.formatCurrency(data.total_price)}</Typography>
          </Box>
          <Box className="price-wrap">
            <Typography className="text">New Amount</Typography>
            <Typography className="text">{this.formatCurrency(data[key].total)}</Typography>
          </Box>
          <Divider sx={{ backgroundColor: '#E2E8F0', my: 2 }} />
          <Box mt="20px" className="price-wrap">
          <Typography className="total">{this.changeAmountValues().type}</Typography>
          <Typography className="total_cr" sx={{color:this.getAmountColor()}}>{this.formatCurrency(this.changeAmountValues().val)}</Typography>
        </Box>
        </Box>
        :
        <Box mt="40px" className="price-wrap">
          <Typography className="total">TOTAL</Typography>
          <Typography className="total">{this.formatCurrency(data[breakdownKey].total)}</Typography>
        </Box>}
        {(this.isChangeRequestedAndHost() && !this.isCanceled()) && <>
          <Box textAlign="center" sx={{marginTop:'62px'}}>
          <Typography className="text">You have received a booking update request</Typography>
            <Typography className="request-info">Please review and approve or decline it. If approved, the guest will need to complete the payment within 48 hours to confirm the change.</Typography>
          </Box>
          <Box display="flex" gap="30px">
            <WhiteButton data-test-id="cancel-btn" onClick={this.rejectBooking}>Reject</WhiteButton>
            <BlackButton data-test-id="accept-btn" onClick={this.acceptBooking}>Accept</BlackButton>
          </Box>
          <Box display="flex" justifyContent={'center'} sx={{ marginTop: '56px' }}>
            <Box><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5006 2.5H2.50065C1.58398 2.5 0.833984 3.25 0.833984 4.16667V15.8333C0.833984 16.75 1.58398 17.5 2.50065 17.5H17.5006C18.4173 17.5 19.1673 16.75 19.1673 15.8333V4.16667C19.1673 3.25 18.4173 2.5 17.5006 2.5ZM17.5007 15C17.5007 15.4583 17.1257 15.8333 16.6673 15.8333H3.33398C2.87565 15.8333 2.50065 15.4583 2.50065 15V5C2.50065 4.54167 2.87565 4.16667 3.33398 4.16667H16.6673C17.1257 4.16667 17.5007 4.54167 17.5007 5V15ZM12.1548 7.00626C12.3752 7.00626 12.5866 7.09396 12.7423 7.25C13.0673 7.575 13.0673 8.10833 12.7507 8.425L11.1757 10L12.7507 11.575C13.0757 11.9 13.0757 12.425 12.7507 12.75C12.4257 13.075 11.9007 13.075 11.5757 12.75L10.0007 11.175L8.42565 12.75C8.10065 13.075 7.57565 13.075 7.25065 12.75C6.92565 12.425 6.92565 11.9 7.25065 11.575L8.82565 10L7.25065 8.425C6.92565 8.1 6.92565 7.575 7.25065 7.25C7.40634 7.09396 7.61772 7.00626 7.83815 7.00626C8.05858 7.00626 8.26996 7.09396 8.42565 7.25L10.0007 8.825L11.5673 7.25C11.723 7.09396 11.9344 7.00626 12.1548 7.00626Z" fill="#DC2626" />
            </svg></Box><Typography className="red-text" onClick={() => {this.toggleCancelConfirmPopup() }}> Cancel Booking</Typography>
          </Box>
          </>
        }
        {(!this.isPaymentRequiredForGuest()) && <>
       <Box textAlign="center" sx={{marginTop:'35px'}}>
          {this.isChangeRequestedAndGuest() &&
          <><Typography className="text">Your request has been sent </Typography>
           <Typography className="request-text">If the host approves, you need to complete the payment within 48 hrs to confirm the change.</Typography>
         </>}
         {this.isCRRejectedForGuest() &&
          <><Typography className="text">Your request has been rejected </Typography>
           <Typography className="request-text">Your request has been rejected. However, your original booking still stands. You can make new changes up to 2 days before start of booking or cancel it.</Typography>
           <WhiteButton data-test-id="cancel-btn" onClick={this.changeRequest}>Request new changes</WhiteButton>
         </>}
         {this.isConfirmedForGuest() && <Typography className="request-text">Changes are up to the host; they must accept your request, which could affect the final price.</Typography>}
        </Box>
        {this.isGuest() && <Box display="flex" gap="30px">
           {!this.isCRRejectedForGuest() && <WhiteButton data-test-id="cancel-btn" onClick={this.toggleCancelConfirmPopup}>Cancel Booking</WhiteButton>}
          {this.isChangeRequested() && <BlackButton data-test-id="cancel-req" onClick={this.cancelRequest}>Cancel request</BlackButton> } 
          {this.isConfirmedForGuest() && <BlackButton data-test-id="change-btn" onClick={this.changeRequest}>Request Change</BlackButton>}
        </Box>}
        </>
        }
        {(this.isPaymentRequiredForGuest() && !this.isCanceled()) &&
          <StripePay bookingDetailsConfirm={this.state.data} navigation={this.props.navigation} id={""} />
        }
      </Box>
    );
  }

  renderAdditionalMessageInput = () => {
      return (
        <Box>
          <TextField
            multiline
            rows={4}
            variant="outlined"
            data-test-id='additional-info'
            value={this.state.additionalMsg}
            onChange={(e) => this.setState({ additionalMsg: e.target.value })}
            inputProps={{ maxLength: 1000 }}
            sx={{
              width: '100%',
              marginTop: '8px',
              borderRadius: '8px',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#CBD5E1',
                },
                '&:hover fieldset': {
                  borderColor: '#CBD5E1',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#CBD5E1',
                },
              },
              '& .MuiInputBase-input': {
                color: '#475569',
                fontSize: '14px',
                fontWeight: 500
              },
            }}
          />
        </Box>
      )
    }
    
  renderActionDialog = () => {
    return (
      <Box>
        <CustomDialog
          open={this.state.showPopup}
          onClose={() => this.closePopup()}
        >
          <DialogTitle className="title">
            <IconButton
              onClick={() => this.closePopup()}
              sx={{ position: "absolute", right: 8, top: 8 }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className="dialog-content">
            <Typography className="popup-title">{this.state.action}</Typography>
            <Box>
              <Typography className="dropdown-label" sx={{ paddingTop: '40px' }}>{'Additional message to the guest:'}</Typography>
              {this.renderAdditionalMessageInput()}
            </Box>
          </DialogContent>
          <DialogActions className="dialog-action">
            <BlackButton data-test-id={'done-btn'} onClick={() => { this.updateBookingStatus() }}>Done</BlackButton>
          </DialogActions>
        </CustomDialog>
      </Box>
    )
  }

  renderCancelConfirmPopup = () => {
    return (
      <CustomDialog
        className="change-host"
        onClose={this.toggleCancelConfirmPopup}
        open={this.state.showCancelConfirmPopup}
      >
        <DialogTitle className="title">
                  <IconButton className="close-btn"               
                  sx={{ position: "absolute", right: 20, top: 10 }}
                  onClick={this.toggleCancelConfirmPopup}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="dialog-content">
       <Box> <EventBusyOutlinedIcon sx={{color:"#F87171",marginBottom:'20px'}} fontSize="large"/></Box>
          <Typography className="popup-title">Cancel Booking</Typography>
          <Typography className="popup-desc">{this.cancelBookingInfo()}</Typography>

        </DialogContent>
        <DialogActions className="dialog-action">
        <Box display="flex" gap={'30px'} width='100%'>
        <WhiteButton data-test-id="cancel-btn" onClick={this.toggleCancelConfirmPopup}>Keep booking</WhiteButton> 
        <BlackButton onClick={this.cancelBooking}>Delete booking</BlackButton>
        </Box></DialogActions>
      </CustomDialog>
    );
  }

  renderCRApprovedPopup = () => {
    return (
      <CustomDialog
        className="change-host"
        onClose={this.toggleCRApprovedPopup}
        open={this.state.showCRApprovedPopup}
      >
        <DialogTitle className="title">
                  <IconButton className="close-btn"               
                  sx={{ position: "absolute", right: 20, top: 10 }}
                  onClick={this.toggleCRApprovedPopup}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="dialog-content">
       <Box> <EventBusyOutlinedIcon sx={{color:"#F87171",marginBottom:'20px'}} fontSize="large"/></Box>
          <Typography className="popup-title">Your change request has been approved!</Typography>
          <Typography className="popup-desc">Your request has been approved, Please make pending payment to confirm your booking.</Typography>
        </DialogContent>
        <DialogActions className="dialog-action">
        <Box display="flex" gap={'30px'} width='256px'>
        <StripePay popupBtnOnly={true} payClicked={()=>this.toggleCRApprovedPopup()} bookingDetailsConfirm={this.state.data} navigation={this.props.navigation} id={""} />
        </Box>
        </DialogActions>
      </CustomDialog>
    );
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <Header hideFilter navigation={this.props.navigation} active="Booking">
        <Loader loading={this.state.isLoading} />
        <Wrapper>
          {this.state.loading ? (
            <Box className="loading">
              <CircularProgress sx={{ color: "#000" }} />
            </Box>
          ) : (
            this.state.data &&
            <>
              <Box display="flex" height="calc(100vh - 75px)">
                <Box className="left-wrapper">
                  <Box className="column">
                    <Typography className="booking" onClick={this.onBack}>
                      <ArrowBackIcon />
                      <span>Booking</span>
                    </Typography>
                    {this.renderBasicInfo()}
                    {this.renderBookingId()}
                    {this.renderImportantInfo()}
                    {this.renderTripInfo()}
                    {this.renderHostInfo()}
                  </Box>
                </Box>
                <Box>
                  {this.renderPaymentDetails()}
                  {this.renderActionDialog()}
                  {this.renderCancelConfirmPopup()}
                  {this.renderCRApprovedPopup()}
                </Box>
              </Box>
            </>
          )}
        </Wrapper>
      </Header>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const CustomDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    borderRadius: "16px",
    width: "550px",
  },
  "& .title": {
    textAlign: "center",
  },
  "& .MuiDialogContent-root": {
    padding: "0px 30px 18px !important"
  },
  "& .dialog-content": {
    textAlign:'center',
    marginBottom: '10px',
    "& .popup-title": {
      textAlign: "center",
      letterSpacing: "0px",
      fontFamily: "Spartan",
      fontWeight: "700",
      fontSize: "18px",
      color: "#003249"
    },
    "& .dropdown-label": {
      fontFamily: 'Spartan',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '24px',
      color: '#003249'
    },
    "& .dropdown-label-note": {
      fontFamily: 'Spartan',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '18px',
      color: '#716F77',
      paddingTop: '8px'
    },
    "& .option-description": {
      fontFamily: 'Spartan',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '24px',
      color: '#716F77',
      paddingTop: '8px'
    },
    "& .popup-desc": {
      letterSpacing: "-2%",
      fontFamily: "Spartan",
      fontWeight: "500",
      fontSize: "15px",
      color: "#94A3B8"
    },
  },
  "& .dialog-action": {
    gap: "10px",
    padding: "30px",
    justifyContent: "center",
  },
  "& .close-btn": {
    color: "#003249",
    height: "15px",
    width: "15px",
  },
  "&.change-host": {
    "& .MuiDialogContent-root": {
      paddingTop: "15px !important",
      paddingBottom: "15px !important",
    }
  }
})

const Wrapper = styled(Box)({
  height: "100%",
  backgroundColor: "#F8FAFC",
  '& .loading': {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  "& .left-wrapper": {
    flex: "1",
    overflow: "auto",
    padding: "30px 90px 40px 30px",
    "&::-webkit-scrollbar": {
      width: "4px",
      height: "4px"
    },
    "& .booking": {
      width: "fit-content",
      fontFamily: "Spartan",
      fontWeight: "700",
      fontSize: "18px",
      color: "#003249",
      textTransform: "uppercase",
      display: "flex",
      cursor: "pointer",
      "& span": {
        marginLeft: "20px",
      }
    },
    "& .column": {
      display: "flex",
      gap: "20px",
      flexDirection: "column"
    },
  },
  "& .payment-detail": {
    width: "500px",
    height: "100%",
    overflow: "scroll",
    padding: "40px 20px 40px",
    boxSizing: "border-box",
    backgroundColor: "#fff",
    boxShadow: "-2px 0px 8px 0px #00000014",
    "&::-webkit-scrollbar": {
      width: "4px",
      height: "4px"
    },
    "& .title": {
      fontFamily: 'Spartan',
      fontWeight: "700",
      fontSize: '16px',
      color: '#003249',
      textTransform: "uppercase",
      letterSpacing: '-0.5px',
    },
    "& .nights": {
      marginTop: "30px",
      marginBottom: "30px",
      fontFamily: 'Spartan',
      letterSpacing: '-0.5px',
      fontWeight: "500",
      fontSize: '16px',
      color: '#003249',
    },
    "& .text": {
      marginTop: "2px",
      fontFamily: 'Spartan',
      fontWeight: "700",
      fontSize: '14px',
      color: '#003249',
      letterSpacing: '-0.5px',
    },
    "& .price-wrap": {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginRight: '44px',
      marginBottom: '8px',
      "& .total": {
        fontFamily: 'Spartan',
        fontWeight: "700",
        fontSize: '16px',
        color: '#003249',
      },
      "& .total_cr": {
        fontFamily: 'Spartan',
        fontWeight: "700",
        fontSize: '16px',
      }
    },
    "& .price": {
      fontFamily: 'Spartan',
      fontWeight: "500",
      fontSize: '14px',
      color: '#003249',
    },
    "& .red-text": {
      fontFamily: 'Spartan',
      fontWeight: "700",
      fontSize: '16px',
      marginLeft:'10px',
      color: '#DC2626',
      cursor:'pointer'
    },
    "& .wrap-info": {
      width: '100%',
      marginRight: '20px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    "& .request-text": {
      marginBottom: "25px",
      letterSpacing: "0px",
      fontFamily: 'Spartan',
      fontWeight: "500",
      fontSize: '14px',
      color: '#64748B',
    },
    "& .request-info": {
      marginBottom: "25px",
      letterSpacing: "0px",
      fontFamily: 'Spartan',
      fontWeight: "500",
      fontSize: '14px',
      color: '#64748B',
    }
  }
});

const CustomCard = styled(Card)({
  padding: "10px",
  borderRadius: "16px",
  backgroundColor: "#fff",
  boxShadow: "0px 2px 8px 0px #00000014",
  "&.basic-info": {
    display: "flex",
    gap: "20px",
  },
  "& .slider":SliderStyles,
  "& .image": {
    borderRadius: "16px",
  },
  "& .content": {
    padding: "0px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "& .status": {
      letterSpacing: "-0px",
      width: "fit-content",
      fontSize: "11px",
      fontFamily: "Spartan",
      fontWeight: "500",
      padding: "3px 10px",
      borderRadius: "20px",
      color: "#B45309",
      backgroundColor: "#FFE5C7",
      "&.green": {
        color: "#059669",
        backgroundColor: "#ECFDF5",
      },
      "&.blue": {
        color: "#2563EB",
        backgroundColor: "#DBEAFE",
      },
      "&.active": {
        color: "#06D6A0",
        backgroundColor: "#ECFDF5",
      },
      "&.red": {
        color: "#DC2626",
        backgroundColor: "#FEE2E2"
      },
    }
  },
  "& .title": {
    fontFamily: "Spartan",
    letterSpacing: "-0.5px",
    fontSize: "16px",
    fontWeight: "700",
    color: "#003249",
    marginBottom: "10px"
  },
  "& .address": {
    marginBottom: "7px",
    fontFamily: "Spartan",
    letterSpacing: "-0.5px",
    fontSize: "14px",
    fontWeight: "500",
    color: "#003249"
  },
  "& .blur-text": {
    marginBottom: "10px",
    fontFamily: "Spartan",
    letterSpacing: "-0.5px",
    fontSize: "14px",
    fontWeight: "400",
    color: "#475569"
  },
  "& .card-title": {
    marginBottom: "15px",
    fontFamily: "Spartan",
    letterSpacing: "-0.5px",
    fontSize: "20px",
    fontWeight: "700",
    color: "#003249",
    display: "flex",
    alignItems: "center",
    "& span": {
      marginLeft: "10px",
      fontSize: "14px",
      fontWeight: "500",
    }
  },
  "& .items": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  "& .sub-title": {
    letterSpacing: "-0.5px",
    fontFamily: "Spartan",
    fontSize: "14px",
    fontWeight: "500",
    color: "#003249",
  },
  "& .sub-wrap": {
    display: "flex",
    "& svg": {
      marginTop: "2px",
      marginRight: "5px",
      fill: "#475569",
      fontSize: "14px"
    },
    "&.between": {
      justifyContent: "space-between"
    }
  },
  "& .link": {
    cursor: "pointer",
    fontFamily: "Spartan",
    letterSpacing: "-0.5px",
    fontSize: "14px",
    fontWeight: "500",
    color: "#3B82F6",
  },
  "& .trip-title": {
    marginBottom: "5px",
    fontFamily: "Spartan",
    letterSpacing: "-0.5px",
    fontSize: "16px",
    fontWeight: "700",
    color: "#003249",
  },
  "& .trip-sub": {
    fontFamily: "Spartan",
    letterSpacing: "-0.5px",
    fontSize: "14px",
    fontWeight: "500",
    color: "#003249",
  },
  "& .trip-sub-striked": {
    fontFamily: "Spartan",
    letterSpacing: "-0.5px",
    fontSize: "14px",
    fontWeight: "500",
    color: "#64748B",
    textDecoration: "line-through",
  },
  "& .rating": {
    marginTop: "5px",
    "& span": {
      marginLeft: "4px",
      fontFamily: "Spartan",
      letterSpacing: "-0.5px",
      fontSize: "14px",
      fontWeight: "700",
      color: "#003249",
    }
  },
  "& .user-info": {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    "& .avatar": {
      width: "44px",
      height: "44px",
      border: "1.5px solid #E0E7FF",
    },
    "& .user-name": {
      letterSpacing: "-0.5px",
      color: "#003249",
      fontFamily: "Spartan",
      fontSize: "14px",
      fontWeight: "700",
      marginBottom: "5px"
    },
    "& .info": {
      letterSpacing: "-0.5px",
      color: "#475569",
      fontFamily: "Spartan",
      fontSize: "14px",
      fontWeight: "500",
    }
  }
});

const Dot = styled("div")({
  width: "10px",
  height: "10px",
  backgroundColor: "#fff",
  borderRadius: "50%",
  transition: "background-color 0.3s ease",
});

const CustomAccordion = styled(Accordion)({
  border: 'none !important',
  boxShadow: 'none !important',
  padding: '0px',
  margin: '0px',
  marginBottom: "16px",
  position: 'relative',
  '&::before': {
    position: 'absolute',
    left: 0,
    top: '-1px',
    right: 0,
    height: '1px',
    content: '""',
    opacity: 1,
    backgroundColor: 'transparent',
    transition: 'opacity 150ms cubic-bezier(0.4, 0, 0.2, 1), background-color 150ms cubic-bezier(0.4, 0, 0.2, 1)',
  },
  "&.MuiAccordion-root.Mui-expanded": {
    margin: "0px",
    marginBottom: "16px"
  }
});

const CustomAccordionSummary = styled(AccordionSummary)({
  padding: '0px',
  margin: '0px',
  outline: 'none',
  minHeight: "auto",
  border: 'none !important',
  boxShadow: 'none !important',
  "& .MuiAccordionSummary-content,& .MuiAccordionSummary-content.Mui-expanded": {
    margin: "0px",
  },
  "&.Mui-expanded": {
    margin: "0px",
    marginBottom: "10px",
    minHeight: "auto",
  },
});

const CustomAccordionDetails = styled(AccordionDetails)({
  padding: '0px',
  margin: '0px',
  marginLeft: '10px',
  border: 'none !important',
  boxShadow: 'none !important',
  outline: 'none',
});

const WhiteButton = styled(Button)({
  width: "100%",
  padding: "6px 13px",
  fontFamily: "Spartan",
  color: "#003249",
  fontSize: "16px",
  letterSpacing: "-0.5px",
  textTransform: "none",
  whiteSpace: "nowrap",
  borderRadius: "8px",
  backgroundColor: "#fff",
  fontWeight: "500",
  cursor:'pointer',
  border: "1.5px solid #003249",
  "&:hover": {
    color: "#003249",
    backgroundColor: "#fff",
  }
});

const BlackButton = styled(Button)({
  width: "100%",
  padding: "10px 16px",
  borderRadius: "8px",
  letterSpacing: "-0.5px",
  textTransform: "none",
  fontSize: "16px",
  fontFamily: "Spartan",
  whiteSpace: "nowrap",
  fontWeight: "700",
  color: "#fff",
  backgroundColor: "#003249",
  cursor:'pointer',
  border: "1.5px solid #003249",
  "&:hover": {
    color: "#fff",
    backgroundColor: "#003249",
  }
});
// Customizable Area End