Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.ACCOUNT_TYPE_EMAIL = "EmailAccount";
exports.ACCOUNT_TYPE_SOCIAL = "SocialAccount";
exports.ACCOUNT_TYPE_PHONE = "SmsAccount";

exports.contentTypeApiUpdateUser = "application/json";
exports.apiEndPointUpdateUser = "profile/profile";
exports.apiUpdateUserType = "PUT";

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";

exports.contenttypeApiValidateMobileNo = "application/json";
exports.endPointApiValidateMobileNo = "profile/change_phone_validation";
exports.callTypeApiValidateMobileNo = "POST";

exports.endPointApiGetUserProfile = "profile/profile";
exports.contentTypeApiGetUserProfile = "application/json";
exports.methodTypeApiGetUserProfile = "GET";

// Customizable Area Start
exports.placeHolderEmail = "Email";
exports.labelHeader =
  "This is your profile, Here you can see and update your personal information.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelArea = "Area";
exports.labelMobile = "Mobile";
exports.labelEmail = "Email";
exports.labelCurrentPassword = "Current password";
exports.labelNewPassword = "New Password";
exports.labelRePassword = "Re-Type Password";
exports.btnTextCancelPasswordChange = "Cancel";
exports.btnTextSaveChanges = "Save Changes";
exports.btnTextChangePassword = "Change Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorMobileNoNotValid = "Phone number is not valid.";
exports.errorTitle = "Error";
exports.errorBothPasswordsNotSame = "Passwords must match.";
exports.errorCurrentNewPasswordMatch = "New password cannot match current password.";
exports.errorCurrentPasswordNotValid = "Current password not valid.";
exports.errorNewPasswordNotValid = "New password not valid.";
exports.errorReTypePasswordNotValid = "Re-type password not valid.";
exports.hintCountryCode = "Select Country";
exports.errorBlankField = "can't be blank";
exports.errorEmailNotValid = "Email not valid.";
exports.PaymentDetails = "Payment Details";
exports.gettingPaidHeadding="Getting Paid Faster";
exports.getingPaidInfo="Haul-Inn has partnered with Stripe to enable secure and prompt payments for our users. You can set up your Stripe account now or anytime in our app’s Payment Center. Please note that if you are creating an account for the first time or have recently updated your information, verification may take a few days.";
exports.setUpAccountTxt="Set up account";
exports.recivePaymentTxt="Receive Payments";
exports.connectStripeMsg ="Your account is connected to Stripe";
exports.recivePaymentMsgSuccess ="Haul-Inn has partnered with Stripe to enable secure and prompt payments for our users. You can set up your Stripe account now or anytime in our app’s Payment Center.Please note that if you are creating an account for the first time or have recently updated your information, verification may take a few days.";
exports.managePayment ="Manage payout method";
exports.disconnectButton = "Disconnect from Stripe";
exports.setUpHostAccount="/bx_block_stripe_integration/payment_methods/setup_host_account";
exports.deletStripeAccount = "/bx_block_stripe_integration/payment_methods/delete_stripe_account";
exports.connectExixtingAccount ="/bx_block_stripe_integration/payment_methods/connect_existing_account";
exports.userProfile="/account_block/accounts/user_profile";
exports.blockUserApiEndpoint="/bx_block_block_users/block_users"
// Customizable Area End

