// Customizable Area Start
import React from "react";
import {
  Typography, IconButton, Box, Button, CircularProgress, InputBase, ClickAwayListener,
  Dialog, DialogTitle, DialogContent, DialogActions, List, ListItemButton,
} from "@mui/material";
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from "@mui/icons-material/Close";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';

import HorseInfoController, {
  IAddHorse
} from "./HorseInfoController.web";
import Header from "../../../components/src/AppHeader.web";
import Loader from "../../../components/src/Loader.web";
import { notFoundIcon, horseIcon, infoIcon, documentIcon } from "./assets";
// Customizable Area End

export default class HorseInfo extends HorseInfoController {
  // Customizable Area Start
  renderDeletePopup = () => {
    return (
      <CustomDialog
        onClose={this.onClose}
        open={this.state.openPopup === "delete"}
      >
        <DialogTitle className="title">
          <DeleteOutlineIcon className="delete-icon" />
          <IconButton className="close-btn" onClick={this.onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="dialog-content">
          <Typography className="popup-title">Are you sure you want to delete the account?</Typography>
          <Typography className="popup-desc">Are you sure you want to delete this horse's profile?</Typography>
        </DialogContent>
        <DialogActions className="dialog-action">
          <WhiteButton onClick={this.onClose}>Cancel</WhiteButton>
          <BlackButton onClick={this.onDeleteHorse} data-test-id="delete-account">Delete</BlackButton>
        </DialogActions>
      </CustomDialog>
    );
  }

  renderLimitPopup = () => {
    return (
      <CustomDialog
        onClose={this.onClose}
        open={this.state.openPopup === "limit"}
      >
        <DialogTitle className="title">
          <WarningAmberRoundedIcon sx={{ fontSize: "40px", color: "#F87171" }} />
          <IconButton className="close-btn" onClick={this.onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="dialog-content">
          <Typography className="popup-title">Horse Limit Reached</Typography>
          <Typography className="popup-desc">You've maxed out your horse capacity. Consider removing one to add more!</Typography>
        </DialogContent>
        <DialogActions className="dialog-action">
          <BlackButton sx={{ width: "fit-content", padding: "10px 60px" }} onClick={this.onClose}>Okay</BlackButton>
        </DialogActions>
      </CustomDialog>
    );
  }

  renderNotFound = () => {
    return (
      <Box className="not-found">
        <Box maxWidth="480px" textAlign="center">
          {notFoundIcon}
          <Typography className="information">Add your horses' health papers here. Some hosts might ask to verify that the health papers are up to date.</Typography>
          <BlackButton onClick={this.addHorse} data-test-id="add-btn">Add horse</BlackButton>
        </Box>
      </Box>
    );
  }

  renderAddHorses = (item: IAddHorse, index: number) => {
    return (
      <Box className="container">
        <Box className="form-box">
          <IconButton className="close-icon" data-test-id="remove-btn" onClick={() => this.removeCard(index)}>
            <CloseIcon />
          </IconButton>
          <Box>
            <Typography className="title">Horse name</Typography>
            <CustomInputBase
              value={item.horseName}
              placeholder="Horse name"
              data-test-id="horse-name"
              onChange={(e) => {
                this.handleNameChange(index, e.target.value)
              }}
            />
            <div className="error">{item.horseErr}</div>
          </Box>
          <Box mt="15px">
            <Typography className="title">Health papers</Typography>
            <input
              type="file"
              id={`upload-${index}`}
              data-test-id="upload"
              style={{ display: "none" }}
              accept=".pdf, .jpg, .jpeg"
              onChange={(e) => {
                if (e.target.files) {
                  this.handleFileChange(index, e.target.files[0])
                }
              }}
            />
            {item.file ? (
              <Box className="upload file">
                <Box className="left-side">
                  <Box className="icon">
                    {documentIcon}
                  </Box>
                  <Box>
                    <Typography className="attachment">{item.fileName}</Typography>
                    <Typography className="accepted">{item.fileSize}</Typography>
                  </Box>
                </Box>
                <Box>
                  <IconButton data-test-id="remove-file" onClick={() => this.handleFileRemove(index)}>
                    <CloseIcon className="close" />
                  </IconButton>
                </Box>
              </Box>
            ) : (
              <Box component="label" className="upload" htmlFor={`upload-${index}`}>
                <Box className="icon">
                  <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.64258 12.9648C3.50508 12.9648 2.53333 12.5711 1.72733 11.7836C0.920828 10.9961 0.517578 10.0336 0.517578 8.89609C0.517578 7.92109 0.811328 7.05234 1.39883 6.28984C1.98633 5.52734 2.75508 5.03984 3.70508 4.82734C4.01758 3.67734 4.64258 2.74609 5.58008 2.03359C6.51758 1.32109 7.58008 0.964844 8.76758 0.964844C10.2301 0.964844 11.4706 1.47409 12.4891 2.49259C13.5081 3.51159 14.0176 4.75234 14.0176 6.21484C14.8801 6.31484 15.5958 6.68659 16.1648 7.33009C16.7333 7.97409 17.0176 8.72734 17.0176 9.58984C17.0176 10.5273 16.6896 11.3243 16.0336 11.9808C15.3771 12.6368 14.5801 12.9648 13.6426 12.9648H9.51758C9.10508 12.9648 8.75208 12.8181 8.45858 12.5246C8.16458 12.2306 8.01758 11.8773 8.01758 11.4648V7.60234L6.81758 8.76484L5.76758 7.71484L8.76758 4.71484L11.7676 7.71484L10.7176 8.76484L9.51758 7.60234V11.4648H13.6426C14.1676 11.4648 14.6113 11.2836 14.9738 10.9211C15.3363 10.5586 15.5176 10.1148 15.5176 9.58984C15.5176 9.06484 15.3363 8.62109 14.9738 8.25859C14.6113 7.89609 14.1676 7.71484 13.6426 7.71484H12.5176V6.21484C12.5176 5.17734 12.1521 4.29284 11.4211 3.56134C10.6896 2.83034 9.80508 2.46484 8.76758 2.46484C7.73008 2.46484 6.84583 2.83034 6.11483 3.56134C5.38333 4.29284 5.01758 5.17734 5.01758 6.21484H4.64258C3.91758 6.21484 3.29883 6.47109 2.78633 6.98359C2.27383 7.49609 2.01758 8.11484 2.01758 8.83984C2.01758 9.56484 2.27383 10.1836 2.78633 10.6961C3.29883 11.2086 3.91758 11.4648 4.64258 11.4648H6.51758V12.9648H4.64258Z" fill="#003249" />
                  </svg>
                </Box>
                <Box>
                  <Typography className="attachment">Add attachment</Typography>
                  <Typography className="accepted">Attach pdf. jpg. files only</Typography>
                </Box>
              </Box>
            )}
            <div className="error">{item.fileErr}</div>
          </Box>
        </Box>
      </Box>
    );
  }

  renderCard = (item: IAddHorse, index: number) => {
    return (
      <Box className="horse-detail">
        <Box padding="20px">
          <Box className="heading">
            <Typography className="name">{item.horseName}</Typography>
            <ClickAwayListener
              mouseEvent="onMouseDown"
              touchEvent="onTouchStart"
              onClickAway={(e) => {
                if (this.menuRef?.contains(e.target as Node)) return;
                this.setState({ currentIndex: -1 });
              }}
            >
              <Box position="relative">
                <IconButton data-test-id="menu-btn" onClick={() => this.onOpenMenu(item.id, index)}>
                  <MoreVertIcon sx={{ color: "#000" }} />
                </IconButton>
                {this.state.currentIndex === index && (
                  <List className="option-menu" ref={(ref) => (this.menuRef = ref)}>
                    <ListItemButton data-test-id="edit-btn" onClick={this.onEdit}>
                      <Typography className="menu-item edit">
                        <EditIcon />
                        <span>Edit</span>
                      </Typography>
                    </ListItemButton>
                    <ListItemButton data-test-id="delete-btn" onClick={this.onDelete}>
                      <Typography className="menu-item delete">
                        <DeleteOutlineIcon />
                        <span>Delete</span>
                      </Typography>
                    </ListItemButton>
                  </List>
                )}
              </Box>
            </ClickAwayListener>
          </Box>
          <Box className="file-info">
            <Box>
              <AttachFileIcon sx={{ color: "black" }} />
            </Box>
            <Box>
              <Typography className="file-name">{item.fileName}</Typography>
              <Typography className="file-size">{item.fileSize}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }

  renderHorseInfo = () => {
    return (
      <Box className="info-wrapper">
        {this.state.data.map((item: IAddHorse, index: number) => {
          return item.isEdit || item.isNew ? this.renderAddHorses(item, index) : this.renderCard(item, index)
        })}
        <BlackButton sx={{ width: "400px" }} onClick={this.addHorse}>Add horse</BlackButton>
      </Box>
    );
  }

  renderRightSection = () => {
    return (
      <Box className="right-section">
        {infoIcon}
        <Typography className="guide">Hint on how to fill in horse data</Typography>
        <Box className="guide-wrapper">
          <Typography className="guide-text">
            {horseIcon}
            <span>Add a horse name</span>
          </Typography>
          <Typography className="guide-text">
            {documentIcon}
            <span>Download pet passport in pdf. or jpg.</span>
          </Typography>
        </Box>
      </Box>
    );
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <Header isSidebar hideFilter navigation={this.props.navigation} active="HorseInfo">
        <Wrapper>
          <Loader loading={this.state.isLoading} />
          {this.state.loading ? (
            <Box className="loading">
              <CircularProgress sx={{ color: "#000" }} />
            </Box>
          ) : (
            <>
              <Box className="heading">
                <Typography className="horses">Horses</Typography>
                {this.state.data.length > 0 && <Typography className="save-btn" data-test-id="save-btn" onClick={this.onSaveHandler}>Save</Typography>}
              </Box>
              {this.state.data.length === 0 ?
                this.renderNotFound()
                : (
                  <Box display="flex" justifyContent="space-between">
                    {this.renderHorseInfo()}
                    {this.renderRightSection()}
                  </Box>
                )}
            </>
          )}
          {this.renderDeletePopup()}
          {this.renderLimitPopup()}
        </Wrapper>
      </Header>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const Wrapper = styled(Box)({
  padding: "30px 20px",
  height: "100%",
  paddingBottom: "105px",
  backgroundColor: "#fff",
  "& .loader": {
    marginLeft: "300px"
  },
  "& .loading": {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .heading": {
    marginBottom: "20px",
    display: "flex",
    letterSpacing: "-0.5px",
    justifyContent: "space-between",
    "& .save-btn": {
      cursor: "pointer",
      display: "flex",
      fontFamily: "Spartan",
      fontWeight: "700",
      fontSize: "16px",
      color: "#003249",
    },
    "& .horses": {
      fontFamily: "Spartan",
      fontWeight: "700",
      fontSize: "17px",
      color: "#003249",
      textTransform: "uppercase",
    }
  },
  "& .not-found": {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    "& .information": {
      margin: "20px auto 40px",
      fontFamily: "Spartan",
      fontWeight: "500",
      fontSize: "16px",
      color: "#64748B",
    }
  },
  "& .info-wrapper": {
    flex: "1",
    gap: "20px",
    padding: "0px 10px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  "& .container": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    gap: "20px",
    fontFamily: "Spartan",
    width: "400px",
    "& .form-box": {
      border: "1.5px solid #E2E8F0",
      position: "relative",
      width: "100%",
      padding: "20px",
      paddingTop: "30px",
      borderRadius: "16px",
      background: "#fff",
      boxShadow: "0px 2px 8px 0px #00000014",
      "& .close-icon": {
        position: "absolute",
        right: "5px",
        top: "4px",
        "& svg": {
          color: "#000"
        }
      },
      "& .title": {
        marginBottom: "3px",
        fontFamily: "Spartan",
        fontWeight: "700",
        fontSize: "16px",
        color: "#003249",
        letterSpacing: "-0.5px"
      },
      "& .upload": {
        display: "flex",
        alignItems: "center",
        gap: "10px",
        border: "1.5px dashed #CBD5E1",
        borderRadius: "8px",
        padding: "10px",
        cursor: "pointer",
        "& .icon": {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "40px",
          height: "40px",
          background: "#F1F5F9",
          borderRadius: "6px"
        },
        "& .attachment": {
          fontFamily: "Spartan",
          fontWeight: "500",
          fontSize: "12px",
          color: "#0F172A"
        },
        "& .accepted": {
          fontFamily: "Spartan",
          fontWeight: "500",
          fontSize: "12px",
          color: "#64748B"
        },
        "&.file": {
          cursor: "default",
          paddingRight: "2px",
          justifyContent: "space-between",
          "& .left-side": {
            display: "flex",
            gap: "10px",
            alignItems: "center",
          },
          "& .close": {
            fontSize: "19px",
            color: "#000",
          }
        }
      }
    }
  },
  "& .horse-detail": {
    width: "400px",
    borderRadius: "8px",
    border: "1.5px solid #E2E8F0",
    boxShadow: "0px 2px 8px 0px #00000014",
    "& .heading": {
      display: "flex",
      marginBottom: "10px",
      justifyContent: "space-between",
      "& .name": {
        fontFamily: "Spartan",
        fontSize: "18px",
        fontWeight: "700"
      }
    },
    "& .file-info": {
      display: "flex",
      alignItems: "center",
      gap: "10px",
      padding: "10px",
      borderRadius: "8px",
      backgroundColor: "#F1F5F9",
      "& .file-name": {
        fontFamily: "Spartan",
        fontSize: "16px",
        fontWeight: "500",
        color: "#003249"
      },
      "& .file-size": {
        fontFamily: "Spartan",
        fontSize: "16px",
        fontWeight: "500",
        color: "#64748B"
      }
    }
  },
  "& .right-section": {
    width: "285px",
    height: "fit-content",
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    padding: "20px",
    borderRadius: "8px",
    border: "1.5px solid #E2E8F0",
    backgroundColor: "#F8FAFC",
    "& .guide": {
      fontFamily: "Spartan",
      fontWeight: "500",
      letterSpacing: "-0.5px",
      fontSize: "16px",
      color: "#000"
    },
    "& .guide-wrapper": {
      display: "flex",
      flexDirection: "column",
      gap: "5px",
      "& .guide-text": {
        display: "flex",
        fontFamily: "Spartan",
        letterSpacing: "-0.3px",
        fontWeight: "500",
        fontSize: "14px",
        color: "#475569",
        "& span": {
          marginLeft: "5px"
        }
      }
    }
  },
  "& .option-menu": {
    position: "absolute",
    right: "19px",
    top: "40px",
    zIndex: 10,
    width: "130px",
    background: "#fff",
    borderRadius: "8px",
    boxShadow: "0px 8px 32px 0px #0000000F",
    border: "1px solid #E2E8F0",
    "& .menu-item": {
      display: "flex",
      alignItems: "center",
      fontFamily: "Spartan",
      fontWeight: "500",
      fontSize: "14px",
      "& span": {
        marginTop: "3px",
        marginLeft: "5px"
      },
      "& svg": {
        fontSize: "16px"
      }
    },
    "& .edit": {
      color: "#475569"
    },
    "& .delete": {
      color: "#DC2626"
    }
  }
});

const CustomDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    maxWidth: "550px",
    borderRadius: "16px",
  },
  "& .close-btn": {
    position: "absolute",
    right: 8,
    top: 8,
    color: "#000",
  },
  "& .title": {
    paddingTop: "35px",
    textAlign: "center",
  },
  "& .delete-icon": {
    color: "#F87171",
    fontSize: "38px"
  },
  "& .MuiDialogContent-root": {
    padding: "2px 30px 18px !important"
  },
  "& .dialog-content": {
    textAlign: "center",
    "& .popup-title": {
      fontFamily: "Spartan",
      letterSpacing: "-0.5px",
      fontSize: "18px",
      fontWeight: "700",
      marginBottom: "5px",
      color: "#003249",
    },
    "& .popup-desc": {
      fontWeight: "500",
      fontSize: "15px",
      letterSpacing: "-0.5px",
      fontFamily: "Spartan",
      color: "#94A3B8"
    }
  },
  "& .dialog-action": {
    gap: "10px",
    padding: "30px",
    justifyContent: "center",
  },
});

const CustomInputBase = styled(InputBase)({
  width: "100%",
  padding: "6px 8px",
  borderRadius: "8px",
  fontFamily: "Spartan",
  fontWeight: "500",
  fontSize: "14px",
  color: "#003249",
  border: "1px solid #CBD5E1",
  "& input": {
    height: "auto"
  }
});

const WhiteButton = styled(Button)({
  padding: "8px 10px",
  fontFamily: "Spartan",
  width: "100%",
  fontSize: "16px",
  color: "#0F172A",
  textTransform: "none",
  backgroundColor: "#fff",
  borderRadius: "8px",
  fontWeight: "500",
  border: "1.5px solid #003249",
  "&:hover": {
    color: "#0F172A",
    backgroundColor: "#fff",
  }
});

const BlackButton = styled(Button)({
  width: "100%",
  padding: "8px 10px",
  fontFamily: "Spartan",
  whiteSpace: "nowrap",
  textTransform: "none",
  fontSize: "16px",
  borderRadius: "8px",
  color: "#fff",
  fontWeight: "700",
  backgroundColor: "#003249",
  border: "1.5px solid #003249",
  "&:hover": {
    color: "#fff",
    backgroundColor: "#003249",
  }
});
// Customizable Area End