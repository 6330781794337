import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordVisible, imgVisbility, passwordVisible, passwordInVisible, imgPasswordInVisible, imgVisbilityOff, bgImage, checkBox, dialogImage, arrowImage } from "./assets";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import parsePhoneNumberFromString from "libphonenumber-js";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  loading: boolean;
  terms_conditions_id: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  phoneError: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  bgImage: string;
  checkBox: string;
  dialogImage: string;
  arrowImage: string;
  passwordVisible:string;
  passwordInVisible:string;
  enableNewPasswordField: boolean;
  enableConfirmPasswordField: boolean;
  firstNameError: string;
  lastNameError: string;
  emailError: string;
  newPassword: string;
  newPasswordError: string;
  confirmPassword: string;
  confirmPasswordError: string;
  isUppercaseValid: boolean;
  isLengthValid: boolean;
  isNumberValid: boolean;
  isSpecialCharValid: boolean;
  isEmailValid: boolean;
  isPhnNoValid: boolean;
  checked: boolean;
  linkSent: boolean;
  token: string;
  role: string;
  isRolePageVisible: boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;
  currentCountryCode: any;
  createApiId:string="";
  sendLinkId:string='';
  termsCondId: string='';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.NavigationPropsMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    // Customizable Area End

    this.state = {
      // Customizable Area Start
      loading: false,
      terms_conditions_id: "",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      phoneError:"",
      bgImage: bgImage.default,
      checkBox: checkBox.default,
      dialogImage: dialogImage.default,
      arrowImage: arrowImage.default,
      passwordVisible: passwordVisible.default,
      passwordInVisible: passwordInVisible.default,
      imgPasswordVisible: imgVisbility,
      imgPasswordInVisible: imgVisbilityOff,
      enableNewPasswordField: true,
      enableConfirmPasswordField: true,
      firstNameError: "",
      lastNameError: "",
      emailError:"",
      newPassword:"",
      newPasswordError: "",
      confirmPassword:"",
      confirmPasswordError: "",
      isUppercaseValid: false,
      isLengthValid: false,
      isNumberValid: false,
      isEmailValid: false,
      isPhnNoValid: false,
      isSpecialCharValid: false,
      checked:false,
      linkSent: false,
      token: "",
      role: "",
      isRolePageVisible: true
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if(apiRequestCallId==this.createApiId){
          this.handleResponseForCreateApi(responseJson)
        }
      }
    }

    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      if (selectedCode !== undefined) {
        this.setState({
          countryCodeSelected:
            selectedCode.indexOf("+") > 0
              ? selectedCode.split("+")[1]
              : selectedCode
        });
      }
    }
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      const sessionData = message.getData(getName(MessageEnum.SessionResponseData));
      this.setState({checked:sessionData.isAccept});
        }
    this.receive1(message)
    // Customizable Area End
  }

  // Customizable Area Start
  handleResponseForCreateApi(responseJson: any) {
    this.setState({ loading: false });
    if(responseJson.data){
      this.setState({linkSent:true})
    }
    if(responseJson.meta){
      this.setState({token:responseJson.meta.token})
    }
    if(responseJson.errors){
      this.setState({firstNameError:responseJson.errors.find((error: object) => error.hasOwnProperty("first_name"))?.first_name})
      this.setState({lastNameError:responseJson.errors.find((error: object) => error.hasOwnProperty("last_name"))?.last_name})
      this.setState({emailError:responseJson.errors.find((error: object) => error.hasOwnProperty("email"))?.email})
      this.setState({phoneError:responseJson.errors.find((error: object) => error.hasOwnProperty("full_phone_number"))?.full_phone_number})
    }
  }
  async componentDidMount() {
  const storedData = await getStorageData('terms_conditions_id');
    if (storedData) {
      this.setState({
        firstName: await getStorageData('firstName'),
        lastName: await getStorageData('lastName'),
        email: await getStorageData('email'),
        phone: await getStorageData('phone'),
        newPassword: await getStorageData('newPassword'),
        confirmPassword: await getStorageData('confirmPassword'),
      })
      const newPassword = await getStorageData('newPassword')
      const isUppercaseValid = /[A-Z]/.test(newPassword); 
      const isLengthValid = newPassword.length >= 8 && newPassword.length <= 20;
      const isNumberValid = /\d/.test(newPassword);
      const isSpecialCharValid = /[!@#$%^&*(),.?":{}|<>]/.test(newPassword);
      
      this.setState({
        isUppercaseValid,
        isLengthValid,
        isNumberValid,
        isSpecialCharValid,
      });
    } 
  }

  onChangeRole = (role: string) => {
    this.setState({ role });
  }

  onGoBack = () => {
    this.props.navigation.goBack();
  }

  onBackToRole = () => {
    this.setState({ isRolePageVisible: true });
  }

  onNext = () => {
    this.setState({ isRolePageVisible: false });
  }

  receive1 = (message: Message) => {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.termsCondId) {
        
      
          this.setState({terms_conditions_id: responseJson.data[0].id});
          setStorageData("terms_conditions_id",responseJson.data[0].id)

          const message: Message = new Message(getName(MessageEnum.NavigationMessage))
          message.addData(
            getName(MessageEnum.NavigationTargetMessage),
            'TermsConditions'
          );
          message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
          const raiseMessage: Message = new Message(
            getName(MessageEnum.NavigationPayLoadMessage)
          );
          message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
          this.send(message);
        }
      } 
    } 
  }

  handleFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const firstName = event.target.value;
    this.setState({ firstName, firstNameError: "" });
  };

  handleLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const lastName = event.target.value;
    this.setState({ lastName, lastNameError: "" });
  };

  navigateToLoginPage = () => {

    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'EmailAccountLoginBlock'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }

  handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const email = event.target.value;
    this.setState({ email });
    if(!this.emailReg.test(email))
    {this.setState({emailError:'Invalid email'})}
    else{this.setState({emailError:''})}

    const isEmailValid = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/u.test(email);
    this.setState({isEmailValid});
  };

  handlePhoneNumber = (event: any) => {
    this.setState({ phone : event, phoneError: "" });
    const formattedValue = event?.startsWith("+") ? event : `+${event}`;
    const phoneNumber = parsePhoneNumberFromString(formattedValue);
    const isPhnNoValid = phoneNumber?.isValid() || false;
    this.setState({isPhnNoValid});
  };

  handleNewPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = event.target.value;
    this.setState({ newPassword });

    const isUppercaseValid = /[A-Z]/.test(newPassword); 
    const isLengthValid = newPassword.length >= 8 && newPassword.length <= 20;
    const isNumberValid = /\d/.test(newPassword);
    const isSpecialCharValid = /[!@#$%^&*(),.?":{}|<>]/.test(newPassword);
    
    this.setState({
      isUppercaseValid,
      isLengthValid,
      isNumberValid,
      isSpecialCharValid,
    });

  };

  handleShowNewPassword = () => {
    this.setState({
      enableNewPasswordField: !this.state.enableNewPasswordField,
    });
  };

  handleConfirmPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const confirmPassword = event.target.value;
    const { newPassword } = this.state;
    this.setState({ confirmPassword });

    if (confirmPassword !== newPassword) {
      this.setState({ confirmPasswordError: 'Passwords do not match!' });
    } else {
      this.setState({ confirmPasswordError: '' });
    }

  };

  handleShowConfirmPassword = () => {
    this.setState({
      enableConfirmPasswordField: !this.state.enableConfirmPasswordField,
    });
  };

  navigateToTermsConditions = () => {

    setStorageData("firstName", this.state.firstName)
    setStorageData("lastName", this.state.lastName)
    setStorageData("email", this.state.email)
    setStorageData("phone", this.state.phone)
    setStorageData("newPassword", this.state.newPassword)
    setStorageData("confirmPassword", this.state.confirmPassword)

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      "token":this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.termsCondId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_terms_and_conditions/terms_and_conditions`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
   
  }

  handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({checked:event.target.checked});
  };

  handleButtonClick = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sendLinkId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/resend_email`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    //this.setState({linkSent:true})
  }

  handleClose = () => {
    this.setState({linkSent:false})
  }

  createApi=()=>{
    if(this.state.emailError=='' && this.state.newPasswordError==''){
      this.setState({ loading: true });
      removeStorageData('terms_conditions_id')
      removeStorageData("firstName")
      removeStorageData("lastName")
      removeStorageData("email")
      removeStorageData("phone")
      removeStorageData("newPassword")
      removeStorageData("confirmPassword")
      
      const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      "token":this.state.token,
    };

    const httpBody = {
      "data": { 
      "type": "email_account",
      "attributes": {
            "first_name":this.state.firstName,
            "last_name": this.state.lastName,
            "email": this.state.email,
            "full_phone_number": "+" + this.state.countryCodeSelected + this.state.phone,
            "password": this.state.newPassword,
            "password_confirmation": this.state.confirmPassword,
            "role_id": this.state.role,
            "country_name": this.state.countryCodeSelected, 
        }
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `POST`
    );

    this.createApiId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  createAccount(): boolean {
    if (
      this.isStringNullOrBlank(this.state.firstName) ||
      this.isStringNullOrBlank(this.state.lastName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password) ||
      this.isStringNullOrBlank(this.state.reTypePassword)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    }

    var phoneNumberError = this.validateCountryCodeAndPhoneNumber(
      this.state.countryCodeSelected,
      this.state.phone
    );

    if (phoneNumberError) {
      this.showAlert(configJSON.errorTitle, phoneNumberError);
      return false;
    }

    if (!this.isValidEmail(this.state.email)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    }

    if (!this.passwordReg.test(this.state.password)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorPasswordNotValid);
      return false;
    }

    if (this.state.password !== this.state.reTypePassword) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorBothPasswordsNotSame
      );
      return false;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      full_phone_number: "+" + this.state.countryCodeSelected + this.state.phone
    };

    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }
  getCheckIconStyle(isEmailValid: any) {
    if (isEmailValid) {
      return { color: '#10B981' }; // Green color when valid
    } else {
      return { color: 'transparent' }; // Light gray color when not valid
    }
  }

  getInputType(enableNewPasswordField: any) {
    return enableNewPasswordField ? 'password' : 'text';
  }

  getColor(state: { firstName: any; lastName: any; email: any; phone: any; newPassword: any; confirmPassword: any; checked: any; }) {
    return state.firstName && state.lastName && state.email && state.phone && state.newPassword && state.confirmPassword && state.checked ? "FFFFFF" : "#FFFFFF";
  }
  
  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnSignUpProps = {
    onPress: () => this.createAccount()
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    }
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    }
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad"
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    }
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    }
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  // Customizable Area End
}
