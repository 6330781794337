import React from "react";
// Customizable Area Start
import { Box, Typography, Button, styled, Avatar } from "@mui/material";
import { onfidoFooterLogo } from "./assets";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';

const configJson = require("./config.js");

const initialSteps = [
  { number: 1, label: "your identity document" },
  { number: 2, label: "your face" },
];
// Customizable Area End

import KnowYourCustomerKycVerificationController, {
  Props,
} from "./KnowYourCustomerKycVerificationController";
import { Formik } from "formik";
const initialValues = {
  firstNameDefault: "",
  lastNameDefault: "",
  middleNameDefault: "",
  emailStateDefault: "",
  nationalityDefault: "",
  dateOfBirth: "",
  phoneNumber: ""
}

export default class KnowYourCustomerKycVerification extends KnowYourCustomerKycVerificationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderOnfidoPages() {
    return !this.state.isKycCompleted ? (
      <div id="onfido-mount"></div>
    ) : (
      <Box className="card-wrapper">
        <Box className="kyc-card">
          <ArrowBackRoundedIcon data-test-id="backBtn" sx={{ cursor: "pointer" }} onClick={() => this.setState({ stepNumber: 0 })} />
          <Box className="title-wrapper">
            <CheckCircleOutlineRoundedIcon className="complete-icon" />
            <Typography className="card-title">
              {configJson.thankyouText}
            </Typography>
            <Typography className="card-subtitle">
              {configJson.thankyouSubtitle}
            </Typography>
          </Box>
          <Box className="logo">
            {onfidoFooterLogo}
          </Box>
        </Box>
      </Box>
    )
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <Wrapper>
        <Box>
          <Box width="440px">
            <Typography className="main-title">
              {configJson.kycPageTitle}
            </Typography>
            {this.state.stepNumber === 0 ? (
              <Box className="card-wrapper">
                <Box className="kyc-card">
                  <Box className="title-wrapper">
                    <Typography className="card-title">
                      {configJson.kycPageTitle}
                    </Typography>
                    <Typography className="card-subtitle">
                      {configJson.kycPageSubtitle}
                    </Typography>
                  </Box>
                  <Box className="step-wrapper">
                    <Typography className="step-text">
                      {configJson.stepTitle}
                    </Typography>
                    {initialSteps.map((step) => {
                      return (
                        <Box key={step.number}>
                          <Typography className="step-text">
                            <Avatar
                              sx={{
                                bgcolor: "transparent",
                                width: 24,
                                height: 24,
                                border: "1px solid #059669",
                                color: "#059669",
                                fontSize: "12px",
                              }}
                            >
                              {step.number}
                            </Avatar>
                            {step.label}
                          </Typography>
                        </Box>
                      );
                    })}
                  </Box>
                  <Box className="btn-wrapper">
                    <BlackButton
                      data-test-id="choose_doc_btn"
                      onClick={this.getSDKToken}
                    >
                      {configJson.chooseDocumentBtn}
                    </BlackButton>
                    {onfidoFooterLogo}
                  </Box>
                </Box>
              </Box>
            ) : (
              this.renderOnfidoPages()
            )}
          </Box>
        </Box>
      </Wrapper>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const Wrapper = styled(Box)({
  flex: "1",
  padding: "0px 10px",
  display: "flex",
  justifyContent: "center",
  "& .main-title": {
    marginBottom: "25px",
    letterSpacing: "-0.5px",
    fontFamily: "Spartan",
    fontSize: "18px",
    fontWeight: "700",
    color: "#003249",
  },
  "& .card-wrapper": {
    display: "flex",
    gap: "25px",
    flexDirection: "column",
    "& .kyc-card": {
      padding: "32px",
      borderRadius: "16px",
      backgroundColor: "#fff",
      border: "1px solid #E2E8F0",
      "& .title-wrapper": {
        textAlign: "center",
        marginBottom: "40px",
      },
      "& .card-title": {
        letterSpacing: "-0.5%",
        fontFamily: "Spartan",
        fontSize: "24px",
        fontWeight: "700",
        color: "#003249",
        marginBottom: "8px",
      },
      "& .card-subtitle": {
        fontFamily: "Spartan",
        fontSize: "18px",
        fontWeight: "400",
        lineHeight: "26px",
        paragraphSpacing: "12px",
        color: "#003249",
      },
      "& .step-wrapper": {
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      },
      "& .step-text": {
        display: "flex",
        gap: "8px",
        letterSpacing: "-2%",
        fontFamily: "Spartan",
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "24px",
        color: "#003249",
      },
      "& .btn-wrapper": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "20px",
      },
    },
  },
  "& .complete-icon": {
    marginBottom: "16px",
    width: "36px",
    height: "36px",
    color: "#059669",
  },
  "& .logo": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
});

const BlackButton = styled(Button)({
  marginTop: "64px",
  padding: "8px 10px",
  width: "100%",
  borderRadius: "8px",
  textTransform: "none",
  fontSize: "14px",
  fontFamily: "Spartan",
  letterSpacing: "-0.5px",
  whiteSpace: "nowrap",
  fontWeight: "700",
  color: "#fff",
  backgroundColor: "#003249",
  border: "1.5px solid #003249",
  "&:hover": {
    color: "#fff",
    backgroundColor: "#003249",
  },
  "&.Mui-disabled": {
    color: "#fff",
    backgroundColor: "#94A3B8",
    border: "1.5px solid #94A3B8",
  },
});
// Customizable Area End
