// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall } from "../../../components/src/NavigationRouteWrapper/helpers";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

export interface IAddHorse {
  isEdit?: boolean;
  isNew?: boolean;
  previous: string;
  id: string;
  horseName: string;
  file: string | File;
  fileName: string;
  fileSize: string;
  horseErr: string;
  fileErr: string;
}

interface S {
  // Customizable Area Start
  loading: boolean;
  isLoading: boolean;
  openPopup: string;
  data: any;
  horseId: string;
  currentIndex: number;
  // Customizable Area End
}

interface SS {
  id: string;
}

const maxSizeMB = 5;

export default class HorseInfoController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getHorseDetailsApiCallId: string = "";
  deleteHorseApiCallId: string = "";
  saveHorseDetailsApiCallId: string = "";
  menuRef: HTMLUListElement | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      loading: false,
      isLoading: false,
      openPopup: "",
      data: [],
      horseId: "",
      currentIndex: -1,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getHorseDetailsApiCallId) {
        if (responseJson.data) {
          const data = responseJson.data.map((item: any) => {
            return {
              id: item.id,
              horseName: item.attributes.horse_name,
              file: item.attributes?.horse_details_pdf?.horse_document?.url,
              fileSize: item.attributes?.horse_details_pdf?.horse_document?.size,
              fileName: item.attributes?.horse_details_pdf?.horse_document?.filename
            }
          });
          this.setState({ data });
        }
        this.setState({ loading: false });
      } else if (apiRequestCallId === this.saveHorseDetailsApiCallId) {
        if (responseJson?.data) {
          toast.success("Data save successfully!!");
          const data = responseJson.data.map((item: any) => {
            return {
              id: item.id,
              isEdit: false,
              horseName: item.attributes?.horse_name,
              file: item.attributes?.horse_details_pdf?.horse_document?.url,
              fileSize: item.attributes?.horse_details_pdf?.horse_document?.size,
              fileName: item.attributes?.horse_details_pdf?.horse_document?.filename
            }
          });
          this.setState({ data, currentIndex: -1, horseId: "" });
        } else if (responseJson.error) {
          toast.error(responseJson.error);
        }
        this.setState({ isLoading: false });
      } else {
        this.handleResponse(apiRequestCallId, responseJson);
      }
    }
  }

  handleResponse = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.deleteHorseApiCallId) {
      if (Array.isArray(responseJson.message)) {
        if (responseJson.message[0]?.message === "Profile Removed") {
          toast.success("Horse profile deleted!");
          let data = [...this.state.data];
          data = data.filter((item) => item.id !== this.state.horseId);
          this.setState({ data, horseId: "", openPopup: "" });
        }
      }
      this.setState({ isLoading: false });
    }
  }

  async componentDidMount() {
    this.getHorseDetails();
  }

  onClose = () => {
    this.setState({ openPopup: "" });
  }

  addHorse = () => {
    const data = [...this.state.data];
    if (data.length >= 10) {
      this.setState({ openPopup: "limit" });
    } else {
      this.setState({ data: [...data, { horseName: "", isNew: true }] });
    }
  }

  onOpenMenu = (id: string, index: number) => {
    this.setState({ horseId: id, currentIndex: index });
  }

  removeCard = (index: number) => {
    const isOld = this.state.data[index]?.id;
    if (!isOld) {
      this.setState((prevState) => ({
        data: prevState.data.filter((_: string, i: number) => i !== index)
      }));
    } else {
      this.setState((prevState) => ({
        currentIndex: -1,
        horseId: "",
        data: prevState.data.map((item: IAddHorse, i: number) => {
          if (i === index) {
            const obj = JSON.parse(item.previous);
            return {
              ...item,
              isEdit: false,
              horseName: obj.horseName,
              file: obj.file,
              fileName: obj.fileName,
              fileSize: obj.fileSize,
              horseErr: "",
              fileErr: "",
            };
          }
          return item;
        })
      }));
    }
  }

  handleNameChange = (index: number, value: string) => {
    const data = [...this.state.data];
    const horseErr = value.trim().length === 0 ? "Please enter a horse name" : ""
    data[index] = {
      ...data[index],
      horseErr,
      horseName: value,
    }
    this.setState({ data });
  }

  formatFileSize = (size: number) => {
    if (size < 1024) return `${size} B`;
    if (size < 1024 * 1024) return `${(size / 1024).toFixed(2)} KB`;
    return `${(size / (1024 * 1024)).toFixed(2)} MB`;
  }

  checkFileValidation = (file: File | null) => {
    if (!file) {
      return "Please select a file";
    } else if (typeof file !== "string") {
      const allowedTypes = ["application/pdf", "image/jpeg", "image/jpg"];
      if (!allowedTypes.includes(file.type)) {
        return "Only PDF, JPG, and JPEG files are allowed";
      }
      if (file.size / (1024 * 1024) > maxSizeMB) {
        return `File size should not exceed ${maxSizeMB} MB`;
      }
    }
    return "";
  }

  handleFileChange = (index: number, file: File | null) => {
    if (!file) return;
    const data = [...this.state.data];
    const fileErr = this.checkFileValidation(file);
    data[index] = {
      ...data[index],
      fileErr,
      fileName: file.name,
      file: !fileErr ? file : null,
      fileSize: this.formatFileSize(file.size),
    }
    this.setState({ data });
  }

  handleFileRemove = (index: number) => {
    const data = [...this.state.data];
    data[index] = { ...data[index], file: "", fileName: "", fileSize: "", fileErr: "Please select a file" }
    this.setState({ data });
  }

  onEdit = () => {
    let data = [...this.state.data];
    data = data.map((item) => item.id === this.state.horseId
      ? { ...item, isEdit: true, previous: JSON.stringify({ horseName: item.horseName, file: item.file, fileSize: item.fileSize, fileName: item.fileName }) }
      : item
    );
    this.setState({ data });
  }

  onDelete = () => {
    this.setState({ openPopup: "delete" });
  }

  onSaveHandler = () => {
    let isValid = true;
    const data = this.state.data.map((item: IAddHorse) => {
      const obj = { ...item, horseErr: "", fileErr: "" };
      if (!item.horseName.trim()) {
        isValid = false;
        obj.horseErr = "Please enter a horse name";
      }
      if (!item.file) {
        isValid = false;
        obj.fileErr = "Please select a file";
      } else if (typeof item.file !== "string") {
        const allowedTypes = ["application/pdf", "image/jpeg", "image/jpg"];
        if (!allowedTypes.includes(item.file.type)) {
          isValid = false;
          obj.fileErr = "Only PDF, JPG, and JPEG files are allowed";
        }
        if (item.file.size / (1024 * 1024) > maxSizeMB) {
          isValid = false;
          obj.fileErr = `File size should not exceed ${maxSizeMB} MB`;
        }
      }
      return obj;
    });

    if (isValid) {
      this.onSave();
    } else {
      this.setState({ data });
    }
  }

  onSave = async () => {
    this.setState({ isLoading: true });
    const header = {
      token: localStorage.getItem("token")
    }
    const formData = new FormData();
    this.state.data.map((item: IAddHorse, index: number) => {
      item.id && formData.append(`profiles_attributes[${index}][id]`, item.id);
      formData.append(`profiles_attributes[${index}][horse_name]`, item.horseName);
      typeof item.file !== "string" && formData.append(`profiles_attributes[${index}][horse_details_pdf]`, item.file);
    });

    this.saveHorseDetailsApiCallId = await apiCall({
      header,
      body: formData,
      type: "formData",
      method: "POST",
      endPoint: `/bx_block_profile/profiles/bulk_update`,
    });
  }

  onDeleteHorse = async () => {
    this.setState({ isLoading: true });
    const header = {
      token: localStorage.getItem("token")
    }
    this.deleteHorseApiCallId = await apiCall({
      header,
      method: "DELETE",
      endPoint: `/bx_block_profile/profiles/${this.state.horseId}`,
    });
  }

  getHorseDetails = async () => {
    this.setState({ loading: true });
    const header = {
      token: localStorage.getItem("token")
    }
    this.getHorseDetailsApiCallId = await apiCall({
      header,
      method: "GET",
      endPoint: `/bx_block_profile/profiles`,
    });
  }
  // Customizable Area End
}
