Object.defineProperty(exports, '__esModule', {
  value: true
});

// Customizable Area Start
exports.apiContentType = 'application/json';
exports.apiFormContentType = 'multipart/form-data';
exports.getApiMethod = 'GET';
exports.postApiMethod = 'POST';
exports.putApiMethod = 'PUT';

exports.getChatListApiEndPoint = 'chat/chats';
exports.showChatApiEndPoint = 'chat/chats';
exports.getChatHistoryApiEndPoint = 'chat/chats/history';
exports.searchMessageApiEndPoint = 'chat/chats/search_messages';
exports.searchChatGroupApiEndPoint = 'chat/chats/search';
exports.createChatRoomApiEndPoint = 'chat/chats';
exports.addUserToChatApiEndPoint = 'chat/chats/add_user';
exports.leaveChatApiEndPoint = 'chat/chats/leave';
exports.sendMessageApiEndPoint = 'chat/chats';
exports.updateReadMessageApiEndPoint = 'chat/chats/read_messages';
exports.updateChatApiEndPoint = 'chat/chats';

exports.getMyChatsApiEndpoint = 'chat/chats/mychats';
exports.findChatHistoryEndPoint = "bx_block_chat/chats/history";
exports.createChatApiEndpoint = "bx_block_chat/chats";
exports.addUserToChatApiEndpoint = "bx_block_chat/chats/add_user";
exports.getChatsApiEndPoint = "bx_block_chat/chats/mychats";
exports.openChatApiEndPoint = "bx_block_chat/chats";
exports.markAsReadApiEndPoint = "bx_block_chat/chats/read_messages";
exports.blockUserApiEndPoint = "bx_block_block_users/block_users";
exports.searchChatApiEndPoint = "bx_block_chat/chats/search";
exports.webSocketUrl = "wss://keagensbuildcard-453157-ruby.b453157.dev.eastus.az.svc.builder.cafe/cable";

exports.tokenText = "token";
exports.nameText = "name";
exports.chatDetailsText = "chatDetails";
exports.createButtonText = 'Create';
exports.createRoomButtonText = 'Create Room'
exports.addButtonText = 'Add';
exports.closeButtonText = 'Close';
exports.addAccountText = 'Add Account';
exports.leaveChatRoomText = 'Leave Chat';
exports.sendText = 'Send';
exports.cancelText = 'Cancel';
exports.errorTitle = 'Error';
exports.errorAllFieldsAreMandatory = 'All fields are mandatory.';
exports.unreadMessageText = "Unread Messages";
exports.lastMessageText = "Last Message:";
exports.writeMessagePlaceholder = "Write message here";
exports.usersText = "Users:";
exports.accountIdPlaceholder = "Account ID";
exports.namePlaceholder = "Name";
exports.inbox = "Inbox";
exports.searchPlaceholder = "Search here...";
exports.selectChatText = "Select chat from the listing to view chats";
exports.typeMsgPlaceholder = "Type message ...";
exports.reportMenu = "Report";
exports.blockMenu = "Block";
exports.noMsgTitle = "You don’t have any messages";
exports.noMsgSubtitle = "When you receive a new message, it will appear here.";
exports.notFoundTitle = "Chat not found";
exports.notFoundSubtitle =
  "We are not finding results related to your search. Please try adjusting your search parameters and try again";
exports.emptyMsgText = "Message can not be empty";
exports.blockUserPopupTitle = "Do you want to block"
exports.blockUserPopupSubtitle =
  "Are you sure you want to block this user? Blocking will restrict access to your profile, messages, and any shared content. This action is reversible, so you can unblock them anytime if needed.";
exports.blockBtn = "Block user";
  // Customizable Area End