// Customizable Area Start
import React from "react";
import {
  Box, Grid, Typography, CircularProgress, Card, CardContent,
  CardMedia, Pagination, List, ListItemText, ListItemButton, Button,
  Dialog, DialogTitle, DialogContent, DialogActions, IconButton,
} from "@mui/material";
import { styled } from '@mui/material/styles';
import Slider from "react-slick";
import {
  Close, Edit, DeleteOutline, AddCircleOutlineOutlined,
  MoreVert, RemoveRedEyeOutlined, PauseCircleOutlineOutlined
} from "@mui/icons-material";
import { Dropdown, Button as AntdButton } from "antd";

import ListingsController, { IFile } from "./ListingsController.web";
import Header from "../../../../components/src/AppHeader.web";
import Loader from "../../../../components/src/Loader.web";
import { HomeIcon, RightIcon } from "../assets";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// Customizable Area End

export default class Listings extends ListingsController {
  // Customizable Area Start
  renderDeactivatePopup = () => {
    return (
      <CustomDialog
        onClose={this.handleClose}
        open={this.state.openDialog === "deactivate"}
      >
        <DialogTitle className="title">
          <PauseCircleOutlineOutlined sx={{ color: "#F87171", fontSize: "40px" }} />
          <IconButton className="close-btn" onClick={this.handleClose}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent className="dialog-content">
          <Typography className="popup-title">Are you sure you want to deactivate the listing?</Typography>
          <Typography className="popup-desc">This action will make the listing inactive, but you can activate or edit it at any time. Please note that the deactivation of the listing will take effect after all current guest bookings are completed.</Typography>
        </DialogContent>
        <DialogActions className="dialog-action">
          <WhiteButton onClick={this.handleClose}>Cancel</WhiteButton>
          <BlackButton data-test-id="deactivate" onClick={this.deactivateListing}>Deactivate listing</BlackButton>
        </DialogActions>
      </CustomDialog>
    );
  }

  renderEditPopup = () => {
    return (
      <CustomDialog
        onClose={this.handleClose}
        open={this.state.openDialog === "edit"}
      >
        <DialogTitle className="title">
          <Edit sx={{ color: "#003249", fontSize: "40px" }} />
          <IconButton className="close-btn" onClick={this.handleClose}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent className="dialog-content">
          <Typography className="popup-title">Edit Listing for Future Booking</Typography>
          <Typography className="popup-desc">Changes made here will only affect upcoming reservations; your existing bookings remain unchanged.</Typography>
        </DialogContent>
        <DialogActions className="dialog-action">
          <WhiteButton onClick={this.handleClose}>Cancel</WhiteButton>
          <BlackButton data-test-id="edit-agree" onClick={this.redirectToEditPage}>Agree</BlackButton>
        </DialogActions>
      </CustomDialog>
    );
  }

  renderDeletePopup = () => {
    return (
      <CustomDialog
        onClose={this.handleClose}
        open={this.state.openDialog === "delete"}
      >
        <DialogTitle className="title">
          <DeleteOutline sx={{ color: "#F87171", fontSize: "42px" }} />
          <IconButton className="close-btn" onClick={this.handleClose}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent className="dialog-content">
          <Typography className="popup-title">Delete Listing for Future Bookings</Typography>
          <Typography className="popup-desc">Removing this listing will only impact upcoming reservations; your existing bookings will stay intact.</Typography>
        </DialogContent>
        <DialogActions className="dialog-action">
          <WhiteButton onClick={this.handleClose}>Cancel</WhiteButton>
          <BlackButton data-test-id="delete-agree" onClick={this.deleteListing}>Agree</BlackButton>
        </DialogActions>
      </CustomDialog>
    );
  }

  formatCapacity = (count: number, singular: string, plural: string) => {
    if (count <= 0) return "";
    if (count === 1) return `${count} ${singular}`;
    return `${count} ${plural}`;
  }

  getCapacity = (item: any) => {
    const details = [];
    if (item.total_guest) details.push(this.formatCapacity(item.total_guest, "guest", "guests"));
    if (item.total_horse) details.push(this.formatCapacity(item.total_horse, "horse", "horses"));
    if (item.apartment?.length) details.push(this.formatCapacity(item.apartment.length, "apartment", "apartments"));
    if (item.hookups?.length) details.push(this.formatCapacity(item.hookups.length, "Hookup", "Hookups"));

    const stallCount = item.horse_accommodations?.filter((a: { type: string }) => a.type === "stall").length || 0;
    if (stallCount) details.push(this.formatCapacity(stallCount, "Stall", "Stalls"));

    const pastureCount = item.horse_accommodations?.filter((a: { type: string }) => a.type === "pasture").length || 0;
    if (pastureCount) details.push(this.formatCapacity(pastureCount, "Pasture", "Pastures"));

    return details.length > 0 ? details.join(" • ") : "-";
  }

  threeDotMenu = (id: string) => {
    const options = [
      { key: "1", label: "Edit", icon: <Edit />, onClick: () => this.handleOptionClick("edit", id) },
      { key: "2", label: "Preview", icon: <RemoveRedEyeOutlined />, onClick: () => this.handlePreviewClick(id) },
      { key: "3", label: "Deactivate", icon: <PauseCircleOutlineOutlined />, onClick: () => this.handleOptionClick("deactivate", id) },
      { key: "4", label: <Box color="#DC2626">Delete</Box>, icon: <DeleteOutline sx={{ fill: "#DC2626", fontSize: "16px" }} />, onClick: () => this.handleOptionClick("delete", id) },
    ];
    const items = options.filter(item => item.label !== "Deactivate" || this.state.active === "active");
    return (
      <Dropdown
        menu={{ items }}
        trigger={["click"]}
        placement="bottomRight"
        getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentElement as HTMLElement}
      >
        <AntdButton type="text" shape="circle" icon={<MoreVert />} />
      </Dropdown>
    );
  }

  getAmenities = (amenities: { type: string }[]) => {
    return (amenities && amenities.length > 0) ? amenities.map((obj) => obj.type).join(" • ") : "-";
  }

  getImage = (name: string, url: string) => {
    return (
      <CardMedia
        key={name}
        height="185"
        alt={name}
        image={url}
        component="img"
        className="image"
      />
    );
  }

  renderCard = (item: any) => {
    return (
      <CustomCard>
        <Box mb="10px" className="slider">
          <Slider {...{
            dots: true,
            infinite: true,
            customPaging: () => <Dot />,
          }}>
            {item.cover_photo && this.getImage(item.cover_photo.filename, item.cover_photo.url)}
            {item.files && item.files.map((file: IFile) => {
              return this.getImage(file.file_name, file.file_url)
            })}
          </Slider>
        </Box>
        <CardContent className="content">
          {this.state.active === "saved" && (
            item.step_left === 0 ?
              <Typography className="saved-status ready">Ready to post</Typography>
              : <Typography className="saved-status left">{item.step_left} steps left to post</Typography>
          )}
          <Box>
            <Box display="flex" justifyContent="space-between" alignItems="start">
              <Typography className="title">
                {item.title}
              </Typography>
              <Box mr="-5px" mt="-8px">
                {this.threeDotMenu(item.id)}
              </Box>
            </Box>
            <Typography className="address">
              {item.address}
            </Typography>
          </Box>
          <Box>
            <Typography className="sub-title">Capacity</Typography>
            <Typography className="details">
              {this.getCapacity(item)}
            </Typography>
          </Box>
          <Box>
            <Typography className="sub-title">Amenities</Typography>
            <Typography className="details">{this.getAmenities(item.amenities)}</Typography>
          </Box>
        </CardContent>
      </CustomCard>
    );
  }

  renderNotFound = () => {
    return (
      <Box className="not-found">
        <Box textAlign="center">
          {HomeIcon}
          <Typography className="no-listing">
            {this.state.active === "active"
              ? "No active listings yet"
              : "No saved listings yet"
            }
          </Typography>
          <Typography className="create-one">
            {this.state.active === "active"
              ? <>You currently have no active listings. You can edit your saved listings to <br /> activate them or create a new one.</>
              : <>You currently have no saved listings</>
            }
          </Typography>
        </Box>
      </Box>
    );
  }

  renderMenuOption = () => {
    return (
      <Sidebar>
        <Box padding="0px 15px">
          <Typography className="section-title">Listings</Typography>
          <List className="menu-section">
            {LISTING_OPTION.map(({ label, value }) => (
              <ListItemButton
                key={label}
                data-test-id="menu-option"
                className={`menu-item ${this.state.active === value && "active"}`}
                onClick={() => { this.state.active !== value && this.onChangeTab(value) }}
              >
                <ListItemText primary={label} />
                {RightIcon}
              </ListItemButton>
            ))}
          </List>
        </Box>
      </Sidebar>
    );
  }

  getTitle = () => {
    return LISTING_OPTION.filter((option) => option.value === this.state.active)[0]?.label;
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <Header hideFilter navigation={this.props.navigation} active="Listings">
        <Wrapper>
          <Loader loading={this.state.isLoading} />
          {this.renderMenuOption()}
          {this.state.loading && !this.state.isLoading ? (
            <Box className="center">
              <CircularProgress sx={{ color: "#000" }} />
            </Box>
          ) : (
            <Box className="listing-section" ref={this.scrollRef}>
              <Box className="wrap">
                <Typography className="top-heading">{this.getTitle()}</Typography>
                <IconButton data-test-id="plus-btn" onClick={this.redirectToCreateList}>
                  <AddCircleOutlineOutlined
                    sx={{ color: "#003249" }}
                  />
                </IconButton>
              </Box>
              {this.state.listingData.length === 0
                ? this.renderNotFound()
                : (
                  <Box className="wrapper">
                    <Grid container spacing={2} sx={{ display: "flex" }}>
                      {this.state.listingData.map((item) => (
                        <Grid item xs={12} sm={12} md={4} key={item.id} className="grid-item">
                          {this.renderCard(item.attributes)}
                        </Grid>
                      ))}
                    </Grid>
                    {this.state.pagination.total_pages > 1 && (
                      <Box mt="15px" display="flex" justifyContent="center">
                        <CustomPagination
                          shape="rounded"
                          boundaryCount={1}
                          siblingCount={1}
                          data-test-id="pagination"
                          page={this.state.currentPage}
                          count={this.state.pagination.total_pages}
                          onChange={(_, newPage: number) => this.onChangePage(newPage)}
                        />
                      </Box>
                    )}
                  </Box>
                )
              }
            </Box>
          )}
          {this.renderEditPopup()}
          {this.renderDeactivatePopup()}
          {this.renderDeletePopup()}
        </Wrapper>
      </Header>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const Wrapper = styled(Box)({
  display: "flex",
  overflow: "auto",
  height: "100%",
  backgroundColor: "#F8FAFC",
  "& .center": {
    display: "flex",
    height: "100%",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .listing-section": {
    width: "100%",
    padding: "30px 20px",
    position: "relative",
    flex: "1 1 auto",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh - 65px)",
    "& .not-found": {
      display: "flex",
      width: "100%",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
      "& .no-listing": {
        marginTop: "20px",
        fontSize: "16px",
        fontFamily: "Spartan",
        fontWeight: "700",
        color: "#64748B",
      },
      "& .create-one": {
        marginTop: "5px",
        fontFamily: "Spartan",
        fontSize: "14px",
        fontWeight: "500",
        color: "#64748B",
      },
    },
    "& .wrapper": {
      flex: "1 1 auto",
      display: "flex",
      flexDirection: "column",
    },
    "& .wrap": {
      marginBottom: "30px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    },
    "& .top-heading": {
      display: "flex",
      letterSpacing: "-0.5px",
      fontFamily: "Spartan",
      textTransform: "uppercase",
      color: "#003249",
      fontWeight: "700",
      fontSize: "17px",
    },
    "& .grid-item": {
      display: "flex",
      flexDirection: "column"
    },
  },
});

const Sidebar = styled(Box)({
  minWidth: "323px",
  padding: "20px 0px",
  overflow: "scroll",
  height: "calc(100vh - 65px)",
  backgroundColor: "#fff",
  "&::-webkit-scrollbar": {
    width: "4px",
    height: "4px"
  },
  ".section-title": {
    fontFamily: "Spartan",
    marginTop: "20px",
    fontSize: "14px",
    fontWeight: "500",
    color: "#64748B",
    marginBottom: "15px",
  },
  ".menu-section": {
    borderRadius: "16px",
    padding: "5px 0",
    backgroundColor: "#fff",
    boxShadow: "0px 2px 8px 0px #00000014",
  },
  ".menu-item": {
    display: "flex",
    alignItems: "center",
    padding: "9px 15px",
    marginTop: "2px",
    borderRadius: "8px",
    marginBottom: "2px",
    justifyContent: "space-between",
    paddingRight: "10px",
    "&.active": {
      borderRadius: "16px",
      backgroundColor: "#F1F5F9",
      "& .MuiListItemText-primary": {
        fontWeight: "700",
      }
    },
    "&:hover": {
      borderRadius: "16px",
      backgroundColor: "#F1F5F9",
    },
    "& .MuiListItemText-primary": {
      fontFamily: "Spartan",
      fontWeight: "500",
      fontSize: "14px",
      color: "#003249",
      letterSpacing: "-0.5px",
    },
  },
});

const CustomCard = styled(Card)({
  height: "100%",
  padding: "12px",
  borderRadius: "24px",
  border: "1px solid #E2E8F0",
  boxShadow: "0px 2px 8px 0px #00000014",
  "& .slider": {
    marginBottom: "10px",
    "& .slick-slider .slick-list": {
      height: "185px",
      borderRadius: "16px",
    },
    "& .slick-prev, .slick-next": {
      display: "none !important",
    },
    ".slick-dots": {
      position: "absolute",
      bottom: "14px",
      padding: 0,
      margin: 0,
      listStyle: "none",
      display: "flex !important",
      justifyContent: "center",
    },
    ".slick-dots li": {
      height: "12px",
      display: "flex",
      width: "12px",
      margin: "0px 1px",
      alignItems: "center"
    },
    ".slick-dots li div": {
      backgroundColor: "#fff",
      opacity: "0.5",
      width: "6px",
      height: "6px",
    },
    ".slick-dots li button": {
      opacity: 0,
      cursor: "pointer",
    },
    ".slick-dots li.slick-active div": {
      width: "8px",
      opacity: "1",
      height: "8px",
      backgroundColor: "#fff",
    },
  },
  "& .image": {
    borderRadius: "16px",
  },
  "& .content": {
    gap: "7px",
    display: "flex",
    flexDirection: "column",
    padding: "0px !important",
    "& .saved-status": {
      fontFamily: "Spartan",
      fontSize: "12px",
      fontWeight: "700",
      marginBottom: "10px",
      marginTop: "5px",
      letterSpacing: "-0.5px",
      "&.ready": {
        color: "#059669",
      },
      "&.left": {
        color: "#D97706",
      }
    },
    "& .title": {
      fontFamily: "Spartan",
      fontSize: "16px",
      fontWeight: "700",
      color: "#003249",
      letterSpacing: "-0.5px",
    },
    "& .address": {
      fontFamily: "Spartan",
      fontSize: "12px",
      fontWeight: "400",
      color: "#003249",
      letterSpacing: "-0.5px",
    },
    "& .sub-title": {
      fontFamily: "Spartan",
      fontSize: "14px",
      fontWeight: "500",
      color: "#003249",
      letterSpacing: "-0.5px",
    },
    "& .details": {
      fontFamily: "Spartan",
      fontSize: "12px",
      fontWeight: "400",
      color: "#475569",
      letterSpacing: "-0.5px",
    },
    "& .ant-dropdown": {
      "& .ant-dropdown-menu": {
        borderRadius: "8px",
        background: "#fff",
        border: "1px solid #E2E8F0",
        boxShadow: "0px 8px 32px 0px #0000000F",
        "& svg": {
          color: "#475569",
          fontSize: "16px"
        },
        "& .ant-dropdown-menu-title-content": {
          marginTop: "3px",
          fontSize: "14px",
          fontWeight: "500",
          fontFamily: "Spartan",
          color: "#475569"
        },
      }
    }
  },
});

const Dot = styled("div")({
  width: "10px",
  height: "10px",
  backgroundColor: "#fff",
  borderRadius: "50%",
  transition: "background-color 0.3s ease",
});

const CustomPagination = styled(Pagination)({
  "& .MuiPaginationItem-root": {
    fontSize: "14px",
    color: "#004c6d",
    margin: "0px",
    textDecoration: "underline",
  },
  "& .MuiPaginationItem-ellipsis": {
    textDecoration: "none",
  },
  "& .Mui-selected": {
    color: "#003249",
    fontWeight: "bold",
    textDecoration: "none",
  },
});

const CustomDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    maxWidth: "550px",
    borderRadius: "16px",
  },
  "& .title": {
    paddingTop: "35px",
    textAlign: "center",
  },
  "& .MuiDialogContent-root": {
    padding: "13px 30px 14px !important"
  },
  "& .dialog-content": {
    textAlign: "center",
    "& .popup-title": {
      marginBottom: "5px",
      fontFamily: "Spartan",
      fontWeight: "700",
      fontSize: "18px",
      letterSpacing: "-0.5px",
      color: "#003249"
    },
    "& .popup-desc": {
      fontWeight: "500",
      letterSpacing: "-0.5px",
      fontSize: "15px",
      fontFamily: "Spartan",
      color: "#94A3B8"
    },
  },
  "& .dialog-action": {
    padding: "30px",
    gap: "10px",
    justifyContent: "center",
  },
  "& .close-btn": {
    color: "#000",
    position: "absolute",
    top: 8,
    right: 8,
  },
  "&.change-host": {
    "& .MuiDialogContent-root": {
      paddingBottom: "15px !important",
      paddingTop: "15px !important",
    }
  }
});

const WhiteButton = styled(Button)({
  padding: "8px 10px",
  width: "100%",
  color: "#0F172A",
  borderRadius: "8px",
  textTransform: "none",
  fontSize: "16px",
  fontFamily: "Spartan",
  fontWeight: "500",
  backgroundColor: "#fff",
  letterSpacing: "-0.5px",
  border: "1.5px solid #003249",
  "&:hover": {
    color: "#0F172A",
    backgroundColor: "#fff",
  }
});

const BlackButton = styled(Button)({
  color: "#fff",
  padding: "8px 10px",
  borderRadius: "8px",
  textTransform: "none",
  fontSize: "16px",
  fontFamily: "Spartan",
  whiteSpace: "nowrap",
  width: "100%",
  fontWeight: "700",
  letterSpacing: "-0.5px",
  backgroundColor: "#003249",
  border: "1.5px solid #003249",
  "&:hover": {
    color: "#fff",
    backgroundColor: "#003249",
  }
});

const LISTING_OPTION = [
  { label: "Active listing", value: "active" },
  { label: "Saved listing", value: "saved" },
];
// Customizable Area End