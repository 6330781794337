import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { apiCall } from "../../../components/src/NavigationRouteWrapper/helpers";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export enum MsgType {
  Error = "error",
  Success = "success",
}

export interface IChat {
  id: string;
  muted: boolean;
  unreadCount: number;
  lastMessage: string;
  name: string;
}

export interface IAccountsChats {
  id: string;
  type: string;
  attributes: {
    account_id: number;
    muted: boolean;
    chat_id: number;
    status: string;
    unread_count: number;
    account: {
      full_name: string;
      activated: boolean;
      profile_photo: {
        url: string;
      };
    };
  };
}
export interface IChatRoom {
  id: string;
  type: string;
  attributes: {
    name: string;
    accounts_chats: IAccountsChats[];
    messages: {
      id: string;
      type: string;
      attributes: {
        chat_id: number;
        message: string;
        created_at: string;
        updated_at: string;
        is_mark_read: boolean;
        account_id: number;
      };
    } | null;
    listing: {
      id: number;
      title: string;
    };
  };
}

export interface IMessages {
  id: number;
  name: string;
  accounts_chats: IAccountsChats[];
  listing: {
    id: number;
    title: string;
  };
  messages: {
    id: string;
    type: string;
    attributes: IMessage;
  }[];
}

export interface IMessage {
  id: number;
  account_id: number;
  chat_id: number;
  created_at: string;
  updated_at: string;
  is_mark_read: boolean;
  message: string;
}

interface IGroupedMessages {
  [date: string]: IMessage[];
}

interface IToastMsg {
  message: string;
  type: MsgType;
}
// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  accountId: number;
  chatName: string;
  chatList: IChat[];
  isVisibleModal: boolean;
  isLoading: boolean;
  searchText: string;
  currentAcoountId: number;
  chatRoomList: IChatRoom[];
  selectedChatRoom: number;
  messages: IMessages;
  newMessage: string;
  toastMsg: IToastMsg;
  anchorElMenu: null | HTMLElement;
  openBlockUser: boolean;
  currentUsername: string;
  chatData: { id: number; title: string; accountId: number };
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ChatController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  socket: WebSocket | null = null;
  findChatHistoryApiCallId: string = "";
  createChatApiCallId: string = "";
  addUserToChatApiCallId: string = "";
  getChatListApiCallId: string = "";
  createChatRoomApiCallId: string = "";
  createMessageApiCallId: string = "";
  openChatApiCallId: string = "";
  markAsReadApiCallId: string = "";
  blockUserApiCallId: string = "";
  searchChatApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      accountId: -1,
      chatName: "",
      chatList: [],
      isVisibleModal: false,
      isLoading: false,
      searchText: "",
      currentAcoountId: -1,
      selectedChatRoom: -1,
      chatRoomList: [],
      messages: {} as IMessages,
      newMessage: "",
      toastMsg: {} as IToastMsg,
      anchorElMenu: null,
      openBlockUser: false,
      currentUsername: "",
      chatData: { accountId: 0, id: 0, title: "" },
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    const chatDetails = await getStorageData(configJSON.chatDetailsText);
    const chatData = JSON.parse(chatDetails);
    if(chatData) {
      this.setState({ chatData }, () => this.findChatHistory());
    }
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    if (this.state.selectedChatRoom > 0) {
      this.openChatMessage();
    } else {
      this.getChatList();
    }
    // Customizable Area End
  }

  async componentWillUnmount(): Promise<void> {
    super.componentWillUnmount();
    if (this.socket) {
      this.socket.close();
    }
  }

  getToken = () => {};

  isStringNullOrBlank = (_string: string) => {};

  showModal = () => {
    this.setState({ isVisibleModal: true });
  };

  hideModal = () => {
    this.setState({ isVisibleModal: false });
  };

  navigateToChatView = (_chatId: string) => {};

  getChatList = async () => {
    const token = await getStorageData(configJSON.tokenText);
    this.setState({ toastMsg: {} as IToastMsg, isLoading: true });
    this.getChatListApiCallId = await apiCall({
      header: {
        "Content-Type": configJSON.apiContentType,
        token,
      },
      method: configJSON.getApiMethod,
      endPoint: configJSON.getChatsApiEndPoint,
    });
  };

  createChatRoom = (_chatName: string) => {};

  inputRoomNameProps = {};

  btnAddRoomProps = {};

  btnCloseModalProps = {};

  btnShowAddModalProps = {};

  handleChatNameChange = (_chatName: string) => {};

  async receive(_from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.apiSuccessCallBackController(apiRequestCallId, responseJson);
  }

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJson: Record<string, unknown>
  ) => {
    const successCallbackMap = {
      [this.getChatListApiCallId]: this.handleAPIResponse,
      [this.findChatHistoryApiCallId]: this.handleChatHistoryAPIResponse,
      [this.createChatApiCallId]: this.handleCreateChatRoomApiCall,
      [this.addUserToChatApiCallId]: this.handleAddUserToChatApiResponse,
      [this.createMessageApiCallId]: this.handleCreateMessageAPIResponse,
      [this.openChatApiCallId]: this.handleOpenChatAPIResponse,
      [this.markAsReadApiCallId]: this.handleMarkAsReadAPIResponse,
      [this.blockUserApiCallId]: this.handleBlockUserAPIResponse,
      [this.searchChatApiCallId]: this.handleSearchChatAPIResponse,
    };

    if (apiRequestCallId) {
      const successCallback: (responseJson: Record<string, unknown>) => void =
        successCallbackMap[apiRequestCallId];
      !!successCallback && successCallback(responseJson);
    }
  };

  componentDidUpdate(_prevProps: Readonly<Props>, prevState: Readonly<S>) {
    if (this.state.messages !== prevState.messages) {
      const chatContainer = document.getElementById("chat-container");
      if (chatContainer) {
        chatContainer.scrollTop = chatContainer.scrollHeight;
      }
    }
  }

  findChatHistory = async () => {
    const token = await getStorageData(configJSON.tokenText);
    this.setState({ toastMsg: {} as IToastMsg, isLoading: true });
    this.findChatHistoryApiCallId = await apiCall({
      header: { token },
      method: configJSON.getApiMethod,
      endPoint: `${configJSON.findChatHistoryEndPoint}?receiver_id=${this.state.chatData.accountId}`,
    });
  };

  handleChatHistoryAPIResponse = (responseJSON: Record<string, unknown>) => {
    const response = responseJSON as {
      data: { id: number }[];
      message?: string;
    };
    this.setState({ isLoading: false });
    if (response?.message) {
      this.createChat();
    } else {
      const selectedChatRoom = Number(response.data[0].id);
      this.setState({ selectedChatRoom }, () => {
        this.getChatList();
        this.openChatMessage();
      });
    }
  };

  createChat = async () => {
    const token = await getStorageData(configJSON.tokenText);
    this.setState({ isLoading: true });
    const header = { token, "Content-Type": configJSON.apiContentType };
    const body = {
      chat: {
        name: this.state.chatData.title,
        listing_id: this.state.chatData.id,
      },
    };
    this.createChatApiCallId = await apiCall({
      header,
      method: configJSON.postApiMethod,
      endPoint: configJSON.createChatApiEndpoint,
      body,
    });
  };

  handleCreateChatRoomApiCall = (responseJSON: Record<string, unknown>) => {
    const response = responseJSON as {
      data?: { id: string };
      errors?: string;
    };
    this.setState({ isLoading: false });
    if (response.data) {
      const selectedChatRoom = Number(response.data.id);
      this.setState({ selectedChatRoom });
      this.addUserToChat(selectedChatRoom);
    }
  };

  addUserToChat = async (chatId: number) => {
    const token = await getStorageData(configJSON.tokenText);
    this.setState({ isLoading: true });
    const header = { token, "Content-Type": configJSON.apiContentType };
    const body = {
      chat_id: chatId,
      accounts_id: [this.state.chatData.accountId],
    };
    this.addUserToChatApiCallId = await apiCall({
      header,
      method: configJSON.postApiMethod,
      endPoint: configJSON.addUserToChatApiEndpoint,
      body,
    });
  };

  handleAddUserToChatApiResponse = () => {
    removeStorageData(configJSON.chatDetailsText);
    this.getChatList();
    if(this.state.chatData) {
      this.openChatMessage();
    }
  };

  openChat = (messages: IChatRoom) => {
    const selectedChatRoom = Number(messages.id);
    this.setState(
      {
        selectedChatRoom,
        newMessage: "",
      },
      () => {
        this.openChatMessage();
        this.markAsRead();
      }
    );
  };

  closeChat = () => {
    this.setState({
      messages: {} as IMessages,
    });
  };

  openChatMessage = async () => {
    this.setState({ toastMsg: {} as IToastMsg, isLoading: true });
    const token = await getStorageData(configJSON.tokenText);
    this.openChatApiCallId = await apiCall({
      header: {
        "Content-Type": configJSON.apiContentType,
        token,
      },
      method: configJSON.getApiMethod,
      endPoint: `${configJSON.openChatApiEndPoint}/${this.state.selectedChatRoom}`,
    });
  };

  createMessage = async () => {
    if (this.state.newMessage.trim() === "") {
      this.setState({
        toastMsg: { message: configJSON.emptyMsgText, type: MsgType.Error },
        isLoading: false,
      });
      return;
    }
    this.setState({ toastMsg: {} as IToastMsg, isLoading: true });
    const token = await getStorageData(configJSON.tokenText);
    const formData = new FormData();
    formData.append("message[message]", this.state.newMessage);
    this.createMessageApiCallId = await apiCall({
      header: {
        token,
      },
      method: configJSON.postApiMethod,
      endPoint: `${configJSON.openChatApiEndPoint}/${this.state.selectedChatRoom}/messages`,
      body: formData,
      type: "formData",
    });
  };

  handleAPIResponse = async (responseJSON: Record<string, unknown>) => {
    const response = responseJSON as {
      data?: IChatRoom[];
      errors?: string;
    };
    const currentUsername = await getStorageData(configJSON.nameText);
    this.setState({ isLoading: false });
    if (response.data?.length) {
      const currentAcoountId =
      response.data[0].attributes.accounts_chats?.find(
        (chat) => chat.attributes.account?.full_name === currentUsername
      )?.attributes?.account_id || -1;
      this.setState({
        chatRoomList: response.data,
        currentAcoountId,
        currentUsername,
      }, this.initWebSocket);
    } else if (response.errors) {
      this.setState({
        toastMsg: { message: response.errors, type: MsgType.Error },
      });
    }
  };

  handleCreateMessageAPIResponse = (responseJSON: Record<string, unknown>) => {
    const response = responseJSON as {
      data?: { id: string; type: string; attributes: IMessage };
      errors?: { message: string[] };
    };
    this.setState({ isLoading: false });
    if (response.data) {
      this.setState(
        {
          newMessage: "",
        },
        () => {
          this.markAsRead();
          this.openChatMessage();
          this.getChatList();
        }
      );
    } else if (response.errors) {
      this.setState({
        toastMsg: { message: response.errors.message[0], type: MsgType.Error },
      });
    }
  };

  handleOpenChatAPIResponse = (responseJSON: Record<string, unknown>) => {
    const response = responseJSON as {
      data?: { id: string; type: string; attributes: IMessages };
      errors?: string;
    };
    this.setState({ isLoading: false });
    removeStorageData(configJSON.chatDetailsText)
    if (response.data) {
      this.setState({ messages: response.data.attributes });
    } else if (response.errors) {
      this.setState({
        toastMsg: { message: response.errors, type: MsgType.Error },
      });
    }
  };

  markAsRead = async () => {
    const token = await getStorageData(configJSON.tokenText);
    this.setState({ isLoading: true });
    this.markAsReadApiCallId = await apiCall({
      header: {
        token,
      },
      method: configJSON.postApiMethod,
      endPoint: `${configJSON.markAsReadApiEndPoint}?chat_id=${this.state.selectedChatRoom}`,
    });
  };

  handleMarkAsReadAPIResponse = (responseJSON: Record<string, unknown>) => {
    const response = responseJSON as {
      data?: { message: string };
      errors?: string;
    };
    this.setState({ isLoading: false });
    if (response.errors) {
      this.setState({
        toastMsg: { message: response.errors, type: MsgType.Error },
      });
    }
  };

  groupMessagesByDate = (
    messages: { id: string; type: string; attributes: IMessage }[]
  ): IGroupedMessages => {
    const sortedMessages = messages?.sort(
      (a, b) =>
        new Date(a.attributes.created_at).getTime() -
        new Date(b.attributes.created_at).getTime()
    );

    return sortedMessages?.reduce(
      (
        groupedMessages: IGroupedMessages,
        message: { id: string; type: string; attributes: IMessage }
      ) => {
        const date = new Date(message.attributes.created_at).toLocaleDateString(
          "en-GB",
          {
            day: "2-digit",
            month: "long",
            year: "numeric",
          }
        );
        if (!groupedMessages[date]) {
          groupedMessages[date] = [];
        }
        groupedMessages[date].push(message.attributes);
        return groupedMessages;
      },
      {}
    );
  };

  openMenu = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
      anchorElMenu: event.currentTarget,
    });
  };

  blockUser = async () => {
    const token = await getStorageData(configJSON.tokenText);
    this.setState({ isLoading: true });
    this.blockUserApiCallId = await apiCall({
      header: {
        token,
        "Content-Type": configJSON.apiContentType,
      },
      method: configJSON.postApiMethod,
      endPoint: configJSON.blockUserApiEndPoint,
      body: {
        data: {
          type: "reviews",
          attributes: {
            account_id: this.state.messages?.accounts_chats?.find(
              (account) =>
                account.attributes.account.full_name !==
                this.state.currentUsername
            )?.attributes.account_id,
          },
        },
      },
    });
  };

  handleBlockUserAPIResponse = (responseJSON: Record<string, unknown>) => {
    const response = responseJSON as {
      meta?: { message: string };
      errors?: string;
    };
    this.setState({ isLoading: false });
    if (response.meta) {
      this.setState({
        toastMsg: { message: response.meta.message, type: MsgType.Success },
      });
    } else if (response.errors) {
      this.setState({
        toastMsg: { message: response.errors, type: MsgType.Error },
      });
    }
  };

  handleOpenBlockUser = () => {
    this.setState({ openBlockUser: true });
  };

  handleCloseBlockUser = () => {
    this.setState({ openBlockUser: false, anchorElMenu: null });
  };

  handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchText: event.target.value });
  };

  searchChat = async () => {
    const token = await getStorageData(configJSON.tokenText);
    this.setState({ isLoading: true });
    this.searchChatApiCallId = await apiCall({
      header: {
        token,
      },
      method: configJSON.getApiMethod,
      endPoint: `${configJSON.searchChatApiEndPoint}?query=${this.state.searchText}`,
    });
  };

  handleSearchChatAPIResponse = (responseJSON: Record<string, unknown>) => {
    const response = responseJSON as {
      data?: { attributes: { messages: IMessage[] } }[];
      errors?: string;
    };
    this.setState({ isLoading: false });
    if (response.data) {
      const chatRoomList = response.data.map((charRoom) => {
        return {
          ...charRoom,
          attributes: {
            ...charRoom.attributes,
            messages: charRoom.attributes.messages.reduce(
              (message, msgItem) =>
                Number(msgItem.id) > Number(message.id) ? msgItem : message,
              charRoom.attributes.messages[0]
            ),
          },
        };
      });
      this.setState({ chatRoomList: (chatRoomList as unknown) as IChatRoom[] });
    } else if (response.errors) {
      this.setState({
        toastMsg: { message: response.errors, type: MsgType.Error },
      });
    }
  };

  getUserDetails = (item: IChatRoom) => {
    return item.attributes.accounts_chats?.find(
      (chat: { attributes: { account: { full_name: string } } }) =>
        chat.attributes.account?.full_name !== this.state.currentUsername
    );
  };

  getUnreadMsgs = (item: IChatRoom) => {
    return item.attributes.accounts_chats?.filter(
      (chat) =>
        chat.attributes.unread_count > 0
    ).length > 0;
  };

  initWebSocket = async () => {
    const token = await getStorageData(configJSON.tokenText);

    this.socket = new WebSocket(`${configJSON.webSocketUrl}?token=${token}`);

    this.socket.onopen = () => this.subscribeChannel();

    this.socket.onmessage = (event) => this.getMessage(event);

    this.socket.onerror = (_error) => {};

    this.socket.onclose = () => {};
  };

  subscribeChannel = () => {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      const subscribeCommand = JSON.stringify({
        command: "subscribe",
        identifier: JSON.stringify({
          channel: "ChatChannel",
          id: this.state.selectedChatRoom,
        }),
        data: JSON.stringify({ id: this.state.selectedChatRoom }),
      });
      this.socket.send(subscribeCommand);
    }
  };

  getMessage = (event: MessageEvent) => {
    if (
      this.state.messages.messages &&
      JSON.parse(event.data).type === undefined
    ) {
      const parsedData = JSON.parse(event.data);
      const socketMessage = parsedData.message;
      const message = {
        id: socketMessage.id.toString(),
        type: "chat_message",
        attributes: {
          ...socketMessage,
        },
      };

      if (
        !this.state.messages.messages.find(
          (messageItem) => messageItem.id === message.id
        )
      ) {
        this.setState((prevState) => ({
          messages: {
            ...prevState.messages,
            messages: [
              ...prevState.messages.messages,
              { ...message, attachments: null },
            ],
          },
        }));
      }
    }
  };
  // Customizable Area End
}
