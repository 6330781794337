// Customizable Area Start
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { apiCall } from "../../../../components/src/NavigationRouteWrapper/helpers";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  isHeader:boolean;
  onPaymentStatusChange?: (key: string, status: string) => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  isLoading: boolean;
  stripeAccountId: string | null;
  // Customizable Area End
}

interface SS {
  id: string;
}

export default class PaymentInfoController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  setUpAccountApiCallID: string = "";
  deleteAccountApiCallId: string = "";
  getAccountInfoApiCallId: string = "";
  connectExistingAccountApiID: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      loading: false,
      isLoading: false,
      stripeAccountId: null,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) return;

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    this.setState({ isLoading: false });

    switch (apiRequestCallId) {
      case this.getAccountInfoApiCallId:
        if (responseJson.data?.attributes) {
          this.setState({
            stripeAccountId: responseJson.data.attributes.stripe_account_id
          });
        }
        this.setState({ loading: false });
        break;

      case this.setUpAccountApiCallID:
        if (responseJson.onboarding_url) {
          window.location.href = responseJson.onboarding_url;
        }
        break;

      case this.deleteAccountApiCallId:
        if (responseJson.message) {
          this.props.onPaymentStatusChange?.("payment_details", "Pending");
          this.getAccountDetails();
          this.setState({ stripeAccountId: null });
        } else {
          toast.error(responseJson.error);
        }
        break;

      case this.connectExistingAccountApiID:
        if (responseJson.success) {
          toast.success(responseJson.message);
          this.props.onPaymentStatusChange?.("payment_details", "Completed");
        } else {
          toast.error(responseJson.message);
        }
        this.setState({ stripeAccountId: null });
        break;

      default:
        break;
    }
  }
  async componentDidMount() {
    this.getAccountDetails();
  }

  setUpHostAccount = async () => {
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    const body = {
      account: {
        country: "US",
      },
    };

    this.setUpAccountApiCallID = await apiCall({
      header,
      body,
      method: "POST",
      endPoint: configJSON.setUpHostAccount,
    });
  };

  deleteStripeAccount = async () => {
    this.setState({ isLoading: true });
    const header = {
      token: localStorage.getItem("token"),
    };
    this.deleteAccountApiCallId = await apiCall({
      header,
      method: "DELETE",
      endPoint: configJSON.deletStripeAccount,
    });
  };

  connectExistingAccount = async () => {
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    const body = {
      stripe_account_id: this.state.stripeAccountId,
    };

    this.connectExistingAccountApiID = await apiCall({
      header,
      body,
      method: "POST",
      endPoint: configJSON.connectExixtingAccount,
    });
  };

  getAccountDetails = async () => {
    this.setState({ loading: true });
    const header = {
      token: localStorage.getItem("token"),
    };
    this.getAccountInfoApiCallId = await apiCall({
      header,
      method: "GET",
      endPoint: configJSON.userProfile,
    });
  };
  // Customizable Area End
}
