// Customizable Area Start
import React from "react";
import { Box, Typography, Button, InputBase } from "@mui/material";
import { styled } from '@mui/material/styles';
import { Formik, FormikErrors, FormikTouched } from "formik";
import * as Yup from 'yup';
import Select from "react-select";

import ContactUsController from "./ContactUsController.web";
import Header from "../../../components/src/AppHeader.web";
import Loader from "../../../components/src/Loader.web";
import { infoIcon } from "./assets";
// Customizable Area End

export default class ContactUs extends ContactUsController {
  // Customizable Area Start
  validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required")
      .email("Enter valid email"),
    issueType: Yup.string()
      .required("Please select option"),
    description: Yup.string()
      .required("Description is required")
  })

  errorMsg = (touched: FormikTouched<{ [key: string]: string | boolean }>, errors: FormikErrors<{ [key: string]: string | boolean }>, value: string) => {
    return (touched[value] && errors[value])
      ? <div className="error">{errors[value]}</div>
      : <div />
  }

  renderLeftSection = () => {
    return (
      <Box className="info-wrapper">
        <Box className="left-section">
          <Formik
            enableReinitialize
            initialValues={{
              flag: this.state.flag,
              email: "",
              issueType: "",
              description: ""
            }}
            validationSchema={this.validationSchema}
            onSubmit={(values) => this.saveContactInfo(values)}
          >
            {({ values, touched, errors, isValid, dirty, setTouched, setFieldValue, handleSubmit }) => (
              <form
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit}
              >
                <Box>
                  <Box mb="20px">
                    <Typography className="label">Email</Typography>
                    <CustomInputBase
                      data-test-id="email"
                      value={values.email}
                      placeholder="Add your email"
                      onChange={(e) => {
                        setTouched({ ...touched, email: true });
                        setFieldValue("email", e.target.value);
                      }}
                    />
                    {this.errorMsg(touched, errors, "email")}
                  </Box>
                  <Box mb="20px">
                    <Typography className="label">Issue type</Typography>
                    <CustomSelect
                      isSearchable={false}
                      data-test-id="issue-type"
                      placeholder="Issue type"
                      classNamePrefix="dropdown"
                      options={ISSUE_TYPE}
                      value={ISSUE_TYPE.filter((type) => type.value === values.issueType)[0] ?? null}
                      components={{ IndicatorSeparator: () => null }}
                      onChange={(e: any) => {
                        setTouched({ ...touched, issueType: true });
                        setFieldValue("issueType", e?.value);
                      }}
                    />
                    {this.errorMsg(touched, errors, "issueType")}
                  </Box>
                  <Box mb="20px">
                    <Typography className="label">Detail of the query</Typography>
                    <CustomInputBase
                      multiline
                      minRows={6}
                      data-test-id="description"
                      value={values.description}
                      placeholder="Tell us more information about your query"
                      onChange={(e) => {
                        let value = e.target.value;
                        if (value.length > 1000) {
                          value = value.slice(0, 1000);
                        }
                        setTouched({ ...touched, description: true });
                        setFieldValue("description", value);
                      }}
                    />
                    <Box display="flex" justifyContent="space-between">
                      {this.errorMsg(touched, errors, "description")}
                      <div className="limit">{values.description.length}/1000</div>
                    </Box>
                  </Box>
                  <BlackButton disabled={!(isValid && dirty)} type="submit" sx={{ width: "400px" }}>Submit</BlackButton>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Box>
    );
  }

  renderRightSection = () => {
    return (
      <Box className="right-section">
        {infoIcon}
        <Typography className="guide">We're here to help!</Typography>
        <Box className="guide-wrapper">
          <Typography className="guide-text">
            If you have any questions, feedback, or need assistance, please reach out to us.
          </Typography>
        </Box>
      </Box>
    );
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <Header isSidebar hideFilter navigation={this.props.navigation} active="ContactUs">
        <Loader loading={this.state.loading} />
        <Wrapper>
          <Box className="heading">
            <Typography className="contact-us">Contact us</Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            {this.renderLeftSection()}
            {this.renderRightSection()}
          </Box>
        </Wrapper>
      </Header>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const Wrapper = styled(Box)({
  height: "100%",
  padding: "30px 20px",
  "& .heading": {
    justifyContent: "space-between",
    display: "flex",
    marginBottom: "30px",
    "& .contact-us": {
      letterSpacing: "-0.5px",
      fontFamily: "Spartan",
      textTransform: "uppercase",
      color: "#003249",
      fontWeight: "700",
      fontSize: "17px",
    }
  },
  "& .info-wrapper": {
    gap: "20px",
    display: "flex",
    padding: "0px 10px",
    alignItems: "center",
    flex: "1",
    flexDirection: "column",
    "& .left-section": {
      alignItems: "flex-start",
      gap: "20px",
      width: "400px",
      display: "flex",
      fontFamily: "Spartan",
      justifyContent: "space-between",
      "& form": {
        width: "100%",
        "& .label": {
          letterSpacing: "-0.5px",
          marginBottom: "5px",
          fontFamily: "Spartan",
          fontSize: "16px",
          fontWeight: "700",
          color: "#003249"
        },
        "& .limit": {
          marginTop: "5px",
          fontFamily: "Spartan",
          fontSize: "13px",
          fontWeight: "400",
          color: "#64748B"
        }
      }
    }
  },
  "& .right-section": {
    padding: "20px",
    width: "285px",
    gap: "15px",
    display: "flex",
    borderRadius: "8px",
    flexDirection: "column",
    height: "fit-content",
    backgroundColor: "#F8FAFC",
    border: "1.5px solid #E2E8F0",
    "& .guide": {
      color: "#000",
      fontFamily: "Spartan",
      fontWeight: "500",
      fontSize: "16px",
      letterSpacing: "-0.5px",
    },
    "& .guide-wrapper": {
      display: "flex",
      flexDirection: "column",
      gap: "5px",
      "& .guide-text": {
        display: "flex",
        letterSpacing: "-0.1px",
        fontSize: "14px",
        fontWeight: "500",
        fontFamily: "Spartan",
        color: "#475569",
      }
    }
  },
});

const CustomInputBase = styled(InputBase)({
  width: "100%",
  padding: "6px 8px",
  fontSize: "14px",
  fontFamily: "Spartan",
  color: "#003249",
  fontWeight: "500",
  borderRadius: "8px",
  border: "1px solid #CBD5E1",
  "& input": {
    height: "auto"
  },
  "& .MuiInputBase-inputMultiline": {
    fontSize: "14px",
    fontFamily: "Spartan",
    color: "#003249",
    fontWeight: "500",
  }
});

const BlackButton = styled(Button)({
  marginTop: "40px",
  width: "100%",
  padding: "8px 10px",
  textTransform: "none",
  fontSize: "16px",
  color: "#fff",
  fontWeight: "700",
  fontFamily: "Spartan",
  borderRadius: "8px",
  backgroundColor: "#003249",
  border: "1.5px solid #003249",
  "&:hover": {
    color: "#fff",
    backgroundColor: "#003249",
  },
  "&.Mui-disabled": {
    backgroundColor: "#94A3B8",
    color: "#fff",
    border: "1.5px solid #94A3B8",
  }
});

const CustomSelect = styled(Select)({
  "& .dropdown__control": {
    minHeight: "43px",
    borderRadius: "8px"
  },
  "& .dropdown__control:hover": {
    borderColor: "#CBD5E1"
  },
  "& .dropdown__control--is-focused": {
    boxShadow: "none",
    border: "1px solid #CBD5E1",
  },
  "& .dropdown__single-value": {
    fontFamily: "Spartan",
    fontWeight: "500",
    fontSize: "14px",
    color: "#003249",
  },
  "& .dropdown__option": {
    fontFamily: "Spartan",
    fontWeight: "500",
    fontSize: "14px",
    color: "#64748B",
    padding: "12px"
  },
  "& .dropdown__option--is-selected": {
    color: "#003249",
    backgroundColor: "#E2E8F0"
  },
  "& .dropdown__option--is-focused:hover": {
    color: "#003249",
    backgroundColor: "#E2E8F0"
  }
});

const ISSUE_TYPE = [
  { label: "General inquiry", value: "general_inquiry" },
  { label: "Technical support", value: "technical_support" },
  { label: "Issue with booking", value: "issue_with_booking" },
  { label: "Feedback", value: "feedback" },
  { label: "Other", value: "other" },
];
// Customizable Area End