const SliderStyles = {
    "& .slick-slider .slick-list": {
      borderRadius: "16px",
      height: "185px",
    },
    "& .slick-prev, & .slick-next": {
      display: "none !important",
    },
    ".slick-dots": {
      padding: 0,
      margin: 0,
      listStyle: "none",
      position: "absolute",
      bottom: "14px",
      display: "flex !important",
      justifyContent: "center",
    },
    ".slick-dots li": {
      width: "12px",
      height: "12px",
      display: "flex",
      margin: "0px 1px",
      alignItems: "center",
    },
    ".slick-dots li div": {
      width: "6px",
      height: "6px",
      opacity: "0.5",
      backgroundColor: "#fff",
    },
    ".slick-dots li button": {
      opacity: 0,
      cursor: "pointer",
    },
    ".slick-dots li.slick-active div": {
      width: "8px",
      opacity: "1",
      height: "8px",
      backgroundColor: "#fff",
    },
  };
  export default SliderStyles;