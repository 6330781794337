// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall } from "../../../components/src/NavigationRouteWrapper/helpers";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

interface IValues {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}


export interface S {
  // Customizable Area Start
  loading: boolean;
  currentPassVisible: boolean;
  newPassVisible: boolean;
  confirmPassVisible: boolean;
  currentPassErr: string;
  newPassErr: string;
  flag: boolean;
  // Customizable Area End
}

interface SS {
  id: string;
}

export default class ChangePasswordController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  changePasswordApiCallId: string = "";
  forgotPasswordApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      loading: false,
      currentPassVisible: false,
      newPassVisible: false,
      confirmPassVisible: false,
      currentPassErr: "",
      newPassErr: "",
      flag: false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.changePasswordApiCallId) {
        if (responseJson.data) {
          toast.success("Password updated successfully!!");
          this.setState({
            flag: !this.state.flag,
            currentPassVisible: false,
            newPassVisible: false,
            confirmPassVisible: false,
          });
        } else if (Array.isArray(responseJson.errors)) {
          this.setState({
            currentPassErr: responseJson.errors[0].password || "",
            newPassErr: responseJson.errors[0].new_password || ""
          });
        }
        this.setState({ loading: false });
      } else if (apiRequestCallId === this.forgotPasswordApiCallId) {
        if (responseJson.message) {
          toast.success(responseJson.message);
        }
        this.setState({ loading: false });
      }
    }
  }

  toggleVisibility = (field: string) => {
    this.setState((prevState) => ({
      ...prevState,
      [field as keyof S]: !prevState[field as keyof S]
    }));
  }

  clearCurrentPassErr = () => {
    this.setState({ currentPassErr: "" });
  }

  clearNewPassErr = () => {
    this.setState({ newPassErr: "" });
  }

  changePassword = async (values: IValues) => {
    this.setState({ loading: true });
    const header = {
      "Content-Type": "application/json",
      "token": localStorage.getItem("token")
    }
    const body = {
      "data": {
        "current_password": values.currentPassword,
        "new_password": values.newPassword,
        "confirmation_password": values.confirmPassword
      }
    }
    this.changePasswordApiCallId = await apiCall({
      header,
      body,
      method: "PATCH",
      endPoint: `/bx_block_profile/passwords`,
    });
  }

  forgotPassword = async () => {
    this.setState({ loading: true });
    const header = {
      token: localStorage.getItem("token")
    }

    this.forgotPasswordApiCallId = await apiCall({
      header,
      method: "GET",
      endPoint: `/bx_block_forgot_password/passwords/forgot_password_link`,
    });
  }
  // Customizable Area End
}