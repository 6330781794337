// Customizable Area Start
import React from "react";
import { Box, Typography, CircularProgress, Switch } from "@mui/material";
import { styled } from '@mui/material/styles';

import NotificationSettingController from "./NotificationSettingController.web";
import Header from "../../../components/src/AppHeader.web";
import Loader from "../../../components/src/Loader.web";
import { infoIcon } from "./assets";
// Customizable Area End

export default class NotificationSetting extends NotificationSettingController {
  // Customizable Area Start

  renderLeftSection = () => {
    return (
      <Box className="info-wrapper">
        <Box className="left-section">
          <Box width="100%">
            <Box width="100%">
              <Typography className="title">Email notifications</Typography>
              <Box className="card">
                <Box className="content" mb="20px">
                  <Typography className="sub-title">Reviews</Typography>
                  <CustomSwitch
                    data-test-id="email-review"
                    checked={this.state.emailReview}
                    onChange={(e) => this.onChangeToggle("emailReview", e.target.checked)}
                  />
                </Box>
                <Box className="content">
                  <Typography className="sub-title">New Message</Typography>
                  <CustomSwitch
                    data-test-id="email-msg"
                    checked={this.state.emailMsg}
                    onChange={(e) => this.onChangeToggle("emailMsg", e.target.checked)}
                  />
                </Box>
              </Box>
            </Box>
            <Box width="100%" mt="40px">
              <Typography className="title">Push notifications</Typography>
              <Box className="card">
                <Box className="content" mb="20px">
                  <Typography className="sub-title">Reviews</Typography>
                  <CustomSwitch
                    data-test-id="push-review"
                    checked={this.state.pushReview}
                    onChange={(e) => this.onChangeToggle("pushReview", e.target.checked)}
                  />
                </Box>
                <Box className="content">
                  <Typography className="sub-title">New Message</Typography>
                  <CustomSwitch
                    data-test-id="push-msg"
                    checked={this.state.pushMsg}
                    onChange={(e) => this.onChangeToggle("pushMsg", e.target.checked)}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }

  renderRightSection = () => {
    return (
      <Box className="right-section">
        {infoIcon}
        <Typography className="guide">Notification settings</Typography>
        <Box className="guide-wrapper">
          <Typography className="guide-text">
            Here you can turn your notifications off and on as needed.
          </Typography>
        </Box>
      </Box>
    );
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <Header isSidebar hideFilter navigation={this.props.navigation} active="NotificationSetting">
        <Loader loading={this.state.isLoading} />
        <Wrapper>
          {this.state.loading ? (
            <Box className="loading">
              <CircularProgress sx={{ color: "#000" }} />
            </Box>
          ) : (
            <>
              <Box className="heading">
                <Typography className="notification-settings">Notification settings</Typography>
              </Box>
              <Box display="flex" justifyContent="space-between">
                {this.renderLeftSection()}
                {this.renderRightSection()}
              </Box>
            </>
          )}
        </Wrapper>
      </Header>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const Wrapper = styled(Box)({
  height: "100%",
  padding: "30px 20px",
  paddingBottom: "105px",
  "& .loader": {
    marginLeft: "300px"
  },
  "& .loading": {
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .heading": {
    justifyContent: "space-between",
    display: "flex",
    marginBottom: "30px",
    "& .notification-settings": {
      letterSpacing: "-0.5px",
      fontFamily: "Spartan",
      textTransform: "uppercase",
      color: "#003249",
      fontWeight: "700",
      fontSize: "17px",
    }
  },
  "& .info-wrapper": {
    gap: "20px",
    display: "flex",
    padding: "0px 10px",
    alignItems: "center",
    flex: "1",
    flexDirection: "column",
    "& .left-section": {
      alignItems: "flex-start",
      gap: "20px",
      width: "400px",
      display: "flex",
      fontFamily: "Spartan",
      justifyContent: "space-between",
      "& .title": {
        color: "#003249",
        fontFamily: "Spartan",
        fontSize: "14px",
        fontWeight: "700",
        marginBottom: "15px"
      },
      "& .card": {
        boxSizing: "border-box",
        border: "1.5px solid #E2E8F0",
        width: "100%",
        padding: "10px",
        borderRadius: "16px",
        background: "#fff",
        boxShadow: "0px 2px 8px 0px #00000014",
        "& .content": {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          "& .sub-title": {
            color: "#003249",
            fontFamily: "Spartan",
            fontSize: "14px",
            fontWeight: "500",
          }
        }
      }
    }
  },
  "& .right-section": {
    width: "285px",
    gap: "15px",
    flexDirection: "column",
    display: "flex",
    padding: "20px",
    height: "fit-content",
    borderRadius: "8px",
    backgroundColor: "#F8FAFC",
    border: "1.5px solid #E2E8F0",
    "& .guide": {
      fontFamily: "Spartan",
      color: "#000",
      letterSpacing: "-0.5px",
      fontWeight: "500",
      fontSize: "16px",
    },
    "& .guide-wrapper": {
      gap: "5px",
      flexDirection: "column",
      display: "flex",
      "& .guide-text": {
        display: "flex",
        fontFamily: "Spartan",
        fontSize: "14px",
        letterSpacing: "-0.4px",
        fontWeight: "500",
        color: "#475569",
      }
    }
  },
});

const CustomSwitch = styled(Switch)({
  width: "51px",
  height: "30px",
  padding: "0px",
  "& .MuiSwitch-switchBase": {
    padding: "3px 1px",
    transform: "translateX(2px)",
    "&.Mui-checked": {
      transform: "translateX(22px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: "1",
        backgroundColor: "#003249 !important",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    width: "24px",
    height: "24px",
    backgroundColor: "#fff",
  },
  "& .MuiSwitch-track": {
    borderRadius: "20px",
    opacity: "1",
    backgroundColor: "#94A3B8 !important",
  },
});
// Customizable Area End