import React, { Component } from "react";
import { InputAdornment, TextField, styled } from "@mui/material";
import { SvgIconComponent } from "@mui/icons-material";

interface Props {
  size?: "medium" | "small";
  variant?: "outlined" | "filled" | "standard";
  className?: string;
  placeholder?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: () => void;
  value: string | number;
  multiline?: boolean;
  startIcon?: SvgIconComponent | string;
  endIcon?: SvgIconComponent;
  error?: boolean;
  errorMsg?: string;
  maxLength?: number;
  InputProps?: any;
  onKeyDown?: () => void;
}

export default class CustomInput extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      size = "medium",
      placeholder,
      onClick,
      variant = "outlined",
      className = "",
      multiline = false,
      startIcon: StartIcon,
      endIcon: EndIcon,
      error = false,
      errorMsg = "",
      value,
      maxLength,
      InputProps,
      onKeyDown,
      onChange,
    } = this.props;

    return (
      <Wrapper>
        <TextField
          variant={variant}
          size={size}
          multiline={multiline}
          value={value}
          type="text"
          minRows={multiline ? 4 : 1}
          placeholder={placeholder}
          className={`customInput ${className}`}
          onChange={onChange}
          onClick={onClick}
          InputProps={{
            startAdornment: StartIcon ? (
              <InputAdornment position="start">
                {typeof StartIcon === "string" ? (
                  StartIcon
                ) : (
                  <StartIcon className="adornmentColor" />
                )}
              </InputAdornment>
            ) : null,
            endAdornment: EndIcon ? (
              <InputAdornment position="end">
                <EndIcon className="adornmentColor" />
              </InputAdornment>
            ) : null,
            ...InputProps,
          }}
          inputProps={{ maxLength }}
          error={error}
          helperText={error ? errorMsg : ""}
          onKeyDown={(event) => {
            if (event.keyCode == 13) {
              onKeyDown && onKeyDown();
            }
          }}
        />
      </Wrapper>
    );
  }
}

const Wrapper = styled("div")(() => ({
    width: "100%",
  "& .MuiInputBase-input::placeholder": {
    fontFamily: "Spartan",
    opacity: 1,
    color: "#94A3B8",
    fontWeight: 500,
  },
  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#CBD5E1",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    fontFamily: "Spartan",
  },
  "& .customInput": {
    width: "100%",
    margin: "5px 0",
  },
  "& .adornmentColor": {
    color: "#64748B",
    cursor: "pointer",
  },
  "& .MuiFormHelperText-root.Mui-error": {
    color: "red",
    marginLeft: 0,
    fontFamily: "Spartan",
  },
}));
