// Customizable Area Start
import { createRef } from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall } from "../../../components/src/NavigationRouteWrapper/helpers";
import _ from "lodash";
import moment from "moment";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

interface IList {
  id: number;
  title: string;
}

interface S {
  // Customizable Area Start
  loading: boolean;
  isLoading: boolean;
  listLoading: boolean;
  isOpen: boolean;
  data: any[];
  filterOpen: boolean;
  startDate: Date | null;
  endDate: Date | null;
  active: string;
  search: string;
  listingArr: string[];
  statusArr: string[];
  listingData: IList[];
  currentPage: number;
  perPage: number;
  isChangeReqExist: boolean;
  isSearchPage: boolean;
  rating: number | null;
  description: string;
  currentId: string;
  pagination: {
    current_page: number;
    total_pages: number;
  };
  role: string | null;
  reviewData:any;
  // Customizable Area End
}

interface SS {
  id: string;
}

const STATUS = ["upcoming", "active", "past", "cancelled", "change", "all"];

export default class ManageBookingController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getBookingApiCallId: string = "";
  getListingApiCallId: string = "";
  sendReviewApiCallId: string = "";
  // scrollRef: any = "";
  scrollRef: React.RefObject<HTMLDivElement> = createRef();
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    const hash = window.location.hash.replace("#", "");
    const active = STATUS.includes(hash) ? hash : "upcoming";
    this.state = {
      loading: false,
      isLoading: false,
      listLoading: false,
      isOpen: false,
      data: [],
      filterOpen: false,
      startDate: null,
      endDate: null,
      active: active,
      search: "",
      listingArr: [],
      statusArr: [],
      listingData: [],
      currentPage: 1,
      perPage: 6,
      isChangeReqExist: false,
      isSearchPage: false,
      currentId: "",
      rating: 0,
      description: "",
      pagination: {
        current_page: 1,
        total_pages: 1,
      },
      role: localStorage.getItem("role"),
      reviewData:{},
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getListingApiCallId) {
        if (Array.isArray(responseJson.data)) {
          this.setState({ listingData: responseJson.data });
        }
        this.setState({ listLoading: false });
      } else if (apiRequestCallId === this.sendReviewApiCallId) {
        if (responseJson.data) {
          toast.success("Review sent!");
          this.getBookingData(1);   
          const { id: reviewId, attributes } = responseJson.data;
          const { reviewable, rating, description } = attributes;     
      
          const updatedBookings = this.state.data.map((booking: any) => booking.id === reviewable.id.toString()
                  ? {
                        ...booking,
                        attributes: {
                            ...booking.attributes,
                            guest_reviewed: true,
                            reviews_count: rating,
                            guest_review: { id: reviewId, description ,rating},
                        },
                    }
                  : booking
          );
      
          this.setState({ data: updatedBookings });              
        } else if (Array.isArray(responseJson.errors)) {
          const msg = responseJson.errors[0]?.Review?.base;
          if (msg === "You have already reviewed this booking") {
            toast.error(msg);
          }
        }
        this.setState({ isLoading: false, isOpen: false, currentId: "", rating: 0, description: "" });
      } else {
        this.handleResponse(apiRequestCallId, responseJson);
      }
    }
  }

  handleResponse = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.getBookingApiCallId) {
      if (Array.isArray(responseJson.data)) {
        this.setState({
          data: responseJson.data,
          pagination: responseJson.pagination,
          isChangeReqExist: responseJson.change_request_exist ?? false,
          currentPage: responseJson.pagination.current_page ?? this.state.currentPage,
        });
      } else if (Array.isArray(responseJson.message)) {
        const msg = responseJson.message[0]?.Booking?.message;
        if (msg === "No bookings were found that match your criteria." || msg === "No booking found matching the search criteria.") {
          this.setState({ currentPage: 0, data: [] });
        }
      } else if (Array.isArray(responseJson.errors)) {
        const msg = responseJson.errors[0]?.Booking?.message;
        if (msg === "Only host can perform this action.") {
          toast.error(msg);
        }
      }
      this.setState({ loading: false, isLoading: false });
    }
  }

  handleHashChange = () => {
    const hash = window.location.hash.replace("#", "");
    const active = STATUS.includes(hash) ? hash : "upcoming";

    if (this.state.active !== active) {
      this.setState({ active }, () => {
        this.getBookingData(1);
      });
    }
  }

  async componentDidMount() {
    this.getBookingData(1);
    this.state.role !== "guest" && this.getListing();
    window.addEventListener("hashchange", this.handleHashChange);
  }

  async componentWillUnmount() {
    window.removeEventListener("hashchange", this.handleHashChange);
  }

  componentDidUpdate(prevProps: Props, prevState: S) {
    if ((prevState.loading !== this.state.loading || prevState.listLoading !== this.state.listLoading) && !this.state.isLoading
    ) {
      this.scrollToTop();
    }
  }

  scrollToTop = () => {
    if (this.scrollRef.current) {
      this.scrollRef.current.scrollTo({ top: 0 });
    }
  };

  redirectToDetailPage = (bookingId: string) => {
    this.props.navigation.navigate("BookingDetail", { path: { bookingId } });
  }

  onChangeRequestClick = () => {
    if (this.state.active === "change") return;
    window.location.hash = "change";
    this.setState({ active: "change" }, () => {
      this.getBookingData(1);
    });
  }

  onBack = () => {
    this.setState({
      search: "",
      startDate: null,
      endDate: null,
      listingArr: [],
      statusArr: [],
      filterOpen: false,
      isSearchPage: false,
      active: "upcoming",
    }, () => {
      this.getBookingData(1);
    });
  }

  debouncedSearch = _.debounce(() => {
    window.location.hash = "all";
    this.setState({ isSearchPage: true, active: "all" }, () => {
      this.getBookingData(1);
    });
  }, 500);

  onChangeSearch = (search: string) => {
    this.setState({ search }, () => {
      this.debouncedSearch();
    });
  };

  onChangeListing = (option: string) => {
    let listingArr = [...this.state.listingArr];
    listingArr = listingArr.includes(option) ? listingArr.filter(item => item !== option) : [...listingArr, option];
    this.setState({ listingArr });
  }

  onChangeTab = (active: string) => {
    window.location.hash = active;
    this.setState({ active, isSearchPage: false }, () => {
      this.getBookingData(1);
    });
  }

  onChangeStatus = (option: string) => {
    let statusArr = [...this.state.statusArr];
    statusArr = statusArr.includes(option) ? statusArr.filter(item => item !== option) : [...statusArr, option];
    this.setState({ statusArr });
  }

  removeFilter = () => {
    this.setState({ active: "upcoming", isSearchPage: false, filterOpen: false, startDate: null, endDate: null, listingArr: [], statusArr: [] }, () => {
      this.getBookingData(1);
    });
  }

  applyFilter = () => {
    this.setState({ isSearchPage: true, active: "all" }, () => {
      this.getBookingData(1);
    });
  }

  onChangePage = (currentPage: number) => {
    if (currentPage === this.state.currentPage) return;
    this.setState({ isLoading: true }, () => {
      this.getBookingData(currentPage);
    });
  }

  handleClose = () => {
    this.setState({ isOpen: false, currentId: "", description: "", rating: null });
  }

  redirectToDetailsPage = (bookingId: string) => {
    this.props.navigation.navigate("BookingDetail", { path: { bookingId } });
  }

  onLeaveReview = (currentId: string, item:{reviews_count:number,guest_reviewed:boolean,guest_review:{description:string,rating:number}}, e: React.MouseEvent<HTMLButtonElement>) => {
   e?.stopPropagation();
    this.setState({
      isOpen: true, 
      currentId ,
      reviewData:item , 
      rating:item.guest_review?.rating,
      description: item.guest_review?.description || ""});
    if (!item.guest_reviewed) {
      this.setState({ rating: 0, description: "" });
    }
  }

  onChangeRating = (rating: number | null) => {
    this.setState({ rating });
  }

  onChangeDescription = (description: string) => {
    this.setState({ description });
  }

  browseListing = () => {
    localStorage.removeItem("checkIn");
    localStorage.removeItem("checkOut");
    localStorage.removeItem("guests");
    localStorage.removeItem("horses");
    this.props.navigation.navigate("AdvancedSearch");
  }

  getBookingData = async (currentPage: number) => {
    this.setState({ currentPage, loading: true });
    const header = {
      token: localStorage.getItem("token")
    }
    const role = this.state.role === "guest" ? "guest" : "host";
    let { startDate, endDate, active, listingArr, statusArr, search, perPage } = this.state;

    const params = new URLSearchParams();
    if (active && active !== "change") params.append("type", active);
    if (search) params.append("query", search);
    if (startDate) params.append("start_date", moment(startDate).format("DD-MM-YYYY"));
    if (endDate) params.append("end_date", moment(endDate).format("DD-MM-YYYY"));
    if (listingArr.length > 0) params.append("listing_id", listingArr.join(","));
    if (statusArr.length > 0) params.append("status", statusArr.join(","));
    if (currentPage) params.append("page", currentPage.toString());
    if (perPage) params.append("per_page", perPage.toString());
    if (active === "change") params.append("change_type", "true");

    this.getBookingApiCallId = await apiCall({
      header,
      method: "GET",
      endPoint: `/bx_block_advanced_search/search/search_bookings_for_${role}?${params.toString()}`,
    });
  }

  sendReview = async () => {
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    }
    const body = {
      "data": {
        "type": "reviews",
        "attributes": {
          "rating": this.state.rating,
          "description": this.state.description,
          "reviewable_id": this.state.currentId,
          "reviewable_type": "BxBlockRequestManagement::Booking",
        }
      }
    }

    this.sendReviewApiCallId = await apiCall({
      header,
      body,
      method: "POST",
      endPoint: `/bx_block_reviews/reviews`,
    });
  }

  getListing = async () => {
    this.setState({ listLoading: true });
    const header = {
      token: localStorage.getItem("token")
    }
    this.getListingApiCallId = await apiCall({
      header,
      method: "GET",
      endPoint: `/bx_block_bulk_uploading/listings/get_listing`,
    });
  }
  // Customizable Area End
}