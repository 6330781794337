// Customizable Area Start
import React from "react";
import { Box, List, ListItemButton, ListItemText, Typography, CircularProgress } from "@mui/material";
import { styled } from '@mui/material/styles';

import PoliciesController from "./PoliciesController.web";
import Header from "../../../components/src/AppHeader.web";
// Customizable Area End

export default class Policies extends PoliciesController {
  // Customizable Area Start
  rightIcon = () => {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.99953 6.71051C8.60953 7.10051 8.60953 7.73051 8.99953 8.12051L12.8795 12.0005L8.99953 15.8805C8.60953 16.2705 8.60953 16.9005 8.99953 17.2905C9.38953 17.6805 10.0195 17.6805 10.4095 17.2905L14.9995 12.7005C15.3895 12.3105 15.3895 11.6805 14.9995 11.2905L10.4095 6.70051C10.0295 6.32051 9.38953 6.32051 8.99953 6.71051Z" fill="#003249" />
      </svg>
    );
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <Wrapper>
        <Header isSidebar hideFilter navigation={this.props.navigation} active="Policies">
          <Box className="wrapper">
            <Box className="options" sx={{ overflow: "auto" }}>
              <Typography className="policies">Policies</Typography>
              <List className="menu-section">
                {POLICIES_OPTIONS.map((label, index) => (
                  <ListItemButton
                    key={label}
                    data-test-id="option"
                    onClick={() => this.getPolicyInfo(index)}
                    className={`menu-item ${this.state.active === index && "active"}`}
                  >
                    <ListItemText primary={label} />
                    {this.rightIcon()}
                  </ListItemButton>
                ))}
              </List>
            </Box>
            <Box className="right">
              {this.state.loading ? (
                <Box className="loading">
                  <CircularProgress sx={{ color: "#000" }} />
                </Box>
              ) : (
                <Box>
                  <Box className="policy-text" dangerouslySetInnerHTML={{ __html: this.state.data }} />
                </Box>
              )}
            </Box>
          </Box>
        </Header>
      </Wrapper>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const Wrapper = styled(Box)({
  height: "100%",
  "& .below-section .children": {
    overflow: "hidden",
    display: "block"
  },
  "& .wrapper": {
    height: "100%",
    display: "flex",
    overflow: "auto",
  },
  "& .loading": {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .options": {
    minWidth: "320px",
    padding: "30px 20px",
    borderRight: "1px solid #E2E8F0",
    "& .policies": {
      marginLeft: "20px",
      marginBottom: "40px",
      letterSpacing: "-0.5px",
      fontFamily: "Spartan",
      textTransform: "uppercase",
      color: "#003249",
      fontWeight: "700",
      fontSize: "17px",
    },
    "& .menu-section": {
      backgroundColor: "#fff",
      borderRadius: "16px",
      padding: "5px 0",
      boxShadow: "0px 2px 8px 0px #00000014",
      "& .menu-item": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "9px 15px",
        marginTop: "2px",
        marginBottom: "2px",
        paddingRight: "10px",
        borderRadius: "8px",
        "& .MuiListItemText-primary": {
          fontFamily: "Spartan",
          fontSize: "14px",
          fontWeight: "500",
          color: "#003249",
        },
        "&:hover": {
          borderRadius: "16px",
          backgroundColor: "#F1F5F9",
        },
        "&.active": {
          borderRadius: "16px",
          backgroundColor: "#F1F5F9",
          "& .MuiListItemText-primary": {
            fontWeight: "700",
          }
        },
      }
    },
  },
  "& .right": {
    width: "100%",
    padding: "30px",
    position: "relative",
    flex: "1 1 auto",
    overflow: "auto",
    "& .policy-text": {
      fontFamily: "Spartan",
    }
  }
});

const POLICIES_OPTIONS = [
  "Terms and Conditions",
  "Privacy and Data Protection Policy",
  "Booking and Cancellation Policy",
  "Safety and Security Policy"
];
// Customizable Area End