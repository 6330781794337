import React from "react";

// Customizable Area Start
import {Typography, Box, Grid,styled, Button} from '@mui/material';
import {
  StyleSheet,
} from "react-native";

const MainBoxTextArea=styled(Box)({
  width: "82%",
  position: "absolute",
  background: "#FFFFFF33",
  bottom:"38px",
  borderRadius: "5px",
  backdropFilter: "blur(15px)",
  padding:"2rem 1rem",
  textAlign:"center",
  display: "flex",
  flexDirection: "column",
  gap: "1rem"
})
const RightGrid = styled(Grid)({
  height: "100vh",
  position: "relative",
  justifyContent: "center",
  padding: "5% 8% 3%",
})
// Customizable Area End

import TermsConditionsController, {
  Props,
  configJSON,
  ITermsConds,
} from "./TermsConditionsController";

export default class TermsConditions extends TermsConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
      <Grid container>
        <Grid item xs={12} sm={6} sx={{ position:"relative", display:'flex',justifyContent:'center'}}>
          <Box 
          style ={{
              display: "flex",
              width: "100%", 
              }}
          >
              <img src = {this.state.backImage} 
              style={{             
                height: "100vh",
                width: '100%',
                position:'relative',
                objectPosition: "top",
                objectFit: "cover", 
                }}/>
          </Box>
          <Box 
          sx={{
              backgroundColor: "#FEFEFE26",
              width: { xs: "40px", sm: "48px", md: "54px" },
              height: { xs: "40px", sm: "48px", md: "54px" }, 
              top: { xs: "20px", sm: "30px", md: "50px" }, 
              left: { xs: "20px", sm: "30px", md: "50px" }, 
              gap: "0px",
              borderRadius: "14px",
              opacity: "0px",
              boxShadow: "-5px 4px 26px 0px #0000001A",
              position: "absolute",
          }}
          >
              <Box 
              sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: { xs: "8px", sm: "10px", md: "14px" }, 
              }}
              >
              <img src={this.state.arrowImage}
              onClick={this.signUpNavigation}
              data-test-id="signUpNavigation"/>
              </Box>
          </Box>
          <MainBoxTextArea>
              <Box>
              <Typography 
              style={{
                  color: "#FFFFFF",
                  fontFamily: "Spartan",
                  fontSize: "30px",
                  fontWeight: 700,
                  lineHeight: "40px",
                  letterSpacing: "-0.03em",
              }}
              >
              Find Your Horse's Perfect Stay
              </Typography>
              </Box>  
              <Box>
              <Typography 
              style={{
                  color: "#FFFFFF",
                  fontFamily: "Spartan",
                  fontSize: "20px",
                  fontWeight: 500,
                  lineHeight: "30px",
                  letterSpacing: "-0.02em",
                  textAlign: "center", 
                  padding: "0 25px",
              }}
              >
              Discover reliable, temporary stabling options effortlessly.
              </Typography>
              </Box>         
          </MainBoxTextArea>
        </Grid>
        <RightGrid item xs={12} sm={6} sx={{ position:"relative", display:'flex',justifyContent:'center' }}>
          <Box 
          style ={{
            width: "100%", 
          }}>
            <Box>
              <Typography 
              style = {{
                fontFamily: "Spartan",
                fontSize: "30px",
                fontWeight: 700,
                lineHeight: "40px",
                letterSpacing: "-0.03em",
                textAlign: "left",
                color: "#003249",
              }}>
              Terms and conditions
              </Typography>
            </Box>  
            <Box
            style={{
              height:"calc(100vh - 250px)",
              overflow: "scroll"
            }}>
              <div style={{fontFamily:"Spartan", fontSize: "12px", paddingTop: "17px"}} dangerouslySetInnerHTML={{ __html: this.state.termsConditionData}}/>
            </Box>
            <Box>
              <Button
              onClick={this.navigateToSignUpPage}
              data-test-id="navigateToSignUpPage"
              style={{ 
                display: "flex", 
                width: "100%",
                height: "56px",
                gap: "8px",
                borderRadius: "8px",
                backgroundColor: "#003249",
                marginTop: "20px",
                marginBottom: "55px",
              }}>
                <Typography
                style={{
                  fontFamily: "Spartan",
                  fontSize: "16px",
                  fontWeight: 700,
                  lineHeight: "24px",
                  letterSpacing: "-0.03em",
                  textAlign: "center",
                  textTransform: "none",
                  color:"#FFFFFF",
                }}>
                  Accept
                </Typography>
              </Button>
            </Box>
          </Box>
        </RightGrid>
      </Grid>
      </>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    margin: 20,
  },
  spinnerContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    flex: 1,
    padding: 20,
    fontWeight: "600",
    fontSize: 22,
  },
  termsCondsTitle: {
    fontWeight: "600",
    marginBottom: 20,
    fontSize: 22,
  },
  scrollView: {
    height: "100%",
  },
  checkboxContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: "auto",
    marginBottom: 40,
    marginRight: 20,
  },
  termsCondsContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottomWidth: 1,
    padding: 20,
  },
  button: {
    backgroundColor: "blue",
    marginLeft: 10,
    width: 120,
    height: 40,
    display: "flex",
    justifyContent: "center",
    borderRadius: 4,
    alignSelf: "flex-end",
  },
  buttonLabel: {
    fontSize: 16,
    color: "#fff",
    textAlign: "center",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    margin: 20,
  },
});
// Customizable Area End
