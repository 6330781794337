import React from "react";
import {
  // Customizable Area Start
  Box,
  Button,
  Typography,
  IconButton,
  Dialog, DialogTitle, DialogContent, DialogActions, TextField
  // Customizable Area End
} from "@mui/material";
// Customizable Area Start
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
// Customizable Area End

import ReportUserController, {
  Props,
} from "./ReportUserController.web";

export default class ReportUser extends ReportUserController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
  }

  renderOtherReasonDetails = () => {
    return (
      <Box>
        <TextField
          multiline
          rows={4}
          variant="outlined"
          data-test-id='other-info'
          value={this.state.otherInfo}
          onChange={(e) => this.setState({ otherInfo: e.target.value })}
          inputProps={{ maxLength: 1000 }}
          sx={{
            width: '100%',
            marginTop: '8px',
            borderRadius: '8px',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#CBD5E1',
              },
              '&:hover fieldset': {
                borderColor: '#CBD5E1',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#CBD5E1',
              },
            },
            '& .MuiInputBase-input': {
              color: '#475569',
              fontSize: '14px',
              fontWeight: 500
            },
          }}
        />
      </Box>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box>
        <CustomDialog
          open={this.props.isOpen}
          onClose={() => this.closeReport()}
        >
          <DialogTitle className="title">
            <IconButton
              onClick={() => this.closeReport()}
              sx={{ position: "absolute", right: 8, top: 8 }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className="dialog-content">
            {this.state.showSuccess ?
              <Box>
                <Typography className="dropdown-label">Report submitted successfully</Typography>
                <Typography className="option-description" sx={{ paddingTop: '40px' }}>{'Our team will review you report and take the right action to solve your problem'}</Typography>
              </Box>
              : <>
                <Typography className="popup-title">REPORT</Typography>
                {!this.state.selectedReason.length && <><Box sx={{ paddingBottom: '24px' }}>
                  <Typography className="dropdown-label" sx={{ paddingTop: '40px' }}>Share why you’re reporting this user</Typography>
                  <Typography className="dropdown-label-note" sx={{}}>Select a reason</Typography>
                </Box>
                  <Box sx={webStyle.optionsWrapper}>
                    {Object.entries(this.state.reportKeys).map(([key]) => (
                      <Box sx={webStyle.menuContainer} data-test-id={key} onClick={() => this.handleChange(key)}>
                        <Typography sx={webStyle.menuLabel}>{key}</Typography><KeyboardArrowRightOutlinedIcon /></Box>
                    ))}
                  </Box></>}
                {!!this.state.selectedReason.length && <Box>
                  <Typography className="dropdown-label" sx={{ paddingTop: '40px' }}>{this.state.selectedReason}</Typography>
                  <Typography className="option-description" sx={{}}>{this.state.reportKeys[this.state.selectedReason]}</Typography>
                </Box>}
                {!!this.isOtherSelected() && <Box>
                  <Typography className="dropdown-label" sx={{ paddingTop: '40px' }}>{'Tell us about your experience:'}</Typography>
                  {this.renderOtherReasonDetails()}
                </Box>}
              </>}
          </DialogContent>
          {(!!this.state.selectedReason.length || this.state.showSuccess) && <DialogActions className="dialog-action">
            {this.state.showSuccess ? <BlackButton data-test-id={'login-btn'} onClick={() => { this.closeReport() }}>Done</BlackButton>
              : <BlackButton data-test-id={'send-btn'} onClick={() => { this.sendReport() }}>Send report</BlackButton>}
          </DialogActions>}
        </CustomDialog>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const CustomDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    borderRadius: "16px",
    width: "550px",
  },
  "& .title": {
    textAlign: "center",
  },
  "& .MuiDialogContent-root": {
    padding: "0px 30px 18px !important"
  },
  "& .dialog-content": {
    marginBottom: '10px',
    "& .popup-title": {
      textAlign: "center",
      letterSpacing: "0px",
      fontFamily: "Spartan",
      fontWeight: "700",
      fontSize: "18px",
      color: "#003249"
    },
    "& .dropdown-label": {
      fontFamily: 'Spartan',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '24px',
      color: '#003249'
    },
    "& .dropdown-label-note": {
      fontFamily: 'Spartan',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '18px',
      color: '#716F77',
      paddingTop: '8px'
    },
    "& .option-description": {
      fontFamily: 'Spartan',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '24px',
      color: '#716F77',
      paddingTop: '8px'
    },
    "& .popup-desc": {
      letterSpacing: "-2%",
      fontFamily: "Spartan",
      fontWeight: "500",
      fontSize: "15px",
      color: "#94A3B8"
    },
  },
  "& .dialog-action": {
    gap: "10px",
    padding: "30px",
    justifyContent: "center",
  },
  "& .close-btn": {
    color: "#003249",
    height: "15px",
    width: "15px",
  },
  "&.change-host": {
    "& .MuiDialogContent-root": {
      paddingTop: "15px !important",
      paddingBottom: "15px !important",
    }
  }
})
const BlackButton = styled(Button)({
  padding: "8px 10px",
  width: "100%",
  borderRadius: "8px",
  textTransform: "none",
  fontSize: "16px",
  fontFamily: "Spartan",
  whiteSpace: "nowrap",
  fontWeight: "700",
  color: "#fff",
  backgroundColor: "#003249",
  border: "1.5px solid #003249",
  "&:hover": {
    color: "#fff",
    backgroundColor: "#003249",
  },
  "&:disabled": {
    color: "#fff",
    backgroundColor: "#94A3B8",
    border: 'none'
  }
});
const webStyle = {
  menuContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '12px',
    cursor: 'pointer',
    "&:hover": {
      backgroundColor: '#f0f0f0',
    }
  },
  menuLabel: {
    fontFamily: 'Spartan',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
    color: '#003249'
  },
  optionsWrapper: {
    borderRadius: '16px',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.2)',

  }
};
// Customizable Area End
