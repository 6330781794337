// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall } from "../../../components/src/NavigationRouteWrapper/helpers";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

interface IValues {
  email: string;
  issueType: string;
  description: string;
}

interface S {
  // Customizable Area Start
  loading: boolean;
  flag: boolean;
  // Customizable Area End
}

interface SS {
  id: string;
}

export default class ContactUsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  contactUsApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      loading: false,
      flag: false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.contactUsApiCallId) {
        if (responseJson.data) {
          this.setState({ flag: !this.state.flag });
          toast.success("Inquiry has been sent to support team!");
        }
        this.setState({ loading: false });
      }
    }
  }

  saveContactInfo = async (values: IValues) => {
    this.setState({ loading: true });
    const header = {
      "Content-Type": "application/json",
    }
    const body = {
      "data": {
        "email": values.email,
        "issue_type": values.issueType,
        "description": values.description
      }
    }

    this.contactUsApiCallId = await apiCall({
      header,
      body,
      method: "POST",
      endPoint: `/bx_block_contact_us/contacts`,
    });
  }
  // Customizable Area End
}