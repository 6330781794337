// Customizable Area Start

import React from "react";
import {Typography, Box, Grid, TextField,styled, Button, InputAdornment,IconButton, Dialog} from '@mui/material';
import ForgotPasswordController, {Props,} from "./ForgotPasswordController";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

const MainBoxTextArea=styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: "82%",
  borderRadius: "5px", position: "absolute", background: "#FFFFFF33",
  bottom:38, gap: "1rem", backdropFilter: "blur(15px)", padding:"2rem 1rem", textAlign:'center'
  })
const RightGrid=styled(Grid)
({
    padding: "5% 8% 3%",
    overflowY: "scroll",
    height: "100vh", backgroundColor: "#FFFFFF", 
    justifyContent:'center',position:'relative',
})
export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
  }
  
  render() {
    const {
      isUppercaseValid,
      isLengthValid,
      isNumberValid,
      isSpecialCharValid,
    } = this.state;
    return (
        <>
        <Grid container alignItems="center" justifyContent="center" style={{height:'100vh'}}>
            <Grid item xs={12} sm={6} sx={{ position:"relative", display:'flex',justifyContent:'center' }}>
                <img
                  src = {this.state.backImage} 
                  style={{ width: "100%", height: "100vh", objectFit: "cover", position:'relative', opacity: "0px", objectPosition: "top" }}
                />
                <Box 
                sx={{
                    backgroundColor: "#FEFEFE26",
                    width: { xs: "40px", sm: "48px", md: "54px" }, height: { xs: "40px", sm: "48px", md: "54px" }, 
                    top: { xs: "20px", sm: "30px", md: "50px" }, left: { xs: "20px", sm: "30px", md: "50px" }, 
                    gap: "0px", borderRadius: "14px", opacity: "0px",
                    boxShadow: "-5px 4px 26px 0px #0000001A", position: "absolute" 
                  }}>
                    <Box 
                    sx={{alignItems: "center", display: "flex", justifyContent: "center",
                    padding: { xs: "8px", sm: "10px", md: "14px"} 
                    }}>
                    <img src={this.state.arrowImage}
                    onClick={this.navigateToLoginPage}
                    data-test-id="navigateToLoginPage"/>
                    </Box>
                </Box>
                <MainBoxTextArea>
                    <Box>
                      <Typography 
                      style={{
                        lineHeight: "40px", letterSpacing: "-0.03em",
                        color: "#FFFFFF",
                        fontFamily: "Spartan", fontSize: "30px", fontWeight: 700,
                      }}>
                      Secure the Best Retreat for Your Horse
                      </Typography>
                    </Box>  
                    <Box>
                      <Typography 
                      style={{
                        padding: "0 25px", color: "#FFFFFF", lineHeight: "30px",
                        letterSpacing: "-0.02em", textAlign: "center", 
                        fontFamily: "Spartan", fontSize: "20px", fontWeight: 500,
                      }}>
                      Easily locate dependable, temporary stabling options for peace of mind.
                      </Typography>
                    </Box>         
                </MainBoxTextArea>
            </Grid>
            <RightGrid item xs={12} sm={6}>
                <Box>
                  <Box>
                      <Typography 
                      style={{textAlign: "left",color: "#003249",fontFamily: "Spartan",
                      fontSize: "30px", fontWeight: 700, lineHeight: "40px", letterSpacing: "-0.03em",
                      }}>
                        Create new password
                      </Typography>
                      <Typography 
                      style={{textAlign: "left", color: "#475569",
                        letterSpacing: "-0.02em",fontFamily: "Spartan",
                        fontSize: "16px", fontWeight: 500,lineHeight: "24px",
                      }}>
                        Secure your account with a strong and unique password.
                      </Typography>
                  </Box>
                  <Box sx={{ marginTop: "2rem" }}>
                    <Box>
                      <Typography 
                      style={{
                        fontFamily: "Spartan",
                        fontSize: "14px",
                        fontWeight: 700,
                        lineHeight: "22px",
                        letterSpacing: "-0.02em",
                        textAlign: "left",
                        color: "#64748B",
                      }}>
                        Password
                      </Typography>
                      <TextField onChange={this.handleNewPassword}
                      value={this.state.newPassword}
                      data-test-id="handleNewPassword"
                      error={!!this.state.newPasswordError}
                      // helperText={this.state.passwordError}
                        sx={{
                          borderRadius: "8px",
                          border: "1px ",
                          marginTop: "4px",
                          color: "#94A3B8",
                        }}
                        placeholder="xxxxxxxx"
                        type={this.state.enableNewPasswordField?'password':'text'}
                        name="password"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={this.handleShowNewPassword}
                                edge="end"
                              >
                                {!this.state.enableNewPasswordField ? (
                              <img src={this.state.passwordVisible}  />
                            ) : (
                              <img src={this.state.passwordInVisible} />
                            )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                    <Box sx={{ marginTop: "8px" }}>
                      <Typography 
                      style={{
                        lineHeight: "22px", letterSpacing: "-0.02em",
                        textAlign: "left", color: "#64748B",fontFamily: "Spartan",
                        fontSize: "14px", fontWeight: 700,
                        }}
                      >
                        Password
                      </Typography>
                      <TextField onChange={this.handleConfirmPassword}
                      value={this.state.confirmPassword}
                      data-test-id="handleConfirmPassword"
                      error={!!this.state.confirmPasswordError}
                      // helperText={this.state.confirmPasswordError}
                        sx={{
                          borderRadius: "8px",
                          border: "1px ",
                          marginTop: "4px",
                          color: "#94A3B8",
                        }}
                        placeholder="xxxxxxxx"
                        type={this.state.enableConfirmPasswordField?'password':'text'}
                        name="password"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={this.handleShowConfirmPassword}
                                edge="end"
                              >
                                {!this.state.enableConfirmPasswordField ? (
                              <img src={this.state.passwordVisible}  />
                            ) : (
                              <img src={this.state.passwordInVisible} />
                            )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                    <Typography 
                    style={{
                      fontFamily: "Spartan",
                      fontSize: "12px",
                      fontWeight: 400,
                      lineHeight: "20px",
                      letterSpacing: "-0.02em",
                      textAlign: "left",
                      color: "#DC2626",
                      }}>
                      {this.state.confirmPasswordError}
                    </Typography>
                    <Box sx={{ marginTop: "8px" }}>
                      <Typography 
                      style={{
                        fontFamily: "Spartan",fontSize: "12px",
                        fontWeight: 700, textAlign: "left",
                        color: "#64748B",lineHeight: "18px",
                        letterSpacing: "-0.02em",
                      }}>
                        Your password must contain
                      </Typography>
                    </Box>
                    <Box 
                    >
                      <Box style={{ display: "flex", alignItems: "center", marginTop: "4px"}}>
                        {isUppercaseValid ? <CheckCircleOutlineIcon style={{ color: '#059669', marginRight: '8px'}} /> : <CancelOutlinedIcon style={{ color: '#DC2626', marginRight: '8px' }} />}
                        <Typography 
                        style={{
                          fontFamily: "Spartan",
                          fontSize: "12px",
                          fontWeight: 500,
                          lineHeight: "18px",
                          letterSpacing: "-0.02em",
                          textAlign: "left",
                          color: "#475569",
                        }}
                        >
                          
                        1 upper case letter
                        </Typography>
                      </Box>
                      <Box style={{ display: "flex", alignItems: "center", marginTop: "4px"}}>
                        {isLengthValid ? <CheckCircleOutlineIcon style={{ color: '#059669', marginRight: '8px' }} /> : <CancelOutlinedIcon style={{ color: '#DC2626', marginRight: '8px' }} />}
                        <Typography 
                        style={{
                          fontFamily: "Spartan",
                          fontSize: "12px",
                          fontWeight: 500,
                          lineHeight: "18px",
                          letterSpacing: "-0.02em",
                          textAlign: "left",
                          color: "#475569",
                        }}
                        >
                          
                        Between 8 and 20 characters
                        </Typography>
                      </Box> 
                      <Box style={{ display: "flex", alignItems: "center", marginTop: "4px",}}>
                        {isNumberValid ? <CheckCircleOutlineIcon style={{ color: '#059669', marginRight: '8px', }} /> : <CancelOutlinedIcon style={{color: '#DC2626', marginRight: '8px' }} />}
                        <Typography 
                        style={{
                          fontFamily: "Spartan",
                          fontSize: "12px",
                          fontWeight: 500,
                          lineHeight: "18px",
                          letterSpacing: "-0.02em",
                          textAlign: "left",
                          color: "#475569",
                        }}
                        >
                          
                        1 or more numbers
                        </Typography>
                      </Box>
                      <Box style={{ display: "flex", alignItems: "center", marginTop: "4px"}}>
                        {isSpecialCharValid ? <CheckCircleOutlineIcon style={{ color: '#059669', marginRight: '8px' }} /> : <CancelOutlinedIcon style={{color: '#DC2626', marginRight: '8px' }} />}
                        <Typography 
                        style={{
                          fontFamily: "Spartan",
                          fontSize: "12px",
                          fontWeight: 500,
                          lineHeight: "18px",
                          letterSpacing: "-0.02em",
                          textAlign: "left",
                          
                          color: "#475569",
                        }}
                        >
                          
                        1 or more special characters
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <Button disabled={this.state.newPassword && this.state.confirmPassword ? false: true}
                    onClick={this.handleResetPassword}
                    data-test-id="handleResetPassword"
                    style={{
                      width: "100%",
                      height: "56px",
                      borderRadius: "8px",      
                      backgroundColor:this.state.newPassword && this.state.confirmPassword  ? "#003249":'#94A3B8',
                      color:"#FFFFFF",
                      fontFamily: "Spartan",
                      fontSize: "16px",
                      fontWeight: 700,
                      lineHeight: "24px",
                      letterSpacing: "-0.03em",
                      textTransform: "none",
                      marginTop: "3rem",
                      }}
                    >
                      Reset my password
                    </Button>
                  </Box>
                </Box>
            </RightGrid>
        </Grid>
        </>
    )
  }

}
// Customizable Area End
