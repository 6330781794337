//@ts-nocheck
// Customizable Area Start
import React from "react";
import {
 Box, Button,
  Typography, CircularProgress, Divider, DialogActions, Dialog, DialogContent, DialogTitle, IconButton
} from "@mui/material";
import { styled } from '@mui/material/styles';
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Header from "../../../components/src/AppHeader.web";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import moment from "moment";
import CommonDatePicker from '../../../../packages/components/src/helper/CommonDatePicker.web';
import ReactSelect from "react-select";
import GuestBookingFormController, { Props } from "./GuestBookingFormController";
import BookingOptionSelector from "../../../../packages/components/src/BookingOptionSelector.web";
import { Camp, Home, Hookup, Pasture, Stalls } from "./assets";


// Customizable Area End
export default class GuestBookingForm extends GuestBookingFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const productId = this.props.navigation.getParam?.('productId');
    const isBookingReqChange = localStorage.getItem('isBookingReqChange');
    if (isBookingReqChange && isBookingReqChange == 'YES') {
      this.getBookingDetails(productId, true);
    } else {
      this.getProductDetails(productId);
    }
  }

  componentDidUpdate(prevProps: any) {
    const productId = this.props.navigation.getParam?.('productId');
    const isBookingReqChange = localStorage.getItem('isBookingReqChange');
    if (prevProps.navigation.getParam?.('productId') !== productId) {
      if (isBookingReqChange && isBookingReqChange == 'YES') {
        this.getBookingDetails(productId, true);
      } else {
        this.getProductDetails(productId);
      }
    }
  }

  customStyles = {
    control: (provided: any) => ({
      ...provided,
      padding: '4px 2px',
      borderRadius: '8px'
    }),
    input: (provided: any) => ({
      ...provided,
    }),
  };

  renderReqSubmitedDialog = () => {
    return (
      <Box>
        <CustomDialog
          open={this.state.reqSubmitedDialog}
          onClose={this.toggleCRSubmitedDialog}
        >
          <DialogTitle className="title">
            <IconButton
              onClick={this.toggleCRSubmitedDialog}
              sx={{ position: "absolute", right: 8, top: 8 ,cursor:'pointer'}}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className="dialog-content">
            <Box sx={{display:'flex',flexDirection:'column',justifyContent:'center'}}>
              <Box><Typography className="dropdown-label">Request Sent</Typography></Box>
              <Box><Typography className="option-description" sx={{ paddingTop: '40px' }}>{'We will wait for the host to approve the change and later you will need to complete payment within 48 hrs to confirm the change.'}</Typography></Box>
            </Box>
          </DialogContent>
          <DialogActions className="dialog-action">
            <DialogButton data-test-id={'done-btn'} onClick={this.onDoneClick}>Done</DialogButton>
          </DialogActions>
        </CustomDialog>
      </Box>
    )
  }

  renderAddDates = () => {
    return <Typography>
      {this.state.checkOut ? (
        moment(this.state.checkOut).format('MMM DD/YY')
      ) : (
        <Box component="span" sx={{ color: 'hsl(0, 0%, 50%)' }}>
          Add dates
        </Box>
      )}
    </Typography>
  }

  renderInputView = () => {
    const { available_from, available_until } = this.state.productDetails?.attributes
    return (
      <Box>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              ...webStyle.dateContainer,
              backgroundColor: '#FFFFF'
            }}
          >
            <Box sx={{ marginBottom: '16px' }}>
              <Typography sx={webStyle.inputHeader}>Check in</Typography>
            </Box>
            <Box
              data-test-id={'check-in-field'}
              onClick={() =>
                this.onChangeValue(
                  this.state.currentOpen === 'checkIn' ? '' : 'checkIn'
                )
              }
              sx={{
                border: '1px solid #CBD5E1',
                borderRadius: '8px',
                padding: '10px 8px',
              }}
            >
              <Typography>
                {this.state.checkIn ? (
                  moment(this.state.checkIn).format('MMM DD/YY')
                ) : (
                  <Box component="span" sx={{ color: 'hsl(0, 0%, 50%)' }}>
                    Add dates
                  </Box>
                )}
              </Typography>
            </Box>
            {this.isCheckInOpen() && (
              <Box sx={{ ...webStyle.dateWrapper, position: 'absolute', zIndex: 99 }}>
                <CommonDatePicker
                  inline
                  disabledKeyboardNavigation 
                  excludeDates={this.getBlackoutDates()}
                  minDate={new Date()}
                  maxDate={new Date(available_until)}
                  data-test-id='check-in'
                  selected={this.state.checkIn}
                  onChange={(date) => this.onChangeCheckIn(date)}
                />
              </Box>
            )}
          </Box>

          <Box
            sx={{
              ...webStyle.dateContainer,
              backgroundColor:'#FFFFF'
            }}
          >
            <Box sx={{ marginBottom: '16px' }}>
              <Typography sx={webStyle.inputHeader}>Check out</Typography>
            </Box>
            <Box
             data-test-id={'check-out-field'}
              onClick={() =>
                this.onChangeValue(this.onChangeStr())
              }
              sx={webStyle.checkOutField}
            >
              {this.renderAddDates()}
            </Box>
            {this.state.currentOpen === 'checkout' && (
              <Box sx={{
                ...webStyle.dateWrapper,
                position: 'absolute',
                zIndex: 3
              }}>
                <CommonDatePicker
                  inline
                  selectsRange
                  selectsEnd
                  data-test-id='check-out'
                  excludeDates={this.getBlackoutDates()}
                  selected={this.state.checkOut}
                  disabledKeyboardNavigation
                  minDate={this.state.checkIn ?? this.getMinDate(available_from)}
                  maxDate={new Date(available_until)}
                  startDate={this.state.checkIn}
                  endDate={this.state.checkOut}
                  onChange={(date: [Date | null, Date | null]) => this.onChangeCheckout(date)}
                />
              </Box>
            )}
          </Box>
        </Box>
        {!!this.state.bookingDatesError.length && <Typography color="error" sx={{fontSize:'14px', marginTop: '8px' }}>{this.state.bookingDatesError}</Typography>}
        <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: '24px', justifyContent: 'space-between' }}>
          <Box sx={{ minWidth: '45%' }}>
            <Typography sx={{ marginBottom: '16px', ...webStyle.inputHeader }}>Guests</Typography>
            <ReactSelect
              isSearchable={false}
              classNamePrefix="dropdown"
              data-test-id={'guest-select'}
              styles={this.customStyles}
              value={this.guestSelectorData()}
              options={['1', '2', '3', '4', '5', '6', '7', '8', '9'].map(
                (val) => ({ label: val, value: val })
              )}
              components={{ IndicatorSeparator: () => null }}
              onChange={(e: any) => {
                this.setState({ guests: e.value });
              }}
            />
          </Box>
          <Box sx={{ minWidth: '45%' }}>
            <Typography sx={{ marginBottom: '16px', ...webStyle.inputHeader }}>Horses stabling</Typography>
            <ReactSelect
              isSearchable={false}
              classNamePrefix="dropdown"
              data-test-id={'horses-select'}
              styles={this.customStyles}
              value={this.horseSelectorData()}
              options={['1', '2', '3', '4', '5', '6', '7', '8', '9'].map(
                (val) => ({ label: val, value: val })
              )}
              components={{ IndicatorSeparator: () => null }}
              onChange={(e: any) => {
                this.setState({ horses: e.value });
              }}
            />
          </Box>
        </Box>
      </Box>
    );
  }

  renderVisitors = () => {
    return <Box sx={webStyle.cardContainer}>
      <CustomAccordion defaultExpanded>
        <CustomAccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: '#003249' }} />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography sx={webStyle.cardTitle}>NUMBER OF VISITORS</Typography>
        </CustomAccordionSummary>
        <CustomAccordionDetails>
          {this.renderInputView()}
        </CustomAccordionDetails>
      </CustomAccordion>
    </Box>
  }

  renderHorses = () => {
    return <Box sx={webStyle.cardContainer}>
      <CustomAccordion defaultExpanded>
        <CustomAccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: '#003249' }} />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography sx={webStyle.cardTitle}>HORSE & STABLING</Typography>
        </CustomAccordionSummary>
        <CustomAccordionDetails>
          <Box sx={{ marginLeft: '10px' }}>
            {this.state.bookingDetails.horse_accommodations.map((item,index) => (<BookingOptionSelector
              data-test-id={'booing-selector'}
              testID={item.id}
              showBorder={true}
              showMargin={true}
              onChangeValue={(val) => this.handleFormChange(item, val, 'count')}
              title={this.capitalizedText(item.type)}
              type={'horses'}
              priceText={'$ ' + item.price + ' per horse/night'}
              capacity={'max capacity ' + item.number_of_stalls}
              iconTemplate={Stalls}
              item={item}
            />))}
          </Box>
        </CustomAccordionDetails>
      </CustomAccordion>
    </Box>
  }

  renderCheckBoxSelector = (item) => {
    return (
      <BookingOptionSelector
        testID={item.id}
        data-test-id={'booing-selector'}
        showCheckbox={true}
        onChangeCheck={(isChecked) => this.handleFormChange(item, isChecked, 'select')}
        onChangeValue={(val) => this.handleFormChange(item, val, 'count')}
        title={this.capitalizedText(item.name)}
        priceText={'$ ' + item.price_per_night + ' per/night'}
        capacity={'max capacity ' + item.guest_capacity}
        totalCol={{ total: this.formatCurrency(item.total_price), no: this.state.totalNights + ' nights' }}
        item={item}
      />
    )
  }

  renderAccomodation = () => {
    return <Box sx={webStyle.cardContainer}>
      <CustomAccordion defaultExpanded>
        <CustomAccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: '#003249' }} />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography sx={webStyle.cardTitle}>GUEST ACCOMODATION</Typography>
        </CustomAccordionSummary>
        <CustomAccordionDetails>

          <Box sx={webStyle.groupWrapper}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
              <Box>{Home}</Box>
              <Box>
                <Typography sx={{ ...webStyle.inputHeader, marginLeft: '11px' }}>Apartment Guests</Typography>
              </Box>
            </Box>
            <Box sx={{ margin: '0px 0px' }}>
              {this.state.bookingDetails.apartment.map((item, index) => this.renderCheckBoxSelector(item))}
            </Box>
          </Box>
          <Box sx={webStyle.groupWrapper}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
              <Box>{Camp}</Box>
              <Box>
                <Typography sx={{ ...webStyle.inputHeader, marginLeft: '11px' }}>Camping Spots</Typography>
              </Box>
            </Box>
            <Box sx={{ margin: '0px 0px' }}>
              {this.state.bookingDetails.campsite.map((item, index) => this.renderCheckBoxSelector(item))}
            </Box>
          </Box>

          <Box sx={webStyle.groupWrapper}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
              <Box>{Hookup}</Box>
              <Box>
                <Typography sx={{ ...webStyle.inputHeader, marginLeft: '11px' }}>Hookup Options</Typography>
              </Box>
            </Box>
            <Box sx={{ margin: '0px 0px' }}>
            {this.state.bookingDetails.hookups.map((item, index) => {
                return (
                  <BookingOptionSelector
                    testID={item.id}
                    data-test-id={'booing-selector'}
                    onChangeValue={(val) => this.handleFormChange(item, val, 'count')}
                    title={this.capitalizedText(item.hookups_type)}
                    priceText={'$ ' + item.price_per_night + ' per/night'}
                    capacity={'max capacity ' + item.hookups_spot}
                    totalCol={{ total:this.formatCurrency(item.total_price), no: this.state.totalNights+' nights' }}
                    item={item}
                  />
                )
              }
              )}
            </Box>
          </Box>
        </CustomAccordionDetails>
      </CustomAccordion>
    </Box>
  }

  renderAmenities = () => {
    return <Box sx={webStyle.cardContainer}>
      <CustomAccordion defaultExpanded>
        <CustomAccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: '#003249' }} />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography sx={webStyle.cardTitle}>AMENITIES</Typography>
        </CustomAccordionSummary>
        <CustomAccordionDetails>
          <Box sx={{ margin: '0px 0px' }}>
          {this.state.bookingDetails.amenities.map((item, index) => {
                return (
                  <BookingOptionSelector
                    testID={item.id}
                    data-test-id={'booing-selector'}
                    onChangeValue={(val) => this.handleFormChange(item, val, 'count')}
                    title={this.capitalizedText(item.type)}
                    priceText={'$ ' + item.price + ' per horse/night'}
                    capacity={'max capacity ' + item.horse_capacity}
                    totalCol={{ total: this.formatCurrency(item.total_price), no: this.state.totalNights + ' nights' }}
                    item={item}
                  />
                )
              }
              )}
          </Box>
        </CustomAccordionDetails>
      </CustomAccordion>
    </Box>
  }

  renderPricingDetails = () => {
    return <><Box sx={{ paddingLeft: '20px' }}>
      <CustomAccordion defaultExpanded>
        <CustomAccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: '#003249' }} />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between', marginRight: '20px' }}>
            <Box><Typography sx={webStyle.inputHeader}>Horse stabling</Typography></Box>
            <Box><Typography sx={webStyle.inputHeader}>{this.formatCurrency(this.totalBlockPrice('horse_accommodations'))}</Typography></Box>
          </Box>
        </CustomAccordionSummary>
        <CustomAccordionDetails sx={{ marginLeft: '10px' }}>
          {this.state.bookingDetails.horse_accommodations.map((item) =>
            <Box sx={webStyle.priceBlockWrapper}>
              <Typography sx={webStyle.priceText}>{item.type}</Typography>
              <Typography sx={webStyle.priceText}>{this.formatCurrency(item.total_price)}</Typography>
            </Box>
          )} 
        </CustomAccordionDetails>
      </CustomAccordion>
      <CustomAccordion defaultExpanded >
        <CustomAccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: '#003249' }} />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between', marginRight: '20px' }}>
            <Box><Typography sx={webStyle.inputHeader}>Guest accommodation</Typography></Box>
            <Box><Typography sx={webStyle.inputHeader}>{this.formatCurrency(this.totalAccomodation())}</Typography></Box>
          </Box>
        </CustomAccordionSummary>
        <CustomAccordionDetails sx={{ marginLeft: '10px' }}>
          <Box sx={webStyle.priceBlockWrapper}>
            <Typography sx={webStyle.priceText}>Apartment Guests </Typography>
            <Typography sx={webStyle.priceText}>{this.formatCurrency(this.totalBlockPrice('apartment'))}</Typography>
          </Box>
          <Box sx={webStyle.priceBlockWrapper}>
            <Typography sx={webStyle.priceText}>Camping Spots</Typography>
            <Typography sx={webStyle.priceText}>{this.formatCurrency(this.totalBlockPrice('campsite'))}</Typography>
          </Box>
          <Box sx={webStyle.priceBlockWrapper}>
            <Typography sx={webStyle.priceText}>Hookup Options</Typography>
            <Typography sx={webStyle.priceText}>{this.formatCurrency(this.totalBlockPrice('hookups'))}</Typography>
          </Box>
        </CustomAccordionDetails>
      </CustomAccordion>
      <CustomAccordion defaultExpanded>
        <CustomAccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: '#003249' }} />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between', marginRight: '20px' }}>
            <Box><Typography sx={{ ...webStyle.inputHeader}}>Amenities</Typography></Box>
            <Box><Typography sx={{ ...webStyle.inputHeader }}>{this.formatCurrency(this.totalBlockPrice('amenities'))}</Typography></Box>
          </Box>
        </CustomAccordionSummary>
        <CustomAccordionDetails sx={{ marginLeft: '10px' }}>
          {this.state.bookingDetails.amenities.map((item) =>
            <Box sx={webStyle.priceBlockWrapper}>
              <Typography sx={webStyle.priceText}>{item.type}</Typography>
              <Typography sx={webStyle.priceText}>$ {item.total_price}</Typography>
            </Box>)
          }
        </CustomAccordionDetails>
      </CustomAccordion>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginRight: '44px' }}>
        <Box><Typography sx={webStyle.inputHeader}>Platform Fee 10%</Typography></Box>
        <Box><Typography sx={webStyle.inputHeader}>{this.formatCurrency(this.bookingTotal().charges)}</Typography></Box>
      </Box>
    </Box>
      {!this.state.isRequestChanges ?
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginRight: '44px', marginTop: '32px' }}>
        <Box><Typography sx={webStyle.priceTitle}>TOTAL</Typography></Box>
        <Box><Typography sx={webStyle.priceTitle}>{this.formatCurrency(this.bookingTotal().total)}</Typography></Box>
      </Box>
      :
       <>
       <Divider sx={{ backgroundColor: '#E2E8F0', height: '0.5px' ,margin:'20px 0px'}} />
       <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginRight: '44px' }}>
       <Box><Typography sx={webStyle.inputHeader}>Original payment</Typography></Box>
       <Box><Typography sx={webStyle.inputHeader}>{this.formatCurrency(this.state.previousBookingDetails?.attributes.total_price)}</Typography></Box>
       </Box>
       <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginRight: '44px',marginTop:'16px' }}>
       <Box><Typography sx={webStyle.inputHeader}>New Amount</Typography></Box>
       <Box><Typography sx={webStyle.inputHeader}>{this.formatCurrency(this.bookingTotal().total)}</Typography></Box>
       </Box>
       <Divider sx={{ backgroundColor: '#E2E8F0', height: '0.5px',margin:'20px 0px' }} />
       <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginRight: '44px', marginTop: '32px' }}>
        <Box><Typography sx={webStyle.priceTitle}>AMOUNT DUE</Typography></Box>
        <Box><Typography sx={{...webStyle.priceTitle,color:'#DC2626'}}>{this.formatCurrency(this.bookingTotal().total - this.state.previousBookingDetails?.attributes.total_price)}</Typography></Box>
      </Box>
     </>}
      
      <Box sx={{ display: 'flex', justifyContent: 'center' }}><BlackButton sx={{ marginTop: '64px', width: '325px' }} onClick={() => this.createBooking()} disabled={!this.isEnableBookNow()} data-test-id={'book-now-btn'}>{this.state.isRequestChanges ? 'Send Request' : 'Book Now'}</BlackButton></Box>
    </>
  }
  // Customizable Area End


  render() {
    // Customizable Area Start
    return (
      <Header
        data-test-id="header"
        navigation={this.props.navigation}
      >
        <Wrapper sx={{ backgroundColor: "#F8FAFC" }}>
          {this.state.isloading ? (
            <Box className="loading">
              <CircularProgress sx={{ color: '#000' }} />
            </Box>
          ) : this.state.productDetails?.attributes && (
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', backgroundColor: "#F8FAFC" }}>
              <Box sx={{ display: 'flex', margin: '40px 135px 40px 0px', flexDirection: 'row' }}>
                <Box data-test-id={'back-press'} sx={{ marginLeft: '40px', cursor: 'pointer', width: '48px' }} onClick={this.onBackPress}>
                  <ArrowBackIcon sx={{ height: '48px' }} />
                </Box>
                {this.state.productDetails && <Box sx={{ display: 'flex', flexDirection: 'column', minWidth: '100%', marginBottom: '60px', marginLeft: '62px' }}>
                  {this.renderVisitors()}
                  {this.renderHorses()}
                  {this.renderAccomodation()}
                  {this.renderAmenities()}
                </Box>}
              </Box>
              <Box sx={{
                backgroundColor: '#FFFFFF', minWidth: '500px', padding: '40px 40px 40px 24px', boxShadow: '-2px 0px 8px 0px #00000014'
              }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', paddingLeft: '16px', justifyContent: 'space-between' }}>
                  <Typography sx={webStyle.priceTitle}>PRICE DETAIL</Typography>
                </Box>
                <Box>
                  <Typography sx={{ ...webStyle.totalPriceText, marginTop: '36px', paddingLeft: '16px', marginBottom: '24px' }}>{this.state.totalNights + ' nights'}</Typography>
                  {this.renderPricingDetails()}
                  {this.renderReqSubmitedDialog()}
                </Box>
              </Box>
            </Box>)}
        </Wrapper>
      </Header>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  cardContainer: {
    backgroundColor: 'white',
    marginBottom: '40px',
    padding: '20px',
    borderRadius: '16px',
    boxShadow: '0px 0px 18px 0px #00000014',
  },
  cardTitle: {
    fontSize: "14px",
    lineHeight: '22px',
    fontFamily: "Spartan",
    fontWeight: "700",
    color: '#003249'
  },
  inputHeader: {
    fontFamily: 'Spartan',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '22px',
    letterSpacing: '-2%',
    color: '#003249'
  },
  dateContainer: {
    minWidth: '45%',
    position: 'relative',
    zIndex: 99,
  },
  dateWrapper: {
    zIndex: '9999',
    top: '90px',
    cursor: 'default',
  },
  priceTitle: {
    fontFamily: 'Spartan',
    fontWeight: 700,
    fontSize: '16px',
    lineheight: '24px',
    letterSpacing: '-2%',
    color: '#003249'
  },
  totalPriceText: {
    fontFamily: 'Spartan',
    fontWeight: 500,
    fontSize: '16px',
    lineheight: '24px',
    letterSpacing: '-2%',
    color: '#003249'
  },
  priceText: {
    fontFamily: 'Spartan',
    fontWeight: 500,
    fontSize: '14px',
    lineheight: '24px',
    color: '#003249',
  },
  groupWrapper: {
    borderRadius: '8px',
    border: '1px solid #E2E8F0',
    padding: '16px 10px',
    marginBottom: '16px'
  },
  priceBlockWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginRight: '44px',
    marginBottom: '8px'
  },
  checkOutField:{
    border: '1px solid #CBD5E1',
    borderRadius: '8px',
    padding: '10px 8px',
  }
}

const CustomDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    borderRadius: "16px",
    width: "550px",
  },
  "& .title": {
    textAlign: "center",
  },
  "& .MuiDialogContent-root": {
    padding: "0px 30px 18px !important"
  },
  "& .dialog-content": {
    textAlign: "center",
    marginBottom: '10px',
    "& .popup-title": {
      textAlign: "center",
      letterSpacing: "0px",
      fontFamily: "Spartan",
      fontWeight: "700",
      fontSize: "18px",
      color: "#003249"
    },
    "& .dropdown-label": {
      fontFamily: 'Spartan',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '24px',
      color: '#003249'
    },
    "& .dropdown-label-note": {
      fontFamily: 'Spartan',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '18px',
      color: '#716F77',
      paddingTop: '8px'
    },
    "& .option-description": {
      fontFamily: 'Spartan',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '24px',
      color: '#716F77',
      paddingTop: '8px'
    },
    "& .popup-desc": {
      letterSpacing: "-2%",
      fontFamily: "Spartan",
      fontWeight: "500",
      fontSize: "15px",
      color: "#94A3B8"
    },
  },
  "& .dialog-action": {
    gap: "10px",
    padding: "30px",
    justifyContent: "center",
  },
  "& .close-btn": {
    color: "#003249",
    height: "15px",
    width: "15px",
  },
  "&.change-host": {
    "& .MuiDialogContent-root": {
      paddingTop: "15px !important",
      paddingBottom: "15px !important",
    }
  }
})

const DialogButton = styled(Button)({
  padding: "8px 10px",
  width: "256px",
  borderRadius: "8px",
  textTransform: "none",
  fontSize: "16px",
  fontFamily: "Spartan",
  whiteSpace: "nowrap",
  fontWeight: "700",
  color: "#fff",
  cursor:'pointer',
  backgroundColor: "#003249",
  border: "1.5px solid #003249",
  "&:hover": {
    color: "#fff",
    backgroundColor: "#003249",
  },
  "&:disabled": {
    color: "#fff",
    backgroundColor: "#94A3B8",
    border:'none'
  }
});


const BlackButton = styled(Button)({
  padding: "8px 10px",
  width: "100%",
  borderRadius: "8px",
  textTransform: "none",
  fontSize: "16px",
  fontFamily: "Spartan",
  whiteSpace: "nowrap",
  fontWeight: "700",
  color: "#fff",
  backgroundColor: "#003249",
  border: "1.5px solid #003249",
  "&:hover": {
    color: "#fff",
    backgroundColor: "#003249",
  },
  "&:disabled": {
    color: "#fff",
    backgroundColor: "#94A3B8",
    border:'none'
  }
});

const CustomAccordion = styled(Accordion)(({ theme }) => ({
  border: 'none !important',
  boxShadow: 'none !important',
  padding: '0px',
  margin: '0px',
  position: 'relative',
  '&::before': {
    position: 'absolute',
    left: 0,
    top: '-1px',
    right: 0,
    height: '1px',
    content: '""',
    opacity: 1,
    backgroundColor: 'transparent',
    transition: 'opacity 150ms cubic-bezier(0.4, 0, 0.2, 1), background-color 150ms cubic-bezier(0.4, 0, 0.2, 1)',
  },
}));

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  padding: '0px',
  border: 'none !important',
  boxShadow: 'none !important',
  margin: '0px',
  outline: 'none',
}));

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: '0px',
  border: 'none !important',
  boxShadow: 'none !important',
  margin: '0px',
  outline: 'none',
}));

const Wrapper = styled(Box)({
  flex: "1 1 auto",
  backgroundColor: "#F8FAFC",
  '& .loading': {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  "& .heading": {
    fontSize: "18px",
    fontFamily: "Spartan",
    fontWeight: "700"
  },
  "& .not-found": {
    marginTop: "80px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .title": {
      marginTop: "20px",
      fontSize: "16px",
      fontFamily: "Spartan",
      fontWeight: "500",
      color: "#64748B"
    }
  },
  "& .search-title": {
    fontSize: "18px",
    fontFamily: "Spartan",
    fontWeight: "700",
    color: "#003249"
  },
  '& .MuiBadge-badge': {
    minWidth: "25px",
    height: "25px",
    marginTop: "3px",
    borderRadius: "15px",
    paddingTop: "3px",
    backgroundColor: '#003249',
    color: '#FFFFFF',
    fontSize: '12px',
    fontWeight: '700',
    fontFamily: "Spartan",
  },
  "& .sub-title": {
    fontSize: "14px",
    fontFamily: "Spartan",
    fontWeight: "500"
  },
  "& .gridContainer": {
    padding: "20px",
    "& .card": {
      height: "100%"
    },
    "& .grid-item": {
      flex: 1,
      display: "flex",
      flexDirection: "column"
    }
  },
  "& .slick-slider .slick-list": {
    height: "185px",
    borderRadius: "16px",
  },
  "& .slick-prev, .slick-next": {
    display: "none !important",
  },
  ".slick-dots": {
    position: "absolute",
    bottom: "14px",
    display: "flex !important",
    justifyContent: "center",
    listStyle: "none",
    padding: 0,
    margin: 0,
  },
  ".slick-dots li": {
    height: "12px",
    width: "12px",
    margin: "0px 1px",
    display: "flex",
    alignItems: "center"
  },
  ".slick-dots li div": {
    opacity: "0.5",
    height: "6px",
    width: "6px",
    backgroundColor: "#fff"
  },
  ".slick-dots li button": {
    opacity: 0,
    cursor: "pointer",
  },
  ".slick-dots li.slick-active div": {
    height: "8px",
    width: "8px",
    opacity: "1",
    backgroundColor: "#fff",
  },
  "& .card": {
    border: "1px solid #E2E8F0",
    padding: "15px",
    borderRadius: "24px",
    boxShadow: "0px 2px 8px 0px #00000014",
    "& .rating": {
      marginLeft: "3px",
      fontSize: "12px",
      fontFamily: "Spartan",
      fontWeight: "700"
    },
    "& .image": {
      borderRadius: "16px",
    },
    "& .content": {
      display: "flex",
      flexDirection: "column",
      gap: "7px",
      padding: "0px"
    },
    "& .title": {
      fontSize: "16px",
      fontFamily: "Spartan",
      fontWeight: "700"
    },
    "& .location": {
      fontSize: "14px",
      fontFamily: "Spartan",
      fontWeight: "500"
    },
    "& .details, & .guest": {
      fontSize: "12px",
      fontFamily: "Spartan",
      fontWeight: "400"
    },
    "& .price": {
      fontSize: "12px",
      fontFamily: "Spartan",
      fontWeight: "500"
    }
  },
  "& .filterButton": {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "20px",
  },
  "& .map-view": {
    display: "flex",
    height: "calc(100vh - 75px)",
    "& .left-section": {
      padding: "10px 15px",
      width: "415px",
      overflow: "auto",
      "& .loading": {
        display: "flex",
        justifyContent: "center",
        marginTop: "20px",
        marginBottom: "20px",
        "& .MuiCircularProgress-root": {
          color: "#000"
        }
      }
    },
    "& .right-section": {
      width: "100%",
      position: "relative",
    }
  }
});
// Customizable Area End