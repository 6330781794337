import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";
import {
  getStorageData,
  setStorageData,
} from "../../../framework/src/Utilities";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { apiCall } from "../../../components/src/NavigationRouteWrapper/helpers";
// Customizable Area Start
interface StripeKeys {
  publishable_key: string;
  secret_key: string;
}
export interface APIPayloadType {
  contentType: string;
  method: string;
  endPoint: string;
  body?: object;
}
interface ProfilePhoto {
  blob_id: number;
  url: string;
  filename: string;
  size: string;
}

interface Account {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  full_phone_number: string;
  activated: boolean;
  average_rating_count: number;
  reviews_count: number;
  profile_photo: ProfilePhoto;
}

interface BookingHorseAccommodation {
  type: string;
  number_of_stalls: number;
  price: number;
  selected_stall: number;
  selected_item_price: number;
}

interface BreakdownGuestAccommodations {
  apartment: number;
  campsite: number;
  hookups: number;
}

interface BreakdownHorseAccommodations {
  type: string;
  selected_item_price: number;
}

interface BookingPriceBreakdown {
  horse_accommodations: number;
  breakdown_horse_accommodations: BreakdownHorseAccommodations[];
  guest_accommodations: number;
  breakdown_guest_accommodations: BreakdownGuestAccommodations;
  amenities: number;
  platform_fee: number;
  total: number;
}

interface ListingFile {
  blob_id: number;
  url: string;
  filename: string;
  size: string;
}

interface ListingAttributes {
  id: number;
  title: string;
  address: string;
  latitude: string;
  longitude: string;
  location_name: string;
  files: ListingFile[];
  cover_photo: ListingFile;
}

interface ListingData {
  id: string;
  type: string;
  attributes: ListingAttributes;
}

interface Listing {
  data: ListingData;
}

interface BookingAttributes {
  id: number;
  status: string;
  start_date: string;
  end_date: string;
  request_change_start_date: string | null;
  request_change_end_date: string | null;
  total_price: string;
  created_at: string;
  updated_at: string;
  booking_horse_accommodations: BookingHorseAccommodation[];
  booking_id: string;
  cancelled_by: string | null;
  due_amount?: string;
  payment_status: string;
  refunded_amount: string;
  additional_message: string | null;
  booking_price_breakdown: BookingPriceBreakdown;
  total_nights: number;
  change_request_total_night: number;
  total_guest_count: number;
  total_horse_count: number;
  check_in_time: string;
  check_out_time: string;
  average_rating_count: number;
  reviews_count: number;
  guest_account: Account;
  host_account: Account;
  listing: Listing;
}

interface BookingData {
  id: string;
  type: string;
  attributes: BookingAttributes;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  bookingDetailsConfirm: BookingData;
  popupBtnOnly?: Boolean;
  payClicked?: () => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  stripeKeysData: StripeKeys;
  isModalOpen: boolean;
  isProcessing: boolean;
  showBookingConfirmPopup: boolean;
  stripePromise: Promise<Stripe | null> | null;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class StripePayController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getStripeCredsApiCallID: string = "";
  createPaymentIntentApiCallId: string = "";
  addCardApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];
    // Customizable Area End

    this.state = {
      // Customizable Area Start
      stripeKeysData: {
        publishable_key: "",
        secret_key: "",
      },
      stripePromise: null,
      isModalOpen: false,
      isProcessing: false,
      showBookingConfirmPopup: false,
      // Customizable Area End
    };
    // Customizable Area Start
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (apiRequestCallId) {
        case this.getStripeCredsApiCallID:
          this.setState({ stripeKeysData: responseJson }, async () => {
            await setStorageData(
              "stripeKeysData",
              JSON.stringify(responseJson)
            );
          });
          break;

        case this.createPaymentIntentApiCallId:
          if (responseJson.message) {
            this.setState({ isModalOpen: false });
            toast.error(responseJson.message);
          }
          if (responseJson.payment_intent?.status === "succeeded") {
            this.setState({
              isModalOpen: false,
              showBookingConfirmPopup: true,
            });
          }
          break;

        case this.addCardApiCallId:
          if (responseJson.data?.id) {
            this.createPaymentIntent(responseJson.data.id);
          }
          break;
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    this.callGetStripeKeysApi();
    const storedStripeKeys = JSON.parse(await getStorageData("stripeKeysData"));
    const stripePublishableKey = storedStripeKeys?.publishable_key.trim();
    const stripePromise = stripePublishableKey ? loadStripe(stripePublishableKey) : null;
    this.setState({ stripePromise });
  }

  togglePopup = () =>
    this.setState({
      showBookingConfirmPopup: !this.state.showBookingConfirmPopup,
    });

  handleView = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "ManageBooking"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
    this.togglePopup();
    this.props.navigation.navigate("ManageBooking");
  };
  callGetStripeKeysApi = () => {
    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("token"),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getStripeCredsApiCallID = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getStripeKeysEndpoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  isPaymentRequired = () => this.props.bookingDetailsConfirm.attributes.status === "Payment required";

  createPaymentIntent = async (cardId: string | undefined) => {
    let apiData = {
      total_amount: this.isPaymentRequired()
        ? this.props.bookingDetailsConfirm.attributes.due_amount
        : this.props.bookingDetailsConfirm.attributes.booking_price_breakdown
          .total,
      booking_id: this.props.bookingDetailsConfirm.attributes.id,
      card_id: cardId,
    };
    this.createPaymentIntentApiCallId = await apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.confirmPaymentMethod,
      endPoint: configJSON.createPyamentEndpoint,
      body: apiData,
    });
  };

  addCard = async (tokenID: string | undefined) => {
    const data = {
      card_token: tokenID,
    };
    this.addCardApiCallId = await apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.confirmPaymentMethod,
      endPoint: configJSON.addCardEndPoint,
      body: data,
    });
  };

  handlePayClick = () => {
    this.setState({ isModalOpen: true });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false }, () => {
      this.props.payClicked?.();
    });
  };

  cancelBtn = () => {
    this.setState({ showBookingConfirmPopup: false });
    this.props.navigation.navigate("ManageBooking");
  };
  // Customizable Area End
}
