
import React, { FunctionComponent, useState } from "react";
import {
    Typography,
    Box,
    Checkbox
} from "@mui/material";
import ReactSelect from "react-select";

interface MyProps {
    testID: string;
    showBorder?: boolean;
    showMargin?: boolean;
    showCheckbox?: boolean;
    title?: string;
    priceText?: string;
    type?: string;
    capacity?: string;
    item?:any;
    totalCol?: { total: string, no?: string }
    iconTemplate?: any;
    onChangeValue?: (value: boolean) => void;
    onChangeCheck?: (value: boolean) => void;
}

const customStyles = {
    menu: (provided: any) => ({
        ...provided,
        zIndex: 9999,
    }),
    control: (provided: any) => ({
        ...provided,
        padding: '4px 2px',
        borderRadius: '8px',
    }),
    input: (provided: any) => ({
        ...provided,
    }),
};

const BookingOptionSelector: FunctionComponent<MyProps> = (props) => {
    const [selectedVal, setSelectedVal] = useState(props.item?.count || '0');
    const [checked, setChecked] = useState(props.item?.is_selected || false);
    const options: any = ['1', '2', '3', '4', '5', '6', '7', '8', '9'].map(
        (val, index) => ({ label: props.type === 'horses' ? ((index === 1) ? val + ' horse' : val + ' horses') : ((index === 1) ? val + ' guest' : val + ' guests'), value: val })
    )
    const onCheckToggle = () => {
        props.onChangeCheck?.(!checked);
        setChecked(!checked);
    }

    return (
        <Box sx={{ ...style.containerWrapper, padding: '16px', marginBottom: props.showMargin ? '16px' : '0px', border: props.showBorder ? '1px solid #E2E8F0' : 'none', borderRadius: '8px' }}>
            <Box sx={{display:'flex',flexDirection:'row',width:'210px'}}>
                <Box sx={{ marginRight: '11px' }}>
                    {props.iconTemplate && props.iconTemplate}
                    {props.showCheckbox && <Checkbox sx={{
                        color: '#64748B',
                        border: '1px',
                        borderRadius: '6px',
                        '& .MuiSvgIcon-root': { fontSize: 32 },
                        '&.Mui-checked': {
                            color: '#06D6A0',
                        },
                    }}
                        checked={checked} onChange={() =>onCheckToggle()} />}
                </Box>
                <Box sx={{ alignContent: 'center' }}>
                    <Typography sx={style.mainText}>{props.title}</Typography>
                    <Typography sx={style.priceText}>{props.priceText}</Typography>
                    <Typography sx={style.subText}>{props.capacity}</Typography>
                </Box>
            </Box>

            <Box sx={{ width: '120px', alignContent: 'center' }}>
                <ReactSelect
                    isSearchable={false}
                    classNamePrefix="dropdown"
                    data-test-id={'horses-select'}
                    styles={customStyles}
                    placeholder='0'
                    value={{ label: selectedVal, value: selectedVal }}
                    options={options}
                    components={{ IndicatorSeparator: () => null }}
                    onChange={(e: any) => {
                        setSelectedVal(e.value);
                        props.onChangeValue?.(e.value);
                    }}
                />
            </Box>
            {props.totalCol && <Box sx={{ alignContent: 'center' }}>
                <Typography sx={style.mainText}>{props.totalCol.total}</Typography>
                {props.totalCol.no && <Typography sx={style.priceText}>{props.totalCol.no}</Typography>}
            </Box>}
        </Box>
    )
}


const style = {
    containerWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignContent: 'center',
    },
    mainText: {
        fontFamily: 'Spartan',
        fontWeight: 700,
        fontSize: '14px',
        lineheight: '22px',
        letterSpacing: '-2%',
        color: '#003249'
    },
    priceText: {
        fontFamily: 'Spartan',
        fontWeight: 500,
        fontSize: '14px',
        lineheight: '24px',
        color: '#003249'
    },
    subText: {
        fontFamily: 'Spartan',
        fontWeight: 400,
        fontSize: '12px',
        lineheight: '18px',
        color: '#94A3B8'
    }
}
export default BookingOptionSelector;


