import { Message } from '../../../framework/src/Message';
import MessageEnum, {
  getName,
} from '../../../framework/src/Messages/MessageEnum';
import { runEngine } from "../../../framework/src/RunEngine";

export const sendRunEngineNavigationEvent = (
  sendMessage: (from: string, message: Message) => void,
  screenName: string,
  blockName: string
) => {
  const navMessage = new Message(getName(MessageEnum.NavigationMessage));
  navMessage.addData(MessageEnum.NavigationTargetMessage, screenName);

  sendMessage(blockName, navMessage);
};

export const apiCall = async (data: {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: any;
  token?: string;
  type?: string;
  header?: any;
}) => {
  let { contentType, method, endPoint, body, token, header, type } = data;
  if (!header) {
    header = {
      token: token ? token : localStorage.getItem("token"),
    };
  }
  if (contentType) {
    header["content-type"] = contentType;
  }
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    endPoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    method
  );
  body
    ? type !== "formData"
      ? requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      : requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      )
    : null;
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return requestMessage.messageId;
};
