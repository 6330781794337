Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.GETApiMethod = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.POSTAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "KnowYourCustomerKycVerification";
exports.labelBodyText = "KnowYourCustomerKycVerification Body";
exports.getonfidoApi="https://api.onfido.com/v3/sdk_token";
exports.getSDKTokenApi = "bx_block_knowyourcustomerkycverification2/onfidos/sdk_token";
exports.createOnfido = "bx_block_knowyourcustomerkycverification2/onfidos";

exports.btnExampleTitle = "CLICK ME";
exports.kycPageTitle = "Verify your identity";
exports.kycPageSubtitle = "It should take a few minutes";
exports.stepTitle = "Use your device to photograph:";
exports.chooseDocumentBtn = "Choose document";
exports.thankyouText = "Thank you";
exports.thankyouSubtitle = "That’s all we need to start verifying your identity";
// Customizable Area End