import React from "react";

export const RightIcon = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.99953 6.71051C8.60953 7.10051 8.60953 7.73051 8.99953 8.12051L12.8795 12.0005L8.99953 15.8805C8.60953 16.2705 8.60953 16.9005 8.99953 17.2905C9.38953 17.6805 10.0195 17.6805 10.4095 17.2905L14.9995 12.7005C15.3895 12.3105 15.3895 11.6805 14.9995 11.2905L10.4095 6.70051C10.0295 6.32051 9.38953 6.32051 8.99953 6.71051Z" fill="#003249" />
  </svg>
);

export const HomeIcon = (
  <svg width="95" height="64" viewBox="0 0 95 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M94.6021 26.7147C94.5794 26.6631 94.5534 26.613 94.5244 26.5646C94.4434 26.4026 94.3307 26.2585 94.1929 26.141C94.16 26.1052 94.1253 26.0711 94.089 26.0388L70.5714 9.32858C70.5303 9.30563 70.488 9.28481 70.4448 9.26621C70.2993 9.1593 70.1249 9.09884 69.9444 9.09278C69.871 9.075 69.7962 9.0631 69.7209 9.05719H36.7823V1.92816C36.7823 1.53851 36.6275 1.16482 36.352 0.889297C36.0765 0.613772 35.7028 0.458984 35.3131 0.458984C34.9235 0.458984 34.5498 0.613772 34.2743 0.889297C33.9987 1.16482 33.844 1.53851 33.844 1.92816V9.05718H23.9828C23.6644 9.05722 23.3547 9.16071 23.1002 9.35208L0.877617 26.0623C0.844498 26.0871 0.82419 26.122 0.79374 26.1491C0.747258 26.1913 0.703623 26.2364 0.663122 26.2843C0.587853 26.3714 0.523284 26.4671 0.470794 26.5695C0.442098 26.62 0.416495 26.6722 0.394127 26.7258C0.335517 26.8783 0.303009 27.0396 0.297963 27.203C0.297427 27.2148 0.291016 27.2247 0.291016 27.2366V61.6293C0.291158 62.019 0.445991 62.3926 0.721485 62.6681C0.996979 62.9435 1.37059 63.0984 1.76019 63.0985H93.2385C93.6281 63.0984 94.0017 62.9436 94.2772 62.6681C94.5527 62.3926 94.7075 62.019 94.7077 61.6293V27.2366L94.7061 27.2292C94.7029 27.0529 94.6677 26.8785 94.6021 26.7147ZM46.0302 60.1602H45.7817C45.7756 59.9324 45.7154 59.7093 45.606 59.5094L28.0319 28.7058H46.0302V60.1602ZM23.1601 59.6904C23.1443 59.6288 23.1243 59.5683 23.1002 59.5094L5.52718 28.7058H23.1601V59.6904ZM26.0984 31.2443L42.5955 60.1602H26.0984V31.2443ZM66.5685 14.7348L69.7454 12.346L88.6353 25.7674H51.8972L66.5685 14.7348ZM24.4732 11.9956H65.323L47.549 25.3613L47.0089 25.7674H6.15706L24.4732 11.9956ZM3.2294 30.6058L20.0897 60.1602H3.22938L3.2294 30.6058ZM91.7693 60.1602H77.4632V44.433C77.4621 44.044 77.3069 43.6714 77.0315 43.3967C76.7561 43.122 76.383 42.9678 75.994 42.9678C75.605 42.9678 75.232 43.122 74.9566 43.3967C74.6811 43.6714 74.5259 44.044 74.5248 44.433V60.1602H48.9685V28.7058H91.7693V60.1602Z" fill="#94A3B8" stroke="#94A3B8" stroke-width="0.2" />
  </svg>
);

export const ApartmentIcon = (
  <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24.541 27.1255H6.45768C5.74432 27.1255 5.16602 26.5472 5.16602 25.8338V14.2088H3.87761C2.96358 14.2088 2.52861 13.0837 3.20494 12.4689L14.6304 2.08201C15.1231 1.63412 15.8756 1.63412 16.3683 2.08201L27.7938 12.4689C28.4701 13.0837 28.0351 14.2088 27.1211 14.2088H25.8327V25.8338C25.8327 26.5472 25.2544 27.1255 24.541 27.1255ZM7.74935 24.5422H23.2493V11.8289L15.4993 4.7834L7.74935 11.8289V24.5422ZM11.6243 12.9171H19.3743V20.6672H11.6243V12.9171ZM14.2077 15.5005V18.0838H16.791V15.5005H14.2077Z" fill="#003249" />
  </svg>
);

export const CampingIcon = (
  <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.58398 27.1256V23.2506C2.58398 22.9707 2.62704 22.7016 2.71315 22.4433C2.79926 22.185 2.92843 21.9374 3.10065 21.7006L13.8861 7.16936L12.4007 5.16727C12.293 5.01658 12.2177 4.8605 12.1746 4.69905C12.1316 4.53759 12.1208 4.37613 12.1423 4.21467C12.1638 4.05321 12.2177 3.89714 12.3038 3.74644C12.3899 3.59575 12.5083 3.46658 12.659 3.35894C12.9604 3.14366 13.2833 3.05755 13.6277 3.10061C13.9722 3.14366 14.252 3.31589 14.4673 3.61727L15.5007 5.00582L16.534 3.61727C16.7493 3.31589 17.0291 3.14366 17.3736 3.10061C17.718 3.05755 18.0409 3.14366 18.3423 3.35894C18.6437 3.57422 18.8159 3.85408 18.859 4.19852C18.902 4.54297 18.8159 4.86589 18.6007 5.16727L17.1152 7.16936L27.9007 21.7006C28.0729 21.9374 28.202 22.185 28.2881 22.4433C28.3743 22.7016 28.4173 22.9707 28.4173 23.2506V27.1256C28.4173 27.4916 28.2935 27.7983 28.046 28.0459C27.7984 28.2935 27.4916 28.4173 27.1257 28.4173H3.87565C3.50968 28.4173 3.20291 28.2935 2.95534 28.0459C2.70777 27.7983 2.58398 27.4916 2.58398 27.1256ZM15.5007 9.3329L5.16732 23.2506V25.8339H9.04232L14.435 18.2777C14.6934 17.9117 15.0486 17.7287 15.5007 17.7287C15.9527 17.7287 16.3079 17.9117 16.5663 18.2777L21.959 25.8339H25.834V23.2506L15.5007 9.3329ZM12.2069 25.8339H18.7944L15.5007 21.2485L12.2069 25.8339Z" fill="#003249" />
  </svg>
);

export const HookupIcon = (
  <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M28.7317 20.4521V12.9173L23.439 6.45898H0V22.6048H10.9634C10.9634 24.3883 12.4867 25.834 14.3658 25.834C16.245 25.834 17.7683 24.3883 17.7683 22.6048H31V20.4521H28.7317ZM4.5366 20.4521H2.26827V16.5053H4.53654L4.5366 20.4521ZM9.07319 20.4521H6.80486V12.9173H9.07313V20.4521H9.07319ZM14.3658 23.6812C13.7404 23.6812 13.2317 23.1984 13.2317 22.6048C13.2317 22.0113 13.7404 21.5284 14.3658 21.5284C14.9912 21.5284 15.5 22.0113 15.5 22.6048C15.5 23.1984 14.9912 23.6812 14.3658 23.6812ZM26.4634 20.4521H16.9012C16.2782 19.7917 15.3733 19.3757 14.3658 19.3757C13.3583 19.3757 12.4535 19.7916 11.8305 20.4521H11.3415V16.5053H15.8781V17.9405H22.6829V16.5053H26.4634V20.4521ZM18.1463 15.7877V13.6349H20.4146V15.7877H18.1463ZM26.4634 14.3525H22.6829V11.4822H15.8781V14.3525H11.3415V10.7646H4.5366V14.3525H2.26827V8.6118H22.3296L26.4634 13.656V14.3525Z" fill="#003249" />
  </svg>
);