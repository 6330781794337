// Customizable Area Start
import React from "react";
import { Box, Typography, InputBase, Button, IconButton } from "@mui/material";
import { styled } from '@mui/material/styles';
import { Formik, FormikErrors, FormikTouched } from "formik";
import * as Yup from 'yup';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

import ChangePasswordController, { S } from "./ChangePasswordController.web";
import Header from "../../../components/src/AppHeader.web";
import Loader from "../../../components/src/Loader.web";
import { infoIcon, rightIcon, wrongIcon } from "./assets";
// Customizable Area End

export default class ChangePassword extends ChangePasswordController {
  // Customizable Area Start
  validationSchema = Yup.object().shape({
    currentPassword: Yup.string()
      .required("Current password is required")
      .min(8, "Current password must be at least 8 characters long"),
    newPassword: Yup.string()
      .required("New password is required")
      .min(8, "New password must be at least 8 characters long")
      .max(20, "New password must not exceed 20 characters")
      .matches(/[A-Z]/, "New password must contain at least one uppercase letter")
      .matches(/\d/, "New password must contain at least one number")
      .matches(/[!@#$%^&*(),.?":{}|<>]/, "New password must contain at least one special character"),
    confirmPassword: Yup.string()
      .required("Confirm password is required")
      .oneOf([Yup.ref("newPassword")], "Passwords must match")
  })

  errorMsg = (touched: FormikTouched<{ [key: string]: string | boolean }>, errors: FormikErrors<{ [key: string]: string | boolean }>, value: string) => {
    return touched[value] && errors[value] &&
      <div className="error">{errors[value]}</div>
  }

  getIcons = (field: keyof S) => {
    return (
      <IconButton onClick={() => this.toggleVisibility(field)}>
        {this.state[field]
          ? <VisibilityOutlinedIcon sx={{ color: "#94A3B8" }} />
          : <VisibilityOffOutlinedIcon sx={{ color: "#94A3B8" }} />
        }
      </IconButton>
    );
  }

  renderRightSection = (newPassword: string) => {
    const hasUpperCase = /[A-Z]/.test(newPassword);
    const hasValidLength = newPassword.length >= 8 && newPassword.length <= 20;
    const hasNumber = /\d/.test(newPassword);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(newPassword);
    return (
      <Box className="right-section">
        {infoIcon}
        <Typography className="guide">Your password must contain</Typography>
        <Box className="guide-wrapper">
          <Typography className="guide-text">
            {hasUpperCase ? rightIcon : wrongIcon}
            <span>1 upper case letter</span>
          </Typography>
          <Typography className="guide-text">
            {hasValidLength ? rightIcon : wrongIcon}
            <span>Between 8 and 20 characters</span>
          </Typography>
          <Typography className="guide-text">
            {hasNumber ? rightIcon : wrongIcon}
            <span>1 or more numbers</span>
          </Typography>
          <Typography className="guide-text">
            {hasSpecialChar ? rightIcon : wrongIcon}
            <span>1 or more special characters</span>
          </Typography>
        </Box>
      </Box>
    );
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <Header isSidebar hideFilter navigation={this.props.navigation} active="ChangePassword">
        <Loader loading={this.state.loading} />
        <Wrapper>
          <Box className="heading">
            <Typography className="change-password">Change password</Typography>
          </Box>
          <Formik
            enableReinitialize
            initialValues={{
              flag: this.state.flag,
              currentPassword: "",
              newPassword: "",
              confirmPassword: ""
            }}
            validationSchema={this.validationSchema}
            onSubmit={(values) => this.changePassword(values)}
          >
            {({ values, touched, isValid, errors, dirty, setTouched, setFieldValue, handleSubmit }) => (
              <form
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit}
              >
                <Box display="flex" justifyContent="space-between">
                  <Box className="info-wrapper">
                    <Box className="left-section">
                      <Box>
                        <Box mb="10px">
                          <Typography className="label">Current password</Typography>
                          <CustomInputBase
                            placeholder="Current password"
                            data-test-id="current-password"
                            value={values.currentPassword}
                            endAdornment={this.getIcons("currentPassVisible")}
                            type={this.state.currentPassVisible ? "text" : "password"}
                            onChange={(e) => {
                              this.clearCurrentPassErr();
                              setTouched({...touched, currentPassword: true });
                              setFieldValue("currentPassword", e.target.value);
                            }}
                          />
                          {this.errorMsg(touched, errors, "currentPassword")}
                          {this.state.currentPassErr && <div className="error">{this.state.currentPassErr}</div>}
                          <Box display="flex" justifyContent="end">
                            <Typography className="link" onClick={this.forgotPassword} data-test-id="link">Forgot password?</Typography>
                          </Box>
                        </Box>
                        <Box mb="20px">
                          <Typography className="label">New password</Typography>
                          <CustomInputBase
                            placeholder="New password"
                            data-test-id="new-password"
                            value={values.newPassword}
                            endAdornment={this.getIcons("newPassVisible")}
                            type={this.state.newPassVisible ? "text" : "password"}
                            onChange={(e) => {
                              this.clearNewPassErr();
                              setTouched({ ...touched, newPassword: true });
                              setFieldValue("newPassword", e.target.value);
                            }}
                          />
                          {this.errorMsg(touched, errors, "newPassword")}
                          {this.state.newPassErr && <div className="error">{this.state.newPassErr}</div>}
                        </Box>
                        <Box mb="20px">
                          <Typography className="label">Confirm password</Typography>
                          <CustomInputBase
                            placeholder="Confirm password"
                            data-test-id="confirm-password"
                            value={values.confirmPassword}
                            endAdornment={this.getIcons("confirmPassVisible")}
                            type={this.state.confirmPassVisible ? "text" : "password"}
                            onChange={(e) => {
                              setTouched({ ...touched, confirmPassword: true });
                              setFieldValue("confirmPassword", e.target.value)
                            }}
                          />
                          {this.errorMsg(touched, errors, "confirmPassword")}
                        </Box>
                        <BlackButton disabled={!(isValid && dirty)} type="submit" sx={{ width: "400px" }}>Change password</BlackButton>
                      </Box>
                    </Box>
                  </Box>
                  {this.renderRightSection(values.newPassword)}
                </Box>
              </form>
            )}
          </Formik>
        </Wrapper>
      </Header>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const Wrapper = styled(Box)({
  height: "100%",
  padding: "30px 20px",
  paddingBottom: "105px",
  "& .heading": {
    display: "flex",
    letterSpacing: "-0.5px",
    marginBottom: "30px",
    justifyContent: "space-between",
    "& .change-password": {
      fontFamily: "Spartan",
      fontWeight: "700",
      fontSize: "17px",
      color: "#003249",
      textTransform: "uppercase",
    }
  },
  "& .info-wrapper": {
    flex: "1",
    padding: "0px 10px",
    gap: "20px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    "& .left-section": {
      display: "flex",
      gap: "20px",
      alignItems: "flex-start",
      justifyContent: "space-between",
      fontFamily: "Spartan",
      width: "400px",
      "& .label": {
        letterSpacing: "-0.5px",
        marginBottom: "10px",
        fontFamily: "Spartan",
        fontSize: "16px",
        fontWeight: "700",
        color: "#64748B"
      },
      "& .link": {
        marginTop: "4px",
        cursor: "pointer",
        fontFamily: "Spartan",
        fontSize: "12px",
        fontWeight: "400",
        color: "#059669"
      }
    }
  },
  "& .right-section": {
    width: "285px",
    height: "fit-content",
    gap: "15px",
    display: "flex",
    flexDirection: "column",
    borderRadius: "8px",
    backgroundColor: "#F8FAFC",
    padding: "20px",
    border: "1.5px solid #E2E8F0",
    "& .guide": {
      fontFamily: "Spartan",
      fontSize: "16px",
      fontWeight: "500",
      color: "#000",
      letterSpacing: "-0.5px",
    },
    "& .guide-wrapper": {
      display: "flex",
      gap: "5px",
      flexDirection: "column",
      "& .guide-text": {
        letterSpacing: "-0.3px",
        fontWeight: "500",
        display: "flex",
        fontSize: "14px",
        fontFamily: "Spartan",
        color: "#475569",
        "& svg": {
          marginTop: "1px"
        },
        "& span": {
          marginLeft: "7px"
        }
      }
    }
  },
});

const BlackButton = styled(Button)({
  marginTop: "40px",
  width: "100%",
  padding: "8px 10px",
  fontFamily: "Spartan",
  textTransform: "none",
  color: "#fff",
  fontSize: "16px",
  borderRadius: "8px",
  backgroundColor: "#003249",
  whiteSpace: "nowrap",
  fontWeight: "700",
  border: "1.5px solid #003249",
  "&:hover": {
    color: "#fff",
    backgroundColor: "#003249",
  },
  "&.Mui-disabled": {
    color: "#fff",
    backgroundColor: "#94A3B8",
    border: "1.5px solid #94A3B8",
  }
});

const CustomInputBase = styled(InputBase)({
  width: "100%",
  fontWeight: "500",
  borderRadius: "8px",
  fontSize: "14px",
  padding: "6px 8px",
  fontFamily: "Spartan",
  color: "#003249",
  border: "1px solid #CBD5E1",
  "& input": {
    height: "auto"
  }
});
// Customizable Area End