// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { apiCall } from "../../../../components/src/NavigationRouteWrapper/helpers";
import { toast } from "react-toastify";
import { setStorageData } from "../../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

export interface IFile {
  url: string;
  file_name: string;
}

export interface IAccommodation {
  name: string;
  guest_capacity: number;
  price_per_night: number;
  selected_item_price: number;
  selected_guest_capacity: number;
  selected_spot_capacity: number;
  hookups_type: string;
}

export interface ICampsite {
  selected_guest_capacity: number;
}

export interface IHookup {
  hookups_type: string;
  selected_spot_capacity: number;
}

export interface IAmenities {
  type: string;
  selected_horse: number;
  selected_item_price: number;
}

export interface IHorseAcc {
  type: string;
  selected_stall: number;
  selected_item_price: number;
}

interface S {
  // Customizable Area Start
  loading: boolean;
  isLoading: boolean;
  data: any;
  role: string | null;
  showPopup:boolean,
  showCancelConfirmPopup:boolean;
  showCRApprovedPopup:boolean;
  additionalMsg:string,
  action:string,
  // Customizable Area End
}

interface SS {
  id: string;
}

export default class BookingDetailController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getBookingInfoApiCallId: string = "";
  cancelBookingApiCallId: string = "";
  updateBookingStatusApiCallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
    ];

    this.state = {
      loading: false,
      isLoading: false,
      data: null,
      showPopup: false,
      showCancelConfirmPopup: false,
      showCRApprovedPopup: false,
      role: localStorage.getItem("role"),
      additionalMsg:'',
      action:'',
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getBookingInfoApiCallId) {
        if (responseJson.data) {
          this.setState({ data: responseJson.data });
          if (responseJson.data.attributes.status === 'Payment required') {
            this.toggleCRApprovedPopup()
          }
        }
        this.setState({ loading: false });
      } else if (apiRequestCallId === this.cancelBookingApiCallId) {
        if (responseJson.message === "Booking cancelled successfully") {
          toast.success(responseJson.message);
          this.props.navigation.navigate("ManageBooking");
        } else if (responseJson.error) {
          toast.error(responseJson.error);
        }
        this.setState({ isLoading: false });
      }else if(apiRequestCallId===this.updateBookingStatusApiCallId){
        this.handleUpdateStatusResponse(responseJson);
        this.setState({ loading: false,showPopup:false });
      }
    }
  }

  async componentDidMount() {
    const id = this.props.navigation.getParam('bookingId');
    if (id) {
      this.getBookingInfo(id);
    }
  }

  componentDidUpdate(prevProps: Props) {
    const id = this.props.navigation.getParam('bookingId');
    if (prevProps.navigation.getParam('bookingId') !== id) {
      this.getBookingInfo(id);
    }
  }

  onBack = () => {
    this.props.navigation.goBack();
  }

  redirectToListing = () => {
    this.props.navigation.navigate("ProductDescription", { path: { productId: this.state.data.attributes.listing.data.id } });
  }

  guestAccommodationsInfo = (isCR: boolean = false) => {
    const { booking_apartment, booking_campsite, booking_hookups } = this.state.data.attributes;
    const { request_change_apartment = [], request_change_campsite = [], request_change_hookups = [] } = this.state.data.attributes;

    const formatAccommodation = (items: IAccommodation[], key: "name" | "hookups_type", capacityKey: "selected_guest_capacity" | "selected_spot_capacity") =>
      items?.map(item => `${item[key]}: ${item[capacityKey]} guest${item[capacityKey] > 1 ? "s" : ""}`).join(" • ") || "";

    const apartmentInfo = formatAccommodation(isCR ? request_change_apartment : booking_apartment || [], "name", "selected_guest_capacity");
    const campsiteInfo = formatAccommodation(isCR ? request_change_campsite : booking_campsite || [], "name", "selected_guest_capacity");
    const hookupInfo = formatAccommodation(isCR ? request_change_hookups : booking_hookups || [], "hookups_type", "selected_spot_capacity");

    return [apartmentInfo, campsiteInfo, hookupInfo].filter(Boolean).join(" • ");
  }

  horseStablingInfo = (isCR: boolean = false) => {
    const { booking_horse_accommodations, request_change_horse_accommodations = [] } = this.state.data.attributes;
    let str = '';
    let data = isCR ? request_change_horse_accommodations : booking_horse_accommodations;
    if (data?.length > 0) {
      data.forEach((item: IHorseAcc, index: number) => {
        str += (index > 0 ? ' • ' : '') + item.type + ': ' + item.selected_stall + (item.selected_stall > 1 ? ' horses' : ' horse');
      });
    }
    return str;
  }

  amenitiesInfo = (isCR:boolean=false) => {
    const { booking_amenities, request_change_amenities = [] } = this.state.data.attributes;
    let str = '';
    let data = isCR ? request_change_amenities : booking_amenities;
    if (data?.length > 0) {
      data.forEach((item: IAmenities, index: number) => {
        str += (index > 0 ? ' • ' : '') + item.type + ': ' + item.selected_horse + (item.selected_horse > 1 ? ' horses' : ' horse');
      });
    }
    return str;
  }

  changeRequest = () => {
    const productId = this.props.navigation.getParam('bookingId');
    localStorage.setItem("isBookingReqChange", 'YES');
    this.props.navigation.navigate("GuestBookingForm", { path: { productId } });
  }

  cancelBooking = async () => {
    this.setState({ isLoading: true });
    const id = this.props.navigation.getParam('bookingId');
    const header = {
      token: localStorage.getItem("token")
    }
    this.cancelBookingApiCallId = await apiCall({
      header,
      method: "GET",
      endPoint: `/bx_block_request_management/bookings/cancel_booking?booking_id=${id}`,
    });
  }

  cancelRequest=()=>{}

  rejectBooking = () => {
    this.setState({ showPopup: true, action: 'Reject Changes' })
  }

  acceptBooking = () => {
    this.setState({ showPopup: true, action: 'Accept Changes' })
  }

  closePopup = () => {
    this.setState({ showPopup: false })
  }

  toggleCancelConfirmPopup = () => {
    this.setState({ showCancelConfirmPopup: !this.state.showCancelConfirmPopup })
  }

  toggleCRApprovedPopup = () => {
    this.setState({ showCRApprovedPopup: !this.state.showCRApprovedPopup })
  }
  calculateDaysBetweenDates = (date1:Date, date2:Date) => {
    const timeZoneOffset = date1.getTimezoneOffset() * 60000;
    const ms1 = date1.getTime() - timeZoneOffset;
    const ms2 = date2.getTime() - timeZoneOffset;
    const differenceInMs = ms2 - ms1;
    const differenceInDays = differenceInMs / (1000 * 60 * 60 * 24);
    return Math.abs(Math.round(differenceInDays)); 
  }

  cancelBookingInfo = () => {
    const startDate = new Date(this.state.data.attributes.start_date);
    const today = new Date();
    const diff = this.calculateDaysBetweenDates(startDate, today);
    console.log('$$diff', diff);
    let guest_a = `Are you sure you want to cancel this booking? Cancelations ${diff} days previous to the booking date have 20% charge, 24 hours cancelations are not refundable. `
    let guest_b = 'Are you sure you want to cancel your booking? Cancelling now will provide a 50% refund, minus the transaction fee.';
    let guest_c = 'Are you sure you want to cancel your booking? Cancellations made less than 2 days before the start date are non-refundable.'
    let host_a = 'Are you sure you want to cancel booking? If you cancel the reservation less than 14 days before the check-in date, the guest will receive a full refund, and a $20 administrative fee will be charged to your account.';
    let host_b = 'Are you sure you want to cancel booking? If the cancellation is made 14 days or more prior to the check-in date, the guest will receive a full refund.';
    if (diff > 14) {
      return this.isGuest() ? guest_a : host_b;
    } else if (diff > 2 && diff < 14) {
      return this.isGuest() ? guest_b : host_a;
    } else {
      return this.isGuest() ? guest_c : host_a;
    }
  }

  updateBookingStatus = async() => {
    this.setState({ loading: true });
    const header = {
      token: localStorage.getItem("token")
    }
    const status = this.state.action == 'Accept Changes' ? 'confirmed' : 'cancelled';
    this.updateBookingStatusApiCallId = await apiCall({
      header,
      method: "GET",
      endPoint: `/bx_block_request_management/bookings/${this.state.data.id}/change_booking_status?status=${status}&additional_message=${this.state.additionalMsg}`,
    });
  }

  handleUpdateStatusResponse = (responseJson: any) => {
    this.setState({ data: responseJson.data })
  }
  

  changeAmountValues = () => {
    const data = this.state.data.attributes;
    const priceKey=this.getKeyBasedOnRole()
    const diff = data.total_price - data[priceKey].total;
    return diff <= 0 ? { type:'DUE AMOUNT', val: Math.abs(diff) } : { type: 'REFUND AMOUNT', val:  Math.abs(diff) }
  }
  isGuest=()=>this.state.role==='guest';

  isChangeRequested = () => this.state.data.attributes.status === 'Change requested';
  isChangeRequestedAndGuest =()=> this.isChangeRequested() && this.state.role === 'guest';
  isChangeRequestedAndHost = () => !this.isGuest() && this.isChangeRequested();
  getKeyBasedOnRole = () => this.isChangeRequestedAndHost() ? 'request_change_price_breakdown_host' : 'request_change_price_breakdown';
  getBreakdownKey = () => this.isChangeRequested() ? 'request_change_price_breakdown' : 'booking_price_breakdown';

  isPaymentRequiredForGuest = () => this.isGuest() && ['Payment required','Pending'].includes(this.state.data.attributes.status);
  
  isConfirmedForGuest = () => this.isGuest() && ['Confirmed'].includes(this.state.data.attributes.status);
  
  isCanceled = () => ['Cancelled'].includes(this.state.data.attributes.status);

  isCRRejectedForGuest=()=> this.isGuest() && this.state.data.attributes.status === 'Request rejected';
  isPaymentRequired = () => this.state.data.attributes.status === 'Payment required'

  nightsText = (count:any) => count > 1 ? "nights" : "night"; 
  horsesText = (count:any) => count > 1 ? "Horses" : "Horse";
  getAmountColor=()=>this.changeAmountValues().type === 'REFUND AMOUNT' ? '#059669' : '#DC2626';


  getBookingInfo = async (id: string) => {
    this.setState({ loading: true });
    const header = {
      token: localStorage.getItem("token")
    }
    this.getBookingInfoApiCallId = await apiCall({
      header,
      method: "GET",
      endPoint: `/bx_block_request_management/bookings/${id}`,
    });
  }

  navigateToInbox = (userInfo: any) => {
    const { listing } = this.state.data.attributes;
    setStorageData("chatDetails",
      JSON.stringify({ accountId: userInfo.id, id: listing.data.attributes.id, title: listing.data.attributes.title })
    );
    const routeMsg = new Message(getName(MessageEnum.NavigationMessage));
    routeMsg.addData(getName(MessageEnum.NavigationTargetMessage), configJSON.chatScreen);
    routeMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(routeMsg);
  };
  // Customizable Area End
}