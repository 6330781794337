// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall } from "../../../components/src/NavigationRouteWrapper/helpers";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

interface IValues {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  photo: string | File;
}

interface S {
  // Customizable Area Start
  loading: boolean;
  isLoading: boolean;
  isEdit: boolean;
  data: any;
  openPopup: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  photo: any;
  phoneErr: string;
  emailErr: string;
  // Customizable Area End
}

interface SS {
  id: string;
}

export default class PersonalInfoController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getAccountInfoApiCallId: string = "";
  deleteAccountApiCallId: string = "";
  postAccountApiCallId: string = "";
  resendMailApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      loading: false,
      isLoading: false,
      isEdit: false,
      data: null,
      openPopup: "",
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      photo: "",
      phoneErr: "",
      emailErr: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getAccountInfoApiCallId) {
        if (responseJson.data?.attributes) {
          const res = responseJson.data.attributes;
          this.setState({
            email: res.email,
            firstName: res.first_name,
            lastName: res.last_name,
            phoneNumber: res.full_phone_number,
            photo: res.profile_photo?.url ?? null
          });
        }
        this.setState({ loading: false });
      } else if (apiRequestCallId === this.resendMailApiCallId) {
        if (responseJson.message) {
          toast.success(responseJson.message);
          this.setState({ openPopup: "" });
        }
        this.setState({ isLoading: false });
      } else {
        this.handleApiResponse(apiRequestCallId, responseJson);
      }
    }
  }

  handleApiResponse = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.postAccountApiCallId) {
      if (responseJson.data?.attributes) {
        const res = responseJson.data.attributes;
        const isNew = this.state.email !== res.new_email;
        !isNew && toast.success("Account updated successfully!!");
        localStorage.setItem("photo", res.profile_photo?.url);
        localStorage.setItem("name", res.full_name);
        this.setState({
          isEdit: false,
          email: res.email,
          firstName: res.first_name,
          lastName: res.last_name,
          phoneNumber: res.full_phone_number,
          photo: res.profile_photo?.url ?? null,
          openPopup: isNew ? "resend" : ""
        });
      } else if (Array.isArray(responseJson.errors)) {
        const err = responseJson.errors[0]?.Account || {};
        this.setState({
          emailErr: err.email || "",
          phoneErr: err.full_phone_number || "",
        });
      }
      this.setState({ isLoading: false });
    } else {
      this.handledDeleteApiResponse(apiRequestCallId, responseJson);
    }
  }

  handledDeleteApiResponse = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.deleteAccountApiCallId) {
      if (Array.isArray(responseJson.message)) {
        const message = responseJson.message[0]?.Account;
        message && toast.success(message);
        localStorage.clear();
        this.props.navigation.navigate("EmailAccountLoginBlock");
      } else if (Array.isArray(responseJson.errors)) {
        const error = responseJson.errors[0]?.Account;
        error && toast.success(error);
      }
      this.setState({ isLoading: false });
    }
  }

  async componentDidMount() {
    this.getAccountDetails();
  }

  onEdit = () => {
    this.setState({ isEdit: true });
  }

  onGoBack = () => {
    this.setState({ isEdit: false, emailErr: "", phoneErr: "" });
  }

  onDelete = () => {
    this.setState({ openPopup: "delete" });
  }

  onClose = () => {
    this.setState({ openPopup: "" });
  }

  saveAccountDetails = async (values: IValues) => {
    this.setState({ isLoading: true });
    const header = {
      token: localStorage.getItem("token")
    }
    const formData = new FormData();
    formData.append("first_name", values.firstName.trim());
    formData.append("last_name", values.lastName.trim());
    formData.append("new_email", values.email.trim());
    formData.append("full_phone_number", values.phoneNumber);
    if (values.photo) {
      typeof values.photo !== "string" && formData.append("profile_photo", values.photo);
    }

    this.postAccountApiCallId = await apiCall({
      header,
      body: formData,
      method: "PUT",
      type: "formData",
      endPoint: `/account_block/accounts/update_profile`,
    });
  }

  resendMail = async () => {
    this.setState({ isLoading: true });
    const header = {
      token: localStorage.getItem("token")
    }
    this.resendMailApiCallId = await apiCall({
      header,
      method: "GET",
      endPoint: `/account_block/accounts/resend_email_update`,
    });
  }

  onDeleteAccount = async () => {
    this.setState({ isLoading: true });
    const header = {
      token: localStorage.getItem("token")
    }
    this.deleteAccountApiCallId = await apiCall({
      header,
      method: "DELETE",
      endPoint: `/account_block/accounts`,
    });
  }

  getAccountDetails = async () => {
    this.setState({ loading: true });
    const header = {
      token: localStorage.getItem("token")
    }
    this.getAccountInfoApiCallId = await apiCall({
      header,
      method: "GET",
      endPoint: `/account_block/accounts/user_profile`,
    });
  }
  // Customizable Area End
}