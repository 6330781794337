// Customizable Area Start
import { createRef } from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { apiCall } from "../../../../components/src/NavigationRouteWrapper/helpers";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

export interface IFile {
  file_url: string;
  file_name: string;
}

interface S {
  // Customizable Area Start
  loading: boolean;
  isLoading: boolean;
  openDialog: string;
  active: string;
  listingData: any[];
  currentPage: number;
  perPage: number;
  currentId: string;
  pagination: {
    total_count: number;
    current_page: number;
    total_pages: number;
  };
  // Customizable Area End
}

interface SS {
  id: string;
}

const STATUS = ["active", "saved"];

export default class ListingsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getListingApiCallId: string = "";
  deleteListingApiCallId: string = "";
  deactivateListingApiCallId: string = "";
  menuRef: HTMLUListElement | null = null;
  scrollRef: React.RefObject<HTMLDivElement> = createRef();
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    const hash = window.location.hash.replace("#", "");
    const active = STATUS.includes(hash) ? hash : "active";
    this.state = {
      loading: false,
      isLoading: false,
      openDialog: "",
      active: active,
      listingData: [],
      currentPage: 1,
      perPage: 6,
      currentId: "",
      pagination: {
        total_count: 1,
        current_page: 1,
        total_pages: 1,
      },
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getListingApiCallId) {
        if (Array.isArray(responseJson.data)) {
          this.setState({
            listingData: responseJson.data,
            pagination: responseJson.pagination,
            currentPage: responseJson.pagination.current_page ?? this.state.currentPage,
          });
        } else if (Array.isArray(responseJson.error)) {
          const msg = responseJson.error[0]?.Listing?.Listing;
          if (msg === "No listings found, Please create a listing") {
            this.setState({ currentPage: 0, listingData: [] });
          }
        }
        this.setState({ loading: false, isLoading: false });
      } else {
        this.handleResponse(apiRequestCallId, responseJson);
      }
    }
  }

  handleResponse = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.deleteListingApiCallId) {
      if (Array.isArray(responseJson.message)) {
        const msg = responseJson.message[0]?.Listing?.Listing;
        if (msg === "Listing deleted successfully.") {
          toast.success("List deleted successfully!!");
          const pageToFetch = this.getPageNumber();
          this.setState({ isLoading: true }, () => {
            this.getListingData(pageToFetch);
          });
        }
        this.setState({ openDialog: "" });
      }
    } else if (apiRequestCallId === this.deactivateListingApiCallId) {
      if (responseJson.message === "Listing successfully reviewed.") {
        toast.success("List deactivated successfully!!");
        const pageToFetch = this.getPageNumber();
        this.setState({ isLoading: true }, () => {
          this.getListingData(pageToFetch);
        });
      }
      this.setState({ openDialog: "" });
    }
  }

  handleHashChange = () => {
    const hash = window.location.hash.replace("#", "");
    const active = STATUS.includes(hash) ? hash : "active";

    if (this.state.active !== active) {
      this.setState({ active }, () => {
        this.getListingData(1);
      });
    }
  }

  getPageNumber = () => {
    const pagination = this.state.pagination;
    const remainingItems = (pagination.total_count - 1) % this.state.perPage;
    const isEmpty = pagination.current_page === pagination.total_pages && remainingItems === 0;
    const pageToFetch = pagination.current_page === pagination.total_pages && isEmpty
      ? Math.max(pagination.current_page - 1, 1)
      : pagination.current_page;
    return pageToFetch;
  }

  async componentDidMount() {
    this.getListingData(1);
    window.addEventListener("hashchange", this.handleHashChange);
  }

  componentDidUpdate(prevProps: Props, prevState: S) {
    if (prevState.loading !== this.state.loading && !this.state.isLoading) {
      this.scrollToTop();
    }
  }

  async componentWillUnmount() {
    window.removeEventListener("hashchange", this.handleHashChange);
  }

  scrollToTop = () => {
    if (this.scrollRef.current) {
      this.scrollRef.current.scrollTo({ top: 0 });
    }
  }

  onChangeTab = (active: string) => {
    window.location.hash = active;
    this.setState({ active }, () => {
      this.getListingData(1);
      this.scrollToTop();
    });
  }

  onChangePage = (currentPage: number) => {
    if (currentPage === this.state.currentPage) return;
    this.setState({ isLoading: true }, () => {
      this.getListingData(currentPage);
    });
  }

  handleClose = () => {
    this.setState({ openDialog: "", currentId: "" });
  }

  redirectToCreateList = () => {
    localStorage.removeItem("listingId");
    this.props.navigation.navigate("CreateListing");
  }

  redirectToEditPage = () => {
    localStorage.setItem("listingId", this.state.currentId);
    this.props.navigation.navigate("CreateListing");
  }

  onOpenMenu = (currentId: string) => {
    this.setState({ currentId });
  }

  handleOptionClick = (openDialog: string, currentId: string) => {
    this.setState({ openDialog, currentId });
  }

  handlePreviewClick = (id: string) => {
    this.props.navigation.navigate("PreviewListing", { path: { id } });
  }

  getListingData = async (currentPage: number) => {
    this.setState({ currentPage, loading: true });
    const header = {
      token: localStorage.getItem("token")
    }
    let { perPage, active } = this.state;
    const params = new URLSearchParams();
    if (active === "saved") params.append("type", "review");
    if (currentPage) params.append("page", currentPage.toString());
    if (perPage) params.append("per_page", perPage.toString());

    this.getListingApiCallId = await apiCall({
      header,
      method: "GET",
      endPoint: `/bx_block_bulk_uploading/listings?${params.toString()}`,
    });
  }

  deactivateListing = async () => {
    this.setState({ isLoading: true });
    const header = {
      token: localStorage.getItem("token")
    }

    this.deactivateListingApiCallId = await apiCall({
      header,
      method: "GET",
      endPoint: `bx_block_bulk_uploading/listings/${this.state.currentId}/review_listing`,
    });
  }

  deleteListing = async () => {
    this.setState({ isLoading: true });
    const header = {
      token: localStorage.getItem("token")
    }

    this.deleteListingApiCallId = await apiCall({
      header,
      method: "DELETE",
      endPoint: `/bx_block_bulk_uploading/listings/${this.state.currentId}`,
    });
  }
  // Customizable Area End
}