// @ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import * as yuppp from "yup";
// Customizable Area Start
import { Platform } from "react-native";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { Onfido } from "onfido-sdk-ui";
import { apiCall } from "../../../components/src/NavigationRouteWrapper/helpers";
// Customizable Area End
export const configJSON = require("./config");
export interface Props {
  navigation: any;

  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  firstNameDefault:string,
  lastNameDefault: string,
  middleNameDefault:string,
  emailStateDefault:string,
  nationalityDefault:string,
  dateOfBirth:string,
  phoneNumber:string,
  stepNumber: number;
  isKycCompleted: boolean;
  selectedCountry: string;
  // Customizable Area End
}
interface SS {
  // Customizable Area Start

  // Customizable Area End
}
export default class KnowYourCustomerKycVerificationController extends BlockComponent<
  Props,
  S,
  SS
> {
  getVerifyIndentitycallID: string = '';
  getCreateaccountCallID: string = '';
  getreportCallID: string = '';
  // Customizable Area Start
  getSDKTokenApiCallId: string = "";
  createOnfidoApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
      // Customizable Area Start
      // Customizable Area End
    ];
    this.state = {
      // Customizable Area Start
      firstNameDefault: "",
      lastNameDefault: "",
      middleNameDefault: "",
      emailStateDefault: "",
      nationalityDefault: "",
      dateOfBirth: "",
      phoneNumber: "",
      stepNumber: 0,
      isKycCompleted: false,
      selectedCountry: "US",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }
  async receive(from: string, message: Message)//istanbul ignore next
  {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id)//istanbul ignore next
    {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId != null && apiRequestCallId === this.getCreateaccountCallID && !responseJson.error)//istanbul ignore next 
      {
        await setStorageData("AppicantId", responseJson.id)
        await setStorageData("ReportID", responseJson.id)
        this.onfidouserdeta()
      }
      if (apiRequestCallId != null && apiRequestCallId === this.getreportCallID && responseJson)//istanbul ignore next
      {
        this.props.navigation.navigate("KnowYourCustomerKycVerificationAccount")
      }
      if (apiRequestCallId != null && apiRequestCallId === this.getSDKTokenApiCallId && responseJson) {
        this.setState({ stepNumber: 1 });
        Onfido.init({
          token: responseJson.token,
          containerId: "onfido-mount",
          steps: [
            {
              type: "document",
              options: {
                country: this.state.selectedCountry,
                forceCrossDevice: false,
                documentTypes: {
                  passport: true,
                  driving_licence: true,
                  national_identity_card: true,
                  residence_permit: true,
                },
              },
            },
            {
              type: "face",
              options: {
                requestedVariant: "standard",
              },
            },
            "complete",
          ],
          _crossDeviceLinkMethods: ['sms', 'qr_code', 'copy_link'],
          smsNumberCountryCode: "US",
          theme: {
            name: "light",
            config: {
              fontFamilyTitle: "Spartan, sans-serif",
              fontFamilySubtitle: "Spartan, sans-serif",
              fontFamilyBody: "Spartan, sans-serif",
              fontSizeTitle: "24px",
              fontSizeSubtitle: "18px",
              fontSizeBody: "16px",
              fontWeightTitle: 700,
              fontWeightSubitle: 400,
              fontWeightBody: 400,
              colorContentTitle: "#003249",
              colorContentSubtitle: "#003249",
              colorContentBody: "#003249",
              borderRadiusButton: "16px",
              colorBackgroundButtonPrimary: "#003249",
              colorBackgroundButtonPrimaryHover: "#003249",
              colorTextButtonPrimary: "#fff",
            },
          },
          onComplete: (onfidoResponse) => {
            this.setState({ isKycCompleted: true });
            this.createOnfido(onfidoResponse);
          },
        });
      }
      if (apiRequestCallId != null && apiRequestCallId === this.createOnfidoApiCallId && responseJson) {
        // Handle API Response
      }
    }
  }

  startSDK(responseJson: string) {
    Onfido?.start({
      sdkToken: responseJson,
      flowSteps: {
        welcome: true,
        userConsent: true,
        captureDocument: {
        },
        enableNFC: false
      }
    })
      ?.then((response: string) => { this.onreportApi() })
      .catch((error: string) => { })
  }

  signupValidationSchema = () => {
    return yuppp.object().shape({
      firstNameDefault: yuppp.string().required("Please, Enter your firstNameDefault!"),
      lastNameDefault: yuppp.string().required("Please, Enter your lastNameDefault!"),
      middleNameDefault: yuppp.string().required("Please, Enter your middleNameDefault!"),
      emailStateDefault: yuppp
        .string()
        .required("Please, provide your email")
        .email("Please enter valid email"),
      phoneNumber: yuppp
        .string()
        .required("Please, provide your mobile number"),
      nationalityDefault: yuppp.string().required("Please, Enter your nationalityDefault!"),
      dateOfBirth: yuppp.string().required("Please, Enter your Date of Birth!"),
    })
  }
  onNavigationGobackscreen = () => {
    
    this.props.navigation.navigate("KnowYourCustomerKycVerification")
  }

  createAccount = async (values: { firstNameDefault: string; lastNameDefault: string; middleNameDefault: string; emailStateDefault: string; nationalityDefault: string; dateOfBirth: string; phoneNumber: string; }) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const codedata = {
      first_name: values.firstNameDefault,
      last_name: values.lastNameDefault,
      middle_name: values.middleNameDefault,
      date_of_birth: values.dateOfBirth,
      nationalityDefault: values.nationalityDefault,
      phone_number: values.phoneNumber,
      email: values.emailStateDefault,
    }
    const httpBody = {
      data: codedata,
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage))
    this.getCreateaccountCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/applicant_create"    
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POSTAPiMethod
    )
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }

  onfidouserdeta = async () => {
    const UserDataValidation = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.getVerifyIndentitycallID = UserDataValidation.messageId;
    UserDataValidation.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getonfidoApi
    )
    const headers = {
      "Authorization":"Token token=api_sandbox.VfzFDCaYox9.rTHqR4jClR6-IHzdAQQUML5bd7PlNZ_Q"
    }
    UserDataValidation.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    )
    UserDataValidation.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POSTAPiMethod
    )
    let APPID = Platform.OS == "ios" ? "DatingAppDemo" : "com.DatingAppDemo";
    let formdata = new FormData();
    const confidoId = await getStorageData('AppicantId');
    formdata.append("applicant_id", confidoId)
    formdata.append("application_id", APPID)
    UserDataValidation.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    )
    runEngine.sendMessage(UserDataValidation.id, UserDataValidation);
  }

  onreportApi = async () => {
    const confidoId = await getStorageData('AppicantId');
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.getreportCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/retrieve_onfido_report?applicant_id=${confidoId}`
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GETApiMethod
    )
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }
  // web events
  // Customizable Area Start
  getSDKToken = async () => {
    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token,
    };

    this.getSDKTokenApiCallId = await apiCall({
      header,
      method: configJSON.GETApiMethod,
      endPoint: configJSON.getSDKTokenApi,
    });
  };

  createOnfido = async (onfidoResponse) => {
    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token,
    };
    const body = {
      "document_type": onfidoResponse.document_front.type,
      "country_name": this.state.selectedCountry,
      "document_id": onfidoResponse.document_front.id,
      "document_status": "uploaded",
      "selfie_id": onfidoResponse.face.id,
    };

    this.createOnfidoApiCallId = await apiCall({
      header,
      method: configJSON.POSTAPiMethod,
      endPoint: configJSON.createOnfido,
      body
    });
  };
  // Customizable Area End
}


