// Customizable Area Start
import React from "react";
import { Box, Typography, Button, CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import PaymentInfoController, {
  configJSON,
  Props,
} from "./PaymentInfoController.web";
import Header from "../../../../components/src/AppHeader.web";
import Loader from "../../../../components/src/Loader.web";
// Customizable Area End

export default class PaymentInfo extends PaymentInfoController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderContent = () => {
    return (
      <Wrapper>
        <Loader loading={this.state.isLoading} />
        {this.state.loading ? (
          <Box className="loading">
            <CircularProgress sx={{ color: "#000" }} />
          </Box>
        ) : (
          <>
            {!this.props.isHeader && (
              <Box className="heading">
                <Typography className={"payment"}>
                  {configJSON.PaymentDetails}
                </Typography>
              </Box>
            )}
            <Box
              className={
                !this.props.isHeader ? "main-container" : "main-container2"
              }
            >
              <Box className={!this.props.isHeader ? "main-box" : "main-box2"}>
                {this.props.isHeader && (
                  <Box className="heading">
                    <Typography className="main-title">
                      {configJSON.PaymentDetails}
                    </Typography>
                  </Box>
                )}
                {this.state.stripeAccountId === null ? (
                  <Box className="container">
                    <Typography className="paid-title">
                      {configJSON.gettingPaidHeadding}
                    </Typography>
                    <Typography className="info">
                      {configJSON.getingPaidInfo}
                    </Typography>
                    <BlackButton
                      data-test-id="setUpHostAccount"
                      onClick={this.setUpHostAccount}
                    >
                      {configJSON.setUpAccountTxt}
                    </BlackButton>
                    <WhiteButton
                      data-test-id="connectExistingAccount"
                      onClick={this.connectExistingAccount}
                    >
                      Connect existing account
                    </WhiteButton>
                  </Box>
                ) : (
                  <Box className="container">
                    <Typography className="paid-title">
                      {configJSON.recivePaymentTxt}
                    </Typography>
                    <Box className="main-toast-container">
                      <Box className="toast-container">
                        <CustomCheckCircleOutlineIcon />
                        <Typography className="toast-message">
                          {configJSON.connectStripeMsg}
                        </Typography>
                      </Box>
                    </Box>
                    <Typography className="info">
                      {configJSON.recivePaymentMsgSuccess}
                    </Typography>
                    <WhiteButton>{configJSON.managePayment}</WhiteButton>
                    <RedButton
                      data-test-id="deleteStripeAccount"
                      onClick={this.deleteStripeAccount}
                    >
                      {configJSON.disconnectButton}
                    </RedButton>
                  </Box>
                )}
              </Box>
            </Box>
          </>
        )}
      </Wrapper>
    );
  };
  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <>
        {!this.props.isHeader ? (
          <Header
            isSidebar
            hideFilter
            navigation={this.props.navigation}
            active="PaymentInfo"
          >
            {this.renderContent()}
          </Header>
        ) : (
          this.renderContent()
        )}
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const Wrapper = styled(Box)({
  height: "100%",
  padding: "30px 20px",
  paddingBottom: "105px",
  backgroundColor: "#F8FAFC",
  "& .loader": {
    marginLeft: "300px",
  },
  "& .loading": {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .heading": {
    marginBottom: "20px",
    display: "flex",
    justifyContent: "space-between",
    "& .payment": {
      fontFamily: "Spartan",
      fontWeight: "700",
      fontSize: "17px",
      color: "#003249",
      textTransform: "uppercase",
    },
    "& .main-title": {
      fontFamily: "Spartan",
      fontSize: "18px",
      fontWeight: "700",
      color: "#003249",
      textTransform: "capitalize",
    },
  },
  "& .main-container": {
    display: "flex",
    marginTop: "40px",
    "& .main-box": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: "40px auto",
      "& .container": {
        width: "100%",
        maxWidth: "555px",
        borderRadius: "16px",
        backgroundColor: "#fff",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        padding: "20px",
        "@media (max-width: 600px)": {
          width: "90%",
          padding: "15px",
        },
        "& .main-toast-container": {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "40px",
          "& .toast-container": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#D1FAE5",
            borderRadius: "8px",
            padding: "10px 15px",
            gap: "10px",
            width: "350px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            "& .toast-message": {
              color: "#047857",
              fontSize: "14px",
              fontFamily: "Spartan",
              fontWeight: "500",
              letterSpacing: "-0.5px",
              lineHeight: "24px",
              textAlign: "center",
            },
          },
        },
        "& .paid-title": {
          color: "#003249",
          fontSize: "24px",
          fontFamily: "Spartan",
          fontWeight: "700",
          letterSpacing: "-0.5px",
          lignHeight: "32px",
          textAlign: "center",
          marginBottom: "20px",
        },
        "& .info": {
          marginTop: "8px",
          marginLeft: "8px",
          color: "#003249",
          fontSize: "14px",
          fontFamily: "Spartan",
          fontWeight: "500",
          lignHeight: "24px",
          marginBottom: "20px",
        },
      },
    },
  },
  "& .main-container2": {
    flexDirection: "column",
    display: "flex",
    justifyContent: "center",
    "& .main-box2": {
      flexDirection: "column",
      display: "flex",
      margin: "0px auto",
      justifyContent: "center",
      "& .container": {
        maxWidth: "555px",
        width: "100%",
        backgroundColor: "#fff",
        borderRadius: "16px",
        padding: "20px",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        "@media (max-width: 600px)": {
          padding: "15px",
          width: "90%",
        },
        "& .main-toast-container": {
          marginBottom: "40px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          "& .toast-container": {
            alignItems: "center",
            display: "flex",
            backgroundColor: "#D1FAE5",
            justifyContent: "center",
            padding: "10px 15px",
            borderRadius: "8px",
            width: "350px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            gap: "10px",
            "& .toast-message": {
              fontSize: "14px",
              color: "#047857",
              fontWeight: "500",
              fontFamily: "Spartan",
              lineHeight: "24px",
              letterSpacing: "-0.5px",
              textAlign: "center",
            },
          },
        },
        "& .paid-title": {
          fontSize: "24px",
          color: "#003249",
          fontWeight: "700",
          fontFamily: "Spartan",
          lignHeight: "32px",
          letterSpacing: "-0.5px",
          marginBottom: "20px",
          textAlign: "center",
        },
        "& .info": {
          marginTop: "8px",
          color: "#003249",
          marginLeft: "8px",
          fontFamily: "Spartan",
          fontSize: "14px",
          lignHeight: "24px",
          marginBottom: "20px",
          fontWeight: "500",
        },
      },
    },
  },
});

const WhiteButton = styled(Button)({
  padding: "8px 10px",
  textTransform: "none",
  width: "100%",
  color: "#0F172A",
  borderRadius: "8px",
  fontSize: "16px",
  fontFamily: "Spartan",
  fontWeight: "500",
  backgroundColor: "#fff",
  border: "1.5px solid #003249",
  marginBottom: "20px",
  "&:hover": {
    color: "#0F172A",
    backgroundColor: "#fff",
  },
});
const RedButton = styled(Button)({
  padding: "8px 10px",
  textTransform: "none",
  width: "100%",
  color: "#DC2626",
  borderRadius: "8px",
  fontSize: "16px",
  fontFamily: "Spartan",
  fontWeight: "500",
  backgroundColor: "#fff",
  border: "1.5px solid #DC2626",
  marginBottom: "20px",
  "&:hover": {
    color: "#DC2626",
    backgroundColor: "#fff",
  },
});
const BlackButton = styled(Button)({
  padding: "8px 10px",
  color: "#fff",
  width: "100%",
  textTransform: "none",
  fontSize: "16px",
  borderRadius: "8px",
  whiteSpace: "nowrap",
  fontWeight: "700",
  fontFamily: "Spartan",
  backgroundColor: "#003249",
  border: "1.5px solid #003249",
  marginBottom: "20px",
  "&:hover": {
    color: "#fff",
    backgroundColor: "#003249",
  },
});
const CustomCheckCircleOutlineIcon = styled(CheckCircleOutlineIcon)({
  color: "#047857",
  fontSize: "20px",
});
// Customizable Area End
