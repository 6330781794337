Object.defineProperty(exports, "__esModule", {
    value: true,
  });
  
  // Customizable Area Start
  exports.apiContentType = "application/json";
  exports.confirmPaymentMethod = "POST";
  exports.stripePaymentIntent = "stripe_integration/payment_intents"
  
  exports.stripePublishableKey = ""
  exports.stripeMerchantDisplayName = "Example.com"
  exports.stripeMerchantIdentifier = "stripeMerchantIdentifier" //used for apple pay
  exports.urlScheme = "rnbuildingblockmasterapp" //app deep linking url
  exports.getStripeKeysEndpoint = "bx_block_stripe_integration/payment_methods/stripe_credentials"
  exports.getMethod="GET";
  exports.addCardEndPoint = "/bx_block_stripe_integration/payment_methods/add_card";
  exports.getCardEndpoint = "bx_block_stripe_integration/payment_methods/get_card";
  exports.createPyamentEndpoint = "bx_block_stripe_integration/payment_methods/create_payment"
  // Customizable Area End
  