// Customizable Area Start
import React from "react";
import {
  Avatar,
  Box,
  Typography,
  Grid,
  Drawer,
  CircularProgress,
  Card,
  CardMedia,
  CardContent,
  Pagination,
  Popover,
  MenuItem,
  IconButton,
  DialogTitle,
  Button,
  Dialog,
  DialogContent,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Slider from "react-slick";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import ViewProfileWithReportBlockController, {
  IReview,
  IDetails,
  IFile,
} from "./ViewProfileWithReportBlockController.web";
import Header from "../../../../components/src/AppHeader.web";
import Loader from "../../../../components/src/Loader.web";
import TruncatedText from "../../../../components/src/helper/TruncatedText.web";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import ReportUser from "../../../../blocks/blockedusers/src/ReportUser.web";
// Customizable Area End

export default class ViewProfileWithReportBlock extends ViewProfileWithReportBlockController {
  // Customizable Area Start
  getImage = (name: string, url: string) => {
    return (
      <CardMedia
        key={name}
        className="image"
        component="img"
        height="185"
        image={url}
        alt={name}
      />
    );
  };

  getDetail = (item: IDetails) => {
    const types = (arr: any[], key: string): string[] =>
      Array.isArray(arr) ? arr.map((obj) => obj[key] as string) : [];
    return [
      ...types(item.amenities, "type"),
      ...types(item.apartment, "name"),
      ...types(item.campsite, "name"),
      ...types(item.hookups, "hookups_type"),
      ...types(item.horse_accommodations, "type"),
    ].join(" • ");
  };

  renderCard = (item: any) => {
    return (
      <CustomCard>
        <Box mb="10px" className="slider">
          <Slider
            {...{
              dots: true,
              infinite: true,
              customPaging: () => <Dot />,
            }}
          >
            {item.cover_photo &&
              this.getImage(item.cover_photo.filename, item.cover_photo.url)}
            {item.files &&
              item.files.map((file: IFile) => {
                return this.getImage(file.file_name, file.file_url);
              })}
          </Slider>
        </Box>
        <CardContent className="content">
          <Box display="flex" alignItems="start" justifyContent="space-between">
            <Typography className="title">{item.title}</Typography>
            <Box mt="7px" display="flex">
              <svg
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.91692 9.92509C2.08644 10.0545 2.29127 10.0138 2.54202 9.82155L5 7.93564L7.45798 9.82155C7.70873 10.0138 7.91709 10.0545 8.08308 9.92509C8.24553 9.79567 8.28438 9.58119 8.18549 9.27057L7.22137 6.24571L9.69701 4.38198C9.94775 4.19339 10.0466 3.9974 9.97954 3.79032C9.91597 3.59063 9.73233 3.4834 9.41448 3.48709L6.37732 3.50558L5.44851 0.46963C5.35316 0.155311 5.20483 0 5 0C4.79517 0 4.64684 0.155311 4.55149 0.46963L3.62268 3.50558L0.585516 3.48709C0.271205 3.4834 0.0840309 3.59063 0.0204624 3.79032C-0.0466378 3.9974 0.0522467 4.19339 0.302989 4.38198L2.77863 6.24571L1.81804 9.27057C1.71562 9.58119 1.75447 9.79567 1.91692 9.92509Z"
                  fill="#003249"
                />
              </svg>
              <span className="rating">
                {parseFloat(item.average_rating_count).toFixed(1)}
              </span>
            </Box>
          </Box>
          <Typography className="location">{item.address}</Typography>
          <Typography className="details">{this.getDetail(item)}</Typography>
          <Typography className="guest">
            {item.total_guest} {item.total_guest > 1 ? "Guests" : "Guest"} •{" "}
            {item.total_horse} {item.total_horse > 1 ? "Horses" : "Horse"}
          </Typography>
          <Typography className="price">
            <span>staring at </span>
            <Box component="span" fontSize="14px">
              ${item.starting_price} USD / night
            </Box>
          </Typography>
        </CardContent>
      </CustomCard>
    );
  };

  renderReviewCard = (item: IReview, bgColor: string, isOpen: boolean) => {
    return (
      <ReviewCard bgcolor={bgColor}>
        <Box display="flex" alignItems="center" gap="10px">
          <Avatar
            className="card-img"
            src={item.attributes.reviewed_by.profile_photo?.url}
          />
          <Box>
            <Typography className="user-name">
              {item.attributes.reviewed_by.first_name +
                " " +
                item.attributes.reviewed_by.last_name}
            </Typography>
            <Typography className="date">
              {moment(item.attributes.created_date).format("MMMM D/YY")}
            </Typography>
          </Box>
        </Box>
        <Typography className="rating">
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.91692 9.92509C2.08644 10.0545 2.29127 10.0138 2.54202 9.82155L5 7.93564L7.45798 9.82155C7.70873 10.0138 7.91709 10.0545 8.08308 9.92509C8.24553 9.79567 8.28438 9.58119 8.18549 9.27057L7.22137 6.24571L9.69701 4.38198C9.94775 4.19339 10.0466 3.9974 9.97954 3.79032C9.91597 3.59063 9.73233 3.4834 9.41448 3.48709L6.37732 3.50558L5.44851 0.46963C5.35316 0.155311 5.20483 0 5 0C4.79517 0 4.64684 0.155311 4.55149 0.46963L3.62268 3.50558L0.585516 3.48709C0.271205 3.4834 0.0840309 3.59063 0.0204624 3.79032C-0.0466378 3.9974 0.0522467 4.19339 0.302989 4.38198L2.77863 6.24571L1.81804 9.27057C1.71562 9.58119 1.75447 9.79567 1.91692 9.92509Z"
              fill="#003249"
            />
          </svg>
          <span>{item.attributes.rating}</span>
        </Typography>
        <Typography className="review-text">
          {isOpen ? (
            item.attributes.description
          ) : (
            <TruncatedText text={item.attributes.description} maxLength={100} />
          )}
        </Typography>
      </ReviewCard>
    );
  };

  isReviewAvailable = () => {
    return (
      this.state.data?.reviews &&
      Array.isArray(this.state.data.reviews.data) &&
      this.state.data.reviews.data.length > 0
    );
  };

  renderDrawer = () => {
    return (
      <CustomDrawer
        anchor="right"
        data-test-id="drawer"
        open={this.state.isOpen}
        onClose={this.closeDrawer}
      >
        <Box className="top">
          <Box mb="25px" display="flex" justifyContent="space-between">
            <Typography className="review">
              ALL REVIEWS ({this.state.data?.reviews_count})
            </Typography>
            <CloseIcon className="close-icon" onClick={this.closeDrawer} />
          </Box>
          <Box>
            <Grid container spacing={2}>
              {this.isReviewAvailable() &&
                this.state.data?.reviews.data.map((item) => {
                  return (
                    <Grid item xs={12}>
                      {this.renderReviewCard(item, "#F8FAFC", true)}
                    </Grid>
                  );
                })}
            </Grid>
          </Box>
        </Box>
      </CustomDrawer>
    );
  };

  renderAllListing = () => {
    return (
      <>
        <div ref={this.scrollRef} />
        <Box p="20px 30px">
          <Typography
            className="all-listing"
            data-test-id="all-listing"
            onClick={this.onGoBack}
          >
            <ArrowBackIcon />
            <span>All Listings ({this.state.pagination.total_count})</span>
          </Typography>
          <Box>
            <Grid container spacing={2} sx={{ display: "flex" }}>
              {this.state.listingData.map((item: any) => (
                <Grid item xs={3} key={item.id}>
                  {this.renderCard(item.attributes)}
                </Grid>
              ))}
            </Grid>
          </Box>
          {this.state.pagination.total_pages > 1 && (
            <Box mt="15px" display="flex" justifyContent="center">
              <CustomPagination
                shape="rounded"
                siblingCount={1}
                boundaryCount={1}
                data-test-id="pagination"
                page={this.state.currentPage}
                count={this.state.pagination.total_pages}
                onChange={(_, newPage: number) => this.onChangePage(newPage)}
              />
            </Box>
          )}
        </Box>
      </>
    );
  };

  loadingVisible = () => {
    return (
      (this.state.loading || this.state.isLoading) &&
      (!this.state.isAllListingVisible || !this.state.isLoading)
    );
  };

  renderBlockUserPopup = () => {
    return (
      <>
        <CustomDialog
          open={this.state.openBlockUser}
          onClose={() => this.handleCloseBlockUser()}
          data-test-id="handleCloseBlockUser"
        >
          <DialogTitle className="title">
            <IconButton
              onClick={this.handleCloseBlockUser}
              sx={{ position: "absolute", right: 8, top: 8 }}
              data-test-id="handleCloseBlockUser"
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className="dialog-content">
            <>
              <Typography className="popup-title">
                Do you want to block {this.state.data?.full_name}?
              </Typography>
              <Typography className="option-description">
                Are you sure you want to block this user? Blocking will restrict
                access to your profile, messages, and any shared content. This
                action is reversible, so you can unblock them anytime if needed.
              </Typography>
            </>
          </DialogContent>

          <Box className="dialog-action">
            <BlackButton onClick={this.blockUser} data-test-id={"block-btn"}>
              Block user
            </BlackButton>
            <WhiteButton
              onClick={this.handleCloseBlockUser}             
            >
              Cancel
            </WhiteButton>
          </Box>
        </CustomDialog>
      </>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <Header hideFilter navigation={this.props.navigation}>
        <Wrapper>
          <Loader
            loading={this.state.isLoading && this.state.isAllListingVisible}
          />
          {this.loadingVisible() ? (
            <Box className="loading">
              <CircularProgress sx={{ color: "#000" }} />
            </Box>
          ) : (
            <>
              {this.state.isAllListingVisible ? (
                this.renderAllListing()
              ) : (
                <Box>
                  <Box className="top">
                    <Box className="profile-container">
                      <Typography className="view-profile">PROFILE</Typography>
                      <IconButton data-test-id="handleClick" onClick={this.handleClick}>
                        <CustomMoreVertIcon />
                      </IconButton>
                      <StyledPopover
                        open={Boolean(this.state.anchorEl)}
                        anchorEl={this.state.anchorEl}
                        onClose={this.handleClose}
                        data-test-id="handleClose"
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        <MenuItem
                          className="report"
                          onClick={this.handleOpenReport}
                          data-test-id="handleOpenReport"
                        >
                          <FlagOutlinedIcon fontSize="small" className="flag" />
                          Report
                        </MenuItem>
                        <MenuItem
                          onClick={this.handleBlockUser}
                          className="block"
                          data-test-id="handleBlockUser"
                        >
                          <ClearIcon fontSize="small" className="clear" />
                          Block
                        </MenuItem>
                      </StyledPopover>
                    </Box>
                    {this.state.openBlockUser && this.renderBlockUserPopup()}
                    {this.state.openReport && (
                      <ReportUser
                        navigation={undefined}
                        id={""}
                        isOpen={this.state.openReport}
                        reportConfig={this.state.reportConfig}
                        onClose={this.handleCloseReport}
                        data-test-id="blockUserPopup"
                      />
                    )}
                    <Box display="flex" justifyContent="center">
                      <Avatar
                        className="user-img"
                        src={this.state.data?.profile_photo?.url ?? ""}
                      />
                    </Box>
                  </Box>
                  <Box padding="20px" paddingBottom="35px">
                    <Box pt="60px" textAlign="center">
                      <Typography className="name">
                        {this.state.data?.full_name}
                      </Typography>
                      <Typography className="duration">
                        Member for {this.state.data?.membership}
                      </Typography>
                      <Box className="detail-info">
                        <Box>
                          <Typography className="bold">Reviews</Typography>
                          <Typography className="details">
                            {this.state.data?.reviews_count}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography className="bold">Rating</Typography>
                          <Typography className="details">
                            {this.state.data?.average_rating_count}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography className="bold">Listings</Typography>
                          <Typography className="details">
                            {this.state.data?.bookings_count ??
                              this.state.data?.listings_count}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box className="rating">
                      <Box className="rating-heading">
                        <Typography className="review">
                          Reviews ({this.state.data?.reviews_count})
                        </Typography>
                        {(this.state.data?.reviews_count ?? 0) > 3 && (
                          <Typography
                            className="see-all"
                            data-test-id="see-btn"
                            onClick={this.openDrawer}
                          >
                            <span>See All</span>
                            <svg
                              width="10"
                              height="16"
                              viewBox="0 0 10 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1.24923 14.7615C1.61673 15.129 2.20923 15.129 2.57673 14.7615L8.80923 8.529C9.10173 8.2365 9.10173 7.764 8.80923 7.4715L2.57673 1.239C2.20923 0.871504 1.61673 0.871504 1.24923 1.239C0.881726 1.6065 0.881726 2.199 1.24923 2.5665L6.67923 8.004L1.24173 13.4415C0.881726 13.8015 0.881726 14.4015 1.24923 14.7615Z"
                                fill="#003249"
                              />
                            </svg>
                          </Typography>
                        )}
                      </Box>
                      <Box>
                        <Grid container spacing={2}>
                          {this.isReviewAvailable() &&
                            this.state.data?.reviews.data
                              .slice(0, 3)
                              .map((item) => {
                                return (
                                  <Grid item xs={4}>
                                    {this.renderReviewCard(
                                      item,
                                      "#F1F5F9",
                                      false
                                    )}
                                  </Grid>
                                );
                              })}
                        </Grid>
                      </Box>
                    </Box>
                    {this.state.role == "guest" && (
                      <Box className="rating">
                        <Box className="rating-heading" mt="40px !important">
                          <Typography className="review">
                            Listings ({this.state.pagination.total_count})
                          </Typography>
                          {this.state.pagination.total_count > 3 && (
                            <Typography
                              className="see-all"
                              data-test-id="listing-see-btn"
                              onClick={this.openAllListing}
                            >
                              <span>See All</span>
                              <svg
                                width="10"
                                height="16"
                                viewBox="0 0 10 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M1.24923 14.7615C1.61673 15.129 2.20923 15.129 2.57673 14.7615L8.80923 8.529C9.10173 8.2365 9.10173 7.764 8.80923 7.4715L2.57673 1.239C2.20923 0.871504 1.61673 0.871504 1.24923 1.239C0.881726 1.6065 0.881726 2.199 1.24923 2.5665L6.67923 8.004L1.24173 13.4415C0.881726 13.8015 0.881726 14.4015 1.24923 14.7615Z"
                                  fill="#003249"
                                />
                              </svg>
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    )}
                    {this.state.listArr.length > 0 && (
                      <Box>
                        <Grid container spacing={2} sx={{ display: "flex" }}>
                          {this.state.listArr.map((item: any) => (
                            <Grid item xs={4} key={item.id}>
                              {this.renderCard(item.attributes)}
                            </Grid>
                          ))}
                        </Grid>
                      </Box>
                    )}
                  </Box>
                </Box>
              )}
              {this.renderDrawer()}
            </>
          )}
        </Wrapper>
      </Header>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const Wrapper = styled(Box)({
  height: "100%",
  "& .loading": {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .all-listing": {
    display: "flex",
    width: "fit-content",
    fontFamily: "Spartan",
    fontWeight: "700",
    marginBottom: "20px",
    fontSize: "17px",
    color: "#003249",
    cursor: "pointer",
    textTransform: "uppercase",
    "& span": {
      marginLeft: "10px",
    },
  },
  "& .top": {
    height: "160px",
    position: "relative",
    borderBottomRightRadius: "16px",
    background: "linear-gradient(99.09deg, #008AC9 2.64%, #003249 100%)",
    "& .profile-container": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "& .view-profile": {
        paddingTop: "30px",
        paddingLeft: "20px",
        fontFamily: "Spartan",
        fontWeight: "700",
        fontSize: "16px",
        color: "#fff",
      },
    },
    "& .user-img": {
      position: "absolute",
      height: "120px",
      bottom: "-72px",
      border: "4px solid #DBEAFE",
      width: "120px",
    },
  },
  "& .name": {
    fontFamily: "Spartan",
    fontSize: "18px",
    fontWeight: "700",
    marginBottom: "6px",
    color: "#003249",
  },
  "& .duration": {
    fontFamily: "Spartan",
    fontSize: "14px",
    fontWeight: "500",
    color: "#64748B",
  },
  "& .bold": {
    marginBottom: "5px",
    fontWeight: "700",
    fontFamily: "Spartan",
    color: "#34343C",
    fontSize: "14px",
  },
  "& .details": {
    fontWeight: "700",
    fontFamily: "Spartan",
    color: "#64748B",
    fontSize: "14px",
  },
  "& .detail-info": {
    marginTop: "30px",
    justifyContent: "center",
    display: "flex",
    gap: "30px",
  },
  "& .rating": {
    "& .rating-heading": {
      marginBottom: "20px",
      marginTop: "30px",
      display: "flex",
      justifyContent: "space-between",
    },
    "& .review": {
      fontFamily: "Spartan",
      letterSpacing: "-0.5px",
      fontWeight: "700",
      fontSize: "16px",
      color: "#1E293B",
    },
    "& .see-all": {
      cursor: "pointer",
      display: "flex",
      alignItem: "center",
      fontFamily: "Spartan",
      letterSpacing: "-0.5px",
      fontWeight: "700",
      fontSize: "16px",
      color: "#003249",
      "& span": {
        marginRight: "10px",
      },
      "& svg": {
        marginTop: "2px",
      },
    },
  },
});

const ReviewCard = styled(Box)<{ bgcolor?: string }>(({ bgcolor }) => ({
  height: "100%",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  gap: "5px",
  padding: "10px",
  borderRadius: "16px",
  border: "1px solid #E2E8F0",
  backgroundColor: `${bgcolor}`,
  "& .card-img": {
    height: "37px",
    width: "37px",
  },
  "& .user-name": {
    fontFamily: "Spartan",
    fontWeight: "700",
    fontSize: "15px",
    color: "#334155",
  },
  "& .date": {
    fontFamily: "Spartan",
    fontWeight: "400",
    fontSize: "11px",
    color: "#475569",
  },
  "& .rating": {
    fontFamily: "Spartan",
    fontWeight: "700",
    fontSize: "14px",
    color: "#003249",
    "& span": {
      marginLeft: "5px",
    },
  },
  "& .review-text": {
    color: "#475569",
    fontFamily: "Spartan",
    fontWeight: "500",
    fontSize: "13px",
    "& span": {
      marginLeft: "5px",
      cursor: "pointer",
      color: "#475569",
      fontFamily: "Spartan",
      fontWeight: "500",
      fontSize: "14px",
      textDecoration: "underline",
    },
  },
}));

const CustomDrawer = styled(Drawer)({
  "& .top": {
    overflowX: "hidden",
    padding: "30px 20px 45px",
    width: "570px",
  },
  "& .close-icon": {
    cursor: "pointer",
  },
  "& .review": {
    fontSize: "18px",
    fontFamily: "Spartan",
    fontWeight: "700",
  },
});

const CustomCard = styled(Card)({
  padding: "15px",
  height: "100%",
  borderRadius: "24px",
  boxSizing: "border-box",
  border: "1px solid #E2E8F0",
  boxShadow: "0px 2px 8px 0px #00000014",
  "& .slider": {
    "& .slick-slider .slick-list": {
      borderRadius: "16px",
      height: "185px",
    },
    "& .slick-prev, .slick-next": {
      display: "none !important",
    },
    ".slick-dots": {
      margin: 0,
      padding: 0,
      position: "absolute",
      bottom: "14px",
      display: "flex !important",
      justifyContent: "center",
      listStyle: "none",
    },
    ".slick-dots li": {
      width: "12px",
      display: "flex",
      height: "12px",
      margin: "0px 1px",
      alignItems: "center",
    },
    ".slick-dots li div": {
      width: "6px",
      opacity: "0.5",
      height: "6px",
      backgroundColor: "#fff",
    },
    ".slick-dots li button": {
      opacity: 0,
      cursor: "pointer",
    },
    ".slick-dots li.slick-active div": {
      height: "8px",
      width: "8px",
      opacity: "1",
      backgroundColor: "#fff",
    },
  },
  "& .rating": {
    marginLeft: "3px",
    fontSize: "12px",
    fontFamily: "Spartan",
    fontWeight: "700",
  },
  "& .image": {
    borderRadius: "16px",
  },
  "& .content": {
    display: "flex",
    gap: "7px",
    flexDirection: "column",
    padding: "0px !important",
  },
  "& .title": {
    fontSize: "16px",
    fontFamily: "Spartan",
    fontWeight: "700",
  },
  "& .location": {
    fontSize: "14px",
    fontFamily: "Spartan",
    fontWeight: "500",
  },
  "& .details, & .guest": {
    fontSize: "12px",
    fontFamily: "Spartan",
    fontWeight: "400",
  },
  "& .price": {
    fontSize: "12px",
    fontFamily: "Spartan",
    fontWeight: "500",
  },
});

const Dot = styled("div")({
  height: "10px",
  width: "10px",
  borderRadius: "50%",
  backgroundColor: "#fff",
  transition: "background-color 0.3s ease",
});
const StyledPopover = styled(Popover)({
  "& .MuiPaper-root": {
    width: "145px",
    borderRadius: "8px",
    padding: "12px",
    display: "flex",
    flexDirection: "column",
    gap: "2px",
  },
  "& .report": {
    fontFamily: "Spartan",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "24px",
    color: "#3C3E49",
    cursor: "pointer",
    "& .flag": {
      marginRight: 8,
      color: "#64748B",
    },
  },
  "& .block": {
    fontFamily: "Spartan",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "24px",
    color: "#DC2626",
    cursor: "pointer",
    "& .clear": {
      marginRight: 8,
      color: "#DC2626",
    },
  },
});
const CustomMoreVertIcon = styled(MoreVertIcon)({
  color: "#fff",
  marginRight: "20px",
  marginTop: "30px",
});
const CustomPagination = styled(Pagination)({
  "& .MuiPaginationItem-root": {
    fontSize: "14px",
    color: "#004c6d",
    margin: "0px",
    textDecoration: "underline",
  },
  "& .MuiPaginationItem-ellipsis": {
    textDecoration: "none",
  },
  "& .Mui-selected": {
    color: "#003249",
    fontWeight: "bold",
    textDecoration: "none",
  },
});
const CustomDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    borderRadius: "16px",
    width: "550px",
  },
  "& .title": {
    textAlign: "center",
  },
  "& .MuiDialogContent-root": {
    padding: "0px 30px 18px !important",
  },
  "& .dialog-content": {
    marginBottom: "10px",
    "& .popup-title": {
      textAlign: "center",
      letterSpacing: "0px",
      fontFamily: "Spartan",
      fontWeight: "700",
      fontSize: "18px",
      color: "#003249",
    },

    "& .option-description": {
      fontFamily: "Spartan",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "24px",
      color: "#716F77",
      paddingTop: "8px",
    },
  },
  "& .dialog-action": {
    padding: "0 30px 30px 30px",
    justifyContent: "center",
    display: "block",
    marginLeft: "0",
  },
  "& .close-btn": {
    color: "#003249",
    height: "15px",
    width: "15px",
  },
  "&.change-host": {
    "& .MuiDialogContent-root": {
      paddingTop: "15px !important",
      paddingBottom: "15px !important",
    },
  },
});
const WhiteButton = styled(Button)({
  padding: "8px 10px",
  textTransform: "none",
  width: "100%",
  color: "#0F172A",
  borderRadius: "8px",
  fontSize: "16px",
  fontFamily: "Spartan",
  fontWeight: "500",
  backgroundColor: "#fff",
  border: "1.5px solid #003249",
  marginTop: "10px",
  "&:hover": {
    color: "#0F172A",
    backgroundColor: "#fff",
  },
});
const BlackButton = styled(Button)({
  padding: "8px 10px",
  width: "100%",
  borderRadius: "8px",
  textTransform: "none",
  fontSize: "16px",
  fontFamily: "Spartan",
  whiteSpace: "nowrap",
  fontWeight: "700",
  color: "#fff",
  backgroundColor: "#003249",
  border: "1.5px solid #003249",
  "&:hover": {
    color: "#fff",
    backgroundColor: "#003249",
  },
  "&:disabled": {
    color: "#fff",
    backgroundColor: "#94A3B8",
    border: "none",
  },
});
// Customizable Area End
