// Customizable Area Start
import React, { useState } from "react";
import StripePayController, { Props } from "./StripePayController.web";
import {
  Box,
  Button,
  Typography,
  styled,
  Modal,
  Container,
  CircularProgress,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
} from "@mui/material";
import {
  Stripe,
  StripeCardElementChangeEvent,
  StripeElements,
  TokenResult,
} from "@stripe/stripe-js";
import {
  Elements,
  CardElement,
  ElementsConsumer,
} from "@stripe/react-stripe-js";
import CloseIcon from "@mui/icons-material/Close";
import HandshakeOutlinedIcon from "@mui/icons-material/HandshakeOutlined";
// Customizable Area End

export default class StripePay extends StripePayController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderBookingConfirmPopup = () => {
    return (
      <CustomDialog
        open={this.state.showBookingConfirmPopup}
        onClose={this.togglePopup}
      >
        <DialogTitle className="title">
          <HandshakeOutlinedIcon
            sx={{ height: "40px", width: "40px", color: "003249" }}
          />
          <IconButton
            onClick={this.togglePopup}
            data-test-id="togglePopup"
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="dialog-content">
          <Typography className="popup-title">Booking Confirmed!</Typography>
          <Typography className="popup-desc">
            Thank you for choosing us! Your reservation is now complete, find it
            on “Bookings”
          </Typography>
        </DialogContent>
        <DialogActions className="dialog-action">
          <BlackButton
            sx={{ width: "256px" }}
            data-test-id={"handleView"}
            onClick={this.handleView}
          >
            View bookings
          </BlackButton>
        </DialogActions>
      </CustomDialog>
    );
  };
  // Customizable Area End
  render() {
    // Customizable Area Start
   const StripeCardForm = ({ closeModal }: { closeModal: () => void }) => {
      const [loading, setLoading] = useState(false);
      const [error, setError] = useState<string | null>(null);
      const handleCardChange = (event: StripeCardElementChangeEvent) => { setError(event.error ? event.error.message : null)};
      const handleSubmit = async ( stripe: Stripe | null, elements: StripeElements | null, e: React.FormEvent ) => {e.preventDefault(); if (!stripe || !elements) return; 
      setLoading(true); setError(null);
      const { token, error }: TokenResult = await stripe.createToken( elements.getElement(CardElement)!); if (error) { setError(error.message ?? null) } else if (token) { this.addCard(token.id)};
      setLoading(false);
      };

      const cardElementOptions = {
        hidePostalCode: true,
        style: {
          base: {
            fontSize: "16px",
            color: "#424770",
            padding: "10px",
          },
          invalid: { color: "#9e2146" },
        },
      };

      return (
        <Container maxWidth="xs">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "30px",
            }}
          >
            <Typography variant="h6">Payment Details</Typography>
            <CloseIcon className="close-icon" onClick={closeModal} />
          </Box>
          <Elements stripe={this.state.stripePromise}>
            <ElementsConsumer>
              {({ stripe, elements }) => (<form onSubmit={(e) => handleSubmit(stripe, elements, e)}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                  }}
                  data-test-id="form"
                >
                  <CardElement
                    options={cardElementOptions}
                    onChange={handleCardChange}
                    data-test-id="card-element"
                  />
                  {error && <Typography color="error">{error}</Typography>}
                  <BlackButton
                    type="submit"
                    variant="contained"
                    disabled={!stripe || !elements || loading}
                    fullWidth
                    style={{ marginTop: "20px" }}
                  >
                    {loading ? <CircularProgress size={24} /> : "Pay Now"}
                  </BlackButton>
                </form>
              )}
            </ElementsConsumer>
          </Elements>
        </Container>
      );
    };

    return (
      <>
        {!this.props.popupBtnOnly ? <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "64px",
          }}
        >
          <Box>
            <Typography sx={webStyle.inputHeader}>
              Ready to confirm ?
            </Typography>
          </Box>
          <Box sx={{ padding: "0px 25px" }}>
            <Typography sx={webStyle.confirmSubText}>
              Double-check everything and hit the button below to finalize your reservation!
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              marginTop: "20px",
              gap: "20px",
            }}
          >
            <GrayButton
              sx={{ width: "100%" }}
              onClick={this.cancelBtn}
              data-test-id={"cancelBtn"}
            >
              Cancel
            </GrayButton>
            <BlackButton
              onClick={this.handlePayClick}
              sx={{ width: "100%" }}
              data-test-id={"pay-btn"}
            >
              Pay
            </BlackButton>
          </Box>
        </Box> : <BlackButton
          onClick={this.handlePayClick}
          sx={{ width: "100%",cursor:'pointer' }}
          data-test-id={"pay-btn"}
        >
          Make payment
        </BlackButton>}    
        <Modal open={this.state.isModalOpen} onClose={this.closeModal}>
          <Box sx={webStyle.modalContent}>
            <StripeCardForm data-test-id="StripeCardForm" closeModal={this.closeModal} />
          </Box>
        </Modal>
        {this.state.showBookingConfirmPopup && this.renderBookingConfirmPopup()}
      </>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
const webStyle = {
  headding: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
  },
  modalContent: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: 24,
    width: "400px",
  },
  cardContainer: {
    backgroundColor: "white",
    marginBottom: "40px",
    padding: "20px",
    width: "670px",
    borderRadius: "16px",
    boxShadow: "0px 0px 18px 0px #00000014",
  },
  cardTitle: {
    fontSize: "14px",
    lineHeight: "22px",
    fontFamily: "Spartan",
    fontWeight: "700",
    color: "#003249",
  },
  inputHeader: {
    fontFamily: "Spartan",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "22px",
    letterSpacing: "-2%",
    color: "#003249",
  },
  dateContainer: {
    minWidth: "45%",
    position: "relative",
    zIndex: 99,
  },
  dateWrapper: {
    zIndex: "9999",
    top: "90px",
    cursor: "default",
  },
  infoItem: {
    color: "#475569",
    fontFamily: "Spartan",
    fontWeight: 500,
    fontSize: "14px",
    marginTop: "3px",
  },
  infoItemContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    alignContent: "center",
    padding: "4px",
    margin: "4px",
  },
  containerTitle: {
    color: "#003249",
    fontFamily: "Spartan",
    fontWeight: 700,
    fontSize: "20px",
    LineHeight: "28px",
    marginBottom: "16px",
  },
  profileText: {
    color: " #003249",
    fontFamily: "Spartan",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "22px",
    letterSpacing: "-3%",
    textTransform: "none",
  },
  profileInfo: {
    color: " #475569",
    fontFamily: "Spartan",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "24px",
    letterSpacing: "-3%",
    textTransform: "none",
  },
  infoIcons: {
    height: "16px",
  },
  center: {
    justifyContent: "center",
    alignItems: "center",
  },
  priceTitle: {
    fontFamily: "Spartan",
    fontWeight: 700,
    fontSize: "16px",
    lineheight: "24px",
    letterSpacing: "-2%",
    color: "#003249",
  },
  totalPriceText: {
    fontFamily: "Spartan",
    fontWeight: 500,
    fontSize: "16px",
    lineheight: "24px",
    letterSpacing: "-2%",
    color: "#003249",
  },
  priceText: {
    fontFamily: "Spartan",
    fontWeight: 500,
    fontSize: "14px",
    lineheight: "24px",
    color: "#003249",
  },
  groupWrapper: {
    borderRadius: "8px",
    border: "1px solid #E2E8F0",
    padding: "16px 10px",
    marginBottom: "16px",
  },
  confirmSubText: {
    textAlign:'center',
    fontFamily: "Spartan",
    fontWeight: 500,
    fontSize: "14px",
    lineheight: "24px",
    color: "#64748B",
  },
  infoTitle: {
    fontFamily: "Spartan",
    fontWeight: 700,
    fontSize: "18px",
    lineheight: "26px",
    color: "#003249",
  },
  infoSubText: {
    fontFamily: "Spartan",
    fontWeight: 500,
    fontSize: "14px",
    lineheight: "24px",
    color: "#475569",
  },
  priceBlockWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginRight: "44px",
    marginBottom: "8px",
  },
  checkOutField: {
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    padding: "10px 8px",
  },
};
const BlackButton = styled(Button)({
  padding: "8px 10px",
  width: "100%",
  borderRadius: "8px",
  textTransform: "none",
  fontSize: "16px",
  fontFamily: "Spartan",
  whiteSpace: "nowrap",
  fontWeight: "700",
  color: "#fff",
  backgroundColor: "#003249",
  border: "1.5px solid #003249",
  "&:hover": {
    color: "#fff",
    backgroundColor: "#003249",
  },
});
const CustomDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    borderRadius: "16px",
    maxWidth: "550px",
  },
  "& .title": {
    textAlign: "center",
    paddingTop: "35px",
  },
  "& .MuiDialogContent-root": {
    padding: "30px 30px 18px !important",
  },
  "& .dialog-content": {
    textAlign: "center",
    "& .popup-title": {
      letterSpacing: "0px",
      marginBottom: "5px",
      fontFamily: "Spartan",
      fontWeight: "700",
      fontSize: "18px",
      color: "#003249",
    },
    "& .popup-desc": {
      letterSpacing: "-2%",
      fontFamily: "Spartan",
      fontWeight: "500",
      fontSize: "15px",
      color: "#94A3B8",
    },
  },
  "& .dialog-action": {
    gap: "10px",
    padding: "30px",
    justifyContent: "center",
  },
  "& .close-btn": {
    color: "#003249",
    height: "15px",
    width: "15px",
  },
  "&.change-host": {
    "& .MuiDialogContent-root": {
      paddingTop: "15px !important",
      paddingBottom: "15px !important",
    },
  },
});
const GrayButton = styled(Button)({
  padding: "8px 10px",
  width: "100%",
  borderRadius: "8px",
  textTransform: "none",
  fontSize: "16px",
  fontFamily: "Spartan",
  whiteSpace: "nowrap",
  fontWeight: "500",
  color: "#003249",
  border: "1px solid #003249",
  "&:hover": {},
});
// Customizable Area End
