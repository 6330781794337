// Customizable Area Start
import React from "react";
import {
  Box, Typography, Button, CircularProgress, Tooltip, Grid
} from "@mui/material";
import { styled } from '@mui/material/styles';
import { Dropdown, Button as AntdButton } from "antd";
import {
  PlaceOutlined, ContentCopyOutlined, AccessTime,
  Edit, ArrowBack, DeleteOutline, MoreVert, AttachMoney,
  PlayCircleOutline, MonetizationOnOutlined, Close, GridOn,
} from "@mui/icons-material";

import PreviewListingController from "./PreviewListingController.web";
import Header from "../../../../components/src/AppHeader.web";
import Loader from "../../../../components/src/Loader.web";
import CommonDatePicker from "../../../../../packages/components/src/helper/CommonDatePicker.web";
import TruncatedText from "../../../../../packages/components/src/helper/TruncatedText.web";
// Customizable Area End

export default class PreviewListing extends PreviewListingController {
  // Customizable Area Start
  isReadyToPost = () => {
    return this.state.data.listing_status !== "published" && this.state.data.step_left === 0;
  }

  threeDotMenu = () => {
    const options = [
      { key: "1", label: "Edit", icon: <Edit />, onClick: this.editListing },
      { key: "2", label: "Post", icon: <PlayCircleOutline />, onClick: this.publishListing },
      { key: "3", label: <Box color="#DC2626">Delete</Box>, icon: <DeleteOutline sx={{ fill: "#DC2626", fontSize: "16px" }} />, onClick: this.deleteListing },
    ];
    const items = options.filter(item => item.label !== "Post" || this.isReadyToPost());
    return (
      <Dropdown
        menu={{ items }}
        trigger={["click"]}
        placement="bottomRight"
        getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentElement as HTMLElement}
      >
        <AntdButton shape="circle" type="text" icon={<MoreVert />} />
      </Dropdown>
    );
  }

  convertFormat = (date: string) => {
    const [day, month, year] = date.split("-").map(Number);
    const newDate = new Date(year, month - 1, day);
    return newDate;
  }

  getBlackoutDates = () => {
    return this.state.data.blackout_dates?.map((date: string) => this.convertFormat(date));
  }

  renderImage = () => {
    const images = this.state.data?.files ?? [];
    if (images && images.length > 0) {
      return (
        <Box>
          <img src={images[0].file_url} className="main-img" />
          {images.length > 1 && (
            <Box className="img-wrapper">
              {images.slice(1, images.length === 5 ? 5 : 4).map((img: { file_url: string }, index: number) => (
                <img
                  key={`small-img-${index}`}
                  src={img.file_url}
                  className="small-img"
                  alt={`Thumbnail ${index + 1}`}
                />
              ))}
              {images.length > 5 && (
                <Box className="overlap-wrapper" data-test-id="show-all" onClick={this.onShowAll}>
                  <img
                    alt="Thumbnail 5"
                    src={images[4].file_url}
                    className="small-img grid-img"
                  />
                  <Box className="overlap">
                    <GridOn sx={{ color: "#fff" }} />
                    <span>Show all photos</span>
                  </Box>
                </Box>
              )}
            </Box>
          )}
        </Box>
      );
    }
  }

  renderAllImages = () => {
    return (
      <>
        <Box mb="20px">
          <Typography className="heading back" onClick={this.goBackToMainPage}>
            <ArrowBack />
            <span className="title">{this.state.data.title}</span>
          </Typography>
          <Typography className="total">{this.state.data.files.length} photos for you</Typography>
        </Box>
        <Box>
          <Grid container spacing={2}>
            {this.state.data.files.map((item: { file_url: string }, index: number) => (
              <Grid item key={`${index + 1}`} xs={12} sm={6} md={4}>
                <img src={item.file_url} className="all-img" />
              </Grid>
            ))}
          </Grid>
        </Box>
      </>
    );
  }

  formatCapacity = (count: number, singular: string, plural: string) => {
    if (count <= 0) return "";
    return count === 1 ? singular : plural;
  }

  getCapacity = () => {
    const { apartment, hookups, horse_accommodations } = this.state.data;
    const details = [];

    if (apartment?.length) details.push(this.formatCapacity(apartment.length, "apartment", "apartments"));
    if (hookups?.length) details.push(this.formatCapacity(hookups.length, "Hookup", "Hookups"));

    const stallCount = horse_accommodations?.filter((a: { type: string }) => a.type === "stall").length || 0;
    if (stallCount) details.push(this.formatCapacity(stallCount, "Stall", "Stalls"));

    const pastureCount = horse_accommodations?.filter((a: { type: string }) => a.type === "pasture").length || 0;
    if (pastureCount) details.push(this.formatCapacity(pastureCount, "Pasture", "Pastures"));

    return details.join(" • ");
  }

  renderAddressAndCapacity = () => {
    const { address, total_guest, total_horse } = this.state.data;
    const parts = [];
    if (address) parts.push(address);

    const capacity = this.getCapacity();
    if (capacity) parts.push(capacity);

    const guestCount = Number(total_guest) || 0;
    const horseCount = Number(total_horse) || 0;

    const guestStr = `${guestCount} Guest${guestCount !== 1 ? 's' : ''}`;
    const horseStr = `${horseCount} Horse${horseCount !== 1 ? 's' : ''}`;

    parts.push([guestStr, horseStr].join(" • "));

    return parts.join(" | ");
  }

  renderLocation = () => {
    const { latitude, longitude } = this.state.data;
    const additionalMarkers = [{ lat: 21.17024, lon: 72.831061 }];
    const markers = additionalMarkers
      .map((marker) => `${marker.lon},${marker.lat}`)
      .concat(`${longitude},${latitude}`)
      .join("~");
    const mapUrl = `https://static-maps.yandex.ru/1.x/?ll=${longitude},${latitude}&z=${6}&l=map&pt=${markers},pm2rdm&lang=en_US`;
    return (
      <>
        <Box mt="40px">
          <Typography className="heading mb">Location</Typography>
          <Box>
            <Box mb="5px">
              <Typography className="description location">
                <PlaceOutlined />
                <span>{this.state.data.address}</span>
              </Typography>
            </Box>
            <Box>
              <Typography className="description location">
                <span>{this.state.data.latitude}, {this.state.data.longitude}</span>
                <Tooltip title={this.state.copied ? "Copied!" : "Copy to clipboard"} arrow>
                  <ContentCopyOutlined sx={{ cursor: "pointer" }} onClick={this.handleCopy} />
                </Tooltip>
              </Typography>
            </Box>
            <Box mt="20px">
              <img
                width="100%"
                height="500px"
                src={mapUrl}
                style={{ borderRadius: '16px' }}
              />
            </Box>
          </Box>
        </Box>
      </>
    );
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <Header hideFilter navigation={this.props.navigation} active="Listings">
        <div ref={this.scrollRef} />
        <Wrapper>
          <Loader loading={this.state.isLoading} />
          {this.state.loading ? (
            <Box className="loading">
              <CircularProgress sx={{ color: "#000" }} />
            </Box>
          ) : (
            <>
              {this.state.isShowAll && this.renderAllImages()}
              {(this.state.data && !this.state.isShowAll) &&
                <Box>
                  <Box mb="20px" display="flex" justifyContent="space-between">
                    <Typography className="heading back" onClick={this.goBack}>
                      <ArrowBack />
                      <span>Preview Listing</span>
                    </Typography>
                    {this.threeDotMenu()}
                  </Box>
                  <Box display="flex" justifyContent="center">
                    <Box width="100%" maxWidth="900px">
                      {this.isReadyToPost() && (
                        <Box mb="20px" display="flex" justifyContent="end">
                          <Typography className="ready">Ready to post</Typography>
                        </Box>
                      )}
                      {this.renderImage()}
                      <Box mt="20px" gap="10px" display="flex" justifyContent="space-between">
                        <Box>
                          <Typography className="heading title">{this.state.data.title}</Typography>
                          <Typography className="address">{this.renderAddressAndCapacity()}</Typography>
                        </Box>
                        <Box>
                          <Typography className="price">
                            <span>staring at </span><Box component="span" fontSize="16px" fontWeight="700">${this.state.data.starting_price} USD / night</Box>
                          </Typography>
                        </Box>
                      </Box>
                      <Box mt="40px">
                        <Typography className="heading mb">Description</Typography>
                        <Typography className="description truncated">
                          <TruncatedText
                            maxLength={300}
                            text={this.state.data.description}
                          />
                        </Typography>
                      </Box>
                      {this.state.data.amenities && (
                        <Box mt="40px">
                          <Box mb="20px" gap="15px" display="flex" alignItems="center">
                            <Typography className="heading">Amenities</Typography>
                            <Box display="flex">
                              <MonetizationOnOutlined className="cost" />
                              <Typography className="description">Additional cost</Typography>
                            </Box>
                          </Box>
                          <Box className="amenities">
                            {this.state.data.amenities.map((item: any) => (
                              <Box className="item">{item.type}</Box>
                            ))}
                          </Box>
                        </Box>
                      )}
                      {(this.state.data.available_from && this.state.data.available_until) && (
                        <Box mt="40px">
                          <Typography className="heading mb">Availability</Typography>
                          <CommonDatePicker
                            inline
                            selectsRange
                            monthsShown={2}
                            disabledKeyboardNavigation
                            excludeDates={this.getBlackoutDates()}
                            maxDate={new Date(this.state.data.available_until)}
                            minDate={new Date(this.state.data.available_from)}
                          />
                        </Box>
                      )}
                      {this.renderLocation()}
                      <Box className="card">
                        <Box p="10px">
                          <Typography className="heading mb info">
                            Important Info
                          </Typography>
                          <Box display="flex" flexDirection="column" gap="15px">
                            <Box className="sub-wrap">
                              <AccessTime />
                              <Typography className="sub-title">Check in time {this.state.data.check_in_time}</Typography>
                            </Box>
                            <Box className="sub-wrap">
                              <AccessTime />
                              <Typography className="sub-title">Check out time {this.state.data.check_out_time}</Typography>
                            </Box>
                            <Box className="sub-wrap">
                              <AttachMoney />
                              <Typography className="sub-title">10% Service Fee</Typography>
                            </Box>
                            <Box className="sub-wrap">
                              <Close />
                              <Typography className="sub-title">Cancellation & Refund Policy</Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      {this.isReadyToPost() && (
                        <Box mt="60px" display="flex" justifyContent='center'>
                          <BlackButton data-test-id="save-btn" onClick={this.publishListing}>Post Listing</BlackButton>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              }
            </>
          )}
        </Wrapper>
      </Header>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const Wrapper = styled(Box)({
  height: "100%",
  padding: "30px",
  paddingBottom: "80px",
  "& .loading": {
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .heading": {
    display: "flex",
    fontFamily: "Spartan",
    fontWeight: "700",
    fontSize: "18px",
    color: "#003249",
    letterSpacing: "-0.5px",
    textTransform: "uppercase",
    "&.back": {
      width: "fit-content",
      marginTop: "5px",
      cursor: "pointer",
      "& .title": {
        textTransform: "none",
      }
    },
    "&.title": {
      textTransform: "none",
    },
    "&.info": {
      fontSize: "20px",
      textTransform: "none",
    },
    "&.mb": {
      marginBottom: "20px"
    },
    "& span": {
      marginLeft: "15px"
    }
  },
  "& .total": {
    marginLeft: "38px",
    fontFamily: "Spartan",
    fontWeight: "500",
    fontSize: "14px",
    color: "#003249",
  },
  "& .all-img": {
    objectFit: "cover",
    width: '100%',
    height: '270px',
    borderRadius: '16px',
  },
  "& .ant-dropdown": {
    "& .ant-dropdown-menu": {
      width: "123px",
      background: "#fff",
      borderRadius: "8px",
      border: "1px solid #E2E8F0",
      boxShadow: "0px 8px 32px 0px #0000000F",
      "& svg": {
        fontSize: "16px",
        color: "#475569",
      },
      "& .ant-dropdown-menu-title-content": {
        marginTop: "3px",
        fontFamily: "Spartan",
        fontSize: "14px",
        color: "#475569",
        fontWeight: "500",
      },
    }
  },
  "& .ready": {
    width: "fit-content",
    fontFamily: "Spartan",
    fontWeight: "500",
    fontSize: "10px",
    color: "#059669",
    padding: "5px 15px",
    borderRadius: "15px",
    backgroundColor: "#ECFDF5"
  },
  "& .main-img": {
    height: "500px",
    width: '100%',
    objectFit: "cover",
    borderRadius: "16px",
  },
  "& .img-wrapper": {
    gap: "19px",
    display: "flex",
    marginTop: "19px",
    "& .small-img": {
      objectFit: "cover",
      width: "210px",
      height: "160px",
      borderRadius: "16px",
      "& .grid-img": {
        position: "relative",
        color: "white",
        fontSize: "18px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontWeight: "bold",
        cursor: "pointer",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.7)",
        },
      },
    },
    "& .overlap-wrapper": {
      position: "relative",
      width: "210px",
      height: "160px",
      borderRadius: "16px",
      overflow: "hidden",
      cursor: "pointer",
      "& .overlap": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(0, 0, 0, 0.6)",
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.7)",
        },
        "& span": {
          marginLeft: "5px",
          marginTop: "4px",
          fontFamily: "Spartan",
          fontWeight: "700",
          fontSize: "16px",
          color: "#fff",
          letterSpacing: "-0.5px",
        }
      }
    }
  },
  "& .address": {
    marginTop: "5px",
    fontFamily: "Spartan",
    fontWeight: "400",
    fontSize: "12px",
    color: "#003249",
    letterSpacing: "-0.5px",
  },
  "& .cost": {
    color: "#64748B",
    fontSize: "13px",
    marginRight: "5px",
    marginTop: "2px"
  },
  "& .price": {
    fontFamily: "Spartan",
    fontSize: "12px",
    fontWeight: "500",
    letterSpacing: "-0.5px",
    whiteSpace: "nowrap"
  },
  "& .description": {
    fontFamily: "Spartan",
    fontWeight: "500",
    fontSize: "14px",
    color: "#64748B",
    "&.location": {
      gap: "7px",
      display: "flex",
      color: "#475569",
      "& svg": {
        color: "#475569"
      },
      "& span": {
        marginTop: "4px"
      },
    },
    "&.truncated span": {
      marginLeft: "3px",
      cursor: "pointer",
      color: "#003249",
      fontWeight: "400",
      textDecoration: "underline"
    }
  },
  "& .amenities": {
    gap: "13px",
    display: "flex",
    flexWrap: 'wrap',
    "& .item": {
      padding: '10px 13px',
      fontFamily: "Spartan",
      fontWeight: "500",
      fontSize: "16px",
      color: "#003249",
      borderRadius: '8px',
      letterSpacing: "-0.5px",
      backgroundColor: ' #F1F5F9',
      border: '1px solid #E2E8F0',
    }
  },
  "& .card": {
    padding: "10px",
    marginTop: "40px",
    borderRadius: "16px",
    backgroundColor: "#fff",
    boxShadow: "0px 2px 8px 0px #00000014",
    "& .sub-title": {
      fontFamily: "Spartan",
      fontSize: "14px",
      fontWeight: "500",
      color: "#475569",
    },
    "& .sub-wrap": {
      display: "flex",
      "& svg": {
        marginTop: "2px",
        marginRight: "5px",
        fill: "#475569",
        fontSize: "14px"
      },
    }
  },
});

const BlackButton = styled(Button)({
  width: "440px",
  color: "#fff",
  fontSize: "16px",
  padding: "10px 16px",
  borderRadius: "8px",
  fontWeight: "700",
  letterSpacing: "-0.5px",
  textTransform: "none",
  fontFamily: "Spartan",
  whiteSpace: "nowrap",
  backgroundColor: "#003249",
  border: "1.5px solid #003249",
  "&:hover": {
    color: "#fff",
    backgroundColor: "#003249",
  }
});
// Customizable Area End