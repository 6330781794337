// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall } from "../../../components/src/NavigationRouteWrapper/helpers";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  isLoading: boolean;
  data: any[];
  userId: string;
  // Customizable Area End
}

interface SS {
  id: string;
}

export default class BlockedUserController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getBlockedUserApiCallId: string = "";
  unblockUserApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      loading: false,
      isLoading: false,
      data: [],
      userId: ""
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getBlockedUserApiCallId) {
        if (responseJson.data) {
          this.setState({ data: responseJson.data });
        }
        this.setState({ loading: false });
      } else if (apiRequestCallId === this.unblockUserApiCallId) {
        if (Array.isArray(responseJson.message)) {
          const isUnblocked = responseJson.message[0]?.["Block User"]?.message === "User unblocked.";
          if (isUnblocked) {
            this.setState((prevState) => ({
              data: prevState.data.filter((obj) => obj.id !== this.state.userId)
            }));
          }
        }
        this.setState({ isLoading: false, userId: "" });
      }
    }
  }

  async componentDidMount() {
    this.getBlockedUsers();
  }

  unblockUser = async (userId: string) => {
    this.setState({ userId, isLoading: true });
    const header = {
      token: localStorage.getItem("token")
    }
    this.unblockUserApiCallId = await apiCall({
      header,
      method: "DELETE",
      endPoint: `/bx_block_block_users/block_users/${userId}`,
    });
  }

  getBlockedUsers = async () => {
    this.setState({ loading: true });
    const header = {
      token: localStorage.getItem("token")
    }
    this.getBlockedUserApiCallId = await apiCall({
      header,
      method: "GET",
      endPoint: `/bx_block_block_users/block_users`,
    });
  }
  // Customizable Area End
}