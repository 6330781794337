// Customizable Area Start
import React from "react";
import {
  Box, Button,
  Typography, CircularProgress,
  TextField,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
  CardMedia
} from "@mui/material";
import { styled } from '@mui/material/styles';
import  SliderStyles  from '../../../components/src/SliderStyles.web';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Header from "../../../components/src/AppHeader.web";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import GuestBookingFormController, { Props } from "./GuestBookingFormController";
import StripePay from "../../stripepayments/src/StripePay.web";
import Slider from "react-slick";
// Customizable Area End
export default class GuestBookingConfirmation extends GuestBookingFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const id = this.props.navigation.getParam?.('bookingId');
    this.getBookingDetails(id);
    this.callGetStripeKeysApi(); 
    }

  componentDidUpdate(prevProps: any) {
    const id = this.props.navigation.getParam?.('bookingId');
    if (prevProps.navigation.getParam?.('bookingId') !== id) {
      this.getBookingDetails(id);
    }
  }

  customStyles = {
    control: (provided: any) => ({
      ...provided,
      padding: '4px 2px',
      borderRadius: '8px'
    }),
    input: (provided: any) => ({
      ...provided,
    }),
  };

  renderBookingConfirmPopup = () => {
    return (
      <CustomDialog
        open={this.state.showBookingConfirmPopup}
        onClose={this.togglePopup}
      >
        <DialogTitle className="title">
        <HandshakeOutlinedIcon sx={{height:'40px', width:'40px',color:'003249'}}/>
          <IconButton
            onClick={this.togglePopup}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="dialog-content">
          <Typography className="popup-title">Booking Confirmed!</Typography>
          <Typography className="popup-desc">Thank you for choosing us! Your reservation is now complete, find it on “Bookings”</Typography>
        </DialogContent>
        <DialogActions className="dialog-action">
          <BlackButton sx={{width:'256px'}}data-test-id={'login-btn'} onClick={()=>this.viewBookings()}>View bookings</BlackButton>
        </DialogActions>
      </CustomDialog>
    );
  }

  renderPaymentDetails = () => {
    const { attributes } = this.state.bookingDetailsConfirm;
    return <><Box sx={{ paddingLeft: '20px' }}>
      <CustomAccordion defaultExpanded>
        <CustomAccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: '#003249' }} />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between', marginRight: '20px' }}>
            <Box><Typography sx={webStyle.inputHeader}>Horse stabling</Typography></Box>
            <Box><Typography sx={webStyle.inputHeader}>{this.formatCurrency(attributes.booking_price_breakdown.horse_accommodations)}</Typography></Box>
          </Box>
        </CustomAccordionSummary>
        <CustomAccordionDetails sx={{ marginLeft: '10px' }}>
          {attributes.booking_horse_accommodations.map((item: any) =>
            <Box sx={webStyle.priceBlockWrapper}>
              <Typography sx={webStyle.priceText}>{item.type}</Typography>
              <Typography sx={webStyle.priceText}>{this.formatCurrency(item.selected_item_price)}</Typography>
            </Box>
          )}
        </CustomAccordionDetails>
      </CustomAccordion>
      <CustomAccordion defaultExpanded >
        <CustomAccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: '#003249' }} />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between', marginRight: '20px' }}>
            <Box><Typography sx={webStyle.inputHeader}>Guest accommodation</Typography></Box>
            <Box><Typography sx={webStyle.inputHeader}>{this.formatCurrency(attributes.booking_price_breakdown.guest_accommodations)}</Typography></Box>
          </Box>
        </CustomAccordionSummary>
        <CustomAccordionDetails sx={{ marginLeft: '10px' }}>
          <Box sx={webStyle.priceBlockWrapper}>
            <Typography sx={webStyle.priceText}>Apartment Guests </Typography>
            <Typography sx={webStyle.priceText}>{this.formatCurrency(attributes.booking_price_breakdown.breakdown_guest_accommodations.apartment)}</Typography>
          </Box>
          <Box sx={webStyle.priceBlockWrapper}>
            <Typography sx={webStyle.priceText}>Camping Spots</Typography>
            <Typography sx={webStyle.priceText}>{this.formatCurrency(attributes.booking_price_breakdown.breakdown_guest_accommodations.campsite)}</Typography>
          </Box>
          <Box sx={webStyle.priceBlockWrapper}>
            <Typography sx={webStyle.priceText}>Hookup Options</Typography>
            <Typography sx={webStyle.priceText}>{this.formatCurrency(attributes.booking_price_breakdown.breakdown_guest_accommodations.hookups)}</Typography>
          </Box>
        </CustomAccordionDetails>
      </CustomAccordion>
      <CustomAccordion defaultExpanded>
        <CustomAccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: '#003249' }} />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between', marginRight: '20px' }}>
            <Box><Typography sx={{ ...webStyle.inputHeader }}>Amenities</Typography></Box>
            <Box><Typography sx={{ ...webStyle.inputHeader }}>{this.formatCurrency(attributes.booking_price_breakdown.amenities)}</Typography></Box>
          </Box>
        </CustomAccordionSummary>
        <CustomAccordionDetails sx={{ marginLeft: '10px' }}>
          {attributes.booking_amenities.map((item: any) =>
            <Box sx={webStyle.priceBlockWrapper}>
              <Typography sx={webStyle.priceText}>{item.type}</Typography>
              <Typography sx={webStyle.priceText}>$ {item.selected_item_price}</Typography>
            </Box>)
          }
        </CustomAccordionDetails>
      </CustomAccordion>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginRight: '44px' }}>
        <Box><Typography sx={webStyle.inputHeader}>Platform Fee 10%</Typography></Box>
        <Box><Typography sx={webStyle.inputHeader}>{this.formatCurrency(attributes.booking_price_breakdown.platform_fee)}</Typography></Box>
      </Box>
    </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginRight: '44px', marginTop: '32px' }}>
        <Box><Typography sx={webStyle.priceTitle}>TOTAL</Typography></Box>
        <Box><Typography sx={webStyle.priceTitle}>{this.formatCurrency(attributes.booking_price_breakdown.total)}</Typography></Box>
      </Box>      
      <StripePay bookingDetailsConfirm={this.state.bookingDetailsConfirm} navigation={this.props.navigation} id={""}/>
    </>
  }

  getImage = (name: string, url: string) => {
    return (
      <CardMedia
        key={name}
        component="img"
        className="image"
        height="185"
        alt={name}
        image={url}
      />
    );
  }

  renderBasicDetails = () => {
    const { attributes } = this.state.bookingDetailsConfirm.attributes.listing.data;
    const { total_guest_count, total_horse_count } = this.state.bookingDetailsConfirm.attributes;
    const listing = attributes;
    return (
      <Box sx={webStyle.cardContainer}>
        <Box sx={{ display: 'flex', flrxDirection: 'row' }}>
           <Box sx={{ width: '50%' }}>
             <Box width="300px">
          <Box className="slider">
            <Slider {...{
              dots: true,
              infinite: true,
              customPaging: () => <Dot />,
            }}>
              {listing.cover_photo?.url && this.getImage(listing.cover_photo.filename, listing.cover_photo.url)}
              {Array.isArray(listing.files) && listing.files.map((file: any) => {
                return this.getImage(file.file_name, file.url)
              })}
            </Slider>
          </Box>
        </Box>
          </Box>
          <Box sx={{ width: '50%', marginLeft: '20px' }}>
            <Typography sx={{ ...webStyle.infoTitle, marginTop: '11px' }}>{attributes.title}</Typography>
            <Typography sx={{ ...webStyle.priceText, marginTop: '11px' }}>{attributes.address}</Typography>
            <Typography sx={{ ...webStyle.infoSubText, marginTop: '11px' }}>{this.getDatesString()}</Typography>
            <Typography sx={{ ...webStyle.infoSubText, marginTop: '11px' }}>{`${total_guest_count} Guests • ${total_horse_count} Horses`}</Typography>
          </Box>
        </Box>
      </Box>)
  }

  renderImportantInfo = () => {
    const { check_in_time, check_out_time } = this.state.bookingDetailsConfirm.attributes;
    return (
      <Box sx={webStyle.cardContainer}>
        <Typography sx={webStyle.containerTitle}>Important Info</Typography>
        <Box sx={webStyle.infoItemContainer}><Box sx={webStyle.center}><AccessTimeIcon sx={webStyle.infoIcons} /></Box><Box sx={webStyle.center}><Typography sx={webStyle.infoItem}>Check in time {check_in_time}</Typography></Box></Box>
        <Box sx={webStyle.infoItemContainer}><Box sx={webStyle.center}><AccessTimeIcon sx={webStyle.infoIcons} /></Box><Box sx={webStyle.center}><Typography sx={webStyle.infoItem}>Check out time {check_out_time}</Typography></Box></Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Box sx={webStyle.infoItemContainer}><Box sx={webStyle.center}><AttachMoneyIcon sx={webStyle.infoIcons} /></Box><Box sx={webStyle.center}><Typography sx={webStyle.infoItem}>10% Service Fee</Typography></Box></Box>
          <Box sx={webStyle.infoItemContainer}><Box sx={webStyle.center}></Box><Box sx={webStyle.center}><Typography sx={{ ...webStyle.infoItem, color: '#3B82F6', cursor: 'pointer' }}>Cancelation & Refund Policy</Typography></Box></Box>
        </Box>
      </Box>)
  }

  renderTripInfo = () => {
    return (
      <Box sx={webStyle.cardContainer}>
        <Typography sx={webStyle.containerTitle}>Check the trip information 23</Typography>
        <Typography sx={{ ...webStyle.priceTitle, paddingBottom: '4px' }}>Dates</Typography>
        <Typography sx={{ ...webStyle.priceText, marginBottom: '16px' }}>{this.getDatesString()}</Typography>
        <Typography sx={{ ...webStyle.priceTitle, paddingBottom: '4px' }}>Guests accommodations</Typography>
        <Typography sx={{ ...webStyle.priceText, marginBottom: '16px' }}>{this.renderGuestAccoodationsText()}</Typography>
        <Typography sx={{ ...webStyle.priceTitle, paddingBottom: '4px' }}>Horses stabling</Typography>
        <Typography sx={{ ...webStyle.priceText, marginBottom: '16px' }}>{this.renderHorseStablingText()}</Typography>
        <Typography sx={{ ...webStyle.priceTitle, paddingBottom: '4px' }}>Amenities</Typography>
        <Typography sx={{ ...webStyle.priceText, marginBottom: '16px' }}>{this.renderAmenitiesText()}</Typography>
      </Box>
    )
  }

  renderProfile = () => {
    const { host_account } = this.state.bookingDetailsConfirm.attributes;
    return <Box sx={{ display: 'flex', flexDirection: 'row' }}>
      <Box>
        {host_account.profile_photo?.url ? <img
          src={host_account.profile_photo?.url || 'https://www.gravatar.com/avatar/2c7d99fe281ecd3bcd65ab915bac6dd5?s=250'}
          style={{
            width: '44px',
            height: '44px',
            borderRadius: '50%',
            objectFit: 'cover'
          }} /> :
          <AccountCircleIcon sx={{ height: '44px', width: '44px', color: '#64748B' }} />
        }
      </Box>
      <Box sx={{ marginLeft: '8px', width: '100%', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Box sx={{ width: '280px' }}><Typography sx={{ ...webStyle.profileText, marginRight: '30px' }}>{host_account.first_name + ' ' + host_account.last_name}</Typography></Box>
          <Box sx={{ marginLeft: '10px' }}><Typography sx={webStyle.profileText}>
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.91692 9.92509C2.08644 10.0545 2.29127 10.0138 2.54202 9.82155L5 7.93564L7.45798 9.82155C7.70873 10.0138 7.91709 10.0545 8.08308 9.92509C8.24553 9.79567 8.28438 9.58119 8.18549 9.27057L7.22137 6.24571L9.69701 4.38198C9.94775 4.19339 10.0466 3.9974 9.97954 3.79032C9.91597 3.59063 9.73233 3.4834 9.41448 3.48709L6.37732 3.50558L5.44851 0.46963C5.35316 0.155311 5.20483 0 5 0C4.79517 0 4.64684 0.155311 4.55149 0.46963L3.62268 3.50558L0.585516 3.48709C0.271205 3.4834 0.0840309 3.59063 0.0204624 3.79032C-0.0466378 3.9974 0.0522467 4.19339 0.302989 4.38198L2.77863 6.24571L1.81804 9.27057C1.71562 9.58119 1.75447 9.79567 1.91692 9.92509Z" fill="#003249" />
            </svg>
            <span style={{ paddingLeft: '6px' }}>{host_account.average_rating_count.toFixed(1)}</span>
          </Typography></Box></Box>
        <Typography sx={webStyle.profileInfo}>{host_account.listings_count} listing • {host_account.reviews_count} Ratings</Typography></Box>

    </Box>
  }

  renderAdditionalInfo = () => {
    return (
      <Box sx={webStyle.cardContainer}>
        <Typography sx={webStyle.containerTitle}>Additional information </Typography>
        {this.renderProfile()}
        <Box>
          <TextField
            multiline
            rows={4}
            variant="outlined"
            value={this.state.additionalMsg}
            onChange={(e) => this.setState({ additionalMsg: e.target.value })}
            inputProps={{ maxLength: 500 }}
            sx={{ width: '100%', marginTop: '16px', borderRadius: '8px' }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}><Typography variant="caption" color="textSecondary">
            {`${this.state.additionalMsg.length} / 500 characters`}
          </Typography></Box>
        </Box>
      </Box>)
  }
  // Customizable Area End


  render() {
    // Customizable Area Start
    return (
      <Header
        data-test-id="header"
        navigation={this.props.navigation}
      >
        <Wrapper sx={{ backgroundColor: "#F8FAFC" }}>
          {this.state.isloading ? (
            <Box className="loading">
              <CircularProgress sx={{ color: '#000' }} />
            </Box>
          ) : this.state.bookingDetailsConfirm?.attributes && (
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', backgroundColor: "#F8FAFC" }}>
              <Box sx={{ display: 'flex', margin: '40px 135px 40px 40px', flexDirection: 'column', marginBottom: '60px' }}>
                <Box data-test-id={'back-press'} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer', marginBottom: '33px' }} onClick={this.onBackPress}>
                  <Box><ArrowBackIcon sx={{ height: '48px', color: '#003249' }} /></Box><Box sx={{ marginLeft: '10px', justifyContent: 'center', alignItems: 'center', paddingTop: '8px' }}><Typography sx={{ ...webStyle.priceTitle }}>BOOKING</Typography></Box>
                </Box>
                {this.renderBasicDetails()}
                {this.renderImportantInfo()}
                {this.renderTripInfo()}
                {this.renderAdditionalInfo()}
                {this.renderBookingConfirmPopup()}
              </Box>
              <Box sx={{
                backgroundColor: '#FFFFFF', minWidth: '500px', padding: '40px 40px 40px 24px', boxShadow: '-2px 0px 8px 0px #00000014'
              }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', paddingLeft: '16px', justifyContent: 'space-between' }}>
                  <Typography sx={webStyle.priceTitle}>PAYMENT DETAIL</Typography>
                </Box>
                <Box>
                  <Typography sx={{ ...webStyle.totalPriceText, marginTop: '36px', paddingLeft: '16px', marginBottom: '24px' }}>{this.state.bookingDetailsConfirm.attributes.total_nights + ' nights'}</Typography>
                  {this.renderPaymentDetails()}
                </Box>
              </Box>
            </Box>)}
        </Wrapper>
      </Header>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const Dot = styled("div")({
  width: "10px",
  height: "10px",
  backgroundColor: "#fff",
  borderRadius: "50%",
  transition: "background-color 0.3s ease",
});

const CustomDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    borderRadius: "16px",
    maxWidth: "550px"
  },
  "& .title": {
    textAlign: "center",
    paddingTop: "35px",
  },
  "& .MuiDialogContent-root": {
    padding: "30px 30px 18px !important"
  },
  "& .dialog-content": {
    textAlign: "center",
    "& .popup-title": {
      letterSpacing: "0px",
      marginBottom: "5px",
      fontFamily: "Spartan",
      fontWeight: "700",
      fontSize: "18px",
      color: "#003249"
    },
    "& .popup-desc": {
      letterSpacing: "-2%",
      fontFamily: "Spartan",
      fontWeight: "500",
      fontSize: "15px",
      color: "#94A3B8"
    },
  },
  "& .dialog-action": {
    gap: "10px",
    padding: "30px",
    justifyContent: "center",
  },
  "& .close-btn": {
    color: "#003249",
    height: "15px",
    width: "15px",
  },
  "&.change-host": {
    "& .MuiDialogContent-root": {
      paddingTop: "15px !important",
      paddingBottom: "15px !important",
    }
  }
})

const webStyle = {
  cardContainer: {
    backgroundColor: 'white',
    marginBottom: '40px',
    padding: '20px',
    width: '670px',
    borderRadius: '16px',
    boxShadow: '0px 0px 18px 0px #00000014',
    "& .slider": SliderStyles,
  },
  cardTitle: {
    fontSize: "14px",
    lineHeight: '22px',
    fontFamily: "Spartan",
    fontWeight: "700",
    color: '#003249'
  },
  inputHeader: {
    fontFamily: 'Spartan',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '22px',
    letterSpacing: '-2%',
    color: '#003249'
  },
  dateContainer: {
    minWidth: '45%',
    position: 'relative',
    zIndex: 99,
  },
  dateWrapper: {
    zIndex: '9999',
    top: '90px',
    cursor: 'default',
  },
  infoItem: {
    color: '#475569',
    fontFamily: 'Spartan',
    fontWeight: 500,
    fontSize: '14px',
    marginTop: '3px',
  },
  infoItemContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center',
    padding: '4px',
    margin: '4px'
  },
  containerTitle: {
    color: '#003249',
    fontFamily: 'Spartan',
    fontWeight: 700,
    fontSize: '20px',
    LineHeight: '28px',
    marginBottom: '16px'
  },
  profileText: {
    color: ' #003249',
    fontFamily: 'Spartan',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '22px',
    letterSpacing: '-3%',
    textTransform: 'none'
  },
  profileInfo: {
    color: ' #475569',
    fontFamily: 'Spartan',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '-3%',
    textTransform: 'none'
  },
  infoIcons: {
    height: '16px',
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  priceTitle: {
    fontFamily: 'Spartan',
    fontWeight: 700,
    fontSize: '16px',
    lineheight: '24px',
    letterSpacing: '-2%',
    color: '#003249'
  },
  totalPriceText: {
    fontFamily: 'Spartan',
    fontWeight: 500,
    fontSize: '16px',
    lineheight: '24px',
    letterSpacing: '-2%',
    color: '#003249'
  },
  priceText: {
    fontFamily: 'Spartan',
    fontWeight: 500,
    fontSize: '14px',
    lineheight: '24px',
    color: '#003249',
  },
  groupWrapper: {
    borderRadius: '8px',
    border: '1px solid #E2E8F0',
    padding: '16px 10px',
    marginBottom: '16px'
  },
  confirmSubText: {
    fontFamily: 'Spartan',
    fontWeight: 500,
    fontSize: '14px',
    lineheight: '24px',
    color: '#64748B',
  },
  infoTitle: {
    fontFamily: 'Spartan',
    fontWeight: 700,
    fontSize: '18px',
    lineheight: '26px',
    color: '#003249',
  },
  infoSubText: {
    fontFamily: 'Spartan',
    fontWeight: 500,
    fontSize: '14px',
    lineheight: '24px',
    color: '#475569',
  },
  priceBlockWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginRight: '44px',
    marginBottom: '8px'
  },
  checkOutField: {
    border: '1px solid #CBD5E1',
    borderRadius: '8px',
    padding: '10px 8px',
  }
}
const BlackButton = styled(Button)({
  padding: "8px 10px",
  width: "100%",
  borderRadius: "8px",
  textTransform: "none",
  fontSize: "16px",
  fontFamily: "Spartan",
  whiteSpace: "nowrap",
  fontWeight: "700",
  color: "#fff",
  backgroundColor: "#003249",
  border: "1.5px solid #003249",
  "&:hover": {
    color: "#fff",
    backgroundColor: "#003249",
  }
});

const GrayButton = styled(Button)({
  padding: "8px 10px",
  width: "100%",
  borderRadius: "8px",
  textTransform: "none",
  fontSize: "16px",
  fontFamily: "Spartan",
  whiteSpace: "nowrap",
  fontWeight: "500",
  color: '#003249',
  border: '1px solid #003249',
  "&:hover": {
  }
});

const CustomAccordion = styled(Accordion)(({ theme }) => ({
  border: 'none !important',
  boxShadow: 'none !important',
  padding: '0px',
  margin: '0px',
  position: 'relative',
  '&::before': {
    position: 'absolute',
    left: 0,
    top: '-1px',
    right: 0,
    height: '1px',
    content: '""',
    opacity: 1,
    backgroundColor: 'transparent',
    transition: 'opacity 150ms cubic-bezier(0.4, 0, 0.2, 1), background-color 150ms cubic-bezier(0.4, 0, 0.2, 1)',
  },
}));

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  padding: '0px',
  border: 'none !important',
  boxShadow: 'none !important',
  margin: '0px',
  outline: 'none',
}));

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: '0px',
  border: 'none !important',
  boxShadow: 'none !important',
  margin: '0px',
  outline: 'none',
}));

const Wrapper = styled(Box)({
  flex: "1 1 auto",
  backgroundColor: "#F8FAFC",
  '& .loading': {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  "& .heading": {
    fontSize: "18px",
    fontFamily: "Spartan",
    fontWeight: "700"
  },
  "& .not-found": {
    marginTop: "80px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .title": {
      marginTop: "20px",
      fontSize: "16px",
      fontFamily: "Spartan",
      fontWeight: "500",
      color: "#64748B"
    }
  },
  "& .search-title": {
    fontSize: "18px",
    fontFamily: "Spartan",
    fontWeight: "700",
    color: "#003249"
  },
  '& .MuiBadge-badge': {
    minWidth: "25px",
    height: "25px",
    marginTop: "3px",
    borderRadius: "15px",
    paddingTop: "3px",
    backgroundColor: '#003249',
    color: '#FFFFFF',
    fontSize: '12px',
    fontWeight: '700',
    fontFamily: "Spartan",
  },
  "& .sub-title": {
    fontSize: "14px",
    fontFamily: "Spartan",
    fontWeight: "500"
  },
  "& .gridContainer": {
    padding: "20px",
    "& .card": {
      height: "100%"
    },
    "& .grid-item": {
      flex: 1,
      display: "flex",
      flexDirection: "column"
    }
  },
  "& .slick-slider .slick-list": {
    height: "185px",
    borderRadius: "16px",
  },
  "& .slick-prev, .slick-next": {
    display: "none !important",
  },
  ".slick-dots": {
    position: "absolute",
    bottom: "14px",
    display: "flex !important",
    justifyContent: "center",
    listStyle: "none",
    padding: 0,
    margin: 0,
  },
  ".slick-dots li": {
    height: "12px",
    width: "12px",
    margin: "0px 1px",
    display: "flex",
    alignItems: "center"
  },
  ".slick-dots li div": {
    opacity: "0.5",
    height: "6px",
    width: "6px",
    backgroundColor: "#fff"
  },
  ".slick-dots li button": {
    opacity: 0,
    cursor: "pointer",
  },
  ".slick-dots li.slick-active div": {
    height: "8px",
    width: "8px",
    opacity: "1",
    backgroundColor: "#fff",
  },
  "& .card": {
    border: "1px solid #E2E8F0",
    padding: "15px",
    borderRadius: "24px",
    boxShadow: "0px 2px 8px 0px #00000014",
    "& .rating": {
      marginLeft: "3px",
      fontSize: "12px",
      fontFamily: "Spartan",
      fontWeight: "700"
    },
    "& .image": {
      borderRadius: "16px",
    },
    "& .content": {
      display: "flex",
      flexDirection: "column",
      gap: "7px",
      padding: "0px"
    },
    "& .title": {
      fontSize: "16px",
      fontFamily: "Spartan",
      fontWeight: "700"
    },
    "& .location": {
      fontSize: "14px",
      fontFamily: "Spartan",
      fontWeight: "500"
    },
    "& .details, & .guest": {
      fontSize: "12px",
      fontFamily: "Spartan",
      fontWeight: "400"
    },
    "& .price": {
      fontSize: "12px",
      fontFamily: "Spartan",
      fontWeight: "500"
    }
  },
  "& .filterButton": {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "20px",
  },
  "& .map-view": {
    display: "flex",
    height: "calc(100vh - 75px)",
    "& .left-section": {
      padding: "10px 15px",
      width: "415px",
      overflow: "auto",
      "& .loading": {
        display: "flex",
        justifyContent: "center",
        marginTop: "20px",
        marginBottom: "20px",
        "& .MuiCircularProgress-root": {
          color: "#000"
        }
      }
    },
    "& .right-section": {
      width: "100%",
      position: "relative",
    }
  }
});
// Customizable Area End