// Customizable Area Start
import React from "react";
import {
  Box, CircularProgress, IconButton, Button, Radio, ClickAwayListener,
  List, ListItemText, ListItemButton, InputBase, GlobalStyles, Typography,
} from "@mui/material";
import {
  KeyboardArrowRightOutlined, Edit, CameraAltOutlined,
  Close, Search, ArrowBack, DeleteOutline, AttachMoneyRounded,
  AddCircleOutlineOutlined, KeyboardArrowDownOutlined, MoreVert,
} from "@mui/icons-material";
import * as Yup from 'yup';
import moment from "moment";
import Select from "react-select";
import { toast } from "react-toastify";
import { styled } from '@mui/material/styles';
import { Formik, Form, ErrorMessage } from "formik";
import { TimePicker, Upload, Dropdown, Button as AntdButton } from "antd";

import CreateListingController, { IGuest, ItemType } from "./CreateListingController.web";
import Header from "../../../../components/src/AppHeader.web";
import Loader from "../../../../components/src/Loader.web";
import PaymentInfo from "../../../user-profile-basic/src/components/PaymentInfo.web";
import CommonDatePicker from "../../../../components/src/helper/CommonDatePicker.web";
import KnowYourCustomerKycVerification from "../../../knowyourcustomerkycverification/src/KnowYourCustomerKycVerification.web";

const PastureIcon = require("../../assets/pasture.svg").default;
const StallIcon = require("../../assets/stall.svg").default;
import { ApartmentIcon, CampingIcon, HookupIcon } from "../assets";

import "antd/dist/antd.css";

const MAX_FILES = 15;
const NUMBER = Array.from({ length: 20 }, (_, i) => i + 1);
// Customizable Area End

export default class CreateListing extends CreateListingController {
  // Customizable Area Start
  generalInfoValidationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    location: Yup.string().required("Location is required"),
    checkIn: Yup.string().required("Check-In time is required"),
    checkOut: Yup.string()
      .required("Check-Out time is required")
      .test("is-after-checkIn", "CheckOut must be after checkIn", function (checkOut) {
        const { checkIn } = this.parent;
        return checkIn && checkOut ? checkIn < checkOut : true;
      }),
    description: Yup.string().required("Description is required"),
    photos: Yup.array().min(1, "At least one photo is required"),
  })

  horseStablingValidationSchema = Yup.object().shape({
    horseStabling: Yup.array().of(
      Yup.object().shape({
        horse_capacity: Yup.string().required("Horse is required"),
        price: Yup.string()
          .when("horse_capacity", {
            is: (horse_capacity) => horse_capacity !== "0",
            then: Yup.string()
              .required("Price is required")
              .test("not-zero", "Price can't be 0", (value) => {
                if (!value) return false;
                return Number(value) !== 0;
              }),
            otherwise: Yup.string(),
          }),
      })
    ).test("all-zero-and-empty-price", "At least one horse capacity must be greater than 0",
      function (horseStabling) {
        const isAllZeroAndEmpty = horseStabling.every(
          (item: { horse_capacity: string; price: string | null; }) => {
            return item.horse_capacity === "0" && !item.price;
          })
        return !isAllZeroAndEmpty;
      })
  })

  errorMsg = (touched: any, errors: any, value: string) => {
    return (touched[value] && errors[value])
      ? <div className="error">{errors[value]}</div>
      : <div />
  }

  allowedDescription = (value: string) => {
    if (value.length > 1000) {
      return value.slice(0, 1000);
    }
    return value;
  }

  getTime = (time: moment.Moment | null) => {
    return time ? time.format("HH:mm") : "";
  }

  getTrimValue = (val: string) => {
    if (val.trim() === "") {
      return "";
    } else {
      return val;
    }
  }

  renderGeneralInfo = () => {
    return (
      <Box className="wrapper general-info">
        <Box width="550px">
          <GlobalStyles
            styles={{
              ".ant-picker-time-panel-column": {
                "::-webkit-scrollbar": { width: "4px", height: "4px" },
              },
              ".ant-btn, .ant-btn:hover": { borderColor: "#003249", backgroundColor: "#003249" }
            }}
          />
          <Typography className="main-title">General Information</Typography>
          <Formik
            data-test-id="formik"
            enableReinitialize
            initialValues={{
              title: this.state.title,
              location: this.state.location,
              latitude: this.state.latitude,
              longitude: this.state.longitude,
              checkIn: this.state.checkIn,
              checkOut: this.state.checkOut,
              description: this.state.description,
              photos: this.state.photos
            }}
            validationSchema={this.generalInfoValidationSchema}
            onSubmit={(values) => this.updateGeneralInfo(values)}
          >
            {({ values, touched, isValid, errors, dirty, setTouched, setFieldValue }) => (
              <Form>
                <Box className="card-wrapper">
                  <Box className="card">
                    <Box mb="20px">
                      <Typography className="title">Title *</Typography>
                      <CustomInputBase
                        value={values.title}
                        data-test-id="title"
                        placeholder="add listing name"
                        onChange={(e) => {
                          setTouched({ ...touched, title: true });
                          setFieldValue("title", this.getTrimValue(e.target.value));
                        }}
                      />
                      {this.errorMsg(touched, errors, "title")}
                    </Box>
                    <Box mb="20px">
                      <Typography className="title">Location *</Typography>
                      <CustomInputBase
                        data-test-id="location"
                        placeholder="Search..."
                        value={values.location}
                        startAdornment={<Search />}
                        onChange={(e) => {
                          setTouched({ ...touched, location: true });
                          setFieldValue("location", this.getTrimValue(e.target.value));
                        }}
                      />
                      {this.errorMsg(touched, errors, "location")}
                    </Box>
                  </Box>
                  <Box className="card">
                    <Box display="flex" gap="20px">
                      <Box width="100%">
                        <Typography className="title">Check-In Time *</Typography>
                        <CustomTimePicker
                          format="HH:mm"
                          showNow={false}
                          placeholder="00:00"
                          data-test-id="checkIn"
                          value={values.checkIn ? moment(values.checkIn, "HH:mm") : null}
                          suffixIcon={<KeyboardArrowDownOutlined style={{ color: "#000" }} />}
                          onChange={(time) => {
                            setTouched({ ...touched, checkIn: true });
                            setFieldValue("checkIn", this.getTime(time));
                          }}
                        />
                        {this.errorMsg(touched, errors, "checkIn")}
                      </Box>
                      <Box width="100%">
                        <Typography className="title">Check-Out Time *</Typography>
                        <CustomTimePicker
                          format="HH:mm"
                          showNow={false}
                          placeholder="00:00"
                          data-test-id="checkOut"
                          value={values.checkOut ? moment(values.checkOut, "HH:mm") : null}
                          suffixIcon={<KeyboardArrowDownOutlined style={{ color: "#000" }} />}
                          onChange={(time) => {
                            setTouched({ ...touched, checkOut: true });
                            setFieldValue("checkOut", this.getTime(time));
                          }}
                        />
                        {this.errorMsg(touched, errors, "checkOut")}
                      </Box>
                    </Box>
                    <Box mt="20px">
                      <Typography className="title">Description *</Typography>
                      <CustomInputBase
                        multiline
                        minRows={5}
                        data-test-id="description"
                        value={values.description}
                        placeholder="Add more information about your listing"
                        onChange={(e) => {
                          const value = this.allowedDescription(e.target.value);
                          setTouched({ ...touched, description: true });
                          setFieldValue("description", this.getTrimValue(value));
                        }}
                      />
                      <Box display="flex" justifyContent="space-between">
                        {this.errorMsg(touched, errors, "description")}
                        <div className="limit">{values.description.length}/1000</div>
                      </Box>
                    </Box>
                  </Box>
                  <Box pb="15px" className="card">
                    <Typography className="title">Photo Gallery *</Typography>
                    <Upload.Dragger
                      name="file"
                      multiple
                      data-test-id="photos"
                      maxCount={MAX_FILES}
                      showUploadList={false}
                      fileList={values.photos}
                      beforeUpload={this.beforeUpload}
                      disabled={values.photos.length >= MAX_FILES}
                      onChange={({ fileList }) => {
                        if (fileList.length > MAX_FILES) {
                          toast.error(`You can only upload up to ${MAX_FILES} photos.`);
                          return;
                        }
                        setTouched({ ...touched, photos: true });
                        setFieldValue("photos", fileList);
                      }}
                    >
                      <Box className={`upload ${values.photos.length === MAX_FILES && "max"}`}>
                        <CameraAltOutlined className="icon" />
                        <Typography className="info">
                          Upload up to {MAX_FILES} photos
                        </Typography>
                        <Typography className="type">
                          HEIC or JPEG up to 5MB
                        </Typography>
                      </Box>
                    </Upload.Dragger>
                    {this.errorMsg(touched, errors, "photos")}
                    {!errors.photos && (values.photos.length < MAX_FILES
                      ? <Typography className="limit">{values.photos.length}/{MAX_FILES} photos uploaded</Typography>
                      : <Typography className="limit">{MAX_FILES}/{MAX_FILES} photos uploaded. To add a new image, you need to delete one of the existing ones.</Typography>
                    )}
                    {values.photos.length > 0 && (
                      <Box className="image-wrapper">
                        {values.photos.map((file, index) => (
                          <Box position="relative" key={`${index + 1}`}>
                            <Box
                              className="remove-image"
                              data-test-id="remove-photo"
                              onClick={() => {
                                const photos = values.photos.filter((_, index2) => index2 !== index);
                                if (file.isUrl) {
                                  this.setState((prevState) => ({ removeIds: prevState.removeIds.concat(file.uid) }));
                                }
                                setTouched({ ...touched, photos: true });
                                setFieldValue("photos", photos);
                              }}
                            >
                              <Close />
                            </Box>
                            <img src={file.url || URL.createObjectURL(file.originFileObj as Blob)} alt={`Photo ${index + 1}`} />
                          </Box>
                        ))}
                      </Box>
                    )}
                  </Box>
                  <BlackButton type="submit" disabled={!(isValid && dirty)}>Save</BlackButton>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    );
  }

  renderHorseStabling = () => {
    return (
      <Box className="wrapper horse-stabling">
        <Box width="550px">
          <Typography className="main-title">Horse & Stabling</Typography>
          <Formik
            enableReinitialize
            data-test-id="formik"
            initialValues={{
              horseStabling: this.state.horseStabling,
            }}
            validationSchema={this.horseStablingValidationSchema}
            onSubmit={(values) => this.updateHorseStabling(values)}
          >
            {({ values, isValid, dirty, setFieldTouched, setFieldValue }) => (
              <Form>
                <Box className="card-wrapper">
                  {values.horseStabling.map((item, index) => (
                    <Box key={item.name} className="card">
                      <Box className="center">
                        <Box display="flex" alignItems="center">
                          <img src={item.type === "indoor" ? StallIcon : PastureIcon} alt={item.type} />
                          <Typography className="title top">{item.name}</Typography>
                        </Box>
                        <Box display="flex" flexDirection="column">
                          <Box alignSelf="end">
                            <Typography className="title mb">Horses</Typography>
                            <CustomSelect
                              isSearchable={false}
                              data-test-id="horses"
                              classNamePrefix="dropdown"
                              components={{ IndicatorSeparator: () => null }}
                              options={NUMBER.map((val) => ({ label: val, value: val }))}
                              value={{ label: item.horse_capacity, value: item.horse_capacity }}
                              onChange={(e: any) => {
                                setFieldValue(`horseStabling[${index}].horse_capacity`, e.value.toString());
                                setFieldValue(`horseStabling[${index}].horse_capacity`, e.value.toString());
                              }}
                            />
                          </Box>
                        </Box>
                      </Box>
                      {item.horse_capacity !== "0" && (
                        <Box mt="15px">
                          <Typography className="title mb">Price /night <span>(per horse)</span></Typography>
                          <CustomInputBase
                            data-test-id="price"
                            value={item.price ?? ""}
                            startAdornment={<AttachMoneyRounded sx={{ color: "#94A3B8" }} />}
                            onChange={(e) => {
                              const val = e.target.value;
                              if (!isNaN(Number(val)) || val === "") {
                                setFieldTouched(`horseStabling[${index}].price`, true, true);
                                setFieldValue(`horseStabling[${index}].price`, val.trim());
                              }
                            }}
                          />
                          <ErrorMessage name={`horseStabling[${index}].price`} component="div" className="error" />
                        </Box>
                      )}
                    </Box>
                  ))}
                  <BlackButton type="submit" disabled={!(isValid && dirty)}>Save</BlackButton>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    );
  }

  getHookupTitle = (name: string) => {
    return HOOKUP_OPTION.filter(({ value }) => name === value)[0]?.label;
  }

  threeDotMenu = (type: ItemType, index: number) => {
    const items = [
      { key: "1", label: "Edit", icon: <Edit />, onClick: () => this.editItem(type, index) },
      { key: "2", label: <Box color="#DC2626">Delete</Box>, icon: <DeleteOutline sx={{ fill: "#DC2626", fontSize: "16px" }} />, onClick: () => this.deleteItem(type, index) },
    ];
    return (
      <Dropdown
        menu={{ items }}
        trigger={["click"]}
        placement="bottomRight"
        getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentElement as HTMLElement}
      >
        <AntdButton type="text" shape="circle" icon={<MoreVert sx={{ color: '#475569' }} />} />
      </Dropdown>
    );
  }

  renderSavedInfo = (item: IGuest, type: ItemType, index: number) => (
    <Box className="card detail">
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Typography className="title">{type !== 'hookup' ? item.name : this.getHookupTitle(item.name)}</Typography>
          <Typography className="capacity">Guest capacity {item.guest}</Typography>
        </Box>
        <Box display="flex" gap="10px">
          <Box mt="2px" textAlign="end">
            <Typography className="title">${item.price}</Typography>
            <Typography className="capacity">per night</Typography>
          </Box>
          <Box mt="-8px">
            {this.threeDotMenu(type, index)}
          </Box>
        </Box>
      </Box>
    </Box>
  )

  getGuestAccommodationTitle = (type: ItemType) => {
    if (type === 'apartment') return 'Add apartment';
    if (type === 'campsite') return 'Camp Site Spots';
    else return 'Hookup Capacity';
  }

  getNameTitle = (type: ItemType) => {
    if (type === 'apartment') return 'Apartment name';
    if (type === 'campsite') return 'Spot name';
    else return 'Hookup spots';
  }

  renderEditForm = (item: IGuest, type: ItemType, index: number) => (
    <Box className="card">
      <Box display="flex">
        <Typography className="title">
          {this.getGuestAccommodationTitle(type)}
        </Typography>
        <IconButton
          className="close-btn"
          data-test-id="close-btn"
          onClick={() => this.deleteItem(type, index)}
        >
          <Close />
        </IconButton>
      </Box>
      <Box pl="10px" pt="15px">
        <Box>
          <Typography className="title mb">
            {this.getNameTitle(type)}
          </Typography>
          {type === 'hookup' ? (
            <Box className="tabs">
              {HOOKUP_OPTION.map(({ label, value }) => (
                <CustomButton
                  key={value}
                  data-test-id="tabs"
                  isSelect={item.name === value}
                  onClick={() => this.updateItemField(type, index, 'name', value)}
                >
                  {label}
                </CustomButton>
              ))}
            </Box>
          ) : (
            <CustomInputBase
              value={item.name}
              data-test-id="name"
              placeholder={type === 'apartment' ? 'Apartment name' : 'Spot name'}
              onChange={(e) => this.updateItemField(type, index, 'name', this.getTrimValue(e.target.value))}
            />
          )}
          <div className="error">{item.nameErr}</div>
        </Box>
        <Box className="guest-capacity">
          <Typography className="title">Guest Capacity</Typography>
          <CustomSelect
            isSearchable={false}
            data-test-id="guests"
            classNamePrefix="dropdown"
            components={{ IndicatorSeparator: () => null }}
            value={{ label: item.guest, value: item.guest }}
            options={NUMBER.map((val) => ({ label: val, value: val }))}
            onChange={(e: any) => this.updateItemField(type, index, 'guest', e.value)}
          />
        </Box>
        <Box mt="15px">
          <Typography className="title mb">
            Price /night {type === 'apartment' && <span>(per horse)</span>}
          </Typography>
          <CustomInputBase
            value={item.price}
            data-test-id="price"
            startAdornment={<AttachMoneyRounded sx={{ color: '#94A3B8' }} />}
            onChange={(e) => {
              const val = e.target.value;
              if (!isNaN(Number(val)) || val === '') {
                this.updateItemField(type, index, 'price', val.trim());
              }
            }}
          />
          <div className="error">{item.priceErr}</div>
        </Box>
      </Box>
      <BlackButton
        data-test-id="save-btn"
        onClick={() => this.handleItemSave(type, index)}
        disabled={!item.name || item.guest === '0' || Number(item.price) === 0}
      >
        Save
      </BlackButton>
    </Box>
  )

  renderList = (type: ItemType, icon: React.ReactNode, title: string) => (
    <Box className="card gaps">
      <Box className="center">
        <Box display="flex" alignItems="center">
          {icon}
          <Typography className="title top">{title}</Typography>
        </Box>
        <Box mr="-10px">
          <IconButton
            data-test-id="add-btn"
            onClick={() => this.addItem(type)}
            disabled={this.state.isAdding[type]}
            sx={{
              "&.Mui-disabled": {
                pointerEvents: "auto",
                cursor: "not-allowed",
                "& svg": {
                  color: "#94A3B8 !important"
                }
              }
            }}
          >
            <AddCircleOutlineOutlined sx={{ color: '#003249' }} />
          </IconButton>
        </Box>
      </Box>
      {this.state.lists[type].map((item, index) => (
        <Box key={`${index + 1}`}>
          {!(item.isNew || item.isEdit)
            ? this.renderSavedInfo(item, type, index)
            : this.renderEditForm(item, type, index)
          }
        </Box>
      ))}
    </Box>
  )

  isSaveDisabled = () => {
    const { isAdding, lists } = this.state;
    return (
      (isAdding.apartment || isAdding.campsite || isAdding.hookup) ||
      Object.values(lists).every((list) => list.length === 0)
    );
  }

  renderGuestAccommodation = () => {
    return (
      <Box className="wrapper guest-accommodation">
        <Box width="550px">
          <Typography className="main-title">Guest Accommodation</Typography>
          <Box className="card-wrapper">
            {this.renderList('apartment', ApartmentIcon, 'Apartment Guests')}
            {this.renderList('campsite', CampingIcon, 'Camping Spots')}
            {this.renderList('hookup', HookupIcon, 'Hookup Options')}
            <BlackButton
              data-test-id="save-data"
              disabled={this.isSaveDisabled()}
              onClick={this.updateGuestAccommodation}
            >
              Save
            </BlackButton>
          </Box>
        </Box>
      </Box>
    );
  }

  renderAmenities = () => {
    return (
      <Box className="wrapper">
        <Box width="550px">
          <Typography className="main-title">Amenities</Typography>
          <Box className="card-wrapper">
            {this.state.amenitiesList.map((item, index) => (
              <Box key={item.title} className="card">
                <Box className="center">
                  <Box display="flex" alignItems="center">
                    {item.icon && <img src={item.icon} className="icons" />}
                    <Typography className="title top">{item.type}</Typography>
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <Box alignSelf="end">
                      <Typography className="title mb">Horses</Typography>
                      <CustomSelect
                        isSearchable={false}
                        classNamePrefix="dropdown"
                        data-test-id="horse-dropdown"
                        components={{ IndicatorSeparator: () => null }}
                        options={NUMBER.map((val) => ({ label: val, value: val }))}
                        value={{ label: item.horse_capacity, value: item.horse_capacity }}
                        onChange={(e: any) => this.handleAmenityChange(index, "horse_capacity", e.value)}
                      />
                    </Box>
                    <div className="error">{item.horseErr}</div>
                  </Box>
                </Box>
                <Box className="radio-wrapper">
                  {["free", "set_price"].map((option) => (
                    <Box
                      key={option}
                      className="radio"
                      data-test-id="radio-btn"
                      onClick={() => this.handleAmenityChange(index, "selected", option)}
                    >
                      <CustomRadio
                        checked={item.selected === option}
                        icon={<span className="icon" />}
                        checkedIcon={<span className="checked-icon" />}
                      />
                      <Typography className="option">
                        {option === "free" ? "Free" : "Set a price"}
                      </Typography>
                    </Box>
                  ))}
                </Box>
                <div className="error">{item.radioErr}</div>
                {item.selected === "set_price" && (
                  <Box mt="15px">
                    <Typography className="title mb">Price / horse</Typography>
                    <CustomInputBase
                      data-test-id="price"
                      value={item.price ?? ""}
                      startAdornment={<AttachMoneyRounded sx={{ color: "#94A3B8" }} />}
                      onChange={(e) => {
                        const val = e.target.value;
                        if (!isNaN(Number(val)) || val === "") {
                          this.handleAmenityChange(index, "price", val.trim());
                        }
                      }}
                    />
                    <div className="error">{item.priceErr}</div>
                  </Box>
                )}
              </Box>
            ))}
            <BlackButton disabled={!this.state.isAmenityValid} data-test-id="save-btn" onClick={this.updateAmenities}>Save</BlackButton>
          </Box>
        </Box>
      </Box>
    );
  }

  renderDatePicker = (type: 'start' | 'end') => {
    let { currentOpen, startDate, endDate } = this.state;

    const isStart = type === 'start';
    const date = isStart ? startDate : endDate;
    const isOpen = currentOpen === `${type}Date`;
    const title = isStart ? 'Start Date' : 'End Date';

    return (
      <Box width="100%" position="relative">
        <Typography className="title" onClick={() => this.onChangeCurrentOpen('')}>
          {title}
        </Typography>
        <Box className="date-value" data-test-id="dates" onClick={() => this.onChangeCurrentOpen(isOpen ? '' : `${type}Date`)}>
          <Typography className="date">
            {date ? moment(date).format('MMM DD/YY') : <Box component="span" sx={{ color: 'hsl(0, 0%, 50%)' }}>Add date</Box>}
          </Typography>
        </Box>
        {isOpen && (
          <Box className="date-wrapper">
            <CommonDatePicker
              inline
              selectsEnd={!isStart}
              selectsRange={!isStart}
              selected={date}
              startDate={startDate}
              endDate={endDate}
              data-test-id="datepicker"
              disabledKeyboardNavigation
              minDate={isStart ? new Date() : startDate ?? new Date()}
              maxDate={!isStart && startDate ? new Date(new Date(startDate).setFullYear(startDate.getFullYear() + 1)) : null}
              onChange={(date) => this.handleDateChange(isStart, endDate, date)}
            />
          </Box>
        )}
      </Box>
    );
  }

  renderAvailability = () => {
    let { startDate, endDate, selectedDates } = this.state;
    const maxDate = new Date();
    maxDate.setFullYear(new Date().getFullYear() + 1);
    const btnTitle = this.getAvailabilitySaveTitle();

    return (
      <Box className="wrapper availability">
        <Box width="550px">
          <Typography className="main-title">Availability</Typography>
          <Typography className="sub-title">
            Manage your availability by selecting the dates you will block
          </Typography>
          <Box mt="30px" className="card-wrapper">
            <ClickAwayListener
              mouseEvent="onMouseDown"
              touchEvent="onTouchStart"
              onClickAway={() => this.onChangeCurrentOpen('')}
            >
              <Box>
                <Box display="flex" gap="15px">
                  {this.renderDatePicker('start')}
                  {this.renderDatePicker('end')}
                </Box>
              </Box>
            </ClickAwayListener>
            <Box className="datepicker">
              <CommonDatePicker
                inline
                selectsMultiple
                minDate={startDate}
                maxDate={endDate}
                data-test-id="calender"
                disabledKeyboardNavigation
                hightLightDates={selectedDates}
                onChange={this.handleSelectedDates}
                dayClassName={this.blackedDates}
              />
            </Box>
            <BlackButton
              data-test-id="save-availability"
              disabled={!(startDate && endDate)}
              onClick={() => this.updateAvailability(btnTitle)}
            >
              {btnTitle}
            </BlackButton>
          </Box>
        </Box>
      </Box>
    )
  }

  renderFunctions: Record<number, () => JSX.Element> = {
    0: this.renderGeneralInfo,
    1: this.renderHorseStabling,
    2: this.renderGuestAccommodation,
    3: this.renderAmenities,
    4: this.renderAvailability,
    5: () => <PaymentInfo {...this.props} onPaymentStatusChange={this.updateStatus} isHeader={true} />,
    6: () => <KnowYourCustomerKycVerification {...this.props} />
  }

  renderCreateListing = () => {
    return (
      <Box>
        <Box display="flex">
          <Box mt="30px">
            <Typography className="heading" data-test-id="back-btn" onClick={this.goBack}>
              <ArrowBack />
              <span>Create Listing</span>
            </Typography>
            <Box>
              <StyledList>
                {this.state.option.map(({ label, status, hash, required }, index) => (
                  <ListItemButton
                    key={label}
                    data-test-id="option"
                    onClick={() => this.onChangeOption(hash, index)}
                    className={`menu-item ${this.state.currentOption === index && "active"} ${(!this.state.listingId && index !== 0) && "invalid"}`}
                  >
                    <ListItemText
                      primary={<Typography className="label">{label} {required && "*"}</Typography>}
                      secondary={<Typography className={`status ${status.split(' ').join('-')}`}>{status}</Typography>}
                    />
                    <KeyboardArrowRightOutlined />
                  </ListItemButton>
                ))}
              </StyledList>
              <BlackButton disabled={!this.state.isAllCompleted} onClick={this.publishListing}>Post Listing</BlackButton>
            </Box>
          </Box>
          {this.state.loading ? (
            <Box flex="1">
              <Box className="loading">
                <CircularProgress sx={{ color: "#000" }} />
              </Box>
            </Box>
          ) : (
            <Box className="right-section" ref={this.scrollRef}>
              <Box className="preview-wrapper">
                <Typography
                  data-test-id="preview"
                  onClick={this.onPreview}
                  className={`preview ${this.state.listingId && "active"}`}
                >
                  Preview
                </Typography>
              </Box>
              {this.renderFunctions[this.state.currentOption]?.()}
            </Box>
          )}
        </Box>
      </Box>
    );
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <Header hideFilter navigation={this.props.navigation} active="Listings">
        <Wrapper>
          <Loader loading={this.state.isLoading} />
          {this.state.statusLoading ? (
            <Box className="loading">
              <CircularProgress sx={{ color: "#000" }} />
            </Box>
          ) : (
            <>
              {this.renderCreateListing()}
            </>
          )}
        </Wrapper>
      </Header>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const Wrapper = styled(Box)({
  height: "100%",
  paddingLeft: "35px",
  backgroundColor: "#F8FAFC",
  "& .loading": {
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .error": {
    letterSpacing: "-0.5px"
  },
  "& .center": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  "& .heading": {
    display: "flex",
    fontFamily: "Spartan",
    color: "#003249",
    fontWeight: "700",
    fontSize: "18px",
    letterSpacing: "-0.5px",
    textTransform: "uppercase",
    width: "fit-content",
    cursor: "pointer",
    "& span": {
      marginLeft: "20px"
    }
  },
  "& .preview": {
    fontFamily: "Spartan",
    cursor: "not-allowed",
    color: "#06D6A0",
    fontWeight: "700",
    fontSize: "16px",
    letterSpacing: "-0.5px",
    "&.active": {
      cursor: "pointer",
      color: "#003249",
    }
  },
  "& .not-found": {
    display: "flex",
    height: "100%",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    "& .no-listing": {
      marginTop: "20px",
      letterSpacing: "-0.5px",
      fontFamily: "Spartan",
      fontSize: "16px",
      fontWeight: "700",
      color: "#64748B",
    },
    "& .create-one": {
      marginTop: "5px",
      fontFamily: "Spartan",
      letterSpacing: "-0.5px",
      fontSize: "14px",
      fontWeight: "500",
      color: "#64748B",
    },
  },
  "& .right-section": {
    flex: "1",
    overflow: "auto",
    height: "calc(100vh - 65px)",
    "::-webkit-scrollbar": {
      width: "10px",
      height: "4px"
    },
    "& .preview-wrapper": {
      marginTop: "30px",
      display: "flex",
      justifyContent: "end",
      paddingRight: "20px",
    }
  },
  "& .wrapper": {
    flex: "1",
    padding: "0px 10px",
    display: "flex",
    justifyContent: "center",
    "& .main-title": {
      marginBottom: "25px",
      letterSpacing: "-0.5px",
      fontFamily: "Spartan",
      fontSize: "18px",
      fontWeight: "700",
      color: "#003249",
    },
    "& .sub-title": {
      fontFamily: "Spartan",
      fontSize: "14px",
      fontWeight: "500",
      color: "#64748B",
    },
    "& .card-wrapper": {
      display: "flex",
      gap: "25px",
      paddingBottom: "30px",
      flexDirection: "column",
      "& .card": {
        padding: "20px",
        borderRadius: "16px",
        backgroundColor: "#fff",
        border: "1px solid #E2E8F0",
        "& .icons": {
          height: "31px",
          width: "auto"
        },
        "& .title": {
          letterSpacing: "-0.5px",
          fontFamily: "Spartan",
          fontSize: "14px",
          fontWeight: "700",
          color: "#003249",
          "&.top": {
            marginLeft: "10px",
            textTransform: "none",
          },
          "&.mb": {
            marginBottom: "5px",
            "& span": {
              fontWeight: "500"
            }
          }
        },
        "& .radio-wrapper": {
          display: "flex",
          gap: "15px",
          marginLeft: "-5px",
          marginTop: "15px",
          "& .radio": {
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            gap: "2px",
            "& .option": {
              letterSpacing: "-0.5px",
              fontFamily: "Spartan",
              fontSize: "14px",
              fontWeight: "500",
              color: "#003249",
            }
          }
        },
        "& .limit": {
          marginTop: "5px",
          fontFamily: "Spartan",
          fontWeight: "400",
          fontSize: "13px",
          color: "#64748B",
          letterSpacing: "-0.5px",
        }
      }
    },
    "&.general-info": {
      "& .card-wrapper .card .title": {
        marginBottom: "8px"
      },
      "& .card": {
        boxShadow: "0px 2px 8px 0px #00000014",
        "& .ant-upload": {
          border: "none",
          padding: "0px",
          "&:hover": {
            borderColor: "unset"
          }
        }
      },
      "& .upload": {
        padding: "20px 10px 15px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        borderRadius: "8px",
        backgroundColor: "#fff",
        border: "1px dashed #003249",
        "&:hover": {
          backgroundColor: "#f5f5f5",
        },
        "& .info": {
          marginTop: "5px",
          marginBottom: "8px",
          fontFamily: "Spartan",
          color: "#003249",
          fontWeight: "700",
          fontSize: "14px",
          letterSpacing: "-0.5px",
          textDecoration: "underline",
        },
        "& .icon": {
          color: "#003249"
        },
        "&.max": {
          "& .info,& .icon": {
            color: "#94A3B8",
          }
        },
        "& .type": {
          fontFamily: "Spartan",
          color: "#475569",
          fontWeight: "400",
          fontSize: "12px",
          letterSpacing: "-0.5px",
        }
      },
      "& .image-wrapper": {
        marginTop: "10px",
        display: "flex",
        gap: "10px",
        flexWrap: "wrap",
        "& .remove-image": {
          cursor: "pointer",
          top: "-7px",
          right: "-7px",
          position: "absolute",
          background: "#f2e2e2",
          borderRadius: "50%",
          padding: "3px",
          "& svg": {
            fontSize: "12px"
          }
        }
      },
      "& img": {
        height: "76px",
        width: "93px",
        objectFit: "cover",
        borderRadius: "5px",
      },
    },
    "&.horse-stabling": {
      "& .card-wrapper .card .title.top": {
        marginTop: "7px"
      }
    },
    "&.guest-accommodation": {
      "& .card": {
        position: "relative",
        borderRadius: "8px",
        "&.detail": {
          paddingRight: "10px"
        },
        "& .title.top": {
          marginTop: "5px"
        },
        "& .capacity": {
          marginTop: "5px",
          fontSize: "14px",
          fontFamily: "Spartan",
          color: "#003249",
          fontWeight: "500",
        },
        "& .tabs": {
          marginTop: "10px",
          display: "flex",
          flexWrap: "wrap",
          gap: "10px",
        },
        "& .close-btn": {
          color: "#003249",
          position: "absolute",
          right: "8px",
          top: "11px"
        }
      },
      "& .gaps": {
        display: "flex",
        gap: "15px",
        borderRadius: "16px",
        flexDirection: "column",
      },
      "& .guest-capacity": {
        marginTop: "15px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
      },
    },
    "&.availability": {
      "& .main-title": {
        marginBottom: "10px"
      },
      "& .date-wrapper": {
        zIndex: "9999",
        position: "absolute",
        top: "73px",
        right: "0px",
        cursor: "default",
      },
      "& .date-value": {
        backgroundColor: "#fff",
        borderRadius: '8px',
        padding: '10px 8px',
        cursor: "pointer",
        border: '1px solid #CBD5E1',
      },
      "& .date": {
        fontFamily: "Spartan",
        fontWeight: "500",
        fontSize: "13px",
        color: "#003249"
      },
      "& .title": {
        fontFamily: "Spartan",
        fontSize: "14px",
        color: "#003249",
        fontWeight: "700",
        letterSpacing: "-0.5px",
        marginBottom: "5px",
      },
      "& .dropdown__control": {
        width: "100%",
        minHeight: "45px"
      },
      "& .dropdown__menu": {
        zIndex: 2
      },
      "& .datepicker": {
        backgroundColor: "#fff",
        borderRadius: "16px",
        padding: "0px 15px 20px 8px",
        border: "1.5px solid #E2E8F0",
        "& .date-heading": {
          padding: "20px 30px 0px 15px"
        },
        "& .react-datepicker": {
          border: "none",
        },
        "& .react-datepicker__month-container": {
          padding: "7px 0px"
        },
        "& .react-datepicker__day": {
          fontSize: "16px",
          width: "68px",
          height: "60px",
          margin: "3px",
          paddingTop: "14px",
          borderRadius: "8px",
        },
        "& .blocked-dates": {
          color: "#003249 !important",
          background: "repeating-linear-gradient(-45deg, #E7E5E4, #E7E5E4 7px, #fff 7px,  #fff 12px)",
        },
        "& .react-datepicker__day--highlighted": {
          color: "#fff !important",
          background: "none",
          backgroundColor: "#003249 !important",
          "&.blocked-dates": {
            textDecoration: "line-through"
          }
        },
        "& .react-datepicker__day-names": {
          gap: "58px",
          marginLeft: "34px",
        },
        "& .react-datepicker__navigation--next": {
          zIndex: 1
        }
      }
    }
  },
  "& .ant-dropdown": {
    width: "130px",
    "& .ant-dropdown-menu": {
      borderRadius: "8px",
      background: "#fff",
      border: "1px solid #E2E8F0",
      boxShadow: "0px 8px 32px 0px #0000000F",
      "& svg": {
        fontSize: "16px",
        color: "#475569",
      },
      "& .ant-dropdown-menu-item": {
        padding: "7px 13px"
      },
      "& .ant-dropdown-menu-title-content": {
        marginTop: "3px",
        fontSize: "14px",
        fontWeight: "500",
        color: "#475569",
        fontFamily: "Spartan",
      },
    }
  }
});

const StyledList = styled(List)({
  display: "flex",
  gap: "2px",
  flexDirection: "column",
  padding: "0px",
  marginTop: "20px",
  width: "260px",
  background: "#fff",
  height: "fit-content",
  overflow: "auto",
  borderRadius: "16px",
  boxShadow: "0px 2px 8px 0px #00000014",
  "::-webkit-scrollbar": {
    width: "4px",
    height: "4px"
  },
  "& .menu-item": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "5px 10px",
    paddingRight: "10px",
    borderRadius: "8px",
    "&:hover": {
      borderRadius: "16px",
      backgroundColor: "#F1F5F9",
    },
    "&.active": {
      borderRadius: "16px",
      backgroundColor: "#F1F5F9",
      "& .MuiListItemText-primary": {
        fontWeight: "700",
      }
    },
    "&.invalid": {
      cursor: "not-allowed",
      "&:hover": {
        cursor: "not-allowed",
      }
    }
  },
  "& .label": {
    fontFamily: "Spartan",
    color: "#003249",
    fontWeight: "500",
    fontSize: "14px",
    letterSpacing: "-0.5px",
  },
  "& .active": {
    "& .label": {
      fontWeight: "700",
    }
  },
  "& .status": {
    fontFamily: "Spartan",
    color: "#059669",
    fontWeight: "700",
    fontSize: "12px",
    letterSpacing: "-0.5px",
    "&.pending, &.in-progress": {
      color: "#D97706"
    }
  }
});

const CustomSelect = styled(Select)({
  "& .dropdown__control": {
    borderRadius: "8px",
    width: "75px",
    minHeight: "40px",
  },
  "& .dropdown__control:hover": {
    borderColor: "#CBD5E1"
  },
  "& .dropdown__control--is-focused": {
    boxShadow: "none",
    border: "1px solid #CBD5E1",
  },
  "& .dropdown__single-value": {
    fontFamily: "Spartan",
    fontSize: "14px",
    fontWeight: "500",
    color: "#003249",
  },
  "& .dropdown__menu-list::-webkit-scrollbar": {
    width: "4px",
    height: "4px"
  },
  "& .dropdown__option": {
    fontFamily: "Spartan",
    fontSize: "14px",
    fontWeight: "500",
    color: "#64748B",
    padding: "12px"
  },
  "& .dropdown__option--is-selected": {
    backgroundColor: "#E2E8F0",
    color: "#003249",
  },
  "& .dropdown__option--is-focused:hover": {
    color: "#003249",
    backgroundColor: "#E2E8F0"
  }
});

const CustomRadio = styled(Radio)({
  padding: "5px",
  "& .MuiSvgIcon-root": {
    fontSize: 32,
  },
  "& .icon": {
    height: 20,
    width: 20,
    borderRadius: "50%",
    boxSizing: "border-box",
    border: "1px solid #64748B",
  },
  "& .checked-icon": {
    height: 20,
    width: 20,
    borderRadius: "50%",
    border: "6px solid #003249",
  }
});

const CustomInputBase = styled(InputBase)({
  width: "100%",
  padding: "8px 8px",
  borderRadius: "6px",
  fontFamily: "Spartan",
  fontWeight: "500",
  fontSize: "14px",
  color: "#003249",
  border: "1.5px solid #E2E8F0",
  "& input": {
    height: "auto"
  },
  "& svg": {
    marginRight: "5px"
  },
  "& .MuiInputBase-inputMultiline": {
    fontSize: "14px",
    fontFamily: "Spartan",
    color: "#003249",
    fontWeight: "500",
  }
});

const BlackButton = styled(Button)({
  padding: "8px 10px",
  width: "100%",
  marginTop: "30px",
  borderRadius: "8px",
  textTransform: "none",
  fontSize: "14px",
  fontFamily: "Spartan",
  letterSpacing: "-0.5px",
  whiteSpace: "nowrap",
  fontWeight: "700",
  color: "#fff",
  backgroundColor: "#003249",
  border: "1.5px solid #003249",
  "&:hover": {
    color: "#fff",
    backgroundColor: "#003249",
  },
  "&.Mui-disabled": {
    color: "#fff",
    backgroundColor: "#94A3B8",
    border: "1.5px solid #94A3B8",
  },
});

const CustomTimePicker = styled(TimePicker)({
  height: "48px",
  width: "100%",
  borderRadius: "8px",
  border: "1.5px solid #E2E8F0",
  "&:hover, &:focus-within": {
    borderColor: "#E2E8F0",
    boxShadow: "none"
  },
  "& .ant-picker-clear": {
    right: "5px"
  },
});

const CustomButton = styled(Button)<{ isSelect?: boolean }>(({ isSelect }) => ({
  fontSize: "12px",
  fontFamily: "Spartan",
  whiteSpace: "nowrap",
  borderRadius: "8px",
  letterSpacing: "-0.5px",
  textTransform: "none",
  fontWeight: `${isSelect ? "700" : "400"}`,
  color: `${isSelect ? "#003249" : "#64748B"}`,
  padding: `${isSelect ? "5px 8.8px" : "5px 10px"}`,
  backgroundColor: `${isSelect ? "#F1F5F9" : "#fff"}`,
  border: `1.5px solid ${isSelect ? "#003249" : "#64748B"}`,
}));

const HOOKUP_OPTION = [
  { label: "Full Hookup", value: "full_hookups" },
  { label: "Partial Hookup", value: "partial_hookups" },
  { label: "Dry Hookup", value: "dry_hookups" }
];
// Customizable Area End