// Customizable Area Start
import { createRef } from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { apiCall } from "../../../../components/src/NavigationRouteWrapper/helpers";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  isLoading: boolean;
  id: string;
  data: any;
  copied: boolean;
  isShowAll: boolean;
  // Customizable Area End
}

interface SS {
  id: string;
}

export default class PreviewListingController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getListInfoApiCallId: string = "";
  deleteListingApiCallId: string = "";
  publishListingApiCallId: string = "";
  scrollRef: React.RefObject<HTMLDivElement> = createRef();
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      loading: false,
      isLoading: false,
      id: "",
      data: null,
      copied: false,
      isShowAll: false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getListInfoApiCallId) {
        if (responseJson.data) {
          this.setState({ data: responseJson.data.attributes });
        } else if (Array.isArray(responseJson.errors)) {
          const msg = responseJson.errors[0].Listing.message;
          if (msg === "You are not authorized to access this listing.") {
            toast.error(msg);
          }
        }
        this.setState({ loading: false });
      } else {
        this.handleResponse(apiRequestCallId, responseJson);
      }
    }
  }

  handleResponse = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.deleteListingApiCallId) {
      if (Array.isArray(responseJson.message)) {
        const msg = responseJson.message[0]?.Listing?.Listing;
        if (msg === "Listing deleted successfully.") {
          toast.success("List deleted successfully!!");
          this.props.navigation.navigate("Listings");
        }
      }
      this.setState({ isLoading: false });
    } else if (apiRequestCallId === this.publishListingApiCallId) {
      if (responseJson.message === "Listing successfully published.") {
        toast.success("List published successfully!");
      }
      this.setState({ isLoading: false });
    }
  }

  async componentDidMount() {
    const id = this.props.navigation.getParam('id');
    this.getListInfo(id);
  }

  componentDidUpdate(prevProps: Props, prevState: S) {
    const id = this.props.navigation.getParam('id');
    if (prevProps.navigation.getParam('id') !== id) {
      this.getListInfo(id);
    }
  }

  scrollToTop = () => {
    if (this.scrollRef.current) {
      this.scrollRef.current.scrollIntoView();
    }
  }

  goBack = () => {
    this.props.navigation.goBack();
  }

  onShowAll = () => {
    this.setState({ isShowAll: true }, () => {
      this.scrollToTop();
    });
  }

  goBackToMainPage = () => {
    this.setState({ isShowAll: false });
  }

  editListing = () => {
    localStorage.setItem("listingId", this.state.id);
    this.props.navigation.navigate("CreateListing");
  }

  handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(`${this.state.data.latitude}, ${this.state.data.longitude}`);
      this.setState({ copied: true });
      setTimeout(() => this.setState({ copied: false }), 2000);
    } catch (err) { }
  }

  getListInfo = async (id: string) => {
    if (!id) return;

    this.setState({ id, loading: true });
    const header = {
      token: localStorage.getItem("token")
    }
    this.getListInfoApiCallId = await apiCall({
      header,
      method: "GET",
      endPoint: `/bx_block_bulk_uploading/listings/${id}`,
    });
  }

  publishListing = async () => {
    this.setState({ isLoading: true });
    const header = {
      token: localStorage.getItem("token")
    }
    this.publishListingApiCallId = await apiCall({
      header,
      method: "GET",
      endPoint: `/bx_block_bulk_uploading/listings/${this.state.id}/publish_listing`,
    });
  }

  deleteListing = async () => {
    this.setState({ isLoading: true });
    const header = {
      token: localStorage.getItem("token")
    }
    this.deleteListingApiCallId = await apiCall({
      header,
      method: "DELETE",
      endPoint: `/bx_block_bulk_uploading/listings/${this.state.id}`,
    });
  }
  // Customizable Area End
}